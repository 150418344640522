<template>
  <div class="flex flex-col gap-y-6">
    <div class="flex flex-col gap-y-3">
      <h3>bg-light-level-0</h3>
      <div class="bg-light-level-0 bg-wrapper">
        <p class="text-light-level-0">text-light-level-0</p>
      </div>
    </div>
    <div class="flex flex-col gap-y-3">
      <h3>bg-light-level-1</h3>
      <div class="bg-light-level-1 bg-wrapper">
        <p class="text-light-level-1">text-light-level-1</p>
      </div>
    </div>
    <div class="flex flex-col gap-y-3">
      <h3>bg-light-level-2</h3>
      <div class="bg-light-level-2 bg-wrapper">
        <p class="text-light-level-2">text-light-level-2</p>
      </div>
    </div>
    <div class="flex flex-col gap-y-3">
      <h3>bg-light-level-3</h3>
      <div class="bg-light-level-3 bg-wrapper">
        <p class="text-light-level-3">text-light-level-3</p>
      </div>
    </div>

    <div class="grid grid-flow-row grid-cols-3 gap-3">
      <div class="flex flex-col gap-y-3">
        <h3>border-light-level-0</h3>
        <div class="border-wrapper border border-light-level-0"></div>
      </div>
      <div class="flex flex-col gap-y-3">
        <h3>border-light-level-1</h3>
        <div class="border-wrapper border border-light-level-1"></div>
      </div>
      <div class="flex flex-col gap-y-3">
        <h3>border-light-level-2</h3>
        <div class="border-wrapper border border-light-level-2"></div>
      </div>
    </div>

    <div class="grid grid-flow-row grid-cols-5 gap-3">
      <div class="flex flex-col gap-y-3">
        <h3>rounded-level-1</h3>
        <div class="border-wrapper border border-light-level-0 rounded-level-1"></div>
      </div>
      <div class="flex flex-col gap-y-3">
        <h3>rounded-level-2</h3>
        <div class="border-wrapper border border-light-level-0 rounded-level-2"></div>
      </div>
      <div class="flex flex-col gap-y-3">
        <h3>rounded-level-3</h3>
        <div class="border-wrapper border border-light-level-0 rounded-level-3"></div>
      </div>
      <div class="flex flex-col gap-y-3">
        <h3>rounded-level-4</h3>
        <div class="border-wrapper border border-light-level-0 rounded-level-4"></div>
      </div>
      <div class="flex flex-col gap-y-3">
        <h3>rounded-level-5</h3>
        <div class="border-wrapper border border-light-level-0 rounded-level-5"></div>
      </div>
    </div>

    <div class="grid grid-flow-row grid-cols-3 gap-3">
      <button class="button-light-level-0">button-light-level-0</button>
      <button class="button-light-level-1">button-light-level-1</button>
      <button class="button-light-level-2">button-light-level-2</button>
    </div>

    <div class="grid grid-flow-row grid-cols-4 gap-3">
      <div class="flex items-center justify-center">
        <Loader class="w-4 h-4"></Loader>
      </div>
      <div class="flex items-center justify-center">
        <Loader class="w-4 h-4 text-light-highlight-level-0"></Loader>
      </div>
      <div class="flex items-center justify-center">
        <Loader class="w-8 h-8"></Loader>
      </div>
      <div class="flex items-center justify-center">
        <Loader class="w-8 h-8 text-light-highlight-level-0"></Loader>
      </div>
    </div>

    <div class="grid grid-flow-row grid-cols-3 gap-3">

      <div class="flex items-center justify-center">
        <div
            class="flex overflow-hidden w-10 pr-6 h-1.5 bg-light-level-0 border border-light-level-0 box-content rounded-full">
          <div class="flex flex-grow animate-slide">
            <div class="w-6 bg-light-level-3 rounded-full"></div>
          </div>
        </div>
      </div>

      <div class="flex items-center justify-center">
        <div
            class="flex overflow-hidden w-10 pr-6 h-1.5 bg-light-level-2 box-content rounded-full">
          <div class="flex flex-grow animate-slide">
            <div class="w-6 bg-light-level-3 rounded-full"></div>
          </div>
        </div>
      </div>

      <!-- <div class="flex items-center justify-center">
        <div
            class="flex overflow-hidden w-14 pr-8 h-1.5 bg-light-level-1 border border-light-level-1 box-content rounded-full">
          <div class="flex flex-grow animate-slide">
            <div class="w-8 bg-light-level-4 rounded-full"></div>
          </div>
        </div>
      </div>

      <div class="flex items-center justify-center">
        <div
            class="flex overflow-hidden w-14 pr-8 h-1.5 bg-light-level-0 border border-light-level-0 box-content rounded-full">
          <div class="flex flex-grow animate-slide">
            <div class="w-8 bg-light-highlight-level-1 rounded-full"></div>
          </div>
        </div>
      </div>-->

      <div class="flex items-center justify-center">
        <div
            class="flex overflow-hidden w-10 pr-6 h-1.5 bg-light-level-2 box-content rounded-full">
          <div class="flex flex-grow animate-slide">
            <div class="w-6 bg-light-highlight-level-2 rounded-full"></div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<style>
.bg-wrapper {
  @apply flex flex-col w-full gap-y-3 p-6
}

.border-wrapper {
  @apply w-full h-32
}
</style>

<script>
import Loader from '@/components/Loader'

export default {
  name: 'Styleguide',
  components: { Loader }
}
</script>
