<template>
  <li class="relative col-span-1">
    <router-link :to="{ name: 'Display detail', params: {id: id}}"
                 class="relative flex w-full h-full transition-all duration-150 ease-linear bg-center bg-cover border outline-none cursor-pointer select-none border-our-gray-210 bg-our-gray-20 rounded-xs group r-our-cyan-40 focus:outline-none">
      <!-- :style="{ backgroundImage: 'url(' + require('@/assets/display-placeholder.webp') + ')' }" -->
      <div
          class="absolute top-0 w-full h-full transition-all duration-150 ease-linear bg-black bg-opacity-0 group-hover:bg-opacity-50 z-10"></div>
      <div
          class="absolute flex inline-flex flex-row items-center px-2 py-1 mt-2 ml-2 gap-x-2 text-sm font-medium bg-white bg-opacity-75 border rounded-xs border-our-gray-190 z-20"
          style="backdrop-filter: blur(2px)">
        <span class="leading-6 text-our-gray-190">{{ name }}</span>
        <span class="px-1 text-xs font-semibold leading-5 text-white rounded-md bg-our-gray-190">id:{{
            customid
          }}</span>
      </div>
      <div class="aspect-[16/9] flex flex-grow">
        <img v-if="img" :src="img" class="object-cover w-full h-full">
        <div v-else class="display-placeholder w-full h-full"></div>
      </div>
    </router-link>
  </li>
</template>

<script>
export default {
  name: 'GroupCard',
  props: {
    name: String,
    id: Number,
    customid: Number,
    img: String
  }
}
</script>

<style scoped>
.display-placeholder {
  @apply bg-repeat bg-light-level-3;
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='%23000000' fill-opacity='0.125' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
  /*background-image: url("data:image/svg+xml,%3Csvg width='64' height='64' viewBox='0 0 64 64' fill='%23000000' fill-opacity='0.25' fill-rule='evenodd' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 16c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm0-2c3.314 0 6-2.686 6-6s-2.686-6-6-6-6 2.686-6 6 2.686 6 6 6zm33.414-6l5.95-5.95L45.95.636 40 6.586 34.05.636 32.636 2.05 38.586 8l-5.95 5.95 1.414 1.414L40 9.414l5.95 5.95 1.414-1.414L41.414 8zM40 48c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm0-2c3.314 0 6-2.686 6-6s-2.686-6-6-6-6 2.686-6 6 2.686 6 6 6zM9.414 40l5.95-5.95-1.414-1.414L8 38.586l-5.95-5.95L.636 34.05 6.586 40l-5.95 5.95 1.414 1.414L8 41.414l5.95 5.95 1.414-1.414L9.414 40z' /%3E%3C/svg%3E");*/
}
</style>
