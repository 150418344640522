<template>
  <div>
    <div v-if="groupsList.length > 0"
         class="flex flex-row flex-wrap gap-3 border border-light-level-1 p-level-2 rounded-level-2">
      <button v-for="group in groupsList" :key="group.id" v-on:click="selectGroup(group.id)"
              class="button-light-select-level-1 rounded-level-3"
              :class="group.id === selectedGroup.id ? '!bg-light-highlight-level-1 !border-light-highlight-level-1 hover:!border-light-highlight-level-1 text-white cursor-default' : ''">
        <!-- :class="['button text-sm rounded-md leading-5 mt-2 mr-2 '] + [group.id === selectedGroup.id ? 'button-dark-gray' : 'button-light-gray']" -->
        {{ group.name }}
      </button>
    </div>
    <div v-if="groupsList.length > 0">
      <transition name="fade" mode="out-in">
        <div v-if="Object.keys(selectedGroup).length > 0" :key="selectedGroup.id" class="relative p-level-2 mt-7" v-on:mouseleave="groupHover = false">
          <router-link :to="{ name: 'Group detail', params: {id: selectedGroup.id}}"
                       class="absolute inset-0 transition-all duration-150 ease-linear border rounded-xl bg-light-level-2 border-light-level-0 hover:cursor-pointer"
                       :class="{ 'border-light-highlight-level-1' : groupHover }" v-on:mouseenter="groupHover = true"></router-link>
          <div class="relative z-0 tooltip-wrapper inline-flex flex-row items-center text-our-gray-190">
            <router-link :to="{ name: 'Group detail', params: {id: selectedGroup.id}}"
                         class="button px-3 py-1 rounded-full border"
                         :class="groupHover ? 'text-white bg-light-highlight-level-2 border-light-highlight-level-2' : 'text-light-level-2 border-light-level-2 bg-light-level-2'"
                         v-on:mouseenter="groupHover = true">
              {{ selectedGroup.name }}
            </router-link>
            <Tooltip :text="t('groups.seeGroupDetails')"
                     color="our-gray-30"
                     background="our-gray-170" arrow-background="our-gray-170" position="right"
                     class="p-1 text-sm font-medium whitespace-nowrap"></Tooltip>
          </div>

          <div class="mt-7" v-on:mouseenter="groupHover = false">
            <div v-if="selectedGroup.displays.length === 0"
                 class="relative z-10 gap-y-4 banner--data-information bg-our-gray-10">
              <div class="banner--data-information-inner">
                {{ t('display.form.noDisplaysAssigned') }}
              </div>
            </div>
            <ul v-else class="relative z-10 grid grid-cols-3 gap-5 xl:grid-cols-4">
              <GroupCard v-for="display in selectedGroup.displays"
                         :img="display.screen ? $axios.defaults.baseURL + (display.screen.formats.small.url).substring(1) : ''"
                         :name="display.name" :id="display.id" :customid="display.customId" :key="display.id"
                         class="relative z-10"></GroupCard>
            </ul>
          </div>

          <!-- <div class="relative z-10 inline-flex items-start mt-7">
             <div class="flex flex-row items-center py-1 pl-3 pr-1 gap-x-4 bg-white rounded-sm">
           <span class="flex w-2 h-2">
             <span class="inline-flex w-2 h-2 rounded-full bg-our-cyan-50"></span>
           </span>
               &lt;!&ndash; TODO auto change time from initialization every 5 secs, not every second &ndash;&gt;
               <span class="text-sm text-our-gray-140">Updated 5s ago</span>
               <button type="button" class="flex items-center justify-center text-sm px-1.5 py-1 transition-all duration-150 ease-linear border rounded-xs outline-none bg-our-gray-40 border-our-gray-40 focus:border-our-gray-180 focus:outline-none focus:bg-our-gray-180 focus:text-whiteborder-light-highlight-level-1 cursor-pointer text-our-gray-180 hover:text-our-gray-180 hover:border-our-gray-180 ">
                 Refresh
               </button>
             </div>
           </div>-->

        </div>
        <div
            class="flex flex-row items-end pt-5 pb-6 gap-x-3 px-7 bg-our-gray-50 border-our-gray-50 mt-7 rounded-xl text-our-gray-160"
            key="placeholder" v-else>
          <svg width="32" height="32" viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8 26V7.8281L10.5859 10.4141L12 9L7 4L2 9L3.4141 10.4141L6 7.8281V26C6.00061 26.5302 6.21152 27.0386 6.58646 27.4135C6.9614 27.7885 7.46975 27.9994 8 28H28V26H8Z"/>
          </svg>
          <span>{{ t('groups.helpers.selectGroup') }}</span>
        </div>
      </transition>
    </div>
    <div v-else class="w-full mx-auto">
      <p>{{ t('groups.helpers.noGroups') }}</p>
    </div>
  </div>
</template>

<script>
import GroupCard from '@/components/GroupCard'
import { useI18n } from 'vue-i18n'

export default {
  name: 'Groups',
  components: {
    GroupCard
  },
  setup() {
    return useI18n()
  },
  data() {
    return {
      groupsList: [],
      selectedGroup: {},
      isLoaded: false,
      groupHover: false
    }
  },
  watch: {
    '$store.state.Groups.groups': {
      immediate: true,
      deep: true,
      handler(data) {
        this.groupsList = this.$helpers.purify(data)
      }
    }
  },
  methods: {
    selectGroup(id) {
      this.$router.replace({ query: { 'selectedGroup': id } })
      this.selectedGroup = Object.assign({}, this.groupsList.filter(group => group.id == id)[0])
    }
  },
  mounted() {
    this.$store.dispatch('Groups/fetchAllGroups').then(() => {
      this.isLoaded = true
      if (this.$route.query.selectedGroup) {
        this.selectGroup(this.$route.query.selectedGroup)
      }
    })
  }
}
</script>

<style scoped>

</style>
