<template>
  <div :class="`text-${color} bg-${background} border-${border}` + ' ' + [position === 'bottom' ? 'tooltip-modal-bottom' : 'tooltip-modal-top'] + ' '"
       class="text-sm font-semibold text-center whitespace-nowrap rounded-sm border" style="z-index: 15">
    <slot></slot>
    <span :class="position === 'bottom' ? 'arrow-bottom' : 'arrow-top'"></span>
  </div>
</template>

<script>
export default {
  name: "TooltipModal",
  props: {
    color: String,
    background: String,
    border: String,
    position: String,
  }
}
</script>

<style scoped>

</style>
