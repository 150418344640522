import { createI18n } from 'vue-i18n';

import en from './localization/en.json'
import cs from './localization/cs.json'

const languages = {
	en: en,
	cs: cs,
}

const messages = Object.assign(languages)

const i18n = new createI18n({
	legacy: false,
	locale: localStorage.language || process.env.VUE_APP_I18N_LOCALE || 'en',
	fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
	messages,
	warnHtmlInMessage: 'warn',
	warnHtmlMessage: false
});

export default i18n;
