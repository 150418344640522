<template>
  <div class="flex flex-col gap-y-5">

    <div class="grid grid-cols-2 gap-5">
      <div class="gap-y-5">
        <div class="relative">

          <transition name="fade" mode="out-in">
            <template v-if="dataLoaded">
              <transition name="fade" mode="out-in">
                <div class="aspect-[16/9]" v-if="playableData.type === 'video'">
                  <video v-if="playableData.media_1080p || playableData.p1080" id="player" playsinline controls
                         :poster="$axios.defaults.baseURL + getPlayableThumbnail(playableData)">
                    <source
                        :src="$axios.defaults.baseURL + (playableData.media_1080p ? playableData.media_1080p?.url.substring(1) : playableData.p1080?.substring(1))"
                        type="video/mp4"/>
                  </video>
                  <!-- old version - show only one screenshot video
                  <img alt="Playable screenshot" v-if="playableData.playablescreenshots.length" ref="image"
                    :src="$axios.defaults.baseURL + getPlayableThumbnail(playableData)"
                    class="bg-our-gray-50 w-auto h-full mx-auto">-->
                  <div v-else class="w-full h-full bg-our-gray-50"></div>
                </div>
                <img v-else-if="playableData.type === 'image'" alt="Playable screenshot" ref="image"
                     :src="$axios.defaults.baseURL + getPlayableThumbnail(playableData)"
                     class="bg-our-gray-50 w-auto h-full mx-auto">
                <img v-else-if="playableData.type === 'web'" alt="Playable screenshot" ref="image"
                     :src="$axios.defaults.baseURL + getPlayableThumbnail(playableData)"
                     class="bg-our-gray-50 w-auto h-full mx-auto">
              </transition>
            </template>
            <template v-else>
              <div class="w-full aspect-[16/9] bg-our-gray-60 rounded-md">
                <Loader class="w-4 h-4 mx-auto realtive top-32"/>
              </div>
            </template>
          </transition>

          <a :href="getOriginalLink(playableData)" target="_blank"
             class="absolute top-0 right-0 z-20 flex inline-flex flex-row items-center p-1 mt-2 mr-2 gap-x-2 text-sm font-medium bg-white bg-opacity-75 border rounded-sm cursor-pointer text-our-gray-190 border-our-gray-190"
             style="backdrop-filter: blur(2px)">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
              <path
                  d="M15.25 21h-8.5a.75.75 0 01-.102-1.493l.102-.007H8v-1.496H4.25a2.25 2.25 0 01-2.245-2.095L2 15.755V8.25a2.25 2.25 0 012.096-2.245L4.25 6h6.5a.75.75 0 01.102 1.493l-.102.007h-6.5a.75.75 0 00-.743.648L3.5 8.25v7.505c0 .38.282.693.648.743l.102.007L8 16.504V16.5h5.995v.004h3.75a.75.75 0 00.743-.648l.007-.101v-.504a.75.75 0 011.493-.102l.007.102v.504a2.25 2.25 0 01-2.096 2.244l-.154.006-3.75-.001V19.5h1.255a.75.75 0 01.743.648l.007.102a.75.75 0 01-.648.743L15.25 21h-8.5 8.5zm-2.755-2.996H9.5V19.5h2.995v-1.496zM14.002 4l7.052.001.12.014.116.028.107.039.104.052.116.079.09.08.097.112.071.111.031.06.034.082.024.076.025.117.01.09L22 5v7.002a1 1 0 01-1.993.117L20 12.002V7.414l-6.793 6.792a1 1 0 01-1.32.083l-.094-.084a1 1 0 01-.083-1.32l.083-.094L18.584 6h-4.582a1 1 0 01-.993-.883L13.002 5a1 1 0 011-1z"/>
            </svg>
          </a>

          <div
              class=" select-none absolute top-0 left-0 z-20 flex inline-flex flex-row items-center px-2 py-1 mt-2 ml-2 gap-x-2 text-sm font-medium bg-white bg-opacity-75 border rounded-sm text-our-gray-190 border-our-gray-190"
              style="backdrop-filter: blur(2px)">
            <div class="border-2 border-white rounded-full bg-current" style="width:10px;height:10px;"></div>
            <span class="ml-2 text-sm font-medium tracking-wide">{{ playableData.type }}</span></div>
        </div>
        <div class="gap-y-5 p-5 border border-light-level-1 rounded-level-3" v-if="playableData.original && playableData.type === 'video'">
          <legend>{{ t('playables.form.file.info') }}</legend>

          <template v-if="dataLoaded">

            <div class="flex flex-row items-center gap-x-4">
              <label for="name" class="m-0">
                {{ t('playables.form.file.original') }}
              </label>
              <a :href="$axios.defaults.baseURL+playableData.original?.url.substring(1)"
                 class="text-sm hover:underline" target="_blank">
                <div class="w-full">
                  <span class="mr-2">{{ playableData.original.name }}</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24"
                       class="inline">
                    <path
                        d="M15.25 21h-8.5a.75.75 0 01-.102-1.493l.102-.007H8v-1.496H4.25a2.25 2.25 0 01-2.245-2.095L2 15.755V8.25a2.25 2.25 0 012.096-2.245L4.25 6h6.5a.75.75 0 01.102 1.493l-.102.007h-6.5a.75.75 0 00-.743.648L3.5 8.25v7.505c0 .38.282.693.648.743l.102.007L8 16.504V16.5h5.995v.004h3.75a.75.75 0 00.743-.648l.007-.101v-.504a.75.75 0 011.493-.102l.007.102v.504a2.25 2.25 0 01-2.096 2.244l-.154.006-3.75-.001V19.5h1.255a.75.75 0 01.743.648l.007.102a.75.75 0 01-.648.743L15.25 21h-8.5 8.5zm-2.755-2.996H9.5V19.5h2.995v-1.496zM14.002 4l7.052.001.12.014.116.028.107.039.104.052.116.079.09.08.097.112.071.111.031.06.034.082.024.076.025.117.01.09L22 5v7.002a1 1 0 01-1.993.117L20 12.002V7.414l-6.793 6.792a1 1 0 01-1.32.083l-.094-.084a1 1 0 01-.083-1.32l.083-.094L18.584 6h-4.582a1 1 0 01-.993-.883L13.002 5a1 1 0 011-1z"/>
                  </svg>
                </div>
              </a>
            </div>
            <!--
                      <div class="flex flex-row items-center gap-x-4">
                        <label for="name" class="m-0">
                          {{ t('form.file.extension') }}
                        </label>
                        <span v-text="playableData.original.ext"></span>
                      </div>
            -->
            <div class="flex flex-row items-center gap-x-4">
              <label for="name" class="m-0">
                {{ t('playables.form.file.size') }}
              </label>
              <span v-text='+(Math.round((parseInt(playableData.original.size) / 1024) + "e+2") + "e-2") + "MB"'></span>
            </div>

            <template v-if="playableData.type === 'video' && playableData.duration === null">
              <span>{{ t('playables.form.fileStillConverted') }}</span>
            </template>
            <template v-else>
              <div class="flex flex-row items-center gap-x-4">
                <label for="name" class="m-0">
                  {{ t('playables.form.file.duration') }}
                </label>
                <span v-text="playableData.duration+ ' ms'"></span>
              </div>

              <div class="flex flex-row items-center gap-x-4">
                <label for="name" class="m-0">
                  {{ t('playables.form.file.1080p') }}
                </label>
                <a v-if="playableData.p1080 || playableData.media_1080p"
                   :href="playableData.media_1080p ? $axios.defaults.baseURL+playableData.media_1080p?.url.substring(1) : $axios.defaults.baseURL+'uploads/'+playableData.p1080"
                   target="_blank">
                  <span v-text="'Yes'" class="mr-2"></span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24"
                       class="inline">
                    <path
                        d="M15.25 21h-8.5a.75.75 0 01-.102-1.493l.102-.007H8v-1.496H4.25a2.25 2.25 0 01-2.245-2.095L2 15.755V8.25a2.25 2.25 0 012.096-2.245L4.25 6h6.5a.75.75 0 01.102 1.493l-.102.007h-6.5a.75.75 0 00-.743.648L3.5 8.25v7.505c0 .38.282.693.648.743l.102.007L8 16.504V16.5h5.995v.004h3.75a.75.75 0 00.743-.648l.007-.101v-.504a.75.75 0 011.493-.102l.007.102v.504a2.25 2.25 0 01-2.096 2.244l-.154.006-3.75-.001V19.5h1.255a.75.75 0 01.743.648l.007.102a.75.75 0 01-.648.743L15.25 21h-8.5 8.5zm-2.755-2.996H9.5V19.5h2.995v-1.496zM14.002 4l7.052.001.12.014.116.028.107.039.104.052.116.079.09.08.097.112.071.111.031.06.034.082.024.076.025.117.01.09L22 5v7.002a1 1 0 01-1.993.117L20 12.002V7.414l-6.793 6.792a1 1 0 01-1.32.083l-.094-.084a1 1 0 01-.083-1.32l.083-.094L18.584 6h-4.582a1 1 0 01-.993-.883L13.002 5a1 1 0 011-1z"/>
                  </svg>
                </a>
                <!--<input id="name" :value="playableData.p1080 ? 'Yes' : 'no'" required type="text" class="w-auto form-input" :class="{ 'cursor-not-allowed' : true }" :disabled="true">-->
              </div>

              <div class="flex flex-row items-center gap-x-4">
                <label for="name" class="m-0">
                  {{ t('playables.form.file.2160p') }}
                </label>
                <a v-if="playableData.p2160 || playableData.media_2160p"
                   :href="playableData.media_2160p ? $axios.defaults.baseURL+playableData.media_2160p?.url.substring(1) : $axios.defaults.baseURL+'uploads/'+playableData.p2160"
                   target="_blank">
                  <span v-text="'Yes'" class="mr-2"></span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24"
                       class="inline">
                    <path
                        d="M15.25 21h-8.5a.75.75 0 01-.102-1.493l.102-.007H8v-1.496H4.25a2.25 2.25 0 01-2.245-2.095L2 15.755V8.25a2.25 2.25 0 012.096-2.245L4.25 6h6.5a.75.75 0 01.102 1.493l-.102.007h-6.5a.75.75 0 00-.743.648L3.5 8.25v7.505c0 .38.282.693.648.743l.102.007L8 16.504V16.5h5.995v.004h3.75a.75.75 0 00.743-.648l.007-.101v-.504a.75.75 0 011.493-.102l.007.102v.504a2.25 2.25 0 01-2.096 2.244l-.154.006-3.75-.001V19.5h1.255a.75.75 0 01.743.648l.007.102a.75.75 0 01-.648.743L15.25 21h-8.5 8.5zm-2.755-2.996H9.5V19.5h2.995v-1.496zM14.002 4l7.052.001.12.014.116.028.107.039.104.052.116.079.09.08.097.112.071.111.031.06.034.082.024.076.025.117.01.09L22 5v7.002a1 1 0 01-1.993.117L20 12.002V7.414l-6.793 6.792a1 1 0 01-1.32.083l-.094-.084a1 1 0 01-.083-1.32l.083-.094L18.584 6h-4.582a1 1 0 01-.993-.883L13.002 5a1 1 0 011-1z"/>
                  </svg>
                </a>
              </div>
            </template>


          </template>
          <template v-else>
            <div class="w-full aspect-[16/9] bg-our-gray-60 rounded-md">
              <Loader class="w-4 h-4 mx-auto realtive top-32"/>
            </div>
          </template>

        </div>
      </div>
      <div class="flex flex-col">
        <form ref="form" class="gap-y-5 p-5 border border-light-level-1 rounded-level-3">
          <div>
            <label for="name">
              {{ t('playables.form.name') }}
            </label>
            <div class="mt-1.5">
              <input v-if="dataLoaded" id="name" v-model="playableData.name" required type="text" class="input-light-level-1">
              <input v-else disabled="disabled" type="text" class="input-light-level-1">
            </div>
          </div>
          <div class="mt-4">
            <label for="name">
              {{ t('playables.form.type') }}
            </label>
            <div class="mt-1.5">
              <input id="type" v-model="playableData.type" required type="text" class="input-light-level-1"
                     :class="{ 'cursor-not-allowed' : true }" :disabled="true">
            </div>
          </div>
          <div v-if="playableData.type === 'web' || playableData.type === 'hls'" class="mt-4">
            <label for="name">
              {{ t('playables.form.url') }}
            </label>
            <div class="mt-1.5">
              <input id="url" v-model="playableData.url" required type="text" class="input-light-level-1">
            </div>
          </div>
          <div class="mt-4">
            <label for="description">
              {{ t('playables.form.description') }}
            </label>
            <div class="mt-1.5">
              <textarea id="description" v-model="playableData.description"
                        :placeholder="t('playables.form.descriptionPlaceholder')" rows="4" class="input-light-level-1">
              </textarea>
            </div>
          </div>

          <div v-if="loadTreeSelect" class="mt-4">
            <label for="folder">
              {{ t('playables.form.folder') }}
            </label>
            <div class="mt-2 max-w-sm">
              <Treeselect
                  v-model="playableData.folder"
                  :multiple="false"
                  :searchable="true"
                  :options="folders"
                  class="border"

              />
            </div>
          </div>
          <Loader v-else class="w-4 h-4 mt-4"></Loader>

          <!--<div>
            <label>{{ t('form.groups') }}</label>
            <Multiselect :placeholder="t('form.multiselectPlaceholder')" :items="playableGroupsAll" v-model:selected-items="playableGroupsSelected" ref="multiselectGroups" classes="h-10" class="mt-1.5"></Multiselect>
          </div>-->

        </form>
      </div>
    </div>

    <div class="flex flex-col">
      <div class="gap-y-5 p-5 border border-light-level-1 rounded-level-3">
        <legend>{{ t('playables.playableCalendar') }}</legend>

        <Table
            :tableHeads="tableHeader"
            :tableBody="calendarPlayablesList"
            :usePagination="true"
            :pagination="tableOptions"
        > <!--detailComponent="Display detail"-->
          <template #display="data">
            {{ data.data.displayname }}
          </template>
          <template #playlist="data">
            <router-link class="border-b border-our-cyan-50 text-our-cyan-60"
                         :to="{ name: 'Playlist detail', params: {id: data.data.playlist}}">

              {{ data.data.playlist }}
            </router-link>
          </template>
          <template #from="data">
            {{ $moment(data.data.from).format('HH:mm:ss.SSS') }}
          </template>
          <template #to="data">
            {{ $moment(data.data.to).format('HH:mm:ss.SSS') }}
          </template>
          <!--<template #resolution="data">
            <svg v-if="data.data.resolution === 'p2160'" class="-my-1 w-7 h-7" fill="currentColor" width="32" height="32" viewBox="0 0 32 32">
              <path d="M12 11.03L12 15.03 10 15.03 10 11.03 8 11.03 8 17.03 12 17.03 12 21.03 14 21.03 14 11.03 12 11.03zM24.19 11.03L22 11.03 19 15.42 19 11.03 17 11.03 17 21.03 19 21.03 19 18.3 19.91 16.97 22 21.03 24.19 21.03 21.2 15.41 24.19 11.03z"></path>
              <path d="M28,26H4a2,2,0,0,1-2-2V8A2,2,0,0,1,4,6H28a2,2,0,0,1,2,2V24A2,2,0,0,1,28,26ZM4,8V24H28V8Z"></path>
            </svg>
            <svg v-else-if="data.data.resolution === 'p1080'" class="-my-1 w-7 h-7" fill="currentColor" width="32" height="32" viewBox="0 0 32 32">
              <path d="M28,6H4A2,2,0,0,0,2,8V24a2,2,0,0,0,2,2H28a2,2,0,0,0,2-2V8A2,2,0,0,0,28,6ZM4,24V8H28V24Z"></path>
              <path d="M22 11H18V21h4a3 3 0 003-3V14A3 3 0 0022 11zm1 7a1 1 0 01-1 1H20V13h2a1 1 0 011 1zM13 11L13 15 10 15 10 11 8 11 8 21 10 21 10 17 13 17 13 21 15 21 15 11 13 11z"></path>
            </svg>
            <span v-else>
                      Undefined
                    </span>
          </template>
          <template #customId="data">
            <span><strong>{{ data.data.customId }}</strong> ({{ data.data.id }})</span>
          </template>
          <template #lastActive="data">
                    <span>{{
                        data.data.lastactive ? $moment(data.data.lastactive).format('DD/MM/YYYY HH:mm') : '&nbsp;'
                          }}</span>
          </template>
          <template #status="data">
            <span>{{ data.data.displaystatus ? data.data.displaystatus : '&nbsp;' }}</span>
          </template>-->
          <!--<template #description="data">
            <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">
                      {{ data.data.description ? data.data.description : '&nbsp;' }}
                    </span>
            </span>
          </template>-->
        </Table>

      </div>
    </div>

  </div>
</template>

<script>
import Multiselect from "@/components/Multiselect"
import Table from "@/components/Table/Table"
import Loader from "@/components/Loader"
import {useI18n} from "vue-i18n"
import store from "@/store";

function initialData() {
  return {
    dataLoaded: false,
    e404: false,
    folders: null,
    playableData: {
      id: null,
      folder: null,
      name: null,
      description: null,
      original: {
        ext: '',
        size: null,
      },
      playablescreenshots: [
        {
          id: 0,
          image: {
            formats: {
              small: {
                url: ''
              }
            }
          }
        }
      ]
    },
    calendarPlayablesList: [],
    tableOptions: {
      perPage: 25,
      total: 0
    },
    loadTreeSelect: false,
  }
}

export default {
  name: "PlayableDetail",
  setup() {
    return useI18n()
  },
  components: {
    Multiselect,
    Loader,
    Table
  },
  data() {
    return {
      ...initialData(),
      tableHeader: [
        {
          title: this.t('playables.tableHeader.display'),
          col: 'display'
        },
        {
          title: this.t('playables.tableHeader.playlist'),
          col: 'playlist'
        },
        {
          title: this.t('playables.tableHeader.from'),
          col: 'from'
        },
        {
          title: this.t('playables.tableHeader.to'),
          col: 'to'
        }
      ],
    }
  },
  methods: {
    getPlayableThumbnail(playable) {
      if (playable.type === 'video') {
        return playable.media_thumb?.url.substring(1)
      } else if (playable.type === 'image') {
        if (playable.original.formats != null) {
          if (typeof playable.original.formats.large !== 'undefined') {
            return (playable.original.formats.large.url).substring(1);
          }
          if (typeof playable.original.formats.medium !== 'undefined') {
            return (playable.original.formats.medium.url).substring(1);
          }
          if (typeof playable.original.formats.small !== 'undefined') {
            return (playable.original.formats.small.url).substring(1);
          }
          if (typeof playable.original.formats.thumbnail !== 'undefined') {
            return (playable.original.formats.thumbnail.url).substring(1);
          }
        } else {
          return (playable.original.url).substring(1);
        }
      } else if (playable.type === 'web') {
        return "screenshots/" + playable.id + ".png";
      } else {
        return null;
      }


    },
    getOriginalLink(playable) {
      if (playable.type === "web") {
        return playable.url;
      } else if (playable.type === "video") {
        return this.$axios.defaults.baseURL + (playable.media_1080p ? playable?.media_1080p?.url.substring(1) : playable?.original?.url.substring(1));
      } else if (playable.type === "image") {
        return this.$axios.defaults.baseURL + playable?.original?.url.substring(1);
      } else {
        return "";
      }
    }
  },
  watch: {
    '$store.state.Playables.eventsData': {
      immediate: true,
      handler(listOrig) {
        this.calendarPlayablesList = JSON.parse(JSON.stringify(listOrig))
        this.tableOptions.total = this.calendarPlayablesList.length;
      }
    },
    '$store.state.Playables.playableData': {
      immediate: false,
      deep: true,
      handler(data) {

        this.loadTreeSelect = false;

        if (!this.dataLoaded) {
          this.dataLoaded = true
        }

        if (!this.$helpers.isEqual(data, this.playableData) && parseInt(this.$route.params.id) === data.id) {

          if (Object.keys(data).length > 0) {

            this.playableData = this.$helpers.purify(data)
            this.playableData.folder ? this.playableData.folder = this.playableData.folder.id : this.playableData.folder = null;

            /*this.$store.dispatch('Playables/fetchAllPlayables').then(() => {

              this.playableGroupsAll = this.$store.state.Groups.groups.map(key => {
                if (data.playablegroups.some(group => group.id === key.id)) {
                  return { id: key.id, name: key.name, selected: true }
                }
                else {
                  return { id: key.id, name: key.name, selected: false }
                }
              })
            })*/
            document.title = data.name + ' | ' + document.title.split('|')[1]
            this.$store.dispatch('setDetailName', data.name)
            setTimeout(() => {
              this.loadTreeSelect = true;
            }, 500)
          }
        }
      }
    },
    playableData: {
      immediate: false,
      deep: true,
      handler(data) {

        if (this.dataLoaded) {

          if (this.$refs.form.reportValidity()) {

            let sendData = this.$helpers.purify(data)
            sendData.folder = this.playableData.folder;

            /*sendData.playablegroups = this.playableGroupsSelected*/

            let filteredData = this.$helpers.objectFalseyAndEmptyFilter(sendData)
            this.$emit('save-changes', {...filteredData, _type: 'playable', valid: true})
          } else if (!this.$refs.form.reportValidity()) {
            this.$emit('save-changes', {valid: false})
          }
        }
      }
    },
    /*playableGroupsSelected: {
      immediate: false,
      deep: true,
      handler(data) {

        if (!this.dataLoaded) {
          this.dataLoaded = true
        }
        else {
          this.playableData.playablegroups = data
        }

      }
    }*/
  },
  created() {

    this.$store.dispatch('Playables/fetchPlayable', Number.parseInt(this.$route.params.id)).then((res) => {
      this.$store.dispatch('setDetailName', this.$store.state.Playables.playableData.name);
    }).catch(e => {
      console.error(e);
      this.e404 = true;
      store.dispatch('Notifications/send', {
        variant: 'error',
        message1: this.t('playables.notification.playableNotFound'),
        message2: this.t('playables.notification.weDidntFindPlayableAtAddress'),
        time: new Date().toISOString(),
        duration: 5000,
        overlay: false
      })
      setTimeout(() => {
        setTimeout(() => {
          this.$router.push('/playables')
        }, 1500)
        this.$store.dispatch('Notifications/send', {
          variant: 'info',
          message1: this.t('global.notification.redirectingInMoment'),
          message2: this.t('global.notification.thisPageRedirectedTo') +
                    '<i>' + this.t('global.menu.playables') + '</i>',
          time: new Date().toISOString(),
          duration: 2000,
          overlay: true
        })
      }, 500)
    })

    this.$store.dispatch('Playables/fetchAllPlayableFolder').then((response) => {
      let folders = [], pushedData, children = [], children1 = [], children2 = [], children3 = [];

      // (snad) dočasná prasarna, deadliny jsou neúprosné
      for (const r_folder of response.data) { // for level 0
        children = [];

        if (r_folder.parentid === null) {

          for (const r_folder1 of response.data) { // for level 1
            if (r_folder1.parentid?.id === r_folder.id) {
              children1 = [];

              for (const r_folder2 of response.data) { // for level 2
                if (r_folder2.parentid?.id === r_folder1.id) {
                  children2 = [];

                  for (const r_folder3 of response.data) { // for level 3
                    if (r_folder3.parentid?.id === r_folder2.id) {
                      children3 = [];

                      for (const r_folder4 of response.data) { // for level 4
                        if (r_folder4.parentid?.id === r_folder3.id) {
                          children3.push({id: r_folder4.id, label: r_folder4.name});
                        }
                      }

                      if (typeof children3 !== 'undefined' && children3.length > 0) {
                        pushedData = {id: r_folder3.id, label: r_folder3.name, children: children3};
                      } else {
                        pushedData = {id: r_folder3.id, label: r_folder3.name}
                      }

                      children2.push(pushedData);
                    }
                  }

                  if (typeof children2 !== 'undefined' && children2.length > 0) {
                    pushedData = {id: r_folder2.id, label: r_folder2.name, children: children2};
                  } else {
                    pushedData = {id: r_folder2.id, label: r_folder2.name}
                  }

                  children1.push(pushedData);
                }
              }

              if (typeof children1 !== 'undefined' && children1.length > 0) {
                pushedData = {id: r_folder1.id, label: r_folder1.name, children: children1};
              } else {
                pushedData = {id: r_folder1.id, label: r_folder1.name}
              }

              children.push(pushedData);
            }
          }

          if (typeof children !== 'undefined' && children.length > 0) {
            pushedData = {id: r_folder.id, label: r_folder.name, children: children};
          } else {
            pushedData = {id: r_folder.id, label: r_folder.name}
          }

          folders.push(pushedData);
        }

      }
      this.folders = folders;
    })

    this.$store.dispatch('Playables/fetchEventsForOnePlayableUntil', {
      'playableID': Number.parseInt(this.$route.params.id),
      'untilDate': this.$moment(Date.now()).add(2, 'hours').toISOString()
    })
  },
  unmounted() {
    Object.assign(this.$data, initialData())
  }
}
</script>

<style scoped>

</style>
