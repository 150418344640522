import axios from 'axios'
const END_POINT_AUTH_SERVER = process.env.VUE_APP_AUTH_SERVER
const END_POINT = '/servers';

const getServerName = (data) => {
	return axios({
		baseURL: END_POINT_AUTH_SERVER + END_POINT + '-name/get',
		data: data,
		method: 'POST',
        withCredentials: false,
        headers: {
            'Authorization': localStorage.getItem('strapi-auth-token')
        }
	})
}

const updateServerName = (data) => {
	return axios({
		baseURL: END_POINT_AUTH_SERVER + END_POINT + '-name/',
		data: data,
		method: 'PUT',
		withCredentials: false,
        headers: {
            'Authorization': localStorage.getItem('strapi-auth-token')
        }
	})
}

const addNewServerUser = (data) => {
	return axios({
		baseURL: END_POINT_AUTH_SERVER + '/users-permissions/add-new-server',
		data: data,
		method: 'POST',
		withCredentials: false,
        headers: {
            'Authorization': localStorage.getItem('strapi-auth-token')
        }
	})
}

export default {
    getServerName,
	updateServerName,
	addNewServerUser,
}
