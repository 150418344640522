<template>
  <div class="flex flex-col gap-y-5">

    <div class="flex flex-col gap-y-6">
      <div class="flex flex-row items-center justify-between gap-x-3">

        <input type="search" v-model="fulltextSearch" @input="sendFilter"
               :placeholder="t('playlists.searchPlaceholder')"
               class="w-full max-w-sm rounded-level-3">

        <div class="flex flex-grow flex-row items-center justify-end gap-x-3">
          <select v-model="sort" @input="sendFilter" class="w-full max-w-sm rounded-level-3">
            <option value="name:ASC">{{ t('playlists.sortingFilter.nameAsc') }}</option>
            <option value="name:DESC">{{ t('playlists.sortingFilter.nameDesc') }}</option>
            <option value="created_at:ASC">{{ t('playlists.sortingFilter.createdAtAsc') }}</option>
            <option value="created_at:DESC">{{ t('playlists.sortingFilter.createdAtDesc') }}</option>
          </select>
        </div>
      </div>
    </div>

    <div>
      <Table
          :tableHeads="tableHeads"
          :tableBody="playlistList"
          detailComponent="Playlist detail"
          :usePagination="true"
          :pagination="tableOptions"
      >
        <template #enabled="data">
          <svg v-if="data.data.enabled == true" class="-my-1 w-7 h-7" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="32" height="32" viewBox="0 0 32 32">
            <path d="M14 21.414L9 16.413 10.413 15 14 18.586 21.585 11 23 12.415 14 21.414z"></path>
            <path d="M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Zm0,26A12,12,0,1,1,28,16,12,12,0,0,1,16,28Z"></path>
          </svg>
          <!--<svg v-if="data.data.enabled == false" class="-my-1 w-7 h-7" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="32" height="32" viewBox="0 0 32 32">
            <path d="M14.9 7.2H17.1V24.799H14.9z" transform="rotate(-45 16 16)"></path>
            <path d="M16,2A13.9138,13.9138,0,0,0,2,16,13.9138,13.9138,0,0,0,16,30,13.9138,13.9138,0,0,0,30,16,13.9138,13.9138,0,0,0,16,2Zm0,26A12,12,0,1,1,28,16,12.0353,12.0353,0,0,1,16,28Z"></path>
          </svg>-->
          <svg v-if="data.data.enabled == false" class="-my-1 w-7 h-7" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="32" height="32" viewBox="0 0 32 32">
            <path d="M2,16H2A14,14,0,1,0,16,2,14,14,0,0,0,2,16Zm23.15,7.75L8.25,6.85a12,12,0,0,1,16.9,16.9ZM8.24,25.16A12,12,0,0,1,6.84,8.27L23.73,25.16a12,12,0,0,1-15.49,0Z"></path>
          </svg>
        </template>
        <template #created_at="data">
          {{ $moment(data.data.created_at).format('DD/MM/YYYY HH:mm') }}
        </template>
        <template #totalTimeMs="data">
          {{ $moment.duration(data.data.totalTimeMs, 'ms').format('h [hours] m [minutes] s [seconds] S[ ms]', {trim: true}) }}
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import Table from '@/components/Table/Table'
import { useI18n } from "vue-i18n";

export default {
  name: "Playlists",
  components: {
    Table
  },
  setup() {
    return useI18n()
  },
  data() {
    return {
      page: this.$route.query.page || 1,
      sort: this.$route.query.sort || 'name:ASC',
      fulltextSearch: this.$route.query.search || null,
      tableHeads: [
        {
          title: this.t('playlists.tableHeader.id'),
          col: 'id'
        },
        {
          title: this.t('playlists.tableHeader.name'),
          col: 'name'
        },
        {
          title: this.t('playlists.tableHeader.enabled'),
          col: 'enabled'
        },
        {
          title: this.t('playlists.tableHeader.created_at'),
          col: 'created_at'
        },
        {
          title: this.t('playlists.tableHeader.totalDuration'),
          col: 'totalTimeMs'
        }
      ],
      tableOptions: {
        perPage: 25,
        total: 0
      },
      playlistList: [],
      isLoaded: false
    }
  },
  watch: {
    '$store.state.Playlists.playlists': {
      immediate: true,
      handler(listOrig) {

        let totalTimeMs
        this.playlistList = JSON.parse(JSON.stringify(listOrig))
        this.tableOptions.total = this.playlistList.length

        this.playlistList.forEach(playlistItem => {
          totalTimeMs=0;
          playlistItem.playableorders.forEach(item => {
            totalTimeMs += parseInt(item.duration ?? 0) * parseInt(item.repeat);
          });
          playlistItem.totalTimeMs = totalTimeMs;
        });

      }
    }
  },
  methods: {
    async sendFilter() {

      await this.changeUrlQuery()

      let params = {}
      if (this.fulltextSearch) {
        params['name_contains'] = this.fulltextSearch
      }
      if (this.sort) {
        params['_sort'] = this.sort
      }
      this.loadPageData(params)
    },
    loadPageData(params = {}) {

      this.$store.dispatch('Playlists/fetchAllPlaylists', {
        ...params,
        //list: this.$options.name
      }).then(() => {
        this.isLoaded = true;
      })
    },
    changeUrlQuery() {
      let tmp = Object.assign({}, this.$route.query)
      tmp['sort'] = this.sort
      if(this.fulltextSearch === null) {
        delete tmp['search']
      } else {
        tmp['search'] = this.fulltextSearch
      }
      this.$router.replace({ query: tmp })
    }
  },
  updated() {
    this.changeUrlQuery();
  },
  mounted() {
    this.sendFilter();
  }
}
</script>

<style scoped>

</style>
