<template>
  <div class="fixed top-0 bottom-0 right-0 z-40 flex flex-col w-screen max-w-2xl border-l border-our-gray-70">
    <div class="relative flex flex-col flex-grow h-full pb-6 gap-y-6 overflow-x-hidden overflow-y-scroll bg-white">
      <header class="pl-6 pr-8">
        <div class="flex items-center justify-between h-24 py-6 gap-x-3 border-b border-light-level-0">
          <div class="flex flex-row items-end justify-between flex-grow">
            <div class="flex flex-row items-center gap-x-2">
              <h2 class="text-lg font-medium leading-none text-gray-900 -mb-0.5">
                {{ title }}
              </h2>
            </div>
           <!-- <div class="flex items-center h-7">
              <button aria-label="Close panel" :class="{'cursor-not-allowed' : processing}" :disabled="processing"
                      class="button p-1 text-light-level-0 hover:text-light-level-0 hover:bg-light-level-1 border border-transparent hover:border-light-level-1"
                      v-on:click="$store.dispatch('Slideover/toggleSlideover', false); closeSlideover()">
                &lt;!&ndash; transition-all duration-150 ease-linear bg-white border border-white rounded-sm outline-none text-our-gray-120 hover:bg-our-gray-40 hover:border-our-gray-50 hover:text-our-gray-160border-light-highlight-level-1 focus:outline-none focus:text-our-gray-160 active:text-our-gray-160 &ndash;&gt;
                <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M4.2097 4.3871L4.29289 4.29289C4.65338 3.93241 5.22061 3.90468 5.6129 4.2097L5.70711 4.29289L12 10.585L18.2929 4.29289C18.6834 3.90237 19.3166 3.90237 19.7071 4.29289C20.0976 4.68342 20.0976 5.31658 19.7071 5.70711L13.415 12L19.7071 18.2929C20.0676 18.6534 20.0953 19.2206 19.7903 19.6129L19.7071 19.7071C19.3466 20.0676 18.7794 20.0953 18.3871 19.7903L18.2929 19.7071L12 13.415L5.70711 19.7071C5.31658 20.0976 4.68342 20.0976 4.29289 19.7071C3.90237 19.3166 3.90237 18.6834 4.29289 18.2929L10.585 12L4.29289 5.70711C3.93241 5.34662 3.90468 4.77939 4.2097 4.3871L4.29289 4.29289L4.2097 4.3871Z"/>
                </svg>
              </button>
            </div> -->
          </div>
        </div>
      </header>
      <div class="relative pl-6 pr-8">
        <AsyncComponent v-show="component !== null" v-on:processing="processing = event"></AsyncComponent>
      </div>
    </div>
    <footer id="footer"
            class="flex justify-end flex-shrink-0 w-full py-4 pl-6 pr-8 gap-x-3 bg-white border-t border-light-level-0">
      <button type="button" v-on:click="$store.dispatch('Slideover/toggleSlideover', false); closeSlideover()"
              :class="{'opacity-50 cursor-not-allowed' : processing}" :disabled="processing"
              class="button-light-highlight-level-1 rounded-level-4">
        {{ t('global.close') }}
      </button>
    </footer>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import eventBus from '@/eventBus'

export default {
  name: 'Slideover',
  components: {
    AsyncComponent: defineAsyncComponent(() => import(`./Slideovers/Empty.vue`))
  },
  setup() {
    return useI18n()
  },
  props: {
    title: String,
    component: String,
    open: Boolean
  },
  data() {
    return {
      processing: false
    }
  },
  watch: {
    open() {
      this.$options.components.AsyncComponent = defineAsyncComponent(() => import(`./Slideovers/${this.component}.vue`))
    }
  },
  methods: {
    closeSlideover() {
      if (this.component == 'AddPlayable' || this.component == 'Playables') {
        eventBus.emit('reload-content', true)
      }
    }
  },
  emits: [ 'close-slideover' ],
  /*created() {
    this.$options.components.AsyncComponent = defineAsyncComponent(() => import(`./Slideovers/${ this.component }.vue`))
  },*/
}
</script>

<style scoped>

</style>
