import axios from 'axios'

const END_POINT = '/playlists'
const END_POINT_ORDER = '/playable-orders'

const getAllPlaylists = (payload = {}) => {
	return axios({
		url: END_POINT,
		method: 'GET',
		params: payload
	})
}

const getPlaylistsByPlayableId = (playableID) => {
	return axios({
		url: END_POINT,
		method: 'GET',
		params: {'playableorders.playable': playableID}
	})
}

const getSinglePlaylist = (playlistID) => {
	return axios({
		url: END_POINT + '/' + playlistID,
		method: 'GET'
	})
}

const getPlaylistsCount = () => {
	return axios({
		url: END_POINT + '/count',
		method: 'GET'
	})
}

const getPlaylistsPage = (_start, _limit) => {
	return axios({
		url: END_POINT,
		params: { _start, _limit },
		method: 'GET'
	})
}

const createNewPlaylist = (data) => {
	return axios({
		url: END_POINT,
		data: data,
		method: 'POST'
	})
}

const updateExistingPlaylist = (data) => {
	return axios({
		url: END_POINT + '/' + data.id,
		data: data,
		method: 'PUT'
	})
}

const deletePlaylist = (playlistID) => {
	return axios({
		url: END_POINT + '/' + playlistID,
		method: 'DELETE'
	})
}

const duplicatePlaylist = (playlistID) => {
	return axios({
		url: END_POINT + '/duplicate/' + playlistID,
		method: 'GET'
	})
}

const savePlaylistOrder = (data) => {
	return axios({
		url: END_POINT + '/sort',
		data: { list: data },
		method: 'POST'
	})
}

const getHighestPlayableOrderId = (playlistID) => {
	return axios({
		url: END_POINT_ORDER,
		params: {
			playlist: playlistID,
			_sort: 'id:DESC',
			_limit: 1
		},
		method: 'GET'
	})
}

const getPlayableOrderForOnePlaylist = (playlistID) => {
	return axios({
		url: END_POINT_ORDER + '?playlist=' + playlistID,
		method: 'GET'
	})
}

const createNewPlayableOrder = (data) => {
	return axios({
		url: END_POINT_ORDER,
		data: data,
		method: 'POST'
	})
}

const updateExistingPlayableOrder = (data) => {
	return axios({
		url: END_POINT_ORDER + '/' + data.id,
		params: {
			'id': data.id
		},
		data: data,
		method: 'PUT'
	})
}

const deletePlayableOrder = (playableorderID) => {
	return axios({
		url: END_POINT_ORDER + '/' + playableorderID,
		method: 'DELETE'
	})
}


export default {
	// Playlists
	getAllPlaylists,
	getPlaylistsByPlayableId,
	getSinglePlaylist,
	getPlaylistsCount,
	getPlaylistsPage,
	createNewPlaylist,
	updateExistingPlaylist,
	duplicatePlaylist,
	deletePlaylist,
	savePlaylistOrder,

	// PlayableOrder - order playables in playlist
	getHighestPlayableOrderId,
	getPlayableOrderForOnePlaylist,
	createNewPlayableOrder,
	updateExistingPlayableOrder,
	deletePlayableOrder
}
