<template>
  <router-link :to="path"
               class="overflow-hidden col-span-1 button bg-light-level-0 text-light-level-0 border border-light-level-0 hover:border-light-highlight-level-0 rounded-level-3">
    <div class="px-4 py-5">
      <div class="flex flex-row items-start gap-x-5">
        <div class="self-center flex-shrink-0 p-3 text-white bg-light-highlight-level-2 rounded-level-5"
             v-html="icon"></div>
        <div class="flex flex-col">
          <span class="text-sm font-medium leading-5 truncate text-our-gray-120" v-text="title"></span>
          <Transition name="fade" mode="out-in">
            <span class="text-2xl font-semibold leading-8 text-light-level-1" v-text="count" :key="count"></span>
          </Transition>
        </div>
      </div>
    </div>
    <!--<div class="px-2 py-4 bg-our-gray-30px-4">
      <div class="text-sm leading-5">
        <router-link :to="path" class="px-2 py-1 font-medium transition-all duration-100 ease-linear rounded-sm text-our-gray-160 border border-our-gray-30 bg-our-gray-30 hover:border-our-gray-70 hover:bg-our-gray-40 hover:text-our-gray-190">
          View all
        </router-link>
      </div>
    </div>-->
  </router-link>
</template>

<script>
export default {
  name: 'DashboardCard',
  props: {
    title: String,
    count: Number,
    path: String,
    icon: String
  }
}
</script>

<style scoped>

</style>
