<template>
  <div class="flex flex-col gap-y-5">

    <div class="flex flex-col gap-y-6">
      <div class="flex flex-row items-center gap-x-3">

        <div class="input-search">
          <div>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.5 3C11.5376 3 14 5.46243 14 8.5C14 9.74832 13.5841 10.8995 12.8834 11.8226L17.0303 15.9697C17.3232 16.2626 17.3232 16.7374 17.0303 17.0303C16.7641 17.2966 16.3474 17.3208 16.0538 17.1029L15.9697 17.0303L11.8226 12.8834C10.8995 13.5841 9.74832 14 8.5 14C5.46243 14 3 11.5376 3 8.5C3 5.46243 5.46243 3 8.5 3ZM8.5 4.5C6.29086 4.5 4.5 6.29086 4.5 8.5C4.5 10.7091 6.29086 12.5 8.5 12.5C10.7091 12.5 12.5 10.7091 12.5 8.5C12.5 6.29086 10.7091 4.5 8.5 4.5Z"/>
            </svg>
          </div>
          <input
              v-model="fulltextSearch"
              @input="sendFilter"
              :placeholder="t('devices.searchPlaceholder')"
          >
        </div>

        <div class="flex flex-row items-center flex-grow gap-x-3">
          <select
              v-model="sort"
              @input="sendFilter"
              class="ml-auto"
          >
            <option value="name:ASC">{{ t('devices.sortingFilter.nameAsc') }}</option>
            <option value="name:DESC">{{ t('devices.sortingFilter.nameDesc') }}</option>
            <option value="created_at:ASC">{{ t('devices.sortingFilter.createdAtAsc') }}</option>
            <option value="created_at:DESC">{{ t('devices.sortingFilter.createdAtDesc') }}</option>
          </select>
        </div>
      </div>

      <div>
        <Table
            :tableHeads="tableHeader"
            :tableBody="devicesList"
            detailComponent="Device detail"
            :usePagination="true"
            :pagination="tableOptions"
            v-on:tr-hover="loadDeviceData"
        >
          <template #productName="data">
            <span class="font-bold">{{ data.data.productName }}</span> ({{ data.data.cpuName }} | {{ data.data.arch }})
          </template>
        </Table>
      </div>
    </div>
  </div>
</template>

<script>
import Table from '@/components/Table/Table'
import Multiselect from "@/components/Multiselect"
import { useI18n } from "vue-i18n";

export default {
  name: "Devices",
  components: {
    Table,
    Multiselect
  },
  setup() {
    return useI18n()
  },
  data() {
    return {
      groupsList: [],
      timer: null,
      sort: this.$route.query.sort || 'id:ASC',
      status: this.$route.query.status || 'all',
      fulltextSearch: this.$route.query.search || null,
      selectedGroupsFilter: [],
      devicesList: [],
      isLoaded: false,
      tableOptions: {
        perPage: 25,
        total: 0
      },
      tableHeader: [
        {
          title: this.t('devices.tableHeader.id'),
          col: 'id'
        },
        {
          title: this.t('tdevices.ableHeader.manufacturer'),
          col: 'manufacturer'
        },
        {
          title: this.t('devices.tableHeader.device'),
          col: 'productName'
        },
        {
          title: this.t('devices.tableHeader.ram'),
          col: 'ram'
        },
        {
          title: this.t('devices.tableHeader.mac'),
          col: 'mac'
        }
      ]
    }
  },
  computed: {
    groupsListBetter() {
      return this.groupsList.map(key => {
        return { id: key.id, name: key.name }
      })
    }
  },
  watch: {
    '$store.state.Devices.devices': {
      immediate: true,
      handler(listOrig) {

        this.devicesList = JSON.parse(JSON.stringify(listOrig))
        this.tableOptions.total = this.devicesList.length;
      }
    }
  },
  methods: {
    sendFilter() {

      this.changeUrlQuery()

      let params = {}
      if (this.fulltextSearch) {
        params['name_contains'] = this.fulltextSearch
      }
      if (this.sort) {
        params['_sort'] = this.sort
      }
      this.$store.dispatch('Devices/fetchAllDevices', params).then(() => {
        this.isLoaded = true;
      })
    },
    loadDeviceData({ type, id }) {
      if (type === 'enter') {
        this.timer = setTimeout(async () => {
          this.$store.dispatch('Devices/fetchDevice', id)
        }, 500)
      }
      else if (type === 'leave') {
        clearTimeout(this.timer)
        this.timer = null
      }
    },
    changeUrlQuery() {
      let tmp = Object.assign({}, this.$route.query)
      tmp['sort'] = this.sort
      tmp['status'] = this.status
      if(this.fulltextSearch == null) {
        delete tmp['search']
      } else {
        tmp['search'] = this.fulltextSearch
      }
      this.$router.replace({ query: tmp })
    }
  },
  updated() {
    this.changeUrlQuery()
  },
  mounted() {
    this.sendFilter()
  }
}
</script>

<style>
.text-overflow-dynamic-container {
  position: relative;
  max-width: 100%;
  padding: 0 !important;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  vertical-align: text-bottom !important;
}

.text-overflow-dynamic-ellipsis {
  position: absolute;
  white-space: nowrap;
  overflow-y: visible;
  overflow-x: hidden;
  text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  max-width: 100%;
  min-width: 0;
  width: 100%;
  top: 0;
  left: 0;
}

.text-overflow-dynamic-container:after,
.text-overflow-dynamic-ellipsis:after {
  content: '-';
  display: inline;
  visibility: hidden;
  width: 0;
}
</style>
