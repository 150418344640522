<template>
  <div class="grid gap-y-7">

    <form ref="form" class="grid p-5 gap-y-8 border rounded-md rounded-xl border-our-gray-150 bg-our-gray-10">

      <div class="flex flex-row items-center w-full p-2 gap-x-10 h-16">                
        <div class="w-80">
          <label for="name" class="block text-sm font-medium text-gray-700">
            {{ t('locations.form.name') }}
          </label>
          <div>
            <input id="name" v-model="locationData.name" required type="text" class="input-light-level-1 mt-1.5">
          </div>
        </div>
        <div>&nbsp;</div>
        <div class="w-auto">
          <label for="displays" class="block text-sm font-medium text-gray-700">
            {{ t('locations.form.displays') }}
          </label>
          <div>
            <Multiselect :placeholder="t('locations.form.multiselectPlaceholder')" :items="displaysSelected" ref="multiselectLocation" class="mt-1.5"></Multiselect>
          </div>
        </div>
      </div>
      
      <!-- Monday -->
      <div class="flex flex-row items-center w-full p-2 gap-x-10 h-16">
          <label for="mondayClosed" class="block text-sm font-medium text-gray-700 w-24">
            {{ t('locations.weekdays.monday') }}
          </label>
        <div class="w-40">
          <label for="mondayClosed" class="block text-sm font-medium text-gray-700">
            {{ t('locations.form.closed') }}
          </label>
          <div>
            <SwitchGroup as="div" class="flex items-center gap-x-4 mt-2.5">
            <Switch
                as="button"
                v-model="locationData.monday_closed"
                class="relative inline-flex flex-shrink-0 h-6 transition-all duration-200 ease-in-out border-2 border-transparent rounded-full outline-none cursor-pointer w-11 focus:outline-none"
                :class="locationData.monday_closed ? 'bg-our-cyan-60' : 'bg-our-gray-200 border-light-high light-level-1'"
                v-slot="{ checked }"
            >
              <span
                  class="inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full"
                  :class="{ 'translate-x-5': checked, 'translate-x-0': !checked }"
              />
            </Switch>
          </SwitchGroup>
          </div>  
        </div>
        <div class="w-40" v-show="!locationData.monday_closed">
          <label for="mondayNonstop" class="block text-sm font-medium text-gray-700">
            {{ t('locations.form.nonstop') }}
          </label>
          <div>
            <SwitchGroup as="div" class="flex items-center gap-x-4 mt-2.5">
            <Switch
                as="button"
                v-model="locationData.monday_nonstop"
                class="relative inline-flex flex-shrink-0 h-6 transition-all duration-200 ease-in-out border-2 border-transparent rounded-full outline-none cursor-pointer w-11 focus:outline-none"
                :class="locationData.monday_nonstop ? 'bg-our-cyan-60' : 'bg-our-gray-200 border-light-high light-level-1'"
                v-slot="{ checked }"
            >
              <span
                  class="inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full"
                  :class="{ 'translate-x-5': checked, 'translate-x-0': !checked }"
              />
            </Switch>
          </SwitchGroup>
          </div>  
        </div>
        <div v-if="!locationData.monday_closed && !locationData.monday_nonstop" class="w-40">
          <label for="mondayStart" class="block text-sm font-medium text-gray-700">
            {{ t('locations.form.start') }}
          </label>
          <div>
            <input :value="locationData.monday_start" required v-on:input="locationData.monday_start = $helpers.time($event.target.value)" type="time" class="items-center w-32 h-10 py-2 pl-3 pr-2 text-sm font-semibold transition-all duration-150 ease-linear bg-white border border-transparent rounded-md outline-none text-our-gray-160 border-our-gray-130 hover:border-our-gray-160 focus:outline-none focus:border-our-gray-190 border-light-highlight-level-1 mt-1.5">
          </div>
        </div>
        <div v-if="!locationData.monday_closed && !locationData.monday_nonstop" class="w-40">
          <label for="mondayEnd" class="block text-sm font-medium text-gray-700">
            {{ t('locations.form.end') }}
          </label>
          <div>
            <input :value="locationData.monday_end" required v-on:input="locationData.monday_end = $helpers.time($event.target.value)" type="time" class="items-center w-32 h-10 py-2 pl-3 pr-2 text-sm font-semibold transition-all duration-150 ease-linear bg-white border border-transparent rounded-md outline-none text-our-gray-160 border-our-gray-130 hover:border-our-gray-160 focus:outline-none focus:border-our-gray-190 border-light-highlight-level-1 mt-1.5">
          </div>  
        </div>
      </div>

      <!-- Tuesday -->
      <div class="flex flex-row items-center w-full p-2 gap-x-10 h-16">
          <label for="tuesdayClosed" class="block text-sm font-medium text-gray-700 w-24">
            {{ t('locations.weekdays.tuesday') }}
          </label>
        <div class="w-40">
          <label for="tuesdayClosed" class="block text-sm font-medium text-gray-700">
            {{ t('locations.form.closed') }}
          </label>
          <div>
            <SwitchGroup as="div" class="flex items-center gap-x-4 mt-2.5">
            <Switch
                as="button"
                v-model="locationData.tuesday_closed"
                class="relative inline-flex flex-shrink-0 h-6 transition-all duration-200 ease-in-out border-2 border-transparent rounded-full outline-none cursor-pointer w-11 focus:outline-none"
                :class="locationData.tuesday_closed ? 'bg-our-cyan-60' : 'bg-our-gray-200 border-light-high light-level-1'"
                v-slot="{ checked }"
                @click="closedChangeSetTime('monday_start', 'monday_end', 'tuesday_start', 'tuesday_end')"
            >
              <span
                  class="inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full"
                  :class="{ 'translate-x-5': checked, 'translate-x-0': !checked }"
              />
            </Switch>
          </SwitchGroup>
          </div>  
        </div>
        <div class="w-40" v-show="!locationData.tuesday_closed">
          <label for="tuesdayNonstop" class="block text-sm font-medium text-gray-700">
            {{ t('locations.form.nonstop') }}
          </label>
          <div>
            <SwitchGroup as="div" class="flex items-center gap-x-4 mt-2.5">
            <Switch
                as="button"
                v-model="locationData.tuesday_nonstop"
                class="relative inline-flex flex-shrink-0 h-6 transition-all duration-200 ease-in-out border-2 border-transparent rounded-full outline-none cursor-pointer w-11 focus:outline-none"
                :class="locationData.tuesday_nonstop ? 'bg-our-cyan-60' : 'bg-our-gray-200 border-light-high light-level-1'"
                v-slot="{ checked }"
            >
              <span
                  class="inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full"
                  :class="{ 'translate-x-5': checked, 'translate-x-0': !checked }"
              />
            </Switch>
            </SwitchGroup>
          </div>
        </div>
        <div v-if="!locationData.tuesday_closed && !locationData.tuesday_nonstop" class="w-40">
          <label for="tuesdayStart" class="block text-sm font-medium text-gray-700">
            {{ t('locations.form.start') }}
          </label>
          <div>
            <input :value="locationData.tuesday_start" required v-on:input="locationData.tuesday_start = $helpers.time($event.target.value)" type="time" class="items-center w-32 h-10 py-2 pl-3 pr-2 text-sm font-semibold transition-all duration-150 ease-linear bg-white border border-transparent rounded-md outline-none text-our-gray-160 border-our-gray-130 hover:border-our-gray-160 focus:outline-none focus:border-our-gray-190 border-light-highlight-level-1 mt-1.5">
          </div>
        </div>
        <div v-if="!locationData.tuesday_closed && !locationData.tuesday_nonstop" class="w-40">
          <label for="tuesdayEnd" class="block text-sm font-medium text-gray-700">
            {{ t('locations.form.end') }}
          </label>
          <div>
            <input :value="locationData.tuesday_end" required v-on:input="locationData.tuesday_end = $helpers.time($event.target.value)" type="time" class="items-center w-32 h-10 py-2 pl-3 pr-2 text-sm font-semibold transition-all duration-150 ease-linear bg-white border border-transparent rounded-md outline-none text-our-gray-160 border-our-gray-130 hover:border-our-gray-160 focus:outline-none focus:border-our-gray-190 border-light-highlight-level-1 mt-1.5">
          </div>  
        </div>
      </div>

      <!-- Wednesday -->
      <div class="flex flex-row items-center w-full p-2 gap-x-10 h-16">
          <label for="wednesdayClosed" class="block text-sm font-medium text-gray-700 w-24">
            {{ t('locations.weekdays.wednesday') }}
          </label>
        <div class="w-40">
          <label for="wednesdayClosed" class="block text-sm font-medium text-gray-700">
            {{ t('locations.form.closed') }}
          </label>
          <div>
            <SwitchGroup as="div" class="flex items-center gap-x-4 mt-2.5">
            <Switch
                as="button"
                v-model="locationData.wednesday_closed"
                class="relative inline-flex flex-shrink-0 h-6 transition-all duration-200 ease-in-out border-2 border-transparent rounded-full outline-none cursor-pointer w-11 focus:outline-none"
                :class="locationData.wednesday_closed ? 'bg-our-cyan-60' : 'bg-our-gray-200 border-light-high light-level-1'"
                v-slot="{ checked }"
                @click="closedChangeSetTime('tuesday_start', 'tuesday_end', 'wednesday_start', 'wednesday_end')"
            >
              <span
                  class="inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full"
                  :class="{ 'translate-x-5': checked, 'translate-x-0': !checked }"
              />
            </Switch>
          </SwitchGroup>
          </div>  
        </div>
        <div class="w-40" v-show="!locationData.wednesday_closed">
          <label for="wednesdayNonstop" class="block text-sm font-medium text-gray-700">
            {{ t('locations.form.nonstop') }}
          </label>
          <div>
            <SwitchGroup as="div" class="flex items-center gap-x-4 mt-2.5">
            <Switch
                as="button"
                v-model="locationData.wednesday_nonstop"
                class="relative inline-flex flex-shrink-0 h-6 transition-all duration-200 ease-in-out border-2 border-transparent rounded-full outline-none cursor-pointer w-11 focus:outline-none"
                :class="locationData.wednesday_nonstop ? 'bg-our-cyan-60' : 'bg-our-gray-200 border-light-high light-level-1'"
                v-slot="{ checked }"
            >
              <span
                  class="inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full"
                  :class="{ 'translate-x-5': checked, 'translate-x-0': !checked }"
              />
            </Switch>
          </SwitchGroup>
          </div>  
        </div>
        <div class="w-40" v-if="!locationData.wednesday_closed && !locationData.wednesday_nonstop">
          <label for="wednesdayStart" class="block text-sm font-medium text-gray-700">
            {{ t('locations.form.start') }}
          </label>
          <div>
            <input :value="locationData.wednesday_start" required v-on:input="locationData.wednesday_start = $helpers.time($event.target.value)" type="time" class="items-center w-32 h-10 py-2 pl-3 pr-2 text-sm font-semibold transition-all duration-150 ease-linear bg-white border border-transparent rounded-md outline-none text-our-gray-160 border-our-gray-130 hover:border-our-gray-160 focus:outline-none focus:border-our-gray-190 border-light-highlight-level-1 mt-1.5">
          </div>
        </div>
        <div class="w-40" v-if="!locationData.wednesday_closed && !locationData.wednesday_nonstop">
          <label for="wednesdayEnd" class="block text-sm font-medium text-gray-700">
            {{ t('locations.form.end') }}
          </label>
          <div>
            <input :value="locationData.wednesday_end" required v-on:input="locationData.wednesday_end = $helpers.time($event.target.value)" type="time" class="items-center w-32 h-10 py-2 pl-3 pr-2 text-sm font-semibold transition-all duration-150 ease-linear bg-white border border-transparent rounded-md outline-none text-our-gray-160 border-our-gray-130 hover:border-our-gray-160 focus:outline-none focus:border-our-gray-190 border-light-highlight-level-1 mt-1.5">
          </div>  
        </div>
      </div>

      <!-- Thursday -->
      <div class="flex flex-row items-center w-full p-2 gap-x-10 h-16">
        <label for="thursdayClosed" class="block text-sm font-medium text-gray-700 w-24">
            {{ t('locations.weekdays.thursday') }}
          </label>
        <div class="w-40">
          <label for="thursdayClosed" class="block text-sm font-medium text-gray-700">
            {{ t('locations.form.closed') }}
          </label>
          <div>
            <SwitchGroup as="div" class="flex items-center gap-x-4 mt-2.5">
            <Switch
                as="button"
                v-model="locationData.thursday_closed"
                class="relative inline-flex flex-shrink-0 h-6 transition-all duration-200 ease-in-out border-2 border-transparent rounded-full outline-none cursor-pointer w-11 focus:outline-none"
                :class="locationData.thursday_closed ? 'bg-our-cyan-60' : 'bg-our-gray-200 border-light-high light-level-1'"
                v-slot="{ checked }"
                @click="closedChangeSetTime('wednesday_start', 'wednesday_end', 'thursday_start', 'thursday_end')"
            >
              <span
                  class="inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full"
                  :class="{ 'translate-x-5': checked, 'translate-x-0': !checked }"
              />
            </Switch>
          </SwitchGroup>
          </div>  
        </div>
        <div class="w-40" v-show="!locationData.thursday_closed">
          <label for="thursdayNonstop" class="block text-sm font-medium text-gray-700">
            {{ t('locations.form.nonstop') }}
          </label>
          <div>
            <SwitchGroup as="div" class="flex items-center gap-x-4 mt-2.5">
            <Switch
                as="button"
                v-model="locationData.thursday_nonstop"
                class="relative inline-flex flex-shrink-0 h-6 transition-all duration-200 ease-in-out border-2 border-transparent rounded-full outline-none cursor-pointer w-11 focus:outline-none"
                :class="locationData.thursday_nonstop ? 'bg-our-cyan-60' : 'bg-our-gray-200 border-light-high light-level-1'"
                v-slot="{ checked }"
            >
              <span
                  class="inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full"
                  :class="{ 'translate-x-5': checked, 'translate-x-0': !checked }"
              />
            </Switch>
          </SwitchGroup>
          </div>  
        </div>              
        <div class="w-40" v-if="!locationData.thursday_closed && !locationData.thursday_nonstop">
          <label for="thursdayStart" class="block text-sm font-medium text-gray-700">
            {{ t('locations.form.start') }}
          </label>
          <div>
            <input :value="locationData.thursday_start" required v-on:input="locationData.thursday_start = $helpers.time($event.target.value)" type="time" class="items-center w-32 h-10 py-2 pl-3 pr-2 text-sm font-semibold transition-all duration-150 ease-linear bg-white border border-transparent rounded-md outline-none text-our-gray-160 border-our-gray-130 hover:border-our-gray-160 focus:outline-none focus:border-our-gray-190 border-light-highlight-level-1 mt-1.5">
          </div>
        </div>
        <div class="w-40" v-if="!locationData.thursday_closed && !locationData.thursday_nonstop">
          <label for="thursdayEnd" class="block text-sm font-medium text-gray-700">
            {{ t('locations.form.end') }}
          </label>
          <div>
            <input :value="locationData.thursday_end" required v-on:input="locationData.thursday_end = $helpers.time($event.target.value)" type="time" class="items-center w-32 h-10 py-2 pl-3 pr-2 text-sm font-semibold transition-all duration-150 ease-linear bg-white border border-transparent rounded-md outline-none text-our-gray-160 border-our-gray-130 hover:border-our-gray-160 focus:outline-none focus:border-our-gray-190 border-light-highlight-level-1 mt-1.5">
          </div>  
        </div>
      </div>

      <!-- Friday -->
      <div class="flex flex-row items-center w-full p-2 gap-x-10 h-16">
          <label for="fridayClosed" class="block text-sm font-medium text-gray-700 w-24">
            {{ t('locations.weekdays.friday') }}
          </label>
        <div class="w-40">
          <label for="fridayClosed" class="block text-sm font-medium text-gray-700">
            {{ t('locations.form.closed') }}
          </label>
          <div>
            <SwitchGroup as="div" class="flex items-center gap-x-4 mt-2.5">
            <Switch
                as="button"
                v-model="locationData.friday_closed"
                class="relative inline-flex flex-shrink-0 h-6 transition-all duration-200 ease-in-out border-2 border-transparent rounded-full outline-none cursor-pointer w-11 focus:outline-none"
                :class="locationData.friday_closed ? 'bg-our-cyan-60' : 'bg-our-gray-200 border-light-high light-level-1'"
                v-slot="{ checked }"
                @click="closedChangeSetTime('thursday_start', 'thursday_end', 'friday_start', 'friday_end')"
            >
              <span
                  class="inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full"
                  :class="{ 'translate-x-5': checked, 'translate-x-0': !checked }"
              />
            </Switch>
          </SwitchGroup>
          </div>  
        </div>
        <div class="w-40" v-show="!locationData.friday_closed">
          <label for="fridayNonstop" class="block text-sm font-medium text-gray-700">
            {{ t('locations.form.nonstop') }}
          </label>
          <div>
            <SwitchGroup as="div" class="flex items-center gap-x-4 mt-2.5">
            <Switch
                as="button"
                v-model="locationData.friday_nonstop"
                class="relative inline-flex flex-shrink-0 h-6 transition-all duration-200 ease-in-out border-2 border-transparent rounded-full outline-none cursor-pointer w-11 focus:outline-none"
                :class="locationData.friday_nonstop ? 'bg-our-cyan-60' : 'bg-our-gray-200 border-light-high light-level-1'"
                v-slot="{ checked }"
            >
              <span
                  class="inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full"
                  :class="{ 'translate-x-5': checked, 'translate-x-0': !checked }"
              />
            </Switch>
          </SwitchGroup>
          </div>  
        </div>    
        <div class="w-40" v-if="!locationData.friday_closed && !locationData.friday_nonstop">
          <label for="fridayStart" class="block text-sm font-medium text-gray-700">
            {{ t('locations.form.start') }}
          </label>
          <div>
            <input :value="locationData.friday_start" required v-on:input="locationData.friday_start = $helpers.time($event.target.value)" type="time" class="items-center w-32 h-10 py-2 pl-3 pr-2 text-sm font-semibold transition-all duration-150 ease-linear bg-white border border-transparent rounded-md outline-none text-our-gray-160 border-our-gray-130 hover:border-our-gray-160 focus:outline-none focus:border-our-gray-190 border-light-highlight-level-1 mt-1.5">
          </div>
        </div>
        <div class="w-40" v-if="!locationData.friday_closed && !locationData.friday_nonstop">
          <label for="fridayEnd" class="block text-sm font-medium text-gray-700">
            {{ t('locations.form.end') }}
          </label>
          <div>
            <input :value="locationData.friday_end" required v-on:input="locationData.friday_end = $helpers.time($event.target.value)" type="time" class="items-center w-32 h-10 py-2 pl-3 pr-2 text-sm font-semibold transition-all duration-150 ease-linear bg-white border border-transparent rounded-md outline-none text-our-gray-160 border-our-gray-130 hover:border-our-gray-160 focus:outline-none focus:border-our-gray-190 border-light-highlight-level-1 mt-1.5">
          </div>  
        </div>
      </div>

      <!-- Saturday -->
      <div class="flex flex-row items-center w-full p-2 gap-x-10 h-16">
        <label for="saturdayClosed" class="block text-sm font-medium text-gray-700 w-24">
            {{ t('locations.weekdays.saturday') }}
          </label>
        <div class="w-40">
          <label for="saturdayClosed" class="block text-sm font-medium text-gray-700">
            {{ t('locations.form.closed') }}
          </label>
          <div>
            <SwitchGroup as="div" class="flex items-center gap-x-4 mt-2.5">
            <Switch
                as="button"
                v-model="locationData.saturday_closed"
                class="relative inline-flex flex-shrink-0 h-6 transition-all duration-200 ease-in-out border-2 border-transparent rounded-full outline-none cursor-pointer w-11 focus:outline-none"
                :class="locationData.saturday_closed ? 'bg-our-cyan-60' : 'bg-our-gray-200 border-light-high light-level-1'"
                v-slot="{ checked }"
                @click="closedChangeSetTime('friday_start', 'friday_end', 'saturday_start', 'saturday_end')"
            >
              <span
                  class="inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full"
                  :class="{ 'translate-x-5': checked, 'translate-x-0': !checked }"
              />
            </Switch>
          </SwitchGroup>
          </div>  
        </div>
        <div class="w-40" v-show="!locationData.saturday_closed">
          <label for="saturdayNonstop" class="block text-sm font-medium text-gray-700">
            {{ t('locations.form.nonstop') }}
          </label>
          <div>
            <SwitchGroup as="div" class="flex items-center gap-x-4 mt-2.5">
            <Switch
                as="button"
                v-model="locationData.saturday_nonstop"
                class="relative inline-flex flex-shrink-0 h-6 transition-all duration-200 ease-in-out border-2 border-transparent rounded-full outline-none cursor-pointer w-11 focus:outline-none"
                :class="locationData.saturday_nonstop ? 'bg-our-cyan-60' : 'bg-our-gray-200 border-light-high light-level-1'"
                v-slot="{ checked }"
            >
              <span
                  class="inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full"
                  :class="{ 'translate-x-5': checked, 'translate-x-0': !checked }"
              />
            </Switch>
          </SwitchGroup>
          </div>  
        </div>          
        <div class="w-40" v-if="!locationData.saturday_closed && !locationData.saturday_nonstop">
          <label for="saturdayStart" class="block text-sm font-medium text-gray-700">
            {{ t('locations.form.start') }}
          </label>
          <div>
            <input :value="locationData.saturday_start" required v-on:input="locationData.saturday_start = $helpers.time($event.target.value)" type="time" class="items-center w-32 h-10 py-2 pl-3 pr-2 text-sm font-semibold transition-all duration-150 ease-linear bg-white border border-transparent rounded-md outline-none text-our-gray-160 border-our-gray-130 hover:border-our-gray-160 focus:outline-none focus:border-our-gray-190 border-light-highlight-level-1 mt-1.5">
          </div>
        </div>
        <div class="w-40" v-if="!locationData.saturday_closed && !locationData.saturday_nonstop">
          <label for="saturdayEnd" class="block text-sm font-medium text-gray-700">
            {{ t('locations.form.end') }}
          </label>
          <div>
            <input :value="locationData.saturday_end" required v-on:input="locationData.saturday_end = $helpers.time($event.target.value)" type="time" class="items-center w-32 h-10 py-2 pl-3 pr-2 text-sm font-semibold transition-all duration-150 ease-linear bg-white border border-transparent rounded-md outline-none text-our-gray-160 border-our-gray-130 hover:border-our-gray-160 focus:outline-none focus:border-our-gray-190 border-light-highlight-level-1 mt-1.5">
          </div>  
        </div>
      </div>

      <!-- Sunday -->
      <div class="flex flex-row items-center w-full p-2 gap-x-10 h-16">
        <label for="sundayClosed" class="block text-sm font-medium text-gray-700 w-24">
            {{ t('locations.weekdays.sunday') }}
          </label>
        <div class="w-40">
          <label for="sundayClosed" class="block text-sm font-medium text-gray-700">
            {{ t('locations.form.closed') }}
          </label>
          <div>
            <SwitchGroup as="div" class="flex items-center gap-x-4 mt-2.5">
            <Switch
                as="button"
                v-model="locationData.sunday_closed"
                class="relative inline-flex flex-shrink-0 h-6 transition-all duration-200 ease-in-out border-2 border-transparent rounded-full outline-none cursor-pointer w-11 focus:outline-none"
                :class="locationData.sunday_closed ? 'bg-our-cyan-60' : 'bg-our-gray-200 border-light-high light-level-1'"
                v-slot="{ checked }"
                @click="closedChangeSetTime('saturday_start', 'saturday_end', 'sunday_start', 'sunday_end')"
            >
              <span
                  class="inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full"
                  :class="{ 'translate-x-5': checked, 'translate-x-0': !checked }"
              />
            </Switch>
          </SwitchGroup>
          </div>  
        </div>
        <div class="w-40" v-show="!locationData.sunday_closed">
          <label for="sundayNonstop" class="block text-sm font-medium text-gray-700">
            {{ t('locations.form.nonstop') }}
          </label>
          <div>
            <SwitchGroup as="div" class="flex items-center gap-x-4 mt-2.5">
            <Switch
                as="button"
                v-model="locationData.sunday_nonstop"
                class="relative inline-flex flex-shrink-0 h-6 transition-all duration-200 ease-in-out border-2 border-transparent rounded-full outline-none cursor-pointer w-11 focus:outline-none"
                :class="locationData.sunday_nonstop ? 'bg-our-cyan-60' : 'bg-our-gray-200 border-light-high light-level-1'"
                v-slot="{ checked }"
            >
              <span
                  class="inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full"
                  :class="{ 'translate-x-5': checked, 'translate-x-0': !checked }"
              />
            </Switch>
          </SwitchGroup>
          </div>  
        </div>           
        <div class="w-40" v-if="!locationData.sunday_closed && !locationData.sunday_nonstop">
          <label for="sundayStart" class="block text-sm font-medium text-gray-700">
            {{ t('locations.form.start') }}
          </label>
          <div>
            <input :value="locationData.sunday_start" required v-on:input="locationData.sunday_start = $helpers.time($event.target.value)" type="time" class="items-center w-32 h-10 py-2 pl-3 pr-2 text-sm font-semibold transition-all duration-150 ease-linear bg-white border border-transparent rounded-md outline-none text-our-gray-160 border-our-gray-130 hover:border-our-gray-160 focus:outline-none focus:border-our-gray-190 border-light-highlight-level-1 mt-1.5">
          </div>
        </div>
        <div class="w-40" v-if="!locationData.sunday_closed && !locationData.sunday_nonstop">
          <label for="sundayEnd" class="block text-sm font-medium text-gray-700">
            {{ t('locations.form.end') }}
          </label>
          <div>
            <input :value="locationData.sunday_end" required v-on:input="locationData.sunday_end = $helpers.time($event.target.value)" type="time" class="items-center w-32 h-10 py-2 pl-3 pr-2 text-sm font-semibold transition-all duration-150 ease-linear bg-white border border-transparent rounded-md outline-none text-our-gray-160 border-our-gray-130 hover:border-our-gray-160 focus:outline-none focus:border-our-gray-190 border-light-highlight-level-1 mt-1.5">
          </div>  
        </div>
      </div>

    </form>

  </div>
</template>

<script>

import { useI18n } from "vue-i18n";

import ScheduleRow from "@/components/CalendarRow";
import Multiselect from "@/components/Multiselect";

import {Switch, SwitchGroup, SwitchLabel} from '@headlessui/vue'

function initialData() {
  return {
    dataLoaded: false,
    locationData: {
      name: '',
      displays: [],
      monday_start: null,
      monday_end: null,
      monday_closed: false,
      monday_nonstop: false,
      tuesday_start: null,
      tuesday_end: null,
      tuesday_closed: false,
      tuesday_nonstop: false,
      wednesday_start: null,
      wednesday_end: null,
      wednesday_closed: false,
      wednesday_nonstop: false,
      thursday_start: null,
      thursday_end: null,
      thursday_closed: false,
      thursday_nonstop: false,
      friday_start: null,
      friday_end: null,
      friday_closed: false,
      friday_nonstop: false,
      saturday_start: null,
      saturday_end: null,
      saturday_closed: false,
      saturday_nonstop: false,
      sunday_start: null,
      sunday_end: null,
      sunday_closed: false,
      sunday_nonstop: false,
    },
    displaysSelected: [],
  }
}

export default {
  name: "LocationDetail",
  setup() {
    return useI18n()
  },
  components: {
    Multiselect,
    ScheduleRow,
    SwitchGroup,
    Switch,
    SwitchLabel,
  },
  data() {
    return initialData()
  },
  methods: {
    closedChangeSetTime(fromStart, fromEnd, toStart, toEnd){
      this.locationData[toStart] = this.locationData[fromStart]
      this.locationData[toEnd] = this.locationData[fromEnd]
    }
  },
  watch: {
    '$store.state.Locations.locationData': {
      immediate: false,
      deep: true,
      handler(data) {

        if (!this.$helpers.isEqual(data, this.locationData) && parseInt(this.$route.params.id) === data.id) {

          /* Incoming data controller */
          if (Object.keys(data).length > 0) {

            this.locationData = this.$helpers.purify(data)
            let promises = []

            if (!this.dataLoaded) {
              promises.push(this.$store.dispatch('Displays/fetchAllDisplays').then(() => {

                this.displaysSelected = this.$helpers.purify(this.$store.state.Displays.displays).map(display => {

                  if (data.displays.some(selectedDisplay => selectedDisplay.id === display.id)) {
                    display["selected"] = true
                  }
                  else {
                    display["selected"] = false
                  }
                  return display
                })

              }))
            }

            Promise.all(promises).then(() => {
              this.dataLoaded = true
            })

          }
        }
      }
    },
    '$store.state.Displays.displays': {
      immediate: false,
      deep: true,
      handler(data) {
        this.displaysSelected = data
      }
    },
    locationData: {
      immediate: false,
      deep: true,
      handler(data) { // TODO add timeout for typing ?
        if (this.dataLoaded) {

          /* Outgoing data controller */
          if (this.$refs.form.reportValidity()) {

            let sendData = this.$helpers.purify(data)

            sendData.displays = this.displaysSelected.filter(display => display.selected)

            let filteredData = this.$helpers.objectFalseyAndEmptyFilter(sendData)
            this.$emit('save-changes', { ...filteredData, _type: 'location', valid: true })
          }
          else if (!this.$refs.form.reportValidity()) {
            this.$emit('save-changes', { valid: false })
          }
        }
      }
    },
    displaysSelected: {
      immediate: false,
      deep: true,
      handler(data) {
        if (this.dataLoaded) {
          this.locationData.displays = data.filter(item => item.selected)
        }
      }
    },
  },
  created() {
    this.$store.dispatch('Locations/fetchLocation', Number.parseInt(this.$route.params.id)).then(() => {
      document.title = this.$store.state.Locations.locationData.name + ' | ' + document.title.split('|')[1]
      this.$store.dispatch('setDetailName', this.$store.state.Locations.locationData.name)
    }).catch(() => {
      this.$router.push('/404')
    })
  },
  unmounted() {
    Object.assign(this.$data, initialData())
  }
}
</script>

<style scoped>
.form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='none'%3e%3cpath d='M7 7l3-3 3 3m0 6l-3 3-3-3' stroke='%23323130' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
}

.checked\:hover\:border-our-cyan-40:checked:focus {
  @apply border-our-cyan-40
}
</style>
