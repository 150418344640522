import calendars from '@/api/modules/calendars'

const state = {
	calendars: [],
	calendarData: {},
	calendarItemData: [],
	calendarItemHighestId: null,
	calendarItemPreconditions: null
}


const getters = {
	getCalendarData(state) {
		return state.calendarData;
	}
}


const mutations = {
	SET_CALENDARS_LIST(state, data) {
		state.calendars = data
	},
	ADD_TO_CALENDARS_LIST(state, data) {
		state.calendars.push(data)
	},
	SET_CALENDAR_DATA(state, data) {
		state.calendarData = data
	},
	SET_CALENDAR_ITEM(state, data) {
		state.calendarItemData = data
	},
	SET_CALENDAR_ITEM_HIGHEST_ID(state, id) {
		state.calendarItemHighestId = id
	},
	SET_CALENDAR_ITEM_PRECONDITIONS(state, data) {
		state.calendarItemPreconditions = data
	},
	SET_CALENDAR_DATA_IN_CALENDARS_LIST(state, { data, calendarID }) {
		let index = state.calendars.findIndex(calendar => calendar.id === calendarID)

		Object.assign(state.calendars[index], { ...data, calendarID })
		/*state.calendars[index] = data*/
	}
}


const actions = {
	fetchAllCalendars({ commit }) {
		return new Promise((resolve, reject) => {
			calendars.getAllCalendars().then(response => {
				commit('SET_CALENDARS_LIST', response.data)
				resolve(response)
			}, error => {
				reject(error);
			})
		})
	},
	setOneCalendarInCalendarsList({ commit }, { data, calendarID }) {
		return new Promise((resolve, reject) => {
			commit('SET_CALENDAR_DATA_IN_CALENDARS_LIST', { data, calendarID })
			resolve()
		})
	},
	fetchCalendarsPage({ commit }, payload) {
		return calendars.getCalendarsPage(payload).then((response) => {
			commit('SET_CALENDARS_LIST', response.data)
		})
	},
	fetchCalendar({ commit }, calendarId) {
		return calendars.getSingleCalendar(calendarId).then((response) => {
			commit('SET_CALENDAR_DATA', response.data)
			return response
		})
	},
	createCalendar({ commit }, payload) {
		return new Promise((resolve, reject) => {
			calendars.addNewCalendar(payload).then(response => {
				commit('SET_CALENDAR_DATA', response.data)
				resolve(response);
			}, error => {
				reject(error);
			})
		})
	},
	updateCalendar({ commit }, { calendarId, payload }) {
		return new Promise((resolve, reject) => {
			calendars.updateExistingCalendar(calendarId, payload).then(response => {
				commit('SET_CALENDAR_DATA', response.data)
				resolve(response);
			}, error => {
				reject(error);
			})
		})
	},
	setCalendarData({ commit }, data) {
		return new Promise((resolve, reject) => {
			commit('SET_CALENDAR_DATA', data)
			commit('ADD_TO_CALENDARS_LIST', data)
			resolve()
		})
	},
	fetchCalendarItemsForOnePlaylist({ commit }, calendarId) {
		return new Promise((resolve, reject) => {
			calendars.getCalendarItemsForOnePlaylist(calendarId).then(response => {
				commit('SET_CALENDAR_DATA', response.data[0])
				resolve(response);
			}, error => {
				reject(error);
			})
		})
	},
	fetchHighestCalendarItemId({ commit }, calendarId) {
		return new Promise((resolve, reject) => {
			calendars.getHighestCalendarItemId(calendarId).then(response => {
				if (Array.isArray(response.data) && response.data.length) {
					commit('SET_CALENDAR_ITEM_HIGHEST_ID', response.data[0].id)
				}else{
					commit('SET_CALENDAR_ITEM_HIGHEST_ID', 0)
				}
				resolve(response);
			}, error => {
				reject(error);
			})
		})
	},
	setHighestCalendarItemId({ commit }, calendarId) {
		commit('SET_CALENDAR_ITEM_HIGHEST_ID', calendarId)
	},
	setCalendarItemPreconditions({ commit }, data) {
		return new Promise((resolve, reject) => {
			calendars.getAllCalendarItemPreconditions().then(response => {
				commit('SET_CALENDAR_ITEM_PRECONDITIONS', response.data)
				resolve(response);
			}, error => {
				reject(error);
			})
		})
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
