import users from '@/api/modules/users'

const state = {
	loggedUser: {},
	usersList: [],
	userData: {}
}

const getters = {
	getusersList(state) {
		return state.usersList
	},
	getUser(state) {
		return state.userData
	},
	getLoggedUser(state) {
		return state.loggedUser
	},
}

const mutations = {
	SET_LOGGED_USER(state, data) {
		Object.assign(state.loggedUser, data)
	},
	SET_USERS_LIST(state, data) {
		state.usersList = data
	},
	SET_USER_DATA(state, data) {
		state.userData = data
	}
}

const actions = {
	fetchLoggedUserInformation({ commit }) {
		return new Promise((resolve, reject) => {
			users.getLoggedUserInformation().then(response => {
				commit('SET_LOGGED_USER', response.data)
				resolve(response);
			}, error => {
				reject(error);
			})
		})
	},
	fetchAllUsers({ commit }, payload) {
		return new Promise((resolve, reject) => {
			users.getAllUsers(payload).then(response => {
				commit('SET_USERS_LIST', response.data)
				resolve(response)
			}, error => {
				reject(error);
			})
		})
	},
	fetchUsersPage({ commit }, payload) {
		return new Promise((resolve) => {
			let cache = cacheAPI(payload)

			if (cache.isStored()) {
				commit('SET_USERS_LIST', cache.get())
			}

			delete payload.list

			users.getUsersPage(payload).then((response) => {
				commit('SET_USERS_LIST', response.data)
				cache.save(response.data)
				resolve()
			})
		})
	},
	fetchUser({ commit }, payload) {
		return new Promise((resolve, reject) => {
			users.getSingleUser(payload).then((response) => {
				commit('SET_USER_DATA', response.data)
				resolve(response);
			}, error => {
				reject(error);
			})
		})
	},
	registerUserSendLink({ commit }, payload) {
		return new Promise((resolve, reject) => {
			users.registerUserSendLink(payload).then(response => {
				commit('SET_USER_DATA', response.data)
				resolve(response);
			}, error => {
				reject(error);
			})
		})
	},
	updateUser({ commit }, payload) {
		return new Promise((resolve, reject) => {
			users.updateExistingUser(payload).then(response => {
				commit('SET_USER_DATA', response.data)
				resolve(response);
			}, error => {
				reject(error);
			})
		})
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
