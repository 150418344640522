import locations from '@/api/modules/locations'

const state = {
	count: 0,
	locations: [],
	locationData: {}
}


const getters = {
	getAllLocations(state) {
		return state.locations;
	},
	getLocationData(state) {
		return state.locationData;
	}
}


const mutations = {
	SET_LOCATIONS_COUNT(state, data) {
		state.count = data
	},
	SET_LOCATIONS_LIST(state, data) {
		state.locations = data
	},
	SET_LOCATION_DATA(state, data) {
		state.locationData = data
	}
}


const actions = {
	fetchLocationsCount({ commit }, payload) {
		return new Promise((resolve, reject) => {
			locations.getLocationsCount(payload).then(response => {
				commit('SET_LOCATIONS_COUNT', response.data)
				resolve(response)
			}, error => {
				reject(error);
			})
		})
	},
	fetchAllLocations({ commit }) {
		return new Promise((resolve, reject) => {
			locations.getAllLocations().then(response => {
				commit('SET_LOCATIONS_LIST', response.data)
				resolve(response)
			}, error => {
				reject(error);
			})
		})
	},
	fetchLocationsPage({ commit }, payload) {
		return locations.getLocationsPage(payload).then((response) => {
			commit('SET_LOCATIONS_LIST', response.data)
		})
	},
	fetchLocation({ commit }, locationId) {
		return locations.getSingleLocation(locationId).then((response) => {
			commit('SET_LOCATION_DATA', response.data)
			return response
		})
	},
	createLocation({ commit }, payload) {
		return new Promise((resolve, reject) => {
			locations.createLocation(payload).then(response => {
				commit('SET_LOCATION_DATA', response.data)
				resolve(response);
			}, error => {
				reject(error);
			})
		})
	},
	updateLocation({ commit }, payload) {
		return new Promise((resolve, reject) => {
			locations.updateLocation(payload).then(response => {
				commit('SET_LOCATION_DATA', response.data)
				resolve(response);
			}, error => {
				reject(error);
			})
		})
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
