import axios from 'axios'
const END_POINT_AUTH_SERVER = process.env.VUE_APP_AUTH_SERVER
const END_POINT_PASSWORDLESS = '/passwordless';
const END_POINT = '/users';

const getAllUsers = (_params = {}) => {
	return axios({
		url: END_POINT,
		method: 'GET',
		params: _params
	})
}

const getLoggedUserInformation = () => {
	return axios({
		url: END_POINT + '/me',
		method: 'GET'
	})
}

const getUsersCount = (params) => {
	return axios({
		url: END_POINT + '/count',
		params: params,
		method: 'GET'
	})
}

const getUsersPage = (params) => {
	return axios({
		url: END_POINT,
		params: params,
		method: 'GET'
	})
}

const getSingleUser = (id) => {
	return axios({
		url: END_POINT + '/' + id,
		method: 'GET'
	})
}

const registerUser = (params) => {
	return axios({
		url: END_POINT,
		data: params,
		method: 'POST'
	})
}

const registerUserSendLink = (params) => {
	return axios({
		url: END_POINT_PASSWORDLESS + '/send-link',
		data: params,
		method: 'POST'
	})
}

const updateExistingUser = (data) => {
	return axios({
		url: END_POINT + '/' + data.id,
		data: data,
		method: 'PUT'
	})
}

const updatePassword = (data) => {
	return axios({
		url: END_POINT + '/' + data.id,
		data: {
			password: data.password
		},
		method: 'PUT'
	})
}

const getRolesList = () => {
	return axios({
		url: '/users-permissions/roles',
		method: 'GET'
	})
}

const checkValidTokenClient = (params) => {
	return axios({
		baseURL: params.server + END_POINT_PASSWORDLESS + '/check-valid-token',
		data: params,
		method: 'POST'
	})
}

const checkValidTokenAuth  = (payload) => {
	return axios({
		baseURL: END_POINT_AUTH_SERVER + '/users-permissions/check-valid-token',
		data: payload,
		method: 'POST',
        withCredentials: false,
	})
}

const confirmTokenAuth = (params) => {
	return axios({
		url: '/auth/email-confirmation?confirmation=' + params.token,
		withCredentials: false,
		method: 'GET'
	})
}

const confirmNewInstance = (params) => {
	return axios({
		baseURL: params.server + END_POINT_PASSWORDLESS + '/login',
		data: params,
		method: 'POST'
	})
}

const deleteUser = (id) => {
    return axios({
        url: END_POINT + '/' + id,
        method: 'DELETE'
    })
}

const changePasswordAuth  = (payload) => {
	return axios({
		baseURL: END_POINT_AUTH_SERVER + '/users-permissions/change-password-all-servers',
		data: payload,
		method: 'POST',
        withCredentials: false,
        headers: {
            'Authorization': localStorage.getItem('strapi-auth-token')
        }
	})
}

const resetPassword  = (payload) => {
	return axios({
		baseURL: END_POINT_AUTH_SERVER + '/auth/reset-password',
		data: payload,
		method: 'POST',
        withCredentials: false,
	})
}

export default {
    getAllUsers,
	getLoggedUserInformation,
	getUsersCount,
	getUsersPage,
    getSingleUser,
    registerUser,
	registerUserSendLink,
	updateExistingUser,
	updatePassword,
	getRolesList,
	checkValidTokenClient,
	checkValidTokenAuth,
	confirmTokenAuth,
	confirmNewInstance,
	deleteUser,
	changePasswordAuth,
	resetPassword
}
