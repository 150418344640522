import { createStore } from 'vuex'

import Devices from './modules/Devices'
import Displays from './modules/Displays'
import Groups from './modules/Groups'
import Playlists from './modules/Playlists'
import Login from './modules/Login'
import Users from './modules/Users'
import Playables from './modules/Playables'
import Calendars from './modules/Calendars'
import Notifications from './modules/Notifications'
import Events from './modules/Events'
import Modal from './modules/Modal'
import Slideover from './modules/Slideover'
import Billings from './modules/Billings'
import Locations from './modules/Locations'

let initialState = {
	detail: {
		name: '',
		active: null
	},
	/*
	Devices: Devices.state,
	Displays: Displays.state,
	Groups: Groups.state,
	Playlists: Playlists.state,
	Login: Login.state,
	Users: Users.state,
	Playables: Playables.state,
	Calendars: Calendars.state,
	Notifications: Notifications.state,
	Events: Events.state,
	Modal: Modal.state,
	Slideover: Slideover.state,
	Billings: Billings.state,
	*/
}

const copyInitialState = JSON.stringify(initialState)

export default createStore({
	state: initialState,
	mutations: {
		SET_DETAIL_NAME(state, data) {
			state.detail.name = data
		},
		SET_DETAIL_ACTIVE(state, data) {
			state.detail.active = data
		},
		RESET_STORE(state) {
			let copyState = JSON.parse(copyInitialState);
			Object.keys(state).forEach(key => {
				Object.assign(state[key], copyState[key])
			})
		}
	},
	actions: {
		setDetailName({ commit }, payload) {
			commit('SET_DETAIL_NAME', payload)
		},
		setDetailActive({ commit }, payload) {
			commit('SET_DETAIL_ACTIVE', payload)
		},
		resetStore({ commit }, payload) {
			commit('RESET_STORE', payload)
		}
	},
	modules: {
		Devices,
		Displays,
		Groups,
		Playlists,
		Login,
		Users,
		Playables,
		Calendars,
		Notifications,
		Events,
		Modal,
		Slideover,
		Billings,
		Locations
	}
})
