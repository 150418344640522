<template>
  <div
      class="absolute top-0 z-10 w-full h-full transition-all duration-150 ease-linear bg-black bg-opacity-0"
      :class="{'group-hover:bg-opacity-50': !(playable.type === 'video' && hover && getThumbnailVideo(playable) !== null)}"
  ></div>

  <div
      class="absolute z-10 flex inline-flex flex-row items-center p-1 mt-2 ml-2 gap-x-2 text-sm font-medium bg-white bg-opacity-75 border rounded-sm text-our-gray-190 border-our-gray-190"
      style="backdrop-filter: blur(2px)">
    <div class="relative flex flex-row tooltip-wrapper">
      <svg v-if="playable.type === 'image'" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
           fill="currentColor"
           viewBox="0 0 24 24">
        <path d="M2.25 2.25v19.5h19.5V2.25H2.25zm1.5 1.5h16.5v13.5H3.75V3.75z"/>
        <path
            d="M14.25 12.75L9 7.5l-3.75 3.75v4.5h13.5v-3L16.5 10.5l-2.25 2.25zM17.25 8.25a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"/>
      </svg>
      <svg v-else-if="playable.type === 'video'" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
           fill="currentColor" viewBox="0 0 24 24">
        <path d="M2.25 2.25v19.5h19.5V2.25H2.25zm1.5 1.5h16.5v13.5H3.75V3.75z"/>
        <path d="M7.5 15l9-4.5-9-4.5v9z"/>
      </svg>
      <svg v-else-if="playable.type === 'web'" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"
           xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M15.98 2H2V11.948H4V3.98999H14V9.95898H20V11.949H22V8.40503L15.98 2.00098V2ZM13 17.027C12.9997 16.896 12.9737 16.7663 12.9232 16.6454C12.8728 16.5245 12.7991 16.4147 12.7062 16.3223C12.6133 16.2299 12.5032 16.1566 12.382 16.1068C12.2608 16.057 12.131 16.0316 12 16.032H11V18.022H12C12.552 18.022 13 17.576 13 17.027V17.027ZM13.724 19.384L15 21.896H13L12 19.906H11V21.896H9V13.938H12C13.657 13.938 15 15.326 15 16.974C15 17.983 14.494 18.844 13.724 19.384ZM18 13.938H16V21.896H22V19.906H18V13.938ZM6 13.938H8V19.016C7.99788 19.8096 7.68065 20.5699 7.11807 21.1296C6.55549 21.6894 5.79362 22.0028 5 22.001C3.343 22.001 2 20.664 2 19.016V13.938H4V19.016C4 19.566 4.448 20.011 5 20.011C5.552 20.011 6 19.565 6 19.016V13.938Z"/>
      </svg>
      <svg v-else-if="playable.type === 'hls'" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"
           xmlns="http://www.w3.org/2000/svg">
        <path
            d="M18 8H16V4H2V6H14V8H2V20H18V17.62L22 19.62V8.38L18 10.38V8ZM16 11.38V18H4V10H16V11.38ZM20 11.62V16.38L18 15.38V12.62L20 11.62Z"/>
        <path d="M8 14H6V16H8V14Z"/>
      </svg>
      <svg v-else-if="playable.type === null" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"
           xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.9565 4.5H10.0435C9.17864 4.5 8.34923 4.84355 7.73769 5.45509C7.12616 6.06662 6.78261 6.89603 6.78261 7.76087V9.71739H9.3913V7.76087C9.3913 7.5879 9.46001 7.42202 9.58232 7.29971C9.70463 7.17741 9.87051 7.1087 10.0435 7.1087H13.9565C14.1295 7.1087 14.2954 7.17741 14.4177 7.29971C14.54 7.42202 14.6087 7.5879 14.6087 7.76087V9.71739C14.6087 9.89036 14.54 10.0562 14.4177 10.1785C14.2954 10.3009 14.1295 10.3696 13.9565 10.3696C13.0917 10.3696 12.2623 10.7131 11.6507 11.3247C11.0392 11.9362 10.6957 12.7656 10.6957 13.6304V15.587H13.3043V13.6304C13.3043 13.4575 13.3731 13.2916 13.4954 13.1693C13.6177 13.047 13.7836 12.9783 13.9565 12.9783C14.8214 12.9783 15.6508 12.6347 16.2623 12.0232C16.8738 11.4116 17.2174 10.5822 17.2174 9.71739V7.76087C17.2174 6.89603 16.8738 6.06662 16.2623 5.45509C15.6508 4.84355 14.8214 4.5 13.9565 4.5Z"
            fill="black"/>
        <path d="M13.3044 16.8913H10.6957V19.5H13.3044V16.8913Z"/>
      </svg>

      <Tooltip color="our-gray-30" background="our-gray-120" position="top" :text="playable.type"
               classes="uppercase tracking-wider text-xs font-semibold px-1"></Tooltip>
    </div>
  </div>

  <div
      class="absolute bottom-0 left-0 right-0 z-20 flex inline-flex flex-row items-center px-2 py-1 text-sm font-medium bg-white bg-opacity-75 border-t tooltip-wrapper border-our-gray-190"
      style="backdrop-filter: blur(2px)">
    <span class="leading-6 truncate text-our-gray-190">{{ playable.name }}</span>
    <span class="flex flex-grow flex-row items-center justify-end font-bold" v-if="playable.duration">{{ $helpers.millisecondsToMinutesAndSeconds(playable.duration) }}</span>
    <Tooltip :text="playable.name" color="our-gray-30" position="bottom" background="our-gray-170"
             classes="text-sm px-2 py-1 font-medium text-center" class="z-40"></Tooltip>
  </div>

  <div class="z-0 flex flex-grow aspect-[16/9]">
    <template v-if="playable.type === 'web'">
      <img :alt="playable" :title="playable.url" :src="getThumbnailWeb(playable)"
           class="object-cover bg-top w-auto h-full mx-auto">
    </template>
    <template v-else-if="playable.type === 'image'">
      <img
           :alt="playable.description"
           :src="getThumbnailImage(playable) ? getThumbnailImage(playable) : require('@/assets/playable_placeholder_2.svg')"
           class="object-cover w-auto h-full mx-auto"
           :class="{
              'pb-6': getThumbnailImage(playable) === null
        }"
      >
    </template>
    <template v-else>
      <img
          :alt="playable.description"
          :src="getThumbnailImage(playable) ? getThumbnailImage(playable) : require('@/assets/playable_placeholder_2.svg')"
          class="object-cover w-auto h-full mx-auto"
          :class="{
              'pb-6': getThumbnailImage(playable) === null
        }"
      >
      <video
          v-if="playable.type === 'video' && hover && getThumbnailVideo(playable) !== null"
          class="object-cover w-auto h-full mx-auto absolute left-0 right-0"
          autoplay="autoplay" muted loop playsinline
          :poster="getThumbnailImage(playable) ? getThumbnailImage(playable) : require('@/assets/playable_placeholder.svg')">
        <source :src="getThumbnailVideo(playable)" type="video/mp4"/>
      </video>
    </template>
  </div>
</template>

<script>
export default {
  name: "PlayableCardDetail",
  props: {
    playable: Object,
    hover: Boolean
  },
  methods: {
    getThumbnailImage(playable) {
      let ret = null;
      if (playable.type === 'video') {
        /*if (playable.playablescreenshots != null) {
          if (playable.playablescreenshots.length > 0) {
            if (playable.playablescreenshots[0].image !== null) {
              ret = this.$axios.defaults.baseURL + (playable.playablescreenshots[0].image.formats.thumbnail?.url)?.substring(1);
            }
          }
        }*/
        if (playable.media_thumb != null) {
          ret = this.$axios.defaults.baseURL + (playable.media_thumb?.formats.thumbnail?.url)?.substring(1);
        }
      } else if (playable.type === 'image') {
        if (playable.original) {
          if (playable.original?.formats != null) {
            if (typeof playable.original.formats.thumbnail !== 'undefined') {
              ret = this.$axios.defaults.baseURL + (playable.original.formats.thumbnail.url).substring(1);
            }
          } else {
            ret = this.$axios.defaults.baseURL + (playable?.original?.url)?.substring(1);
          }
        }

      }/* else if (playable.type === 'url') {

      } else if (playable.type === 'hls') {

      }*/
      return ret;
    },
    getThumbnailVideo(playable) {
      let ret = null;
      if (playable.type === 'video') {
        if (playable.media_poster != null) {
          ret = this.$axios.defaults.baseURL + playable.media_poster?.url.substring(1);
        }
      }
      return ret;
    },
    getThumbnailWeb(playable) {
      let ret = null;
      if (playable.type === 'web') {
        ret = this.$axios.defaults.baseURL + 'screenshots/' + playable.id + '.png';
      }
      return ret;
    }
  }
}
</script>

<style scoped>

</style>
