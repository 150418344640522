const state = {
	notifications: []
}


const getters = {}


const mutations = {
	ADD_TO_NOTIFICATIONS_LIST(state, data) {
		state.notifications.push(data)
	},
	REMOVE_FROM_NOTIFICATION_LIST(state, time) {
		/*let index = state.notifications.findIndex(item => item.time === time)
		state.notifications.splice(index, 1)*/

		state.notifications = state.notifications.filter(item => item.time !== time);

	}
}


const actions = {
	send({ commit }, { variant, message1, message2, time, duration, overlay }) {

		/*FOR COUNTDOWN
		let secondsRemain
			let timer = setInterval(function () {
				if (secondsRemain <= 0) {
					clearInterval(timer);
				}
				secondsRemain -= 1;
			}, 1000);*/

		commit('ADD_TO_NOTIFICATIONS_LIST', {
			variant: variant,
			message1: message1,
			message2: message2,
			time: time,
			overlay: overlay
		})

		if (duration) {
			setTimeout(() => {
				commit('REMOVE_FROM_NOTIFICATION_LIST', time)
			}, duration)
		}
	},
	remove({ commit }, time) {
		commit('REMOVE_FROM_NOTIFICATION_LIST', time)
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
