<template>
  <div>

    <div v-if="trialVersionState !== 0" class="select-list-horizontal">
        <button v-on:click="selectMenuTab('actual')"
          class="button-light-select-level-1 rounded-level-3 leading-5 mt-2 mr-2"
          :class="'actual' === selectedMenuTab ? '!bg-light-highlight-level-1 !border-light-highlight-level-1 hover:!border-light-highlight-level-1 text-white cursor-default' : ''">
          {{ t('billing.tabs.currentInvoice') }}
        </button>
        <button v-on:click="selectMenuTab('history')"
          class="button-light-select-level-1 rounded-level-3 leading-5 mt-2 mr-2"
          :class="'history' === selectedMenuTab ? '!bg-light-highlight-level-1 !border-light-highlight-level-1 hover:!border-light-highlight-level-1 text-white cursor-default' : ''">
          {{ t('billing.tabs.historyOfInvoices') }}
        </button>
        <button v-on:click="selectMenuTab('customerDetails')"
          class="button-light-select-level-1 rounded-level-3 leading-5 mt-2 mr-2"
          :class="'customerDetails' === selectedMenuTab ? '!bg-light-highlight-level-1 !border-light-highlight-level-1 hover:!border-light-highlight-level-1 text-white cursor-default' : ''">
          {{ t('billing.tabs.customerDetails') }}
        </button>
        <button v-on:click="selectMenuTab('cardDetail')"
        class="button-light-select-level-1 rounded-level-3 leading-5 mt-2 mr-2"
          :class="'cardDetail' === selectedMenuTab ? '!bg-light-highlight-level-1 !border-light-highlight-level-1 hover:!border-light-highlight-level-1 text-white cursor-default' : ''">
          {{ t('billing.tabs.cardDetails') }}
        </button>
    </div>

    <div v-else class="gap-y-5 p-5 border border-light-level-1 rounded-level-3 rounded-xl bg-our-gray-50 border-our-gray-50">
      <p>{{ t('billing.billingNotAvailable') }}</p>
    </div>

    <div class="flex flex-col gap-y-5 mt-5" v-if="trialVersionState === 1 || (trialVersionState === 2 && !cardDetail)">
      <div class="gap-5 p-5 border border-light-level-1 rounded-level-3">
        <p><strong>{{ t('billing.trialVersion.trialVersion') }} - {{ t('billing.trialVersion.yourAccountNowFree') }}</strong> {{ t('billing.trialVersion.youCanUseOneDisplay') }} </p>
        <p>{{ t('billing.activate.alertNecessaryFillInfo') }}</p>
      </div>
    </div>

    <div class="flex flex-col gap-y-5 mt-5" v-if="trialVersionState === 2 && cardDetail">
      <div class="gap-5 p-5 border border-light-level-1 rounded-level-3">
        <p><strong>{{ t('billing.trialVersion.trialVersion') }} - {{ t('billing.trialVersion.yourAccountNowFree') }}</strong> </p>
        <p>{{ t('billing.activate.alertYouCanStart') }} {{ t('billing.activate.alertHavingTroubles') }}</p>
      </div>
    </div>

    <div class="flex flex-col mt-5 ">
        <transition name="fade" mode="out-in">
          <div>
            <div v-if="selectedMenuTab === 'actual' && trialVersionState === 3" class="gap-y-5 p-5 border border-light-level-1 rounded-level-3 border rounded-xl bg-our-gray-50 border-our-gray-50">

              <div class="w-full flex flex-row justify-between gap-x-4">
                <div class="flex flex-row items-center gap-x-4">
                  <label for="name" class="m-0">
                    <strong>{{ t('billing.tabs.currentInvoice') }}</strong>
                  </label>
                </div>
              </div>
              <div v-if="currentInvoice">
                <div class="w-full flex flex-row justify-between gap-x-4">
                  <div class="flex flex-row items-center gap-x-4">
                    <label for="name" class="m-0">
                      {{ t('billing.tableHeader.period') }}: {{ currentInvoice.billingPeriodFrom }} - {{ currentInvoice.billingPeriodTo }}
                    </label>
                  </div>
                </div>
                <br />
                <div class="w-full flex flex-row justify-between gap-x-4">
                  <div class="flex flex-row items-center gap-x-4">
                    <table class="">

                      <tr>
                        <td><strong>{{ t('billing.tableHeader.displayName') }}</strong></td>
                        <td class="border-l"><strong>{{ t('billing.tableHeader.activeDays') }}</strong></td>
                        <td class="border-l"><strong>{{ t('billing.tableHeader.priceUntilToday') }}</strong></td>
                        <td class="border-l"><strong>{{ t('billing.tableHeader.predictedEndMonthPrice') }}</strong></td>
                      </tr>

                      <tr v-for="actualInvoiceDetail in currentInvoice.actualInvoiceDetails" :key="actualInvoiceDetail.displayid">
                        <td> {{ actualInvoiceDetail.displayName }} </td>
                        <td class="border-l"> {{ actualInvoiceDetail.playedDays }} </td>
                        <td class="border-l"> {{ actualInvoiceDetail.priceUntilToday }} {{ currentInvoice.currency.name }} </td>
                        <td class="border-l"> {{ actualInvoiceDetail.endMonthPrice }} {{ currentInvoice.currency.name }} </td>
                      </tr>

                      <tr class="border-t">
                        <td>&nbsp;</td>
                        <td class="border-l"><strong> {{ currentInvoice.playedDays }} </strong></td>
                        <td class="border-l"><strong> {{ currentInvoice.priceUntilToday }} {{ currentInvoice.currency.name }} </strong></td>
                        <td class="border-l"><strong> {{ currentInvoice.endMonthPrice }} {{ currentInvoice.currency.name }} </strong></td>
                      </tr>

                      <tr>
                        <td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td>
                      </tr>

                      <tr>
                        <td>&nbsp;</td>
                        <td>{{ t('billing.tableHeader.vat') }} 21%</td>
                        <td class="border-l"> {{ ((currentInvoice.priceUntilToday)*0.21).toFixed(4) }} {{ currentInvoice.currency.name }} </td>
                        <td class="border-l"> {{ ((currentInvoice.endMonthPrice)*0.21).toFixed(4) }} {{ currentInvoice.currency.name }} </td>
                      </tr>

                      <tr>
                        <td>&nbsp;</td>
                        <td class="border-t"><strong>{{ t('billing.tableHeader.total') }}</strong></td>
                        <td class="border-l border-t"><strong> {{ ((currentInvoice.priceUntilToday)*1.21).toFixed(4) }} {{ currentInvoice.currency.name }} </strong></td>
                        <td class="border-l border-t"><strong> {{ ((currentInvoice.endMonthPrice)*1.21).toFixed(4) }} {{ currentInvoice.currency.name }} </strong></td>
                      </tr>

                    </table>
                  </div>
                </div>

                <br /><br />

                {{ t('billing.monthPricePerDisplay') }}: <strong>{{ currentInvoice.pricePerMonth }} {{ currentInvoice.currency.name }}</strong>
                <br />
                {{ t('billing.dayPricePerDisplay') }}: <strong>{{ currentInvoice.pricePerDay }} {{ currentInvoice.currency.name }}</strong>

                <br /> <br />

                (Cena za display pro daný měsíc se stanovuje první den v měsíci brzy ráno - TODO doplňující hlášky?
                <br />

              </div>
              <div v-else>
                No data
              </div>
            </div>
            <div v-if="selectedMenuTab === 'history' && trialVersionState === 3" class="gap-y-5 p-5 border border-light-level-1 rounded-level-3 border rounded-xl bg-our-gray-50 border-our-gray-50">
              <!-- TODO Sloupce v table -->
              <div v-if="arr_billingYears.length" class="flex flex-col gap-y-5">
                <div class="flex flex-col gap-y-6">
                  <div class="flex flex-row items-center gap-x-3">
                    <select
                      v-model="selectedYear"
                      @input="sendFilter"

                    >
                      <option v-for="r_billingYears in arr_billingYears" :key="r_billingYears" :value="r_billingYears">
                        {{r_billingYears}}
                      </option>
                    </select>
                  </div>
                  <div>
                    <Table
                        :tableHeads="tableHeader"
                        :tableBody="arr_invoicesHistorySelect"
                        detailComponent="Billing detail"
                        :pagination="tableOptions"
                        :usePagination="true"
                    >
                      <template #billingPeriodFrom="data">
                        {{ $moment(data.data.billingPeriodFrom).format('DD/MM/YYYY') }}
                      </template>
                      <template #billingPeriodTo="data">
                        {{ $moment(data.data.billingPeriodTo).format('DD/MM/YYYY') }}
                      </template>
                      <template #paid="data">
                        <svg v-if="data.data.paid == true" class="-my-1 w-7 h-7" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="32" height="32" viewBox="0 0 32 32">
                          <path d="M14 21.414L9 16.413 10.413 15 14 18.586 21.585 11 23 12.415 14 21.414z"></path>
                          <path d="M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Zm0,26A12,12,0,1,1,28,16,12,12,0,0,1,16,28Z"></path>
                        </svg>
                        <svg v-if="data.data.paid == false" class="-my-1 w-7 h-7" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="32" height="32" viewBox="0 0 32 32">
                          <path d="M2,16H2A14,14,0,1,0,16,2,14,14,0,0,0,2,16Zm23.15,7.75L8.25,6.85a12,12,0,0,1,16.9,16.9ZM8.24,25.16A12,12,0,0,1,6.84,8.27L23.73,25.16a12,12,0,0,1-15.49,0Z"></path>
                        </svg>
                      </template>
                    </Table>
                  </div>
                </div>
              </div>

              <div v-else class="flex flex-col gap-y-5 mt-5">
                <div class="gap-5 p-5 border border-light-level-1 rounded-level-3">
                  <p><strong>{{ t('billing.haventClosedFirstBillingYet') }}</strong> </p>
                </div>
              </div>

            </div>
            <div v-if="selectedMenuTab === 'customerDetails'" class="gap-y-5 p-5 border border-light-level-1 rounded-level-3 rounded-xl bg-our-gray-50 border-our-gray-50">

              <div class="w-full flex flex-row justify-between gap-x-4">
                <div class="flex flex-row items-center gap-x-4">
                  <label for="name" class="m-0">
                    <strong>{{ t('billing.tabs.customerDetails') }}</strong>
                  </label>
                </div>
              </div>

              <div class="flex flex-col gap-y-5 mt-5" v-if="customerDetailsFormDisabled && !customerDetailsData.currency.id">
                <div class="gap-5 p-5 border border-light-level-1 rounded-level-3">
                  <p><strong>{{ t('billing.haveNotYetFilledCustomerDetail') }}</strong> </p>
                  <p>{{ t('billing.clickEditButton') }}</p>
                </div>

                <div class="bg-white px-4 py-3 text-right sm:px-6">
                  <button type="button" @click="customerDetailsFormDisabled = false" class="button-light-action-level-1 rounded-level-4 ml-4">{{ t('billing.edit') }}</button>
                </div>
              </div>

              <div v-else class="">
                
               <div class="mt-5 md:col-span-2 ">
                  <form ref="form" v-on:submit.prevent="saveCustomerDetails">
                    <div class="overflow-hidden shadow sm:rounded-md">
                      <div class="bg-white px-4 py-5 sm:p-6">
                        <div class="grid grid-cols-6 gap-6">
                          
                          <div class="col-span-6 sm:col-span-3">
                            <label for="companyName" class="block text-sm font-medium text-gray-700">
                              {{ t('billing.form.companyName') }}
                              <span v-if="!customerDetailsFormDisabled">*</span>
                            </label>
                            <div class="mt-1.5">
                              <div v-if="customerDetailsFormDisabled">
                                <label>{{ customerDetailsData.companyName }}</label>
                              </div>
                              <div v-else>
                                <input id="companyName" v-model="customerDetailsData.companyName" required type="text" class="input-light-level-1">
                              </div>
                            </div>
                          </div>

                          <div class="col-span-6 sm:col-span-3">
                            <label for="street" class="block text-sm font-medium text-gray-700">
                            {{ t('billing.form.street') }}
                            <span v-if="!customerDetailsFormDisabled">*</span>
                          </label>
                          <div class="mt-1.5">
                            <div v-if="customerDetailsFormDisabled">
                              <label>{{ customerDetailsData.street }}</label>
                            </div>
                            <div v-else>
                              <input id="street" v-model="customerDetailsData.street" required type="text" class="input-light-level-1">
                            </div>
                          </div>
                          </div>

                          <div class="col-span-6 sm:col-span-3">
                            <label for="street2">
                              {{ t('billing.form.street') }} 2
                            </label>
                            <div class="mt-1.5">
                              <div v-if="customerDetailsFormDisabled">
                                {{ customerDetailsData.street2 }}
                              </div>
                              <div v-else>
                                <input id="street2" v-model="customerDetailsData.street2" type="text" class="input-light-level-1">
                              </div>
                            </div>
                          </div>

                          <div class="col-span-6 sm:col-span-3">
                            <label for="city">
                              {{ t('billing.form.city') }}
                              <span v-if="!customerDetailsFormDisabled">*</span>
                            </label>
                            <div class="mt-1.5">
                              <div v-if="customerDetailsFormDisabled">
                                {{ customerDetailsData.city }}
                              </div>
                              <div v-else>
                                <input id="city" v-model="customerDetailsData.city" required type="text" class="input-light-level-1">
                              </div>
                            </div>
                          </div>

                          <div class="col-span-6 sm:col-span-3">
                            <label for="zipCode">
                              {{ t('billing.form.zipCode') }}
                              <span v-if="!customerDetailsFormDisabled">*</span>
                            </label>
                            <div class="mt-1.5">
                              <div v-if="customerDetailsFormDisabled">
                                {{ customerDetailsData.zipCode }}
                              </div>
                              <div v-else>
                                <input id="zipCode" v-model="customerDetailsData.zipCode" required type="text" class="input-light-level-1">
                              </div>
                            </div>
                          </div>

                          <div class="col-span-6 sm:col-span-3">
                            <label for="country">
                              {{ t('billing.form.country') }}
                              <span v-if="!customerDetailsFormDisabled">*</span>
                            </label>
                            <div class="mt-1.5">
                              <div v-if="customerDetailsFormDisabled">
                                {{ customerDetailsData.country }}
                              </div>
                              <div v-else>
                                <select id="country" class="input-light-level-1" required v-model="customerDetailsData.countryCode">
                                  <option v-for="country in getCountries()" :key="country.code" :value="country.code">{{ country.name }}</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div class="col-span-6 sm:col-span-3">
                            <label for="zipCode">
                              {{ t('billing.form.EUVATID') }}
                            </label>
                            <div class="mt-1.5">
                              <div v-if="customerDetailsFormDisabled">
                                {{ customerDetailsData.euVatId }}
                              </div>
                              <div v-else>
                                <input id="euVatId" v-model="customerDetailsData.euVatId" type="text" class="input-light-level-1">
                              </div>
                            </div>
                          </div>

                          <div class="col-span-6 sm:col-span-3">
                            <label for="businessRegistrationNumber">
                              {{ t('billing.form.businessRegistrationNumber') }}
                            </label>
                            <div class="mt-1.5">
                              <div v-if="customerDetailsFormDisabled">
                                {{ customerDetailsData.businessRegistrationNumber }}
                              </div>
                              <div v-else>
                                <input id="businessRegistrationNumber" v-model="customerDetailsData.businessRegistrationNumber" type="text" class="input-light-level-1">
                              </div>
                            </div>
                          </div>

                          <div class="col-span-6 sm:col-span-3">
                            <label for="noteInvoice">
                              {{ t('billing.form.noteOnInvoice') }}
                            </label>
                            <div class="mt-1.5">
                              <div v-if="customerDetailsFormDisabled">
                                {{ customerDetailsData.noteInvoice }}
                              </div>
                              <div v-else>
                                <input id="noteInvoice" v-model="customerDetailsData.noteInvoice" type="text" class="input-light-level-1">
                              </div>
                            </div>
                          </div>

                          <div class="col-span-6 sm:col-span-3">
                            <label for="billingEmail" class="form-label">
                              {{ t('billing.form.billingEmail') }} 
                              <span v-if="!customerDetailsFormDisabled">*</span>
                            </label>
                            <div class="mt-1.5">
                              <div v-if="customerDetailsFormDisabled">
                                {{ customerDetailsData.billingEmail }} 
                              </div>
                              <div v-else>  
                                <input id="billingEmail" v-model="customerDetailsData.billingEmail" required type="email" class="form-input">
                              </div>
                            </div>
                          </div>

                          <div class="col-span-6 sm:col-span-3">
                            <label for="currency">
                              {{ t('billing.form.currency') }}
                              <span v-if="!customerDetailsFormDisabled">*</span>
                            </label>
                            <div class="mt-1.5">
                              <div v-if="customerDetailsFormDisabled || customerDetailsData.currency.id">
                                {{ customerDetailsData.currency.name }}
                              </div>
                              <div v-else>
                                <div class="flex flex-row items-center gap-x-3">
                                  <select
                                    required
                                    v-model="selectedCurrency"
                                  >
                                    <option v-for="currency in currencies" :key="currency" :value="currency.id">
                                      {{currency.name}}
                                    </option>
                                  </select>
                                </div>
                                {{ t('billing.form.currencyCannotBeChanged') }}
                                <!--<input id="currency" v-model="customerDetailsData.currency" type="text" class="input-light-level-1">-->
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div v-if="!customerDetailsFormDisabled" class="bg-white px-4 py-3 text-right sm:px-6">
                        <button type="button" @click="customerDetailsFormClose()" class=" button-light-highlight-level-1 rounded-level-4">{{ t('global.close') }}</button>
                        <button
                          class="button-light-action-level-1 rounded-level-4 ml-4">
                          <!-- :class="{ 'opacity-50 cursor-not-allowed' : !itemsChanged || !dataValid }" :disabled="!itemsChanged || !dataValid"  -->
                          <Loader class="w-4 h-4 mr-2" v-show="savingChanges"></Loader>
                          {{ t('global.buttons.saveChanges') }}
                        </button>
                        <Tooltip v-if="showTooltip" :text="t('billing.noUnsavedChangesOrInvalidData')" class="w-32 text-xs font-medium py-2.5" color="our-gray-30" background="our-gray-170" position="bottom"></Tooltip>
                      </div>
                      <div v-else class="bg-white px-4 py-3 text-right sm:px-6">
                        <button type="button" @click="customerDetailsFormDisabled = false" class="button-light-action-level-1 rounded-level-4 ml-4">{{ t('billing.edit') }}</button>
                      </div>
                    </div>
                  </form>

                </div>
              </div>

            </div>

            <div v-if="selectedMenuTab === 'cardDetail'" class="gap-y-5 p-5 border border-light-level-1 rounded-level-3 rounded-xl bg-our-gray-50 border-our-gray-50">

              <div class="w-full flex flex-row justify-between gap-x-4">
                <div class="flex flex-row items-center gap-x-4">
                  <label for="name" class="m-0">
                    <strong>{{ t('billing.tabs.cardDetails') }}</strong>
                  </label>
                </div>
              </div>
              <div class="mt-5">

                <div v-if="false" class="flex flex-col gap-y-5 mt-5"> <!-- TODO pokud expirace -->
                  <transition name="fade" mode="out-in">
                    <div class="flex flex-col gap-y-6">
                      <div class="flex flex-col gap-y-5 p-5 border border-light-level-1 rounded-level-3 bg-white">
                        <div>
                          <p class="text-our-red">
                            <strong>{{ t('billing.card.attentionCardExpireAlert') }} TODO expirace Změňte ji prosím níže.</strong><br />
                          </p>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>

                <div v-if="paymentUrlNewCard" class="flex flex-col gap-y-5 mt-5"> 
                  <transition name="fade" mode="out-in">
                    <div class="flex flex-col gap-y-6">
                      <div class="flex flex-col gap-y-5 p-5 border border-light-level-1 rounded-level-3 bg-white">
                        <div>
                          <label for="cardNumber">
                            {{ t('billing.card.paymentCardChangeInProgress') }}
                          </label>
                          <div class="mt-1.5">
                            {{ t('billing.card.paymentCouldNotBeProcessed') }}
                          </div>
                        </div>
                        <div>
                          <button type="button" v-on:click="repeatCard(paymentUrlNewCard)" class="h-10 text-sm rounded-md button button-outline-gray">
                            {{ t('billing.card.buttons.fillCardDetails') }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>

                <div v-if="paymentState == 'waiting_for_payment'" class="flex flex-col gap-y-5 mt-5"> 
                  <transition name="fade" mode="out-in">
                    <div class="flex flex-col gap-y-6">
                      <div class="flex flex-col gap-y-5 p-5 border border-light-level-1 rounded-level-3 bg-white">
                        <div>
                          <label for="cardNumber">
                            {{ t('billing.card.waitingForCardVerification') }}
                          </label>
                          <div class="mt-1.5">
                            {{ t('billing.card.paymentCouldNotBeProcessed') }}
                          </div>
                        </div>
                        <div>
                          <button type="button" v-on:click="repeatCard(paymentUrl)" class="h-10 text-sm rounded-md button button-outline-gray">
                            {{ t('billing.card.buttons.fillCardDetails') }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>

                <div v-else-if="isNewCard && paymentState == 'preauth_cancelled'" class="flex flex-col gap-y-5 mt-5"> 
                  <transition name="fade" mode="out-in">
                    <div class="flex flex-col gap-y-6">
                      <div class="flex flex-col gap-y-5 p-5 border border-light-level-1 rounded-level-3 bg-white">
                        <div>
                          <label for="cardNumber">
                            {{ t('billing.card.cardVerifiedSuccessfully') }}
                          </label>
                          <div class="mt-1.5">
                            {{ t('billing.card.cardVerifiedSuccessfullyAlert') }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>

                <div v-else-if="!paymentState" class="flex flex-col gap-y-5 mt-5"> 
                  <transition name="fade" mode="out-in">
                    <div class="flex flex-col gap-y-6">
                      <div class="flex flex-col gap-y-5 p-5 border border-light-level-1 rounded-level-3 bg-white">
                        <div>
                          <label for="cardNumber">
                            {{ t('billing.card.useFullExtentAlert1') }}
                          </label>
                        </div>
                        <div>
                          <p>
                            {{ t('billing.card.useFullExtentAlert2') }}
                          </p>
                          <p>
                            {{ t('billing.card.useFullExtentAlert3') }}  viz TODO odkaz cenik
                          </p>
                          <p>
                            {{ t('billing.card.useFullExtentAlert4') }}
                          </p>
                        </div>
                        <div>
                          <button v-if="trialVersionState === 2" type="button" v-on:click="addCard()" class="h-10 text-sm rounded-md button button-outline-gray">
                              {{ t('billing.card.buttons.enterCardDetails') }}
                          </button>
                          <div v-else class="font-bold">
                            {{ t('billing.card.enterCardAfterFillingCustomerDetails') }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>

                <div v-if="cardDetail" class="flex flex-col gap-y-5 mt-5"> 
                  <transition name="fade" mode="out-in">
                    <div class="flex flex-col gap-y-6">
                      <div class="flex flex-col gap-y-5 p-5 border border-light-level-1 rounded-level-3 bg-white">
                        <div>
                          <label for="cardNumber">
                            {{ t('billing.card.cardNumber') }}
                          </label>
                          <div class="mt-1.5">
                            {{ cardDetail.cardNumber }}
                          </div>
                        </div>
                        <div>
                          <label for="expiration">
                            {{ t('billing.card.expiration') }}
                          </label>
                          <div class="mt-1.5">
                            {{ cardDetail.expiration }}
                          </div>
                        </div>
                        <div>
                          <button type="button" v-on:click="addCard()" class="h-10 text-sm rounded-md button button-outline-gray">
                            {{ t('billing.card.buttons.changeCard') }} *
                          </button>
                        </div>
                        <div>
                          <button type="button" v-on:click="removeCard()" class="h-10 text-sm rounded-md button button-outline-gray">
                            Odstranit platební kartu TODO ??? **
                          </button>
                        </div>
                        <div>
                          <p>
                            * {{ t('billing.card.willBeRedirectedToPaymentGateway') }}
                          </p>
                          <p>
                            ** po odstranění platební karty bude omezena funkčnost aplikace na verzi free.
                          </p>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>

              </div>
            </div>

          </div>
        </transition>
      </div>
  </div>
</template>

<script>
import Table from '@/components/Table/Table'
import {useI18n} from "vue-i18n"
import axios from 'axios'
import countries from './../countries.json';

export default {
  name: "Billings",
  components: {
    Table
  },
  setup() {
    return useI18n()
  },
  data() {
    return {
      selectedMenuTab: 'actual',
      trialVersionState: 0,
      arr_invoicesHistory: [],
      currentInvoice: null,
      selectedYear: new Date().getFullYear(),
      arr_billingYears: [],
      customerDetailsFormDisabled: true,
      currencies: [],
      selectedCurrency: null,
      paymentState: null,
      paymentUrl: null,
      cardDetail: null,
      paymentUrlNewCard: null,
      isNewCard: false,
      customerDetailsData: {
        companyName: null,
        street: null,
        street2: null,
        city: null,
        zipCode: null,
        country: null,
        countryCode: null,
        euVatId: null,
        currency: {
          id: null,
          name: null,
        },
        businessRegistrationNumber: null,
        noteInvoice: null,
        billingEmail: null
      },
      tableOptions: {
        perPage: 12,
        total: 0
      },
      tableHeader: [
        {
          title: this.t('billing.tableHeader.from'),
          col: 'billingPeriodFrom'
        },
        {
          title: this.t('billing.tableHeader.to'),
          col: 'billingPeriodTo'
        },
        {
          title: this.t('billing.tableHeader.totalDaysPlayed'),
          col: 'playedDays'
        },
        {
          title: this.t('billing.tableHeader.priceWithoutVAT'),
          col: 'priceWithoutVAT'
        },
        {
          title: this.t('billing.tableHeader.priceVAT'),
          col: 'priceVAT'
        },
        {
          title: this.t('billing.tableHeader.priceWithVAT'),
          col: 'price'
        },
        {
          title: this.t('billing.tableHeader.paid'),
          col: 'paid'
        }
      ]
    }
  },

  methods: {
    selectMenuTab(name) {
      this.$router.replace({ query: { 'selectedTab': name } })
      this.selectedMenuTab = name;
      this.customerDetailsFormClose()
    },
    addCard(){
      this.$api.billings.createPayment({
        'Domain': this.$axios.defaults.baseURL.slice(0, -1),
      }).then(response => {
        window.open(response.data.pay_url);
      }).catch(e => {
        //console.log("Error addCard - create payment", e)
      })
    },
    repeatCard(url){
      window.open(url);
    },
    getCountries(){
      return countries
    },
    customerDetailsFormClose(){
      this.createdFunction()
      this.customerDetailsFormDisabled = true
    },

    saveCustomerDetails(e) {

      if(this.trialVersionState === 1){ // insert
        this.customerDetailsData.currency = this.selectedCurrency;
      }

      this.customerDetailsData.country = (this.getCountries().find(o => o.code === this.customerDetailsData.countryCode)).name
      let params = {
        'serverIPv4': this.getServerIPv4(this.$axios.defaults.baseURL),
        'data' : this.customerDetailsData
      }

      if(this.trialVersionState === 1){ // insert

        this.$store.dispatch('Billings/createNewCustomerDetails', params ).then((res) => {

          this.customerDetailsData.currency = res.data.currency
          this.trialVersionState = 2

          setTimeout(() => {
            this.$store.dispatch('Notifications/send', {
              variant: 'success',
              message1: this.t('global.notification.successfullyCreated'),
              message2: this.t('global.menu.customerDetails') + ` ` + this.t('global.notification.wasCreated'),
              time: new Date().toISOString(),
              duration: 5000,
              overlay: false
            })
          }, 1000)


        }).catch(() => {
          this.$router.push('/404')
        })

      } else { // update

        params.data.id =  this.$store.getters['Billings/getCustomerDetails'].id

        this.$store.dispatch('Billings/updateCustomerDetails', params ).then(() => {

          setTimeout(() => {
            this.$store.dispatch('Notifications/send', {
              variant: 'success',
              message1: this.t('global.notification.successfullyUpdated'),
              message2: this.t('global.menu.customerDetails') + ` ` + this.t('global.notification.wasUpdated'),
              time: new Date().toISOString(),
              duration: 5000,
              overlay: false
            })
          }, 1000)

        }).catch(() => {
          this.$router.push('/404')
        })
      }
      this.customerDetailsFormDisabled = true
    },

    getServerIPv4(baseURL){
      let IPv4
      IPv4 = baseURL.replace('https://', '');
      IPv4 = IPv4.replace('http://', '');
      IPv4 = IPv4.replace('/', '');
      IPv4 = IPv4.split(':')[0] // port
      return IPv4
    },

    createdFunction(){
      let IPv4 = this.getServerIPv4(this.$axios.defaults.baseURL);
      this.$store.dispatch('Billings/fetchBillingData', IPv4).then((response) => {
        this.trialVersionState = response.data.trialState
        this.paymentUrl = response?.data?.creditCardDetails?.pay_url
        this.cardDetail = response?.data?.creditCardDetails?.card
        this.paymentState = response?.data?.creditCardDetails?.state
        this.paymentUrlNewCard = response?.data?.creditCardDetails?.pay_url_new_card
        this.isNewCard = response?.data?.creditCardDetails?.isNewCard
        
        if(response.data.customerDetail){
          this.customerDetailsData = response.data.customerDetail;
          response.data.actualInvoice ? this.currentInvoice = response.data.actualInvoice : ''
          if(response.data.historyInvoices){
            let year
            this.arr_invoicesHistory = response.data.historyInvoices
            for (const r_invoicesHistory of this.arr_invoicesHistory) { // each display
              year = r_invoicesHistory.billingPeriodTo.substring(0, 4);
              if(!this.arr_billingYears.includes(year)){
                this.arr_billingYears.push(year);
              }
            }
            this.arr_billingYears.sort((a, b) => parseInt(b) - parseInt(a));
          }
        }else{
          this.$api.billings.getCurrencies().then(response => {
            this.currencies = response.data
          })
        }
      }).catch(() => {
        this.$router.push('/404')
      })
    }
  },

  mounted() {
    if(this.$route.query.selectedTab){
      this.selectedMenuTab = this.$route.query.selectedTab
    }
  },

  computed: {
    arr_invoicesHistorySelect(){
      return this.arr_invoicesHistory.filter(item => parseInt(item.billingPeriodTo.substring(0, 4)) === parseInt(this.selectedYear))
    }
  },

  created() {
    this.createdFunction()
  }
}
</script>

<style scoped>

</style>
