<template>
  <div>

    <div class="relative inline-block text-left" v-click-outside="() => { customOpen = false }">
      <Menu v-slot="{ open }">
        <!-- select py-0.5 pl-1 pr-[3em] text-sm leading-4 transition-all duration-150 ease-linear bg-white border outline-none cursor-pointer /// rounded-sm border-transparent text-our-gray-160 border-our-gray-130 hover:border-our-gray-160 focus:outline-none focus:border-our-gray-190-->
        <MenuButton v-on:click="customOpen = !customOpen" :id="buttonId"
                    class="select p-1 pl-1.5 inline-flex flex-wrap items-center justify-center button-light-level-0 pr-[3em]"
                    :class="classes + ' /// ' + [itemsListSelected === null || itemsListSelected.length === 0 ? ' h-10' : '' ]">

          <span v-if="itemsListSelected === null || itemsListSelected.length === 0"
                class="font-medium whitespace-nowrap"> <!-- :class="placeholderClasses.length !== 0 ? placeholderClasses : 'text-our-gray-120'" -->
            {{ placeholder }}
          </span>

          <div class="flex flex-wrap"> <!-- grid grid-cols-5 auto-cols-auto auto-rows-auto gap-2 -->
            <transition-group name="fade" mode="out-in">
              <div v-for="item in itemsListSelected" :key="item.id"
                   class="my-0.5 mr-2 whitespace-nowrap flex flex-row items-center justify-between py-1 pl-2 pr-1 gap-x-1 text-xs font-semibold leading-none transition-all duration-150 ease-linear border outline-none bg-our-gray-50 border-our-gray-50 text-our-gray-170 rounded-xs focus:border-our-gray-170 focus:outline-none focus:bg-our-gray-170 focus:text-whiteborder-light-highlight-level-1 cursor-default">
                <span>{{ item.name }}</span>
                <div v-on:mousedown="item.selected = false, customOpen = false"
                     class="p-0.5 transition-all duration-150 ease-linear border cursor-pointer text-our-gray-160 hover:text-our-gray-190 hover:border-our-gray-170 bg-our-gray-50 border-our-gray-50 hover:bg-our-gray-80">
                  <svg height="14" width="14" viewBox="0 0 20 20" aria-hidden="true" focusable="false"
                       fill="currentColor">
                    <path
                        d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                  </svg>
                </div>
              </div>
            </transition-group>
          </div>
        </MenuButton>

        <transition
            enter-active-class="transition duration-100 ease-in"
            enter-from-class="transform scale-90 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-200 ease-out"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-90 opacity-0"
        >
          <div v-show="customOpen" class="absolute left-0 z-10 origin-top-left py-2">
            <MenuItems static
                       class="p-2 flex flex-col gap-y-1.5 overflow-y-auto border rounded-sm outline-none bg-our-gray-190 border-our-gray-120 max-h-64">
              <MenuItem v-slot="{ active }" v-for="item in items" :key="item.id">
                <label :class="active ? '' : ''"
                       class="!flex flex-row items-center justify-between w-full p-2.5 gap-x-5 hover:bg-our-gray-150 hover:border-our-gray-130 border-our-gray-190 text-sm leading-5 text-left text-white transition-all duration-200 ease-linear border outline-none rounded-xs cursor-pointer">
                  <!--<span v-if="item.customId" class="font-bold">{{ item.customId }}</span>
                  :class="item.customId ? 'justify-end' : ''"-->
                  <div class="flex flex-grow">
                    <span class="button m-0 whitespace-nowrap select-none"
                          :class="item.selected ? 'text-our-gray-30' : 'text-our-gray-70'">
                      {{ item.name }}
                    </span>
                  </div>
                  <input :id="'item-' + item.id" v-model="item.selected" type="checkbox">
                </label>
              </MenuItem>
            </MenuItems>
          </div>
        </transition>
      </Menu>
    </div>
  </div>
</template>

<script>
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'

export default {
  name: 'Multiselect',
  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
  },
  props: {
    items: {
      default: new Array(),
      type: Array
    },
    classes: {
      default: '',
      type: String
    },
    placeholder: {
      default: 'Select multiple values',
      type: String
    },
    placeholderClasses: {
      default: '',
      type: String
    },
    buttonId: {
      default: '',
      type: String
    }
  },
  data() {
    return {
      customOpen: false
    }
  },
  computed: {
    itemsList() {
      if (this.items) {
        return this.items
      }
      else {
        return []
      }
    },
    itemsListSelected() {
      if (this.items) {
        return this.items.filter(item => item.selected)
      }
      else {
        return []
      }
    }
  }
}
</script>

<style lang="css" scoped>
/*.form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='none'%3e%3cpath d='M7 7l3-3 3 3m0 6l-3 3-3-3' stroke='%23323130' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
}

.checked\:hover\:border-our-gray-60:checked:focus {
  @apply border-our-gray-60
}*/
</style>
