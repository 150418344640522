import axios from 'axios'
import store from '../../store'

const END_POINT = '/playables';
const UPLOAD_END_POINT = '/upload';
const FOLDER_END_POINT = '/playable-folders';

const getPlayablesCount = () => {
	return axios({
		url: END_POINT + '/count',
		method: 'GET'
	})
}

const getFilteredPlayablesCount = (params) => {
	return axios({
		url: END_POINT + '/count',
		params: params,
		method: 'GET'
	})
}

const getAllPlayables = () => {
	let params = {_limit: -1, _sort: 'id:DESC'};
	return axios({
		url: END_POINT,
		params: params,
		method: 'GET'
	})
}

const getSomePlayables = (params) => {
	return axios({
		url: END_POINT,
		params: params,
		method: 'GET'
	});
	//.then(res => {console.log("AXIOS RAW",res)});
}

const getPlayablesByParentIdIn = (arr_parentsId) => {

	let url = '?';

	for (const r_parentsId of arr_parentsId) {
		url += 'folder_in=' + r_parentsId + '&';
	}

	url = url.slice(0, -1);

	return axios({
		url: END_POINT + url,
		method: 'GET'
	})
}

const getSinglePlayable = (playableID) => {
	return axios({
		url: END_POINT + '/' + playableID,
		method: 'GET'
	})
}

const getAllPlayablesFolder = () => {
	return axios({
		url: FOLDER_END_POINT,
		params: {
			'_limit': 5000,
			'_sort': 'id:DESC'
		},
		method: 'GET'
	})
}

const getPlayablesFolder = (id) => {
	return axios({
		url: FOLDER_END_POINT + '/' + id,
		method: 'GET'
	})
}

const getPlayablesFolderByParentId = (parentId) => {
	return axios({
		url: FOLDER_END_POINT + '?parentid=' + parentId,
		method: 'GET'
	})
}

const getPlayablesFolderByParentIdIn = (arr_parentsId) => {

	let url = '?';

	for (const r_parentsId of arr_parentsId) {
		url += 'parentid_in=' + r_parentsId + '&';
	}

	url = url.slice(0, -1);

	return axios({
		url: FOLDER_END_POINT + url,
		method: 'GET'
	})
}

const uploadNewFile = (file) => {

	const uploadFileRequestCancel = axios.CancelToken.source();

	const config = {
		onUploadProgress: function (progressEvent) {
			var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
			store.dispatch('Playables/setUploadProgress', percentCompleted)
		}
	}

	let data = new FormData()
	data.append('files', file)

	let request = axios({
		url: UPLOAD_END_POINT,
		data: data,
		method: 'POST',
		...config,
		cancelToken: uploadFileRequestCancel.token
	})

	store.dispatch('Playables/setRequest', uploadFileRequestCancel)

	return request
}

const addNewPlayable = (data) => {
    return axios({
        url: END_POINT,
        data: data,
        method: 'POST'
    })
}

const addNewPlayableFolder = (data) => {
    return axios({
        url: FOLDER_END_POINT,
        data: data,
        method: 'POST'
    })
}

const updateExistingPlayable = (data) => {
	return axios({
		url: END_POINT + '/' + data.id,
		data: data,
		method: 'PUT'
	})
}

const updateExistingPlayableFolder = (data) => {
	return axios({
		url: FOLDER_END_POINT + '/' + data.id,
		data: data,
		method: 'PUT'
	})
}

const deletePlayable = (id) => {
	return axios({
		url: END_POINT + '/' + id,
		method: 'DELETE'
	})
}

const deletePlayableFolder = (id) => {
	return axios({
		url: FOLDER_END_POINT + '/' + id,
		method: 'DELETE'
	})
}

const deleteFile = (id) => {
	return axios({
		url: UPLOAD_END_POINT + '/files/' + id,
		method: 'DELETE'
	})
}

const getEventsForOnePlayableUntil = ({ playableID, untilDate }) => {
	return axios({
		url: 'event-lists/playable-until/' + playableID + '/' + untilDate,
		method: 'GET'
	})
}

export default {
	getPlayablesCount,
	getFilteredPlayablesCount,
	getAllPlayables,
	getSomePlayables,
	getPlayablesByParentIdIn,
	getSinglePlayable,
	getAllPlayablesFolder,
	getPlayablesFolder,
	getPlayablesFolderByParentId,
	getPlayablesFolderByParentIdIn,

	uploadNewFile,
	addNewPlayable,
	addNewPlayableFolder,
	updateExistingPlayable,
	updateExistingPlayableFolder,
	deletePlayable,
	deletePlayableFolder,
	deleteFile,

	getEventsForOnePlayableUntil
}
