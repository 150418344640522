import devices from './modules/devices.js'
import displays from './modules/displays.js'
import groups from './modules/groups.js'
import playlists from './modules/playlists.js'
import users from './modules/users.js'
import playables from './modules/playables.js'
import calendars from './modules/calendars.js'
import events from './modules/events.js'
import errorLogger from "./modules/error-logger.js"
import billings from './modules/billings.js'
import servers from './modules/servers.js'
import locations from './modules/locations.js'

const API = {
	devices,
	displays,
	groups,
	playlists,
	users,
	playables,
	calendars,
	events,
	errorLogger,
	billings,
	servers,
	locations
}

export default API
