import playables from '@/api/modules/playables'

const state = {
	playablesCount: 0,
	playablesList: [],
	playableData: [],
	playableFolderList: [],
	playableFolderData:[],
	uploading: false,
	uploadProgress: 0,
	fileUploadData: {
		name: null,
		size: null
	},
	playableUploadData: {
		name: null,
		type: null,
		description: null,
		p1080: null,
	},
	request: null,
	eventsData: []
}


const getters = {
	getPlayableData(state) {
		return state.playableData;
	}
}


const mutations = {
	SET_PLAYABLES_COUNT(state, data) {
		state.playablesCount = data
	},
	SET_PLAYABLES_LIST(state, data) {
		state.playablesList = data
	},
	SET_PLAYABLE_DATA(state, data) {
		state.playableData = data
	},
	SET_PLAYABLE_FOLDERS_LIST(state, data) {
		state.playableFolderList = data
	},
	SET_PLAYABLE_FOLDER_DATA(state, data) {
		state.playableFolderData = data
	},
	SET_FILE_UPLOAD_DATA(state, { file, playable }) {
		state.fileUploadData = file
		state.playableUploadData = playable
	},
	SET_UPLOADING_STATUS(state, status) {
		state.uploading = status
	},
	SET_UPLOADING_PROGRESS(state, progressPercentage) {
		state.uploadProgress = progressPercentage
	},
	SET_REQUEST(state, request) {
		state.request = request
	},
	SET_EVENTS_DATA(state, data) {
		state.eventsData = data
	}
}


const actions = {
	fetchPlayablesCount({ commit }) {
		return new Promise((resolve, reject) => {
			playables.getPlayablesCount().then((response) => {
				commit('SET_PLAYABLES_COUNT', response.data)
				resolve(response)
			}, error => {
				reject(error);
			})
		})
	},
	fetchFilteredPlayablesCount({ commit }, data) {
		return new Promise((resolve, reject) => {
			playables.getFilteredPlayablesCount(data).then((response) => {
				commit('SET_PLAYABLES_COUNT', response.data)
				resolve(response)
			}, error => {
				reject(error);
			})
		})
	},
	fetchAllPlayables({ commit }) {
		return new Promise((resolve, reject) => {
			playables.getAllPlayables().then((response) => {
				// už tady je to zformátované
				commit('SET_PLAYABLES_LIST', response.data)
				resolve(response)
			}, error => {
				reject(error);
			})
		})
	},
	fetchAllPlayableFolder({ commit }) {
		return new Promise((resolve, reject) => {
			playables.getAllPlayablesFolder().then((response) => {
				// už tady je to zformátované
				commit('SET_PLAYABLE_FOLDERS_LIST', response.data)
				resolve(response)
			}, error => {
				reject(error);
			})
		})
	},
	fetchSomePlayables({ commit }, data) {
		return new Promise((resolve, reject) => {
			playables.getSomePlayables(data).then((response) => {
				commit('SET_PLAYABLES_LIST', response.data)
				resolve(response)
			}, error => {
				reject(error);
			})
		})
	},
	fetchPlayable({ commit }, id) {
		return new Promise((resolve, reject) => {
			playables.getSinglePlayable(id).then((response) => {
				commit('SET_PLAYABLE_DATA', response.data)
				return response
			}, error => {
				reject(error);
			})
		})
	},
	fetchPlayableFolder({ commit }, id) {
		return new Promise((resolve, reject) => {
			playables.getPlayablesFolder(id).then((response) => {
				commit('SET_PLAYABLE_FOLDER_DATA', response.data)
				resolve(response);
				return response
			}, error => {
				reject(error);
			})
		})
	},
	createPlayable({ commit }, payload) {
		return new Promise((resolve, reject) => {
			playables.addNewPlayable(payload).then(response => {
				commit('SET_PLAYABLE_DATA', response.data)
				resolve(response);
			}, error => {
				reject(error);
			})
		})
	},
	createFolder({ commit }, payload) {
		return new Promise((resolve, reject) => {
			playables.addNewPlayableFolder(payload).then((response) => {
				commit('SET_PLAYABLE_FOLDER_DATA', response.data)
				resolve(response);
			}, error => {
				reject(error);
			})
		})
	},
	updatePlayable({ commit }, payload) {
		return new Promise((resolve, reject) => {
			playables.updateExistingPlayable(payload).then(response => {
				commit('SET_PLAYABLE_DATA', response.data)
				resolve(response);
			}, error => {
				reject(error);
			})
		})
	},
	updatePlayableFolderFromSlideOver({commit},data) {
		commit('SET_PLAYABLE_FOLDER_DATA', data)
	},
	updateFolder({ commit }, payload) {
		return new Promise((resolve, reject) => {
			playables.updateExistingPlayableFolder(payload).then(response => {
				commit('SET_PLAYABLE_FOLDER_DATA', response.data)
				resolve(response);
			}, error => {
				reject(error);
			})
		})
	},
	uploadFile({ commit }, { file, playable }) {
		return new Promise((resolve, reject) => {
			commit('SET_UPLOADING_STATUS', true)
			commit('SET_FILE_UPLOAD_DATA', { file, playable })
			playables.uploadNewFile(file).then((response) => {
				commit('SET_UPLOADING_STATUS', false)
				resolve(response)
			}, error => {
				reject(error);
			})
		})
	},
	setUploadProgress({ commit }, percentage) {
		commit('SET_UPLOADING_PROGRESS', percentage)
	},
	setUploadingStatus({ commit }, status) {
		commit('SET_UPLOADING_STATUS', status)
	},
	setRequest({ commit }, request) {
		commit('SET_REQUEST', request)
	},

	fetchEventsForOnePlayableUntil({ commit }, { playableID, untilDate }) {
		return playables.getEventsForOnePlayableUntil({ playableID, untilDate }).then((response) => {
			commit('SET_EVENTS_DATA', response.data)
			return response
		})
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
