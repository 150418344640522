import Router, {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import i18n from '../i18n'
import store from '../store'

// Views
import Page404 from "@/views/404"
import Overview from "@/views/Overview"
import Devices from "@/views/Devices"
import Displays from "@/views/Displays"
import Groups from "@/views/Groups"
import Playlists from "@/views/Playlists"
import Playables from "@/views/Playables"
import Settings from "@/views/Settings"
import Billings from "@/views/Billings"
import Users from "@/views/Users"
import Styleguide from "@/views/Styleguide"
import Locations from "@/views/Locations"

// Authentication
import Authentication from "@/views/Authentication"
import Tokenlogin from "@/views/Tokenlogin"
import Login from "@/views/Login"
import Signup from "@/views/Signup"

// Detail
import Device from "@/views/Detail/Device"
import Display from "@/views/Detail/Display"
import DisplayLive from "@/views/DisplayLive"
import Group from "@/views/Detail/Group"
import Playlist from "@/views/Detail/Playlist"
import Playable from "@/views/Detail/Playable"
import Billing from "@/views/Detail/Billing"
import User from "@/views/Detail/User"
import Location from "@/views/Detail/Location"

let routes = [
    {
        path: '/:locale/auth',
        name: 'Authentication',
        component: Authentication,
        meta: {name_en: 'Authentication', name_cs: 'Autentizace'},
        children: [
            {
                path: 'login',
                name: 'Login',
                component: Login,
                meta: {name_en: 'Login', name_cs: 'Přihlášení'},
            }, {
                path: 'signup',
                name: 'Signup',
                component: Signup,
                meta: {name_en: 'Signup', name_cs: 'Registrace'},
            }, {
                path: 'tokenlogin',
                name: 'Tokenlogin',
                component: Tokenlogin,
                meta: {name_en: 'Tokenlogin', name_cs: 'Tokenlogin'},
            }
        ]
    },
    {
        path: '/',
        name: 'Default',
        redirect: '/en'
    },
    {
        path: '/:locale',
        name: 'Overview',
        component: Overview,
        meta: {name_en: 'Overview', name_cs: 'Přehled'},
        children: [
            {
                path: 'devices',
                name: 'Devices',
                component: Devices,
                meta: {name_en: 'Devices', name_cs: 'Zařízení'},
            },
            {
                path: 'devices/detail/:id',
                name: 'Device detail',
                component: Device,
                meta: {name_en: 'Device detail', name_cs: 'Detail zařízení', parentName: 'Devices'},
            },
            {
                path: 'displays',
                name: 'Displays',
                component: Displays,
                meta: {name_en: 'Displays', name_cs: 'Obrazovky'},
            },
            {
                path: 'displays/detail/:id',
                name: 'Display detail',
                component: Display,
                meta: {name_en: 'Display detail', name_cs: 'Detail obrazovky', parentName: 'Displays'},
            },
            {
                path: 'displays/live/:id',
                name: 'Display live',
                component: DisplayLive,
                meta: {name_en: 'Display live', name_cs: 'Obrazovka živě', parentName: 'Displays'},
            },
            {
                path: 'groups',
                name: 'Groups',
                component: Groups,
                meta: {name_en: 'Groups', name_cs: 'Skupiny'},
            },
            {
                path: 'groups/detail/:id',
                name: 'Group detail',
                component: Group,
                meta: {name_en: 'Group detail', name_cs: 'Detail skupiny', parentName: 'Groups'},
            },
            {
                path: 'locations',
                name: 'Locations',
                component: Locations,
                meta: {name_en: 'Locations', name_cs: 'Lokality'},
            },
            {
                path: 'locations/detail/:id',
                name: 'Location detail',
                component: Location,
                meta: {name_en: 'Location detail', name_cs: 'Detail lokality', parentName: 'Locations'},
            },
            {
                path: 'playlists',
                name: 'Playlists',
                component: Playlists,
                meta: {name_en: 'Playlists', name_cs: 'Playlisty'},
            },
            {
                path: 'playlists/detail/:id',
                name: 'Playlist detail',
                component: Playlist,
                meta: {name_en: 'Playlist detail', name_cs: 'Detail playlistu', parentName: 'Playlists'},
            },
            {
                path: 'playables',
                name: 'Playables',
                component: Playables,
                meta: {name_en: 'Playables', name_cs: 'Média'},
            },
            {
                path: 'playables/detail/:id',
                name: 'Playable detail',
                component: Playable,
                meta: {name_en: 'Playable detail', name_cs: 'Detail média', parentName: 'Playables'},
            },
            {
                path: 'settings',
                name: 'Settings',
                component: Settings,
                meta: {name_en: 'Settings', name_cs: 'Nastavení'},
            },
            {
                path: 'billings',
                name: 'Billings',
                component: Billings,
                meta: {name_en: 'Billings', name_cs: 'Vyúčtování'},
            },
            {
                path: 'billings/detail/:id',
                name: 'Billing detail',
                component: Billing,
                meta: {name_en: 'Billing detail', name_cs: 'Detail vyúčtování', parentName: 'Billings'},
            },
            {
                path: 'users',
                name: 'Users',
                component: Users,
                meta: {name_en: 'Users', name_cs: 'Uživatelé'},
            },
            {
                path: 'users/detail/:id',
                name: 'User detail',
                component: User,
                meta: {name_en: 'User detail', name_cs: 'Detail uživatele', parentName: 'Users'},
            },
            {
                path: 'styleguide',
                name: 'Styleguide',
                component: Styleguide,
                meta: {name_en: 'Styleguide', name_cs: 'Styleguide'},
            },
        ],
    },
    /* {
        path: '/:locale/displays/live/:id',
        name: 'Display live',
        component: DisplayLive,
        meta: {name_en: 'Display live', name_cs: 'Obrazovka live'},
    }, */
    {
        path: '/:pathMatch(.*)*',
        name: 'Page404',
        component: Page404,
        meta: {name_en: 'Page404', name_cs: 'Page404'},
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    /*history: createWebHashHistory(),*/ // enable for locale build
    linkExactActiveClass: false,
    routes
})

/*router.afterEach((to, from) => {
 setTimeout(() => {
 document.getElementById('main').scroll({ top: 0 })
 }, 250)
 })*/

router.beforeEach((to, from, next) => {

    store.dispatch('Slideover/toggleSlideover', false)

    let currentLocale = i18n.global.locale.value
    let paramLocale = to.path.split('/')[1]

    if (to.name === 'Login' || to.name === 'Signup') {
        if (paramLocale !== '' && (paramLocale === 'cs' || paramLocale === 'en')) {
            i18n.global.locale.value = paramLocale
        }
        next()
    } else if (store.getters['Login/isAuthenticated']) {

        store.dispatch('Users/fetchLoggedUserInformation').then((response) => {
            let loggedUserRoleName = response.data.role.name

            if (paramLocale !== '' && (paramLocale === 'cs' || paramLocale === 'en')) {
                i18n.global.locale.value = paramLocale
                currentLocale = paramLocale

                if (from.name === 'Login' && // Only playables users
                    to.name != 'Playables' &&
                    loggedUserRoleName === 'Only playables') {
                    next({name: 'Playables', params: {locale: paramLocale}})
                } else if (to.name === 'Login') {
                    next({name: 'Overview', params: {locale: paramLocale}})
                } else if (to.name === 'Overview') {
                    if (['Admin', 'Standard', 'Authenticated'].indexOf(loggedUserRoleName) !== -1) {
                        next()
                    } else {
                        if (loggedUserRoleName === 'Only playables') {
                            next({name: 'Playables', params: {locale: paramLocale}})
                        } else {
                            console.log("Insufficient permission", loggedUserRoleName);
                            next({path: '/404'})
                        }
                    }
                } else if (to.name === 'Displays' ||
                    to.name === 'Groups' ||
                    to.name === 'Locations' ||
                    to.name === 'Playlists' ||
                    to.name === 'Settings') {
                    if (['Admin', 'Standard', 'Authenticated'].indexOf(loggedUserRoleName) !== -1) {
                        next()
                    } else {
                        next({path: '/404'})
                    }
                } else if (to.name === 'Playables') {
                    next()
                } else if (to.name === 'Billings' ||
                    to.name === 'Users') {
                    if (['Admin', 'Authenticated'].indexOf(loggedUserRoleName) !== -1) {
                        next()
                    } else {
                        next({path: '/404'})
                    }
                } else if (to.fullPath === '/') {
                    next({path: '/' + currentLocale})
                } else if (to.matched.length === 0) {
                    /* not found */
                    next({path: '/' + currentLocale + '/settings'})
                } else {
                    next()
                }
            } else if (paramLocale === '') {
                next({path: '/' + currentLocale})
            } else {
                /*console.log('to', to)*/
                to.params.locale = currentLocale
                next({path: '/' + currentLocale + to.fullPath})
            }
        }).catch((err) => {
            //console.log("err", err)
        })
    } else if (to.name !== 'Login' && to.name !== 'Signup' && to.name !== 'Tokenlogin') {
        /*console.log(paramLocale, to.path.split('/'), to, from)*/
        if (paramLocale === '' || paramLocale === undefined) {
            if (currentLocale) {
                next({name: 'Login', params: {locale: currentLocale}})
            } else {
                next({name: 'Login', params: {locale: 'cs'}})
            }
        } else {
            next({name: 'Login', params: {locale: paramLocale}})
        }
    } else {
        next()
    }
})

export default router
