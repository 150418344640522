<template>
  <transition
      enter-active-class="transition-all duration-300 ease-out delay-50 transform"
      enter-from-class="scale-90 opacity-0"
      enter-to-class="scale-100 opacity-1"
      leave-active-class="duration-300 ease-in transform"
      leave-from-class="scale-100 opacity-1"
      leave-to-class="scale-90 opacity-0"
  >
    <div class="relative overflow-hidden" v-if="loaded">
    <span class="absolute inset-0 w-full h-full before:rounded-full animation-loading before:block before:empty-content before:absolute before:inset before:m-auto before:w-full before:h-full before:border-2 before:border-current">
    </span>
    </div>
  </transition>
</template>

<script>

export default {
  name: "Loader",
  props: {
    classes: String
  },
  data() {
    return {
      loaded: false
    }
  },
  mounted() {
    this.loaded = true
  }
}
</script>

<style scoped>
.animation-loading {
  animation: loading 3s linear infinite;
}

.animation-loading::before {
  border-bottom-color: transparent;
  animation: loading 1.5s cubic-bezier(0.750, 0.000, 0.150, 1.000) infinite;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
