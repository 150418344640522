<template>
  <div class="grid gap-y-7">

    <form ref="form" class="grid p-5 gap-y-5 border rounded-md rounded-xl border-our-gray-150 bg-our-gray-10">
      <div class="max-w-lgmax-w-xs">
        <label for="name" class="block text-base font-medium leading-5 text-our-gray-160">
          {{ t('groups.form.name') }}
        </label>
        <div class="mt-1.5">
          <input id="name" v-model="groupData.name" type="text" class="input-light-level-1">
        </div>
      </div>

      <div>
        <label>{{ t('groups.form.displays') }}</label>
        <Multiselect :placeholder="t('groups.form.multiselectPlaceholder')" :items="displaysSelected" ref="multiselectGroups" class="mt-1.5"></Multiselect>
      </div>

      <!--      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path fill="#212121" d="M5.843 4.568a8.707 8.707 0 0112.314 12.314l-1.187 1.174c-.875.859-2.01 1.963-3.406 3.312a2.25 2.25 0 01-3.128 0l-3.49-3.396c-.44-.43-.807-.794-1.103-1.09a8.707 8.707 0 010-12.314zm11.254 1.06A7.207 7.207 0 106.904 15.823l1.487 1.467c.819.801 1.848 1.802 3.088 3a.75.75 0 001.043 0l3.395-3.3c.469-.46.862-.85 1.18-1.167a7.207 7.207 0 000-10.193zM12 7.999a3.002 3.002 0 110 6.004 3.002 3.002 0 010-6.003zm0 1.5a1.502 1.502 0 100 3.004A1.502 1.502 0 0012 9.5z"/>
            </svg>-->

      <div>
        <legend>{{ t('groups.form.orientation') }}</legend>

        <div class="flex flex-col mt-3 gap-y-2">
          <div class="flex items-center">
            <input id="orientation-horizontal" name="orientation" value="horizontal" v-model="groupData.orientation" type="radio" class="form-radio" checked>
            <label for="orientation-horizontal">
              <span>{{ t('groups.form.orientationHorizontal') }}</span>
            </label>
          </div>
          <div class="flex items-center">
            <input id="orientation-vertical" name="orientation" value="vertical" v-model="groupData.orientation" type="radio" class="form-radio">
            <label for="orientation-vertical">
              <span>{{ t('groups.form.orientationVertical') }}</span>
            </label>
          </div>
        </div>
      </div>

      <div class="max-w-lg max-w-xl">
        <label class="block text-base font-medium leading-5 text-our-gray-160">
          {{ t('groups.form.description') }}
        </label>
        <div class="mt-1.5">
          <textarea v-model="groupData.description" placeholder="Special requirements, recommendations, etc." type="text" rows="4" class="input-light-level-1">
          </textarea>
        </div>
      </div>
    </form>

    <div class="p-5 border rounded-md grid gap-y-7 rounded-xl border-our-gray-150 bg-our-gray-10">

      <!--{{ playlistsPlayablesList[0].name }} {{ playlistsPlayablesList[0].playable.name }}-->


      <div v-if="groupData.playlists.length === 0" class="banner--data-information gap-y-4 bg-our-gray-50">
        <div class="banner--data-information-inner">
          {{ t('groups.form.noPlaylistsAssigned') }}
        </div>
      </div>
      <template v-else>
        <div v-for="playlist in groupData.playlists" :key="playlist.id">

          <div class="flex flex-row items-center mb-3 gap-x-2">
            <legend class="text-base font-medium leading-5 text-our-gray-160">{{ t('groups.form.playlistInformation') }}</legend>
            <router-link :to="{ name: 'Playlist detail', params: { id: playlist.id }}" class="px-3 py-1 text-sm font-medium text-white transition duration-100 ease-linear rounded-full bg-our-gray-190 border-our-gray-190 hover:bg-our-cyan-60">
              {{ playlist.name }}
            </router-link>
          </div>

          <router-link :to="{ name: 'Playlist detail', params: { id: playlist.id }}" class="block px-4 pt-5 pb-6 mt-2 transition duration-100 ease-linear border bg-our-gray-50 rounded-xl border-our-gray-50 hover:border-our-cyan-60">

            <div class="flex flex-row items-center gap-x-4">
              <label for="name" class="m-0">
                {{ t('groups.form.priorityHash') }}
              </label>
              <span v-text="playlist.priority"></span>
            </div>

            <legend class="block mt-4 text-base font-medium leading-5 text-our-gray-160">Order</legend>
            <div class="flex flex-col mt-3 gap-y-2" v-if="playlistsPlayablesList.filter(playableItem => playableItem.playlist.id === playlist.id).length > 0">
              <div v-for="playable in playlistsPlayablesList.filter(playableItem => playableItem.playlist.id === playlist.id).sort((a, b) => a.order - b.order)" :key="playable.id" class="flex flex-row items-center justify-between w-full p-4 gap-x-4 bg-white rounded-lg">

                <div class="flex flex-grow">
                  <div class="flex flex-row">
                    <div>
                      <svg v-if="playable.playable.type === 'image'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M2.25 2.25v19.5h19.5V2.25H2.25zm1.5 1.5h16.5v13.5H3.75V3.75z"/>
                        <path d="M14.25 12.75L9 7.5l-3.75 3.75v4.5h13.5v-3L16.5 10.5l-2.25 2.25zM17.25 8.25a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"/>
                      </svg>
                      <svg v-else-if="playable.playable.type === 'video'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M2.25 2.25v19.5h19.5V2.25H2.25zm1.5 1.5h16.5v13.5H3.75V3.75z"/>
                        <path d="M7.5 15l9-4.5-9-4.5v9z"/>
                      </svg>
                      <svg v-else-if="playable.playable.type === 'web'" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.98 2H2V11.948H4V3.98999H14V9.95898H20V11.949H22V8.40503L15.98 2.00098V2ZM13 17.027C12.9997 16.896 12.9737 16.7663 12.9232 16.6454C12.8728 16.5245 12.7991 16.4147 12.7062 16.3223C12.6133 16.2299 12.5032 16.1566 12.382 16.1068C12.2608 16.057 12.131 16.0316 12 16.032H11V18.022H12C12.552 18.022 13 17.576 13 17.027V17.027ZM13.724 19.384L15 21.896H13L12 19.906H11V21.896H9V13.938H12C13.657 13.938 15 15.326 15 16.974C15 17.983 14.494 18.844 13.724 19.384ZM18 13.938H16V21.896H22V19.906H18V13.938ZM6 13.938H8V19.016C7.99788 19.8096 7.68065 20.5699 7.11807 21.1296C6.55549 21.6894 5.79362 22.0028 5 22.001C3.343 22.001 2 20.664 2 19.016V13.938H4V19.016C4 19.566 4.448 20.011 5 20.011C5.552 20.011 6 19.565 6 19.016V13.938Z"/>
                      </svg>
                      <svg v-else-if="playable.playable.type === 'hls'" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 8H16V4H2V6H14V8H2V20H18V17.62L22 19.62V8.38L18 10.38V8ZM16 11.38V18H4V10H16V11.38ZM20 11.62V16.38L18 15.38V12.62L20 11.62Z"/>
                        <path d="M8 14H6V16H8V14Z"/>
                      </svg>
                    </div>
                    <span class="ml-2">{{ playable.playable.name }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="flex flex-row items-center justify-between w-full p-4 mt-3 gap-x-4 text-center bg-white rounded-lg">
              {{ t('groups.form.noPlayableOrder') }}
            </div>

            <legend class="block mt-4 text-base font-medium leading-5 text-our-gray-160">{{ t('groups.form.conditions') }}</legend>
            <div class="flex flex-col mt-3 gap-y-2" v-if="playlistsConditionsList.filter(item => item.playlist.id === playlist.id).length > 0">
              <template v-for="list in playlistsConditionsList.filter(item => item.playlist.id === playlist.id)">
                <div v-for="condition in list.calendaritemdetails.sort((a, b) => a.order - b.order)" :key="condition.id" class="flex flex-row items-center justify-between w-full p-4 gap-x-4 bg-white rounded-lg">

                  <div class="flex flex-row gap-x-2">

                    <select :value="condition.precondition" class="w-auto h-10 ml-1 rounded-md form-select" disabled>
                      <option :value="1">{{ t('calendar.precondition.date') }}</option>
                      <option :value="2">{{ t('calendar.precondition.time') }}</option>
                      <option :value="3">{{ t('calendar.precondition.weekday') }}</option>
                    </select>

                    <select :value="condition.condition" class="w-auto h-10 rounded-md form-select" v-if="condition.precondition !== 3" disabled>
                      <option v-if="condition.precondition === 1" :value="1">{{ t('calendar.condition.isOnOrBefore') }}</option>
                      <option v-if="condition.precondition === 1" :value="4">{{ t('calendar.condition.isBetween') }}</option>
                      <option v-if="condition.precondition === 1" :value="3">{{ t('calendar.condition.isOnOrAfter') }}</option>
                      <option v-if="condition.precondition === 2" :value="1">{{ t('calendar.condition.isBefore') }}</option>
                      <option v-if="condition.precondition === 2" :value="4">{{ t('calendar.condition.isBetween') }}</option>
                      <option v-if="condition.precondition === 2" :value="3">{{ t('calendar.condition.isAfter') }}</option>
                    </select>

                    <input :value="condition.postconditiondate" v-if="condition.precondition === 1" type="date" class="items-center h-10 py-2 pl-3 pr-2 text-sm font-semibold transition-all duration-150 ease-linear bg-white border border-transparent rounded-md outline-none w-80 text-our-gray-160 border-our-gray-130 hover:border-our-gray-160 focus:outline-none focus:border-our-gray-190border-light-highlight-level-1" disabled>

                    <input :value="condition.postconditiontime1" v-if="condition.precondition === 2" type="time" class="items-center w-32 h-10 py-2 pl-3 pr-2 text-sm font-semibold transition-all duration-150 ease-linear bg-white border border-transparent rounded-md outline-none text-our-gray-160 border-our-gray-130 hover:border-our-gray-160 focus:outline-none focus:border-our-gray-190border-light-highlight-level-1" disabled>

                    <div v-if="condition.precondition === 2 && condition.condition === 4" class="flex flex-row items-center gap-x-4">
                      <span class="text-sm font-semibold">{{ t('calendar.and') }}</span>
                      <input :value="condition.postconditiontime2" v-on:input="this.condition.postconditiontime2 = this.$helpers.time($event.target.value)" type="time" class="items-center w-32 h-10 py-2 pl-3 pr-2 text-sm font-semibold transition-all duration-150 ease-linear bg-white border border-transparent rounded-md outline-none text-our-gray-160 border-our-gray-130 hover:border-our-gray-160 focus:outline-none focus:border-our-gray-190border-light-highlight-level-1" disabled>
                    </div>

                    <span v-if="condition.precondition === 3">
                          <button type="button" :class="['h-10 text-sm rounded-r-none button rounded-l-md border-our-gray-130 '] + [condition.condition === 5 ? 'button-gray' : 'button-outline-gray']" disabled>
                            {{ t('calendar.weekdays.mon') }}
                          </button>
                          <button type="button" :class="['h-10 -ml-px text-sm rounded-none button border-our-gray-130 '] + [condition.condition === 6 ? 'button-gray' : 'button-outline-gray']">
                            {{ t('calendar.weekdays.tue') }}
                          </button>
                          <button type="button" :class="['h-10 -ml-px text-sm rounded-none button border-our-gray-130 '] + [condition.condition === 7 ? 'button-gray' : 'button-outline-gray']">
                            {{ t('calendar.weekdays.wed') }}
                          </button>
                          <button type="button" :class="['h-10 -ml-px text-sm rounded-none button border-our-gray-130 '] + [condition.condition === 8 ? 'button-gray' : 'button-outline-gray']">
                            {{ t('calendar.weekdays.thu') }}
                          </button>
                          <button type="button" :class="['h-10 -ml-px text-sm rounded-none button border-our-gray-130 '] + [condition.condition === 9 ? 'button-gray' : 'button-outline-gray']">
                            {{ t('calendar.weekdays.fri') }}
                          </button>
                          <button type="button" :class="['h-10 -ml-px text-sm rounded-none button border-our-gray-130 '] + [condition.condition === 10 ? 'button-gray' : 'button-outline-gray']">
                            {{ t('calendar.weekdays.sat') }}
                          </button>
                          <button type="button" :class="['h-10 -ml-px text-sm rounded-l-none rounded-r-md button border-our-gray-130 '] + [condition.condition === 11 ? 'button-gray' : 'button-outline-gray']">
                            {{ t('calendar.weekdays.sun') }}
                          </button>
                        </span>

                  </div>
                </div>
              </template>
            </div>
            <div v-else class="flex flex-row items-center justify-between w-full p-4 mt-3 gap-x-4 text-center bg-white rounded-lg">
              {{ t('calendar.noConditions') }}
            </div>
          </router-link>
        </div>
      </template>


      <!--
      <select v-model="playlistSelected" class="inline-flex items-center w-full h-10 max-w-sm py-2 pl-3 pr-10 text-sm font-medium leading-4 tracking-wide transition-all duration-150 ease-linear bg-white border border-transparent rounded-sm outline-none form-select text-our-gray-160 border-our-gray-130 hover:border-our-gray-160 focus:outline-none focus:border-our-gray-190border-light-highlight-level-1">
                  <option v-for="">Playlist 1</option>
                  <option v-if="selectedCalendarId === null" :value="null" disabled>No calendar assigned</option>
                              <option v-for="calendar in calendarsList" :key="calendar.name" :value="calendar.id">
                                <span v-if="calendar.id === 'new'" class="font-medium"><i>{{ calendar.id }} / {{
                                    calendar.name
                                                                                          }} - NEW</i></span>
                                <span v-else>{{ calendar.id }} / {{ calendar.name }} - PLAYLIST {{ calendar.playlist.name }}</span>
                              </option>
                </select>
      -->

      <!-- TODO DATA FOR PLAYLIST <div>
        <span class="block mb-4 text-sm leading-5 text-our-gray-160">Conditions and times for the selected playlist</span>
        <div class="gap-y-5">
          <div class="gap-y-3">
            <ScheduleRow></ScheduleRow>
            <ScheduleRow></ScheduleRow>
          </div>
          <button type="button" class="inline-flex items-center self-start px-3 py-2 text-xs font-medium leading-4 tracking-wide transition-all duration-150 ease-linear border border-transparent rounded-sm outline-none bg-our-gray-10 text-our-gray-190 border-our-gray-160 hover:bg-our-gray-190 hover:text-white hover:border-our-gray-190 focus:outline-none focus:border-our-gray-220border-light-highlight-level-1 active:bg-our-gray-220 active:border-our-gray-220 active:text-white">
            <svg class="-ml-0.5 mr-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 20 20">
              <path d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM6 10a.5.5 0 00.5.5h3v3a.5.5 0 001 0v-3h3a.5.5 0 000-1h-3v-3a.5.5 0 00-1 0v3h-3a.5.5 0 00-.5.5z"/>
            </svg>
            <span>Add another</span>
          </button>
        </div>
      </div>-->
    </div>
  </div>
</template>

<script>
/*import GroupCard from "@/components/GroupCard";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";*/

import { useI18n } from "vue-i18n";

import ScheduleRow from "@/components/CalendarRow";
import Multiselect from "@/components/Multiselect";

/*export default {
  name: "Group detail",
  components: {
    GroupCard,
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    ScheduleRow
  },
  data() {
    return {
      groupID: null,
      groupData: {
        id: null,
        name: null,
        description: null,
        orientation: null,
        playlists: [{
          id: null,
          name: null,
          enabled: null,
          priority: null,
          //TODO load from tbl playlistorder
        }],
        groups: [{
          id: null,
          customId: null,
          name: null,
          description: null,
          groupgroup: null,
          orientation: null,
          groupstatus: null,
        }]
      }
    }
  },
  methods: {
    validateForm() {
      if (!this.groupData.name ||
          !this.groupData.orientation ||
          !this.groupData.description) {
        return false
      }
      return true
    },
    submitForm() {
      if (!this.validateForm()) {
        this.$toast.error('Required field(s) empty or invalid')
        return
      }

      this.updateGroup()
    },
    updateGroup() {
      this.$api.groups.updateGroup(this.groupData).then(() => {

        this.loadGroupData()
        this.$toast.success("Group '" + this.groupData.name + "' successfully updated", { position: 'top-right' })

      }).catch(error => {
        console.error(error)
        this.$toast.error('Failed to update group')
      })
    },
    loadGroupData() {
      this.$api.groups.getSingleGroup(this.groupID).then(response => {
        this.groupData = response.data
      }).finally(() => {
        //this.$store.dispatch('CoreUI/setContainerLoad', false)
      })
    }
  },
  beforeMount() {
    this.groupID = Number.parseInt(this.$route.params.id);

    this.loadGroupData();
  }
}*/

function initialData() {
  return {
    dataLoaded: false,
    groupData: {
      name: '',
      description: '',
      displays: [],
      playlists: [],
      orientation: 'horizontal'
    },
    displaysSelected: [],
    playlistsSelected: [],
    playlistsPlayablesList: [],
    playlistsConditionsList: [],
  }
}

export default {
  name: "GroupDetail",
  setup() {
    return useI18n()
  },
  components: {
    Multiselect,
    ScheduleRow
  },
  data() {
    return initialData()
  },
  watch: {
    '$store.state.Groups.groupData': {
      immediate: false,
      deep: true,
      handler(data) {

        if (!this.$helpers.isEqual(data, this.groupData) && parseInt(this.$route.params.id) === data.id) {

          /* Incoming data controller */
          if (Object.keys(data).length > 0) {

            this.groupData = this.$helpers.purify(data)

            /*this.$store.dispatch('Displays/fetchdisplaysSelected').then(() => {

              this.displaysSelected = this.$store.state.Displays.displays.map(key => {
                if (data.displays.some(group => group.id === key.id)) {
                  return { id: key.id, name: key.name, selected: true }
                }
                else {
                  return { id: key.id, name: key.name, selected: false }
                }
              })
            })*/

            let promises = []

            if (!this.dataLoaded) {
              promises.push(this.$store.dispatch('Displays/fetchAllDisplays').then(() => {

                this.displaysSelected = this.$helpers.purify(this.$store.state.Displays.displays).map(display => {

                  if (data.displays.some(selectedDisplay => selectedDisplay.id === display.id)) {
                    display["selected"] = true
                  }
                  else {
                    display["selected"] = false
                  }
                  return display
                })

              }))

              promises.push(this.$store.dispatch('Playlists/fetchAllPlaylists').then(() => {

                this.playlistsSelected = this.$helpers.purify(this.$store.state.Playlists.playlists).map(playlist => {

                  if (data.playlists.some(selectedPlaylist => selectedPlaylist.id === playlist.id)) {
                    playlist["selected"] = true
                  }
                  else {
                    playlist["selected"] = false
                  }
                  return playlist
                })

              }))
            }

            /*TODO filter only selected + purify before */
            this.groupData.playlists.forEach(playlist => {

              promises.push(this.$store.dispatch('Playlists/fetchPlayableOrderForOnePlaylist', playlist.id).then(response => {
                /*console.log(response.data)*/
                if (response.data.length > 0) {
                  this.playlistsPlayablesList.push(...response.data)
                }
              }))

              promises.push(this.$store.dispatch('Calendars/fetchCalendarItemsForOnePlaylist', playlist.id).then(response => {
                /*console.log(response.data)*/
                if (response.data.length > 0) {
                  this.playlistsConditionsList.push(response.data[0])
                }
              }))


            })

            Promise.all(promises).then(() => {
              /*this.groupData.displays = this.displaysSelected
              this.groupData.playlists = this.playlistsSelected*/

              this.dataLoaded = true
            })

          }
        }
      }
    },
    '$store.state.Displays.displays': {
      immediate: false,
      deep: true,
      handler(data) {
        this.displaysSelected = data
      }
    },
    groupData: {
      immediate: false,
      deep: true,
      handler(data) { // TODO add timeout for typing ?
        if (this.dataLoaded) {

          /* Outgoing data controller */
          if (this.$refs.form.reportValidity()) {

            let sendData = this.$helpers.purify(data)

            sendData.displays = this.displaysSelected.filter(display => display.selected)
            sendData.playlists = this.playlistsSelected.filter(playlist => playlist.selected)

            let filteredData = this.$helpers.objectFalseyAndEmptyFilter(sendData)
            this.$emit('save-changes', { ...filteredData, _type: 'group', valid: true })
          }
          else if (!this.$refs.form.reportValidity()) {
            this.$emit('save-changes', { valid: false })
          }
        }
      }
    },
    displaysSelected: {
      immediate: false,
      deep: true,
      handler(data) {
        if (this.dataLoaded) {
          this.groupData.displays = data.filter(item => item.selected)
        }
      }
    },
    playlistsSelected: {
      immediate: false,
      deep: true,
      handler(data) {
        if (this.dataLoaded) {
          this.groupData.playlists = data.filter(item => item.selected)
        }
      }
    }
  },
  created() {
    this.$store.dispatch('Groups/fetchGroup', Number.parseInt(this.$route.params.id)).then(() => {
      document.title = this.$store.state.Groups.groupData.name + ' | ' + document.title.split('|')[1]
      this.$store.dispatch('setDetailName', this.$store.state.Groups.groupData.name)
    }).catch(() => {
      this.$router.push('/404')
    })
  },
  unmounted() {
    Object.assign(this.$data, initialData())
  }
}
</script>

<style scoped>
.form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='none'%3e%3cpath d='M7 7l3-3 3 3m0 6l-3 3-3-3' stroke='%23323130' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
}

.checked\:hover\:border-our-cyan-40:checked:focus {
  @apply border-our-cyan-40
}
</style>
