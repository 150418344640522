<template>
  <div>
    <div class="gap-y-5 p-5 border border-light-level-1 rounded-level-3">

      <legend>{{ t('settings.events.title') }}</legend>

      <div class="flex flex-row items-center gap-x-4">
        <label for="name" class="m-0">
          {{ t('settings.events.lastGeneratedTime') }}
        </label>
        <transition name="fade" mode="out-in">
          <p>
          <span class="text-our-cyan-80" v-if="lastGenerateTimeLoaded"
                v-text="$helpers.dateAndTime($store.state.Events.lastGeneratedTime)"></span>
          <Loader v-if="!lastGenerateTimeLoaded" class="w-4 h-4 text-our-cyan-60"></Loader>
          </p>
        </transition>
      </div>

      <button type="button" v-on:click="generateList()" class="h-10 text-sm rounded-md button button-outline-gray">
        <Loader class="w-4 h-4 mr-2" v-show="generatingList"></Loader>
        <span>{{ t('settings.events.regenerate') }}</span>
      </button>
    </div>

    <div class="gap-y-5 p-5 border border-light-level-1 rounded-level-3 mt-4">

      <legend>{{ t('global.menu.settings') }}</legend>

      <div class="flex flex-row items-center gap-x-3">
        <button type="button" v-on:click="$store.dispatch('Slideover/toggleSlideover', true), $store.dispatch('Slideover/setSlideoverTitle', t('settings.buttons.changePassword')), $store.dispatch('Slideover/setSlideoverComponent', 'ChangePassword')" class="h-10 text-sm rounded-md button button-outline-gray">
          <span> {{ t('settings.buttons.changePassword') }}</span>
        </button>

        <button type="button"
            v-on:click="$store.dispatch('Slideover/toggleSlideover', true), $store.dispatch('Slideover/setSlideoverTitle', t('settings.buttons.changeServerName')), $store.dispatch('Slideover/setSlideoverComponent', 'ChangeServerName')"
            v-if="$store.state.Users.loggedUser.role?.name === 'Admin'"
            class="h-10 text-sm rounded-md button button-outline-gray">
          <span>{{ t('settings.buttons.changeServerName') }}</span>
        </button>

        <button type="button" v-on:click="$store.dispatch('Slideover/toggleSlideover', true), $store.dispatch('Slideover/setSlideoverTitle', t('settings.buttons.createNewServer')), $store.dispatch('Slideover/setSlideoverComponent', 'CreateNewServer')" class="h-10 text-sm rounded-md button button-outline-gray">
          <span>{{ t('settings.buttons.createNewServer') }}</span>
        </button>

      </div>
    </div>
  </div>
</template>

<script>
import {useI18n} from "vue-i18n"

export default {
  name: "Settings",
  setup() {
    return useI18n()
  },
  data() {
    return {
      generatingList: false,
      lastGenerateTimeLoaded: false,
    }
  },
  methods: {
    generateList() {
      let _this = this;
      this.generatingList = true

      this.$store.dispatch('Events/resetList').then(() => {

        setTimeout(function () {
          _this.getLastGeneratedTime(_this);
          _this.$store.dispatch('Notifications/send', {
            variant: 'success',
            message1: _this.t('settings.notification.eventListCalendarUpdated'),
            message2: _this.t('settings.notification.eventsGenerated'),
            time: new Date().toISOString(),
            duration: 5000,
            overlay: false
          })
        },10);

      }).catch(e => {
        console.log(e);
      })

      setTimeout(() => {
        _this.$store.dispatch('Notifications/send', {
          variant: 'info',
          message1: this.t('settings.notification.inProgress'),
          message2: this.t('settings.notification.eventsGeneratedServer'),
          time: new Date().toISOString(),
          duration: 5000,
          overlay: false
        })

        setTimeout(() => {
          _this.generatingList = false
        }, 300)
      }, 840)
    },
    getLastGeneratedTime(context = this) {
      this.$store.dispatch('Events/fetchLastGeneratedTime').then(res => {
        context.lastGenerateTimeLoaded = true;
      })
    }
  },
  mounted() {
    this.getLastGeneratedTime();
  }
}
</script>

<style scoped>

</style>
