<template>
  <div class="grid gap-y-5">
    <transition name="fade" mode="out-in">
      <div class="gap-y-3" :key="calendarData.playlist.id">
        <transition name="fade" mode="out-in">
          <div v-if="calendarItemsActive.length === 0" class="banner--data-information gap-y-4 bg-our-gray-50">
            <div class="banner--data-information-inner">
              {{ t('calendar.playAlways') }}
            </div>
          </div>
          <div class="flex flex-col gap-y-3" v-else>
            <transition-group name="flip-list">
              <CalendarRow v-for="(calendarItem, index) in calendarItemsActive" :data="{ calendarItem: calendarData.calendaritemdetails.find(item => item.order === calendarItem.order), 'calendarItemsActiveLength': calendarItemsActive.length }" :key="calendarItem.customid + ' ' + calendarData.playlist.id" :index="index" v-on:move-down="moveDown($event)" v-on:move-up="moveUp($event)" v-on:remove-row="removeRow($event)">
                <!--<div class="flex justify-center">
                  <div class="px-3 my-3 rounded-lg bg-our-gray-50 text-our-gray-200" v-if="index !== calendarData.calendaritemdetails.length - 1">
                    <span class="text-sm font-semibold leading-8 tracking-wide uppercase">and</span>
                  </div>
                </div>-->
                <!--<div class="flex justify-center">
                  <div class="p-2 my-3 rounded-full bg-our-gray-50 text-our-gray-160" v-if="index !== calendarData.calendaritemdetails.length - 1">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.8834 3.00673L12 3C12.5128 3 12.9355 3.38604 12.9933 3.88338L13 4V11H20C20.5128 11 20.9355 11.386 20.9933 11.8834L21 12C21 12.5128 20.614 12.9355 20.1166 12.9933L20 13H13V20C13 20.5128 12.614 20.9355 12.1166 20.9933L12 21C11.4872 21 11.0645 20.614 11.0067 20.1166L11 20V13H4C3.48716 13 3.06449 12.614 3.00673 12.1166L3 12C3 11.4872 3.38604 11.0645 3.88338 11.0067L4 11H11V4C11 3.48716 11.386 3.06449 11.8834 3.00673L12 3L11.8834 3.00673Z" />
                    </svg>
                  </div>
                </div>-->
              </CalendarRow>
            </transition-group>
          </div>
        </transition>
      </div>
    </transition>
    <div class="flex justify-end">
      <button type="button" v-on:click="addRow()" class="h-10 text-sm rounded-lg button button-outline-gray">
        <svg class="-ml-0.5 mr-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 20 20">
          <path d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM6 10a.5.5 0 00.5.5h3v3a.5.5 0 001 0v-3h3a.5.5 0 000-1h-3v-3a.5.5 0 00-1 0v3h-3a.5.5 0 00-.5.5z"/>
        </svg>
        <span>{{ t('calendar.addCondition') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import CalendarRow from "@/components/CalendarRow"
import store from "@/store";
import {useI18n} from "vue-i18n";

function initialData() {
  return {
    dataLoaded: false
  }
}

export default {
  name: "Calendar",
  components: {
    CalendarRow
  },
  props: {
    data: Object
  },
  data() {
    return initialData()
  },
  setup() {
    return useI18n()
  },
  computed: {
    calendarData() {
      return this.data
    },
    /*sortedCalendarItems() {

      /!*let array = this.$helpers.purify(this.calendarData.calendaritemdetails)*!/

      return array.sort((a, b) => {
        return a.order - b.order;
      })
    },*/
    calendarItemsActive() {

      let array = this.$helpers.purify(this.calendarData.calendaritemdetails)

      return array.filter(calendarItem => {

        if (calendarItem.customid === null) {
          /*store.dispatch('Notifications/send', {
            variant: 'error',
            message1: 'customid undefined',
            message2: 'Calendar.vue - calendarItemsActive()',
            time: new Date().toISOString(),
            duration: false,
            overlay: false
          })*/
        }
        else if (calendarItem.customid.toString().includes('delete')) {
          return false
        }
        else if (typeof calendarItem.customid === 'string') {
          calendarItem.customid = parseInt(this.$helpers.replaceStringMultiple(calendarItem.customid, ['-new'], ['']))
          return true
        }
        else {
          return true
        }
        /*return !playable.id.toString().includes('delete')*/
      })
    },
  },
  methods: {
    moveDown(order) {
      this.calendarData.calendaritemdetails.forEach((calendarItem) => {
        if (calendarItem.order === order) {
          calendarItem.order += 1
        }
        else if (calendarItem.order === order + 1 && calendarItem.order !== 1) {
          calendarItem.order -= 1
        }
      })
      this.calendarData.calendaritemdetails.sort((a, b) => a.order - b.order)
    },
    moveUp(order) {
      this.calendarData.calendaritemdetails.forEach((calendarItem) => {
        if (calendarItem.order === order && calendarItem.order !== 1) {
          calendarItem.order -= 1
        }
        else if (calendarItem.order === order - 1) {
          calendarItem.order += 1
        }
      })
      this.calendarData.calendaritemdetails.sort((a, b) => a.order - b.order)
    },
    removeRow(order) {
      let removedItems = [];
      this.calendarData.calendaritemdetails.forEach((calendarItem, key) => {

        if (calendarItem.order === order) {
          if(calendarItem.customid.toString().includes("-new")){
            removedItems.push(key);
          }else{
            calendarItem.customid = calendarItem.customid + '-delete'
          }

          calendarItem.order = null
        }
        else if (calendarItem.order > order && calendarItem.order !== 1) {
          calendarItem.order -= 1
        }
      })

      removedItems.forEach(removedItemKey => {
        this.calendarData.calendaritemdetails.splice(removedItemKey,  1);
      })
    },
    addRow() {
      this.calendarData.calendaritemdetails.push({
        customid: this.$store.state.Calendars.calendarItemHighestId + 1 + '-new',
        order: this.calendarItemsActive.length + 1,
        precondition: 1,
        condition: 1,
      })
      this.$store.dispatch('Calendars/setHighestCalendarItemId', this.$store.state.Calendars.calendarItemHighestId + 1)
    },
  },
  created() {

    let promises = []

    promises.push(this.$store.dispatch('Calendars/setCalendarItemPreconditions'))
    promises.push(this.$store.dispatch('Calendars/fetchHighestCalendarItemId'))

    Promise.all(promises).then(() => {
      this.dataLoaded = true
    })
  }
}
</script>

<style scoped>

</style>
