import playlists from '@/api/modules/playlists'
import helpers from '@/helpers'

const state = {
	count: 0,
	playlists: [],
	playlistData: {},
	playlistOrderData: [],
	playlistOrderLastSavedData: [],
	playlistOrderHighestId: null
}


const getters = {
	getAllPlaylists(state) {
		return state.playlists;
	},
	getPlaylistData(state) {
		return state.playlistData;
	},
	getPlaylistOrderData(state) {
		return state.playlistOrderData;
	},
	getPlaylistOrderLastSavedData(state){
		return state.playlistOrderLastSavedData;
	}
}


const mutations = {
	SET_PLAYLISTS_COUNT(state, data) {
		state.count = data
	},
	SET_PLAYLISTS_LIST(state, data) {
		state.playlists = data
	},
	SET_PLAYLIST_DATA(state, data) {
		state.playlistData = data
	},
	SET_PLAYLIST_PLAYABLE_ORDER(state, data) {
		state.playlistOrderData = data
	},
	SET_PLAYLIST_PLAYABLE_ORDER_LAST_SAVED(state, data) {
		state.playlistOrderLastSavedData = data
	},
	SET_PLAYLIST_PLAYABLE_ORDER_HIGHEST_ID(state, id) {
		state.playlistOrderHighestId = id
	},
}


const actions = {
	fetchPlaylistsCount({ commit }, payload) {
		return new Promise((resolve, reject) => {
			playlists.getPlaylistsCount(payload).then(response => {
				commit('SET_PLAYLISTS_COUNT', response.data)
				resolve(response)
			}, error => {
				reject(error);
			})
		})
	},

	fetchAllPlaylists({ commit }, payload) {
		return new Promise((resolve, reject) => {
			playlists.getAllPlaylists(payload).then(response => {
				commit('SET_PLAYLISTS_LIST', response.data)
				resolve(response)
			}, error => {
				reject(error);
			})
		})
	},
	fetchPlaylistsPage({ commit }, payload) {
		return playlists.getPlaylistsPage(payload).then((response) => {
			commit('SET_PLAYLISTS_LIST', response.data)
		})
	},
	fetchPlaylist({ commit }, playlistId) {
		return new Promise((resolve, reject) => {
			playlists.getSinglePlaylist(playlistId).then(response => {
				commit('SET_PLAYLIST_DATA', response.data)
				resolve(response);
			}, error => {
				reject(error);
			})
		})
	},
	createPlaylist({ commit }, payload) {
		return new Promise((resolve, reject) => {
			playlists.createNewPlaylist(payload).then(response => {
				response.data.id
				commit('SET_PLAYLIST_DATA', response.data)
				resolve(response);
			}, error => {
				reject(error);
			})
		})
	},
	updatePlaylist({ commit }, payload) {
		return new Promise((resolve, reject) => {
			playlists.updateExistingPlaylist(payload).then(response => {
				commit('SET_PLAYLIST_DATA', response.data)
				resolve(response);
			}, error => {
				reject(error);
			})
		})
	},
	fetchPlayableOrderForOnePlaylist({ commit }, playlistId) {
		return new Promise((resolve, reject) => {
			playlists.getPlayableOrderForOnePlaylist(playlistId).then(response => {
				response.data.forEach(playableItem => {
					if (playableItem.duration !== null) {
						//playableItem.duration = helpers.millisecondsToMinutesAndSeconds(playableItem.duration)
					}
				})
				commit('SET_PLAYLIST_PLAYABLE_ORDER', response.data)
				resolve(response);
			}, error => {
				reject(error);
			})
		})
	},
	fetchPlayableOrderLastSavedForOnePlaylist({ commit }, playlistId) {
		return new Promise((resolve, reject) => {
			playlists.getPlayableOrderForOnePlaylist(playlistId).then(response => {
				response.data.forEach(playableItem => {
					if (playableItem.duration !== null) {
						//playableItem.duration = helpers.millisecondsToMinutesAndSeconds(playableItem.duration)
					}
				})
				commit('SET_PLAYLIST_PLAYABLE_ORDER_LAST_SAVED', response.data)
				resolve(response);
			}, error => {
				reject(error);
			})
		})
	},
	updatePlayableOrderFromSlideOver({commit},data) {
		commit('SET_PLAYLIST_PLAYABLE_ORDER', data)
	},
	fetchHighestPlayableOrderId({ commit }, playlistId) {
		return new Promise((resolve, reject) => {
			playlists.getHighestPlayableOrderId(playlistId).then(response => {
				let id
				if (response.data.length > 0) {
					id = response.data[0].customid
				}
				else {
					id = 0
				}
				commit('SET_PLAYLIST_PLAYABLE_ORDER_HIGHEST_ID', id)
				resolve(response);
			}, error => {
				reject(error);
			})
		})
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
