<template>
  <div class="gap-y-7">

              <div v-if="billingData && billingData.currency">
                <div class="w-full flex flex-row justify-between gap-x-4">
                  <div class="flex flex-row items-center gap-x-4">
                    <label for="name" class="m-0">
                      {{ t('billing.tableHeader.period') }}: {{ billingData.billingPeriodFrom }} - {{ billingData.billingPeriodTo }}
                    </label>
                  </div>
                </div>
                <br />
                <div class="w-full flex flex-row justify-between gap-x-4">
                  <div class="flex flex-row items-center gap-x-4">

                    <table class="">
                      <tr>
                        <td><strong>{{ t('billing.tableHeader.displayName') }}</strong></td>
                        <td class="border-l"><strong>{{ t('billing.tableHeader.activeDays') }}</strong></td>
                        <td class="border-l"><strong>{{ t('billing.tableHeader.price') }}</strong></td>
                      </tr>

                      <tr v-for="actualBillingDetail in billingData.billingDetails" :key="actualBillingDetail.displayId">
                        <td> {{ actualBillingDetail.displayName }} </td>
                        <td class="border-l"> {{ actualBillingDetail.playedDays }} </td>
                        <td class="border-l"> {{ actualBillingDetail.endMonthPrice }} {{ billingData.currency.name }} </td>
                      </tr>

                      <tr class="border-t">
                        <td>&nbsp;</td>
                        <td class="border-l"><strong> {{ billingData.playedDays }} </strong></td>
                        <td class="border-l"><strong> {{ billingData.endMonthPrice }} {{ billingData.currency.name }} </strong></td>
                      </tr>

                      <tr>
                        <td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td>
                      </tr>

                      <tr>
                        <td>&nbsp;</td>
                        <td>{{ t('billing.tableHeader.vat') }} 21%</td>
                        <td class="border-l"> {{ ((billingData.endMonthPrice)*0.21).toFixed(4) }} {{ billingData.currency.name }} </td>
                      </tr>

                      <tr>
                        <td>&nbsp;</td>
                        <td class="border-t"><strong>{{ t('billing.tableHeader.total') }}</strong></td>
                        <td class="border-l border-t"><strong> {{ ((billingData.endMonthPrice)*1.21).toFixed(4) }} {{ billingData.currency.name }} </strong></td>
                      </tr>

                    </table>
                  </div>
                </div>


              </div>
              <div v-else>
                No data
              </div>
  </div>
</template>

<script>
/*import GroupCard from "@/components/GroupCard";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";*/

import { useI18n } from "vue-i18n";

function initialData() {
  return {
    dataLoaded: false,
    billingData: {
      name: '',
      billingDetails: [],
      price: 0,
      priceVAT: 0,
      priceWithoutVAT: 0,
      billingPeriodFrom: null,
      billingPeriodTo: null
    },
    displaysSelected: [],
    playlistsSelected: [],
    playlistsPlayablesList: [],
    playlistsConditionsList: [],
  }
}

export default {
  name: "BillingDetail",
  setup() {
    return useI18n()
  },
  data() {
    return initialData()
  },
  methods: {
    getServerIPv4(baseURL){
      let IPv4
      IPv4 = baseURL.replace('https://', '');
      IPv4 = IPv4.replace('http://', '');
      IPv4 = IPv4.replace('/', '');
      IPv4 = IPv4.split(':')[0] // port
      return IPv4
    }
  },
  created() {

    let params = {
      'serverIPv4': this.getServerIPv4(this.$axios.defaults.baseURL),
      'billingId' : Number.parseInt(this.$route.params.id)
    }

    this.$store.dispatch('Billings/fetchBillingDetail', params).then((response) => {
      this.billingData = response.data;
      this.$store.dispatch('setDetailName', this.billingData.billingPeriodFrom + ' - ' + this.billingData.billingPeriodTo)
    }).catch(() => {
      this.$router.push('/404')
    })

  },
  unmounted() {
    Object.assign(this.$data, initialData())
  }
}
</script>

<style scoped>
.form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='none'%3e%3cpath d='M7 7l3-3 3 3m0 6l-3 3-3-3' stroke='%23323130' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
}

.checked\:hover\:border-our-cyan-40:checked:focus {
  @apply border-our-cyan-40
}
</style>
