import axios from 'axios'
const END_POINT_AUTH_SERVER = process.env.VUE_APP_AUTH_SERVER
const END_POINT_CUSTOMER_DETAILS = '/customer-details'

const getBillingsDataByIPv4 = (serverIPv4) => {
	return axios({
		baseURL: END_POINT_AUTH_SERVER + '/server-billings-data/' + serverIPv4,
		method: 'GET',
		withCredentials: false,
        headers: {
            'Authorization': localStorage.getItem('strapi-auth-token')
          }
	})
}

const getBillingDataDetail = (payload) => {
	return axios({
		baseURL: END_POINT_AUTH_SERVER + '/server-billing-history-data' + '/' + payload.serverIPv4 + '/' + payload.billingId,
		method: 'GET',
		withCredentials: false,
        headers: {
            'Authorization': localStorage.getItem('strapi-auth-token')
        }
	})
}

const getServerPricingData = (payload) => {
	return axios({
		baseURL: END_POINT_AUTH_SERVER + '/server-trial-state-data' + '/' + payload.displayId  + '/' + payload.serverIPv4,
		method: 'GET',
		withCredentials: false,
        headers: {
            'Authorization': localStorage.getItem('strapi-auth-token')
        }
	})
}

const getCurrencies = () => {
	return axios({
		baseURL: END_POINT_AUTH_SERVER + '/currencies',
		method: 'GET',
		withCredentials: false,
        headers: {
            'Authorization': localStorage.getItem('strapi-auth-token')
          }
	})
}

const updateCustomerDetails = (payload) => {
    return axios({
        baseURL: END_POINT_AUTH_SERVER + END_POINT_CUSTOMER_DETAILS + '/' + payload.serverIPv4 + '/' + payload.data.id,
        data: payload.data,
        method: 'PUT',
        withCredentials: false,
        headers: {
            'Authorization': localStorage.getItem('strapi-auth-token')
        }
    })
}

const createCustomerDetails = (payload) => {
	return axios({
		baseURL: END_POINT_AUTH_SERVER + END_POINT_CUSTOMER_DETAILS + '/' + payload.serverIPv4,
		data: payload.data,
		method: 'POST',
        withCredentials: false,
        headers: {
            'Authorization': localStorage.getItem('strapi-auth-token')
        }
	})
}

const createPayment = (payload) => {
	return axios({
		baseURL: END_POINT_AUTH_SERVER + '/creditcards-create-payment',
		data: payload,
		method: 'POST',
        withCredentials: false,
        headers: {
            'Authorization': localStorage.getItem('strapi-auth-token')
        }
	})
}

export default {
	getBillingsDataByIPv4,
    getBillingDataDetail,
    getServerPricingData,
    getCurrencies,

    updateCustomerDetails,
    createCustomerDetails,
    
    createPayment
}
