import axios from 'axios'

const END_POINT = '/devices'

const getAllDevices = (_params = {}) => {
	return axios({
		url: END_POINT,
		method: 'GET',
		params: _params
	})
}

const getSingleDevice = (deviceID) => {
	return axios({
		url: END_POINT + '/' + deviceID,
		method: 'GET'
	})
}

const getDevicesCount = () => {
	return axios({
		url: END_POINT + '/count',
		method: 'GET'
	})
}

const getDevicesPage = (_start, _limit) => {
	return axios({
		url: END_POINT,
		params: { _start, _limit },
		method: 'GET'
	})
}

const addNewDevice = (data) => {
	return axios({
		url: END_POINT,
		data: data,
		method: 'POST'
	})
}

const updateExistingDevice = (data) => {
	return axios({
		url: END_POINT + '/' + data.id,
		data: data,
		method: 'PUT'
	})
}

const deleteDevice = (deviceID) => {
	return axios({
		url: END_POINT + '/' + deviceID,
		method: 'DELETE'
	})
}

export default {
	getAllDevices,
	getSingleDevice,
	getDevicesCount,
	getDevicesPage,
	addNewDevice,
	updateExistingDevice,
	deleteDevice
}
