import { createApp } from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import router from './router'
import store from './store'
import i18n from './i18n'
import axios from 'axios'
import API from './api/index.js'
import moment from 'moment'
import helpers from "./helpers"
import clickOutside from './library/click-outside'
import VueJsonPretty from 'vue-json-pretty'
import VuePlyr from 'vue-plyr'
import Treeselect from 'vue3-treeselect'
import 'vue3-treeselect/dist/vue3-treeselect.css'
import * as onLoad from 'vue-onload'
import VueDraggable from 'vue-draggable'

/* Components */
import Loader from "./components/Loader"
import Tooltip from "./components/Tooltip"
import TooltipModal from "./components/TooltipModal"


/* Axios */
axios.defaults.baseURL = process.env.VUE_APP_AUTH_SERVER
if (!!localStorage.signageBaseUrl) { // if localStorage exists...
	if (localStorage.signageBaseUrl !== 'undefined') {
		axios.defaults.baseURL = localStorage.signageBaseUrl
	}
}

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

/*axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8'
axios.defaults.headers.post['Accept'] = 'application/json'*/
axios.defaults.withCredentials = true

const token = localStorage.getItem('strapi-token')
if (token) {
	axios.defaults.headers.common['Authorization'] = token
}

/*axios.defaults.headers.Authorization = 'Bearer ' + window.localStorage.getItem("strapi-token")*/


/* Vue */
const app = createApp(App)
app.use(i18n)
app.use(router)
app.use(store)
app.use(onLoad.plugin)
app.use(VuePlyr, {plyr: {}})
app.use(VueDraggable)



var momentDurationFormatSetup = require("moment-duration-format");
app.config.globalProperties.$axios = axios
app.config.globalProperties.$api = API
app.config.globalProperties.$moment = moment
app.config.globalProperties.$moment.locale(localStorage.language || process.env.VUE_APP_I18N_LOCALE || 'en');
app.config.globalProperties.$helpers = helpers
app.config.globalProperties.$duration = momentDurationFormatSetup
app.config.globalProperties.$buildDate = new Date(document.documentElement.dataset.buildTimestampUtc);

/* Register global components */
app.component('Loader', Loader)
app.component('Tooltip', Tooltip)
app.component('TooltipModal', TooltipModal)
app.component('VueJsonPretty', VueJsonPretty)
app.component('Treeselect', Treeselect)

/* Click outside directive */
app.directive('click-outside', clickOutside)

/* Error handler */
// TODO store.dispatch('Notifications/send', {
// 			variant: 'error',
// 			message1: error.name,
// 			message2: error.message,
// 			time: new Date().toISOString(),
// 			duration: false,
// 			overlay: false
// 		})

app.mount('#app')
feather.replace()
