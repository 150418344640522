import axios from 'axios'

const END_POINT = '/display-groups';
const END_POINT_CI = '/calendar-items';
const END_POINT_CI_DETAILS = '/calendar-item-details';
const END_POINT_CI_CONDITIONS = '/calendar-item-detail-conditions';
const END_POINT_CI_CONJUCTIONS = '/calendar-item-detail-conjunctions';
const END_POINT_CI_PRECONDITIONS = '/calendar-item-detail-preconditions';

const getAllGroups = () => {
	return axios({
		url: END_POINT,
		method: 'GET'
	})
}

const getSingleGroup = (groupID) => {
    return axios({
        url: END_POINT + '/' + groupID,
        method: 'GET'
    })
}

const getGroupsCount = () => {
    return axios({
        url: END_POINT + '/count',
        method: 'GET'
    })
}

const getGroupsPage = (_start, _limit) => {
    return axios({
        url: END_POINT,
        params: { _start, _limit },
        method: 'GET'
    })
}

const createGroup = (data) => {
    return axios({
        url: END_POINT,
        data: data,
        method: 'POST'
    })
}

const updateGroup = data => {
    return axios({
        url: END_POINT + '/' + data.id,
        data: data,
        method: 'PUT'
    })
}

const deleteGroup = (groupID) => {
    return axios({
        url: END_POINT + '/' + groupID,
        method: 'DELETE'
    })
}

const getCalendarItemsForOneGroupAndPlaylist = (groupID, playlistID) => {
    return axios({
		url: END_POINT_CI + '?displaygroup=' + groupID + '&playlist=' + playlistID,
		method: 'GET'
	})
}

const getCalendarItemsDetailsForOneCalendarItems = (calendarItemID) => {
    return axios({
		url: END_POINT_CI_DETAILS + '?calendaritem=' + calendarItemID,
		method: 'GET'
	})
}

const getAllCalendarItemDetailConditions = () => {
    return axios({
		url: END_POINT_CI_CONDITIONS,
		method: 'GET'
	})
}

const getAllCalendarItemDetailConjunctions = () => {
    return axios({
		url: END_POINT_CI_CONJUCTIONS,
		method: 'GET'
	})
}

const getAllCalendarItemDetailPreconditions = () => {
    return axios({
		url: END_POINT_CI_PRECONDITIONS,
		method: 'GET'
	})
}

export default {
    // Groups
	getAllGroups,
	getSingleGroup,
	getGroupsCount,
	getGroupsPage,
	createGroup,
	updateGroup,
    deleteGroup,

    // Calendar Items
    getCalendarItemsForOneGroupAndPlaylist,

    // Calendar Item Details
    getCalendarItemsDetailsForOneCalendarItems,

    // Calendar Item Detail - helpers tbl
    getAllCalendarItemDetailConditions,
    getAllCalendarItemDetailConjunctions,
    getAllCalendarItemDetailPreconditions
}
