<template>
  <div>
    <div class="relative flex flex-row items-stretch h-full max-h-screen gap-x-7">

      <div class="flex flex-grow overflow-hidden rounded-level-1">
        <transition name="fade" mode="out-in">
          <div class="w-full aspect-w-2 aspect-h-1 rounded-level-2 overflow-hidden">
            <img v-if="displayData.screen?.formats?.small" ref="image"
                 :src="$axios.defaults.baseURL + (displayData.screen.formats.large.url).substring(1)"
                 class="w-full bg-our-gray-50 object-cover">
            <div v-else class="w-full h-full bg-our-gray-50"></div>
          </div>
        </transition>
      </div>

      <div class="flex flex-col">
        <div class="flex flex-grow items-stretch p-level-1 bg-light-level-2 rounded-level-1">
          <div class="flex flex-col items-stretch justify-end h-full w-[0.3rem] overflow-hidden rounded-full bg-light-level-1"
               style="content-visibility: auto">
            <div class="transition-all duration-1000 ease-linear bg-light-highlight-level-1"
                 :style="`height: 25%`"></div>
          </div>
        </div>
      </div>

      <!--      <div class="flex"> &lt;!&ndash;overflow-hidden rounded-l-full&ndash;&gt;
              <div class="flex items-stretch px-4 py-1 gap-x-2 font-medium tracking-wide text-white rounded-full bg-our-gray-190 border-our-gray-190">
                &lt;!&ndash;rounded-r-xl py-1 px-3&ndash;&gt;
                <div class="flex items-center">
                  <span class="py-1.5">
                    Display name
                  </span>
                </div>
              </div>
            </div>

            <div class="w-full h-full">
              <img :src="require('@/assets/display-placeholder.webp')" class="flex object-contain h-full mx-auto rounded-xs">
            </div>

            <div class="flex flex-row items-stretch p-1 gap-x-1.5 rounded-xl bg-our-gray-50">
              <div class="flex items-center px-3 gap-x-2 bg-white rounded-lg">
                <span class="relative flex w-2 h-2 animate-pulse">
                          &lt;!&ndash;<span class="absolute inline-flex w-full h-full rounded-full animate-ping bg-our-cyan-40"></span>&ndash;&gt;
                          <span class="relative inline-flex w-2 h-2 rounded-full bg-our-cyan-50"></span>
                        </span>
                <span class="text-our-gray-160">
                        Updating in 5s ..
                </span>
                &lt;!&ndash;<div class="w-6 h-6 font-semibold text-center text-white rounded-md bg-our-cyan-50">
                  4s
                </div>&ndash;&gt;
              </div>
              <div class="tooltip-wrapper relative">
                <div class="flex flex-row items-center p-1 gap-x-4 rounded-lg bg-our-gray-10">
                  <router-link to="/playlists/detail/1" class="ml-3 text-sm font-medium leading-5 border-b border-our-cyan-50 text-our-cyan-60">
                    Playlist 1
                  </router-link>
                  <div class="w-32 overflow-hidden rounded-full bg-our-gray-70" style="height: 5px">
                    <div class="w-1/2 h-full bg-our-cyan-50"></div>
                  </div>
                  <span class="flex flex-row items-center gap-x-1 text-sm font-medium leading-5 text-our-gray-160">
                    <span>
                      in
                    </span>
                    <router-link to="/groups/detail/1" class="flex items-center justify-center text-sm px-1.5 py-1 transition-all duration-150 ease-linear border rounded-md outline-none bg-our-gray-40 border-our-gray-40 focus:border-our-gray-180 focus:outline-none focus:bg-our-gray-180 focus:text-whiteborder-light-highlight-level-1 cursor-pointer text-our-gray-180 hover:text-our-gray-180 hover:border-our-gray-180 ">
                      Group 1
                    </router-link>
                  </span>
                </div>
                <Tooltip text="00:00:00" color="our-gray-30" background="our-gray-170"></Tooltip>
              </div>
            </div>
          </div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: 'DisplayLive',
  data() {
    return {
      displayData: {
        id: null,
        screen: null,
        customId: null,
        active: null,
        name: null,
        description: null,
        orientation: null,
        displaystatus: null,
        displaygroups: [],
        devices: []
      },
    }
  },
  watch: {
    '$store.state.Displays.displayData': {
      immediate: true,
      deep: true,
      handler(data) {
        if (!this.$helpers.isEqual(data, this.displayData)) {
          if (Object.keys(data).length > 0) {
            this.displayData = this.$helpers.purify(data)
            /* this.$store.dispatch('setDetailName', data.name) */
          }
        }
      }
    }
  },
  created() {
    this.$store.dispatch('Displays/fetchDisplay', Number.parseInt(this.$route.params.id))
  }
}
</script>

<style scoped>

</style>
