<template>
  <div v-if="!globalErrorBypass && !serverConnection"
       class="absolute opacity-75 bottom-0 top-0 left-0 right-0 z-50 bg-our-gray-190 text-our-gray-30 flex flex-wrap justify-center mx-auto content-center">
    <p class="mb-2 text-center"><i data-feather="alert-triangle"></i></p>
    <p class="w-full text-center">Connection to the server is lost. Check your internet connection</p>
    <button @click="bypassServerError" type="button" class="mt-8 inline-flex items-center px-3 py-2 border border-our-gray-60
     text-sm leading-4 font-medium rounded-md text-our-gray-40 hover:bg-our-gray-160 focus:outline-none
     focus:ring-2 focus:ring-offset-2 focus:ring-our-gray-500">
      Bypass message
    </button>
  </div>
  <div v-if="!serverConnection"
       class="absolute top-0 left-0 right-0 z-40 h-6 bg-our-red text-our-gray-10 w-full text-center text-sm">Server
    unreachable. Check console.
  </div>
  <router-view v-slot="{ Component }">
    <transition name="fade-router-view" appear>
      <component :is="Component"/>
    </transition>
  </router-view>
</template>

<script>
import store from './store'
import router from './router'
import LogRocket from 'logrocket'

if (process.env.NODE_ENV !== 'development') {
  LogRocket.init('veuuku/magenta-prod')
}


export default {
  name: 'App',
  data() {
    return {
      serverConnection: true,
      globalErrorBypass: false
    }
  },
  created() {

    let _this = this

    this.$axios.interceptors.response.use(res => res, function (err) { // old token
      // TODO handle nginx 413 etc.

      store.dispatch('Notifications/send', {
        variant: 'error',
        message1: `${ err.code } - ${ err.message }`,
        /* message2: 'Interceptor logged you out.', */
        time: new Date().toISOString(),
        /* duration: 5000, */
        overlay: false
      })

      console.log('Axios interceptor - error:', err)

      if (typeof err.response === 'undefined') {
        if (typeof err.message !== 'undefined') {
          console.warn('Axios interceptor - error message:', err.message)
        }
        else {
          _this.serverConnection = false
          feather.replace() // just for an icon
          console.warn('Axios interceptor - response undefined:', { err })
          console.error('Connection with server lost', _this.serverConnection)
        }
      }
      else {
        _this.serverConnection = true
        if ([ 401, 403 ].includes(err.response.status) && err.response.config && !err.response.config.__isRetryRequest) {
          console.log('Interceptor logged you out', err.response)

          store.dispatch('Notifications/send', {
            variant: 'warning',
            message1: `${err.response.statusCode} - ${err.response.message}`,
            message2: 'Interceptor logged you out.',
            time: new Date().toISOString(),
            duration: 5000,
            overlay: false
          })

          store.dispatch('Login/signOut')
          if (router.currentRoute.value.name !== 'Login') {
            router.push('/login')
          }
          return err
        }
        else {
          return Promise.reject(err)
        }
      }
    })

    if (this.$store.getters['Login/isAuthenticated']) {
      this.$store.dispatch('Groups/fetchAllGroups')
      this.$store.dispatch('Displays/fetchAllDisplays')

      if (process.env.NODE_ENV !== 'development') {
        LogRocket.identify(store.state.Users.loggedUser.id, {
          name: store.state.Users.loggedUser.username,
          email: store.state.Users.loggedUser.email,

          // Add your own custom user variables here, ie:
          subscriptionType: 'pro'
        })
      }
    }

    /* Error handling */
    window.addEventListener('error', function (event) {
      console.log('EventListener - error:', event)
      store.dispatch('Notifications/send', {
        variant: 'error',
        message1: event.message,
        message2: event.filename,
        time: new Date().toISOString(),
        /* duration: 5000, */
        overlay: false
      })
      /*this.$api.errorLogger.send(event)*/
    })
    window.addEventListener('unhandledrejection', (event) => {
      console.log('EventListener - unhandledrejection:', event)
      store.dispatch('Notifications/send', {
        variant: 'error',
        message1: event.reason.message,
        message2: event.reason.message.response ? `url: <b>${event.reason.response.config.url}</b>, method: <b>${event.reason.response.config.method}</b>, data: ${event.reason.response.config.data}` : 'response undefined',
        time: new Date().toISOString(),
        /* duration: 5000, */
        overlay: false
      })
      /*this.$api.errorLogger.send(event)*/
    })

    window.addEventListener('rejectionhandled', (event) => {
      console.log(event)
    })
  },
  methods: {
    bypassServerError() {
      this.globalErrorBypass = true
    }
  }
}
</script>

<style>

.vue-treeselect__control {
  border: none !important;
  border-radius: unset !important;
}

input:-webkit-autofill::first-line,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset;
  -webkit-text-fill-color: #323130;
  color: #323130;
}
</style>
