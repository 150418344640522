import axios from 'axios'
import store from './../index.js'

const state = {
	status: "",
	token: localStorage.getItem('strapi-token') || '',
}

const getters = {
	isAuthenticated: state => !!state.token,
	authStatus: state => state.status,
	hasAuthError: state => state.status === 'error'
}

const mutations = {
	AUTH_REQUEST(state) {
		state.status = 'loading'
	},
	AUTH_SUCCESS(state, token) {
		state.status = 'success'
		state.token = token
	},
	AUTH_ERROR(state) {
		state.status = 'error'
	},
	AUTH_LOGOUT(state) {
		delete localStorage.signageServerName;
		delete localStorage.signageBaseUrl;
		axios.defaults.baseURL = process.env.VUE_APP_AUTH_SERVER
		axios.defaults.withCredentials = false
		state.token = ''
	}
}

const actions = {
	logIn({ commit, dispatch }, data) {
		return new Promise((resolve, reject) => {
			commit('AUTH_REQUEST')
			axios({
				url: '/auth/local', data: data, method: 'POST', transformRequest: [(data, headers) => {
					delete headers.Authorization
					headers['Content-Type'] = 'application/json; charset=utf-8'
					headers['Accept'] = 'application/json'
					return JSON.stringify(data)
				}]
			}).then(response => {
					if (response.status === 200) {
						const token = 'Bearer ' + response.data.jwt
						localStorage.setItem('strapi-token', token)
						axios.defaults.headers.common['Authorization'] = token
						commit('AUTH_SUCCESS', token)
						resolve(response)
					}
					else {
						resolve(response)
					}
				}).catch(error => {
					commit('AUTH_ERROR', error)
					localStorage.removeItem('strapi-token')
					localStorage.removeItem('strapi-auth-token')
					reject(error)
				})
		})
	},
	signOut({ commit, dispatch }) {
		return new Promise((resolve, reject) => {
			commit('AUTH_LOGOUT')
			localStorage.removeItem('strapi-token')
			localStorage.removeItem('strapi-auth-token')
			delete axios.defaults.headers.common['Authorization']
			store.dispatch('resetStore')
			resolve()
		})
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
