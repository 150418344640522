import groups from '@/api/modules/groups'

const state = {
	count: 0,
	groups: [],
	groupData: {}
}


const getters = {
	getAllGroups(state) {
		return state.groups;
	},
	getGroupData(state) {
		return state.groupData;
	}
}


const mutations = {
	SET_GROUPS_COUNT(state, data) {
		state.count = data
	},
	SET_GROUPS_LIST(state, data) {
		state.groups = data
	},
	SET_GROUP_DATA(state, data) {
		state.groupData = data
	}
}


const actions = {
	fetchGroupsCount({ commit }, payload) {
		return new Promise((resolve, reject) => {
			groups.getGroupsCount(payload).then(response => {
				commit('SET_GROUPS_COUNT', response.data)
				resolve(response)
			}, error => {
				reject(error);
			})
		})
	},
	fetchAllGroups({ commit }) {
		return new Promise((resolve, reject) => {
			groups.getAllGroups().then(response => {
				commit('SET_GROUPS_LIST', response.data)
				resolve(response)
			}, error => {
				reject(error);
			})
		})
	},
	fetchGroupsPage({ commit }, payload) {
		return groups.getGroupsPage(payload).then((response) => {
			commit('SET_GROUPS_LIST', response.data)
		})
	},
	fetchGroup({ commit }, groupId) {
		return groups.getSingleGroup(groupId).then((response) => {
			commit('SET_GROUP_DATA', response.data)
			return response
		})
	},
	createGroup({ commit }, payload) {
		return new Promise((resolve, reject) => {
			groups.createGroup(payload).then(response => {
				commit('SET_GROUP_DATA', response.data)
				resolve(response);
			}, error => {
				reject(error);
			})
		})
	},
	updateGroup({ commit }, payload) {
		return new Promise((resolve, reject) => {
			groups.updateGroup(payload).then(response => {
				commit('SET_GROUP_DATA', response.data)
				resolve(response);
			}, error => {
				reject(error);
			})
		})
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
