const state = {
	showModal: false,
	modalData: []
}


const getters = {}


const mutations = {
	TOGGLE_MODAL(state, data) {
		state.showModal = data
	},
	SET_MODAL_DATA(state, data) {
		state.modalData = data
	}
}


const actions = {
	toggleModal({ commit }, data) {
		commit('TOGGLE_MODAL', data)
	},
	setModalData({ commit }, data) {
		commit('SET_MODAL_DATA', data)
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
