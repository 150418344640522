import axios from 'axios'

const END_POINT = '/locations';

const getAllLocations = () => {
	return axios({
		url: END_POINT,
		method: 'GET'
	})
}

const getSingleLocation = (locationID) => {
    return axios({
        url: END_POINT + '/' + locationID,
        method: 'GET'
    })
}

const getLocationsCount = () => {
    return axios({
        url: END_POINT + '/count',
        method: 'GET'
    })
}

const getGLocationsPage = (_start, _limit) => {
    return axios({
        url: END_POINT,
        params: { _start, _limit },
        method: 'GET'
    })
}

const createLocation = (data) => {
    return axios({
        url: END_POINT,
        data: data,
        method: 'POST'
    })
}

const updateLocation = data => {
    return axios({
        url: END_POINT + '/' + data.id,
        data: data,
        method: 'PUT'
    })
}

const deleteLocation = (locationID) => {
    return axios({
        url: END_POINT + '/' + locationID,
        method: 'DELETE'
    })
}

export default {
    // Locations
	getAllLocations,
	getSingleLocation,
	getLocationsCount,
	getGLocationsPage,
	createLocation,
	updateLocation,
    deleteLocation
}
