<template>

  <main class="min-h-screen bg-cover bg-center sm:bg-top"
        :style="{'background-image': 'url('+require('@/assets/e404bg.jpg')+')'
  }">
    <div class="max-w-7xl mx-auto px-4 py-16 text-center sm:px-6 sm:py-24 lg:px-8 lg:py-48">
      <p class="text-sm font-semibold text-black text-opacity-50 uppercase tracking-wide">{{ t('404.404error') }}</p>
      <h1 class="mt-2 text-4xl font-extrabold text-white tracking-tight sm:text-5xl">{{ t('404.outOfTheBox') }}</h1>
      <p class="mt-2 text-lg font-medium text-black text-opacity-50">{{ t('404.pageDoesntExist') }}<br> ¯\_(ツ)_/¯ </p>
      <div class="mt-6">
        <a href="/"
           class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-black text-opacity-75 bg-white bg-opacity-75 sm:bg-opacity-25 sm:hover:bg-opacity-50">
          {{ t('404.goBackHome') }}
        </a>
      </div>
    </div>
  </main>

</template>

<script>
import {useI18n} from 'vue-i18n'

import Home from "@/components/Home"
import Modal from "@/components/Modal";
import Slideover from "@/components/Slideover"
import {Menu, MenuButton, MenuItems, MenuItem} from "@headlessui/vue"
import Notification from "@/components/Notification"
import store from "@/store";

// for resetting data on $route.fullPath change, also check the watcher
function initialData() {
  return {}
}

export default {
  name: "404",
  setup() {
    return useI18n()
  },
  data() {
    return initialData()
  },
}
</script>

<style scoped>
.router-link-exact-active {
  @apply text-our-cyan-20
}

.router-link-exact-active svg {
  @apply text-our-cyan-30
}

</style>
