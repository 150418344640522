<template>
  <div class="fixed inset-0 z-50 pb-16">
    <div class="flex items-center justify-center w-full h-full">
      <div class="w-full max-w-lg align-middle bg-white overflow-hidden border border-our-gray-70 rounded-sm">
        <div class="px-6 pt-6 pb-8" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
          <div>
            <div class="flex items-center justify-center w-12 h-12 mx-auto bg-green-100 rounded-full" v-if="modal.variant === 'info'">
              <div class="p-1 border border-transparent rounded-full bg-info">
                <svg class="text-info" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" viewBox="0 0 32 32">
                  <path d="M16 2a14 14 0 100 28 14 14 0 000-28zm0 6a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm4 16.125h-8v-2.25h2.875v-5.75H13v-2.25h4.125v8H20v2.25z"/>
                </svg>
              </div>
            </div>
            <div class="mt-3 text-center mt-5">
              <h3 class="text-lg font-medium leading-6 text-gray-900" id="modal-headline">
                {{ modal.message1 }}
              </h3>
              <div class="mt-1.5">
                <p v-if="modal.variant === 'acivateDisplay'" v-html="modal.message2" class="text-sm text-gray-500"></p>
                <p v-else class="text-sm text-gray-500">
                  {{ modal.message2 }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="p-6 bg-our-gray-20">
          <div class="grid grid-flow-row-dense grid-cols-2 gap-3">
            <button type="button" v-on:click="(modal.variant === 'acivateDisplay' || modal.variant === 'deactivateDisplay' ? modal.buttonCancelAction() : $store.dispatch('Modal/toggleModal', false))" class="button button-cyan-sm">
              {{ t('global.cancel') }}
            </button>
            <button v-if="modal.variant === 'acivateDisplay'" v-on:click="modal.buttonAction()" type="button" class="button-light-highlight-level-1">
                {{ t('global.modal.activate') }}
            </button>
            <button v-else-if="modal.variant === 'deactivateDisplay'" v-on:click="modal.buttonAction()" type="button" class="button-light-highlight-level-1">
                {{ t('global.modal.deactivate') }}
            </button>
            <span v-else-if="modal.variant === 'acivateDisplayDisabled'"></span>
            <button v-else v-on:click="modal.buttonAction()" type="button" class="button-light-highlight-level-1">
                {{ t('global.ok') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useI18n} from "vue-i18n";

export default {
  name: "Modal",
  /*data() {
    return {
      variant: 'info'
    }
  },*/
  setup() {
    return useI18n()
  },
  computed: {
    modal() {
      return this.$store.state.Modal.modalData
    }
  },
  /*watch: {
    '$store.state.Modal.modalData': {
      handler() {

      }
    }
  },*/
  unmounted() {
    // delete store data
  }
}
</script>

<style scoped>

</style>
