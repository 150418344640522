<template>
  <div class="flex flex-col gap-y-5">
    <div class="grid grid-cols-2 gap-5">
      <div class="flex flex-col">
        <transition name="fade" mode="out-in">
          <div v-if="deviceData.id !== null" ref="form" class="gap-y-5 p-5 border border-light-level-1 rounded-level-3">
            <div v-if="deviceData.display !== null" class="w-full flex flex-row justify-between gap-x-4">
              <div class="flex flex-row items-center gap-x-4">
                <label class="m-0">
                  {{ t('devices.form.display_id') }}
                </label>
                <router-link :to="{ name: 'Display detail', params: { id: deviceData.display.id } }"
                             class="text-base font-medium leading-5 border-b border-our-cyan-50 text-our-cyan-60">
                  <span v-text="'#' + deviceData.display.id + ' ' + deviceData.display.name"></span>
                </router-link>
              </div>
            </div>
            <div class="w-full flex flex-row justify-between gap-x-4">
              <div class="flex flex-row items-center gap-x-4">
                <label for="name" class="m-0">
                  {{ t('devices.form.id') }}
                </label>
                <span v-text="deviceData.id"></span>
              </div>
            </div>
            <div class="w-full flex flex-row justify-between gap-x-4">
              <div class="flex flex-row items-center gap-x-4">
                <label for="name" class="m-0">
                  {{ t('devices.form.manufacturer') }}
                </label>
                <span v-text="deviceData.manufacturer"></span>
              </div>
            </div>
            <div class="w-full flex flex-row justify-between gap-x-4">
              <div class="flex flex-row items-center gap-x-4">
                <label for="name" class="m-0">
                  {{ t('devices.form.device') }}
                </label>
                <span v-text="deviceData.productName"></span>
              </div>
            </div>
            <div class="w-full flex flex-row justify-between gap-x-4">
              <div class="flex flex-row items-center gap-x-4">
                <label for="name" class="m-0">
                  {{ t('devices.form.arch') }}
                </label>
                <span v-text="deviceData.cpuName + ', ' + deviceData.arch"></span>
              </div>
            </div>
            <div class="w-full flex flex-row justify-between gap-x-4">
              <div class="flex flex-row items-center gap-x-4">
                <label for="name" class="m-0">
                  {{ t('devices.form.ram') }}
                </label>
                <span v-text="deviceData.ram"></span>
              </div>
            </div>
            <div class="w-full flex flex-row justify-between gap-x-4">
              <div class="flex flex-row items-center gap-x-4">
                <label for="name" class="m-0">
                  {{ t('devices.form.mac') }}
                </label>
                <span v-text="deviceData.mac"></span>
              </div>
            </div>
            <div class="w-full flex flex-row justify-between gap-x-4">
              <div class="flex flex-row items-center gap-x-4">
                <label for="name" class="m-0">
                  {{ t('devices.form.actions') }}
                </label>
                <button @click="deleteDevice" role="button"
                             class="text-base font-medium leading-5 border-b border-our-red text-our-red">
                  {{ t('devices.form.deleteDevice') }}
                </button>
              </div>
            </div>
          </div>
          <div v-else class="gap-y-5 p-5 border border-light-level-1 rounded-level-3">
            <div class="w-full aspect-[16/9] bg-our-gray-60 rounded-md">
              <Loader class="w-4 h-4 mx-auto realtive top-32"/>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n"

export default {
  name: "DeviceDetail",
  setup() {
    return useI18n()
  },
  data() {
    return {
      deviceData: {
        id: null,
        mac: null
      }
    }
  },
  watch: {
    '$store.state.Devices.deviceData': {
      immediate: false,
      deep: true,
      handler(data) {

        /* Mostly for initial data load - to wait for async data from API / via Vuex Store */
        if (!this.$helpers.isEqual(data, this.deviceData) && parseInt(this.$route.params.id) === data.id) {

          if (Object.keys(data).length > 0) {

            this.deviceData = this.$helpers.purify(data)

          }
        }
      }
    }
  },
  methods: {
    deleteDevice() {
      let c = confirm("Delete device?");
      if (c) {
        let id = parseInt(this.$route.params.id);
        this.$axios.delete('devices/'+id).then(res => {
          this.$store.dispatch('Notifications/send', {
            variant: 'info',
            message1: this.t('devices.notification.deviceDeletedYouPairAgain'),
            time: new Date().toISOString(),
            duration: 5000,
            overlay: false
          })
          this.$router.push('/');
        }).catch(err => {
          this.$store.dispatch('Notifications/send', {
            variant: 'error',
            message1: this.t('devices.notification.couldNotDeleteDevice'),
            time: new Date().toISOString(),
            duration: 5000,
            overlay: false
          })
          console.error(err);
          this.$router.push('/');
        });
      }
    }
  },
  created() {
    this.$store.dispatch('Devices/fetchDevice', Number.parseInt(this.$route.params.id)).then(response => {
      this.$nextTick(() => {
        this.dataLoaded = true
      })
    })
  }
}
</script>
