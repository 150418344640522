<template>
  <div class="flex items-center justify-between pt-3 pb-3 pl-6 pr-3">

    <!--<div class="flex justify-between flex-1hidden">
      <a href="#" @click="updatePagination(&#45;&#45;page)" class="relative inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-linear bg-white border border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700">
        Previous
      </a>
      <a href="#" @click="updatePagination(++page)" class="relative inline-flex items-center px-4 py-2 ml-3 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-linear bg-white border border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700">
        Next
      </a>
    </div>-->

    <div class="flex-1 flex items-center justify-between">

      <div>
        <p class="text-sm leading-5 text-our-gray-160">
          {{ t('displayed') }}

          <span v-if="allResults != 0">
						<span class="font-medium">
              {{ topRow }}
						</span>
						{{ t('upTo') }}
            <span class="font-medium">
							{{ bottomRow }}
            </span>
						{{ t('ofTotal') }}
          </span>
          <span class="font-medium">
						{{ ' ' + allResults }}
					</span>
          {{ t('results') }}
        </p>
      </div>

      <div class="relative flex flex-row p-2 bg-white rounded-lg no-wrap text-our-gray-160">
        <div class="relative flex flex-row gap-x-2 no-wrap">

          <button @click="updatePagination(--page)" class="flex items-center justify-center w-8 h-8 text-sm transition-all duration-150 ease-linear border rounded-md outline-none cursor-pointer text-our-gray-200 bg-our-gray-80 border-our-gray-80 hover:border-our-gray-200 focus:border-our-gray-200 focus:outline-none focus:bg-our-gray-200 focus:text-whiteborder-light-highlight-level-1">
            <svg class="w-5 h-5 -mx-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd"/>
            </svg>
          </button>

          <button
              v-for="(item, index) in dottedPills"
              :key="index + 'q' + item"
              :class="'flex items-center justify-center w-8 h-8 text-sm transition-all duration-150 ease-linear border rounded-md outline-none focus:border-our-gray-180 focus:outline-none focus:bg-our-gray-180 focus:text-whiteborder-light-highlight-level-1 ' + computedStyles(item)"
              :disabled="item === '..'"
              @click="updatePagination(item)"
          >
            {{ item }}
          </button>

          <button @click="updatePagination(++page)" class="flex items-center justify-center w-8 h-8 text-sm transition-all duration-150 ease-linear border rounded-md outline-none cursor-pointer text-our-gray-200 bg-our-gray-80 border-our-gray-80 hover:border-our-gray-200 focus:border-our-gray-200 focus:outline-none focus:bg-our-gray-200 focus:text-whiteborder-light-highlight-level-1">
            <svg class="w-5 h-5 -mx-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"/>
            </svg>
          </button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';

export default {
  name: 'Pagination',
  setup() {
    return useI18n({
      messages: {
        en: {
          displayed: 'Showing',
          upTo: 'to',
          ofTotal: 'of',
          results: 'results'
        },
        cs: {
          displayed: 'Zobrazeno',
          upTo: 'až',
          ofTotal: 'z',
          results: 'výsledků'
        }
      }
    })
  },
  props: {
    currentPage: {
      default: 1,
      type: Number
    },
    perPage: {
      default: 10,
      type: Number
    },
    allResults: {
      default: 10,
      type: Number
    }
  },
  data: () => ({
    page: 1
  }),
  computed: {
    topRow() {
      return ((this.currentPage - 1) * this.perPage) + 1
    },
    bottomRow() {
      if (this.allResults < this.perPage) {
        return this.allResults
      }
      if (this.allResults < this.perPage * this.currentPage) {
        return this.allResults
      }
      return this.currentPage * this.perPage
    },
    maxPage() {
      return Math.ceil(this.allResults / this.perPage)
    },
    dottedPills() {
      /**>[1]<  [2]   [3]   [4]   [5]   [6]   [7]   [..]  [99]
       *  [1]  >[2]<  [3]   [4]   [5]   [6]   [7]   [..]  [99]
       *  [1]   [2]   [3]   [4]  >[5]<  [6]   [7]   [..]  [99]
       *  [1]   [..]  [4]   [5]  >[6]<  [7]   [8]   [..]  [99]
       *  [1]   [..]  [5]   [6]  >[7]<  [8]   [9]   [..]  [99]
       *  [1]   [..]  [92]  [93] >[94]< [95]  [96]  [..]  [99]
       *  [1]   [..]  [93]  [94] >[95]< [96]  [97]  [98]  [99]
       */
      let current = this.currentPage,
          max = this.maxPage,
          size = 9,
          output = []

      if (max <= size) {
        for (let i = 1; i <= max; ++i)
          output.push(i)
        return output
      }

      output.push(1)

      // prevent looping 5000 times for 5000 pages
      let bottom = ((current - size) < 2) ? 2 : (current - size)
      let top = ((current + size) > (max - 1)) ? (max - 1) : (current + size)

      for (let i = bottom; i <= top; i++) {

        // pills on start
        if (current <= 5 && i <= 7) {
          output.push(i)
        }
        // pills on end
        else if (current >= (max - 4) && i >= (max - 6)) {
          output.push(i)
        }
        // pills in between
        else if ((i + 3) > current && (i - 3) < current) {
          output.push(i)
        }
      }
      output.push(max)

      // add dots if pages have been truncated
      let dotted = []
      for (let i = 0; i < output.length; i++) {

        if (i == 1 && ((output[0] + 1) != output[1])) {
          dotted.push('..')
        }
        else if (i == (output.length - 1) && ((output[output.length - 2] + 1) != output[output.length - 1])) {
          dotted.push('..')
        }
        dotted.push(output[i])
      }

      return dotted
    }
  },
  methods: {
    computedStyles(item) {
      if(item == this.page) {
        return ' px-2 py-1 text-sm font-semibold text-white bg-our-gray-180 border-our-gray-180 focus:outline-noneborder-light-highlight-level-1 '
      } else if(item == '..') {
        return ' cursor-default hover:border-our-gray-40 '
      } else {
        return ' bg-our-gray-40 border-our-gray-40 cursor-pointer text-our-gray-180 hover:text-our-gray-180 hover:border-our-gray-180 '
      }
    },
    updatePagination(newPage) {
      if (newPage[0] == '.') {
        return
      }
      this.page = (newPage < 1) ? 1 : ((newPage > this.maxPage) ? this.maxPage : newPage)
      this.$emit('new-page', this.page)
    }
  },
  mounted() {
    this.page = this.currentPage || 1

    if (this.page < 1) {
      this.page = 1
    }
    else if (this.page > this.maxPage) {
      this.page = this.maxPage
    }
  }
}
</script>
