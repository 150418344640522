<template>
  <div class="flex flex-col gap-y-5">

    <div class="flex flex-col gap-y-6">
      <div class="flex flex-row items-center justify-between gap-x-3">

        <input type="search" v-model="fulltextSearch" @input="sendFilter"
               :placeholder="t('display.searchPlaceholder')"
               class="w-full max-w-sm rounded-level-3">

        <div class="flex flex-grow flex-row items-center justify-end gap-x-3">
          <select v-model="sort" v-on:input="sendFilter" class="w-full max-w-sm rounded-level-3" required>
            <option value="name:ASC">{{ t('display.sortingFilter.nameAsc') }}</option>
            <option value="name:DESC">{{ t('display.sortingFilter.nameDesc') }}</option>
            <option value="created_at:ASC">{{ t('display.sortingFilter.createdAtAsc') }}</option>
            <option value="created_at:DESC">{{ t('display.sortingFilter.createdAtDesc') }}</option>
          </select>

          <!--<Multiselect
              v-model:selectedItems="selectedGroupsFilter"
              @input="sendFilter"
              :items="groupsListBetter"
              :placeholder="t('filterByGroups')"
              placeholderClasses="text-our-gray-190 text-opacity-50"
              classes="table-multiselect rounded-md"
          ></Multiselect>-->

          <select v-model="status" @change="sendFilter" class="w-full max-w-sm rounded-level-3" required>
            <option value="all">{{ t('display.statusFilter.all') }}</option>
            <option value="online">{{ t('display.statusFilter.online') }}</option>
            <option value="playliston">{{ t('display.statusFilter.active') }}</option>
            <option value="offline">{{ t('display.statusFilter.offline') }}</option>
          </select>
        </div>
      </div>

      <div>
        <Table
            :tableHeads="tableHeader"
            :tableBody="displaysList"
            detailComponent="Display detail"
            :usePagination="true"
            :pagination="tableOptions"
            v-on:tr-hover="loadDisplayData"
        >
          <template #created_at="data">
            {{ $moment(data.data.created_at).format('DD/MM/YYYY HH:mm') }}
          </template>
          <template #resolution="data">
            <svg v-if="data.data.resolution === 'p2160'" class="-my-1 w-7 h-7" fill="currentColor" width="32"
                 height="32" viewBox="0 0 32 32">
              <path
                  d="M12 11.03L12 15.03 10 15.03 10 11.03 8 11.03 8 17.03 12 17.03 12 21.03 14 21.03 14 11.03 12 11.03zM24.19 11.03L22 11.03 19 15.42 19 11.03 17 11.03 17 21.03 19 21.03 19 18.3 19.91 16.97 22 21.03 24.19 21.03 21.2 15.41 24.19 11.03z"></path>
              <path d="M28,26H4a2,2,0,0,1-2-2V8A2,2,0,0,1,4,6H28a2,2,0,0,1,2,2V24A2,2,0,0,1,28,26ZM4,8V24H28V8Z"></path>
            </svg>
            <svg v-else-if="data.data.resolution === 'p1080'" class="-my-1 w-7 h-7" fill="currentColor" width="32"
                 height="32" viewBox="0 0 32 32">
              <path d="M28,6H4A2,2,0,0,0,2,8V24a2,2,0,0,0,2,2H28a2,2,0,0,0,2-2V8A2,2,0,0,0,28,6ZM4,24V8H28V24Z"></path>
              <path
                  d="M22 11H18V21h4a3 3 0 003-3V14A3 3 0 0022 11zm1 7a1 1 0 01-1 1H20V13h2a1 1 0 011 1zM13 11L13 15 10 15 10 11 8 11 8 21 10 21 10 17 13 17 13 21 15 21 15 11 13 11z"></path>
            </svg>
            <span v-else-if="data.data.resolution === 'system_default'">
              {{ t('display.form.systemDefault') }}
            </span>
            <span v-else>
              {{ t('display.undefined') }}
            </span>
          </template>
          <template #customId="data">
            <div class="relative tooltip-wrapper inline-block">
              <span><strong>{{ data.data.id }}</strong></span>
              <Tooltip color="our-gray-30" background="our-gray-170" position="right"
                       :text="'User-ID: '+data.data.customId.toString()"></Tooltip>
            </div>
          </template>
          <template #lastActive="data">
            <template v-if="data.data.devices.length > 0">
              <span v-if="data.data.devices[0].lastactive">{{
                  $moment(new Date(parseInt(data.data.devices[0].lastactive))).fromNow()
                }}</span>
              <span v-else>{{ t('display.tableBody.unknown') }}</span>
            </template>
            <template v-else>
              <span>{{ t('display.tableBody.inactive') }}</span>
            </template>
          </template>
          <template #active="data">
            <span v-if="data.data.active === true" class="text-sm font-medium tracking-wide">
              {{ t('display.tableBody.enabled') }}
            </span>
            <template v-else>
              {{ t('display.tableBody.inactive') }}
            </template>
            |
            <span v-if="data.data.displaygroups.length > 0">
              {{ t('display.tableBody.inGroup') }}
            </span>
            <span v-else>
              {{ t('display.tableBody.notInGroup') }}
            </span>
          </template>
          <template #status="data">
            <span v-if="data.data.devices.length > 0" class="text-sm font-medium tracking-wide">
              {{ t('display.tableBody.paired') }}
            </span>
            <span v-else class="text-sm font-normal tracking-wide">
              {{ t('display.tableBody.notPaired') }}
            </span>
            <template v-if="data.data.devices.length > 0">
              |
              <span v-if="Date.now() - data.data.devices[0].lastactive < 65000"
                    class="text-sm font-medium tracking-wide">
              {{ t('display.tableBody.online') }}
            </span>
              <span v-else class="text-sm font-normal tracking-wide">
              {{ t('display.tableBody.unknown') }}
            </span>
            </template>
          </template>
          <template #screen="data">
            <div class="relative tooltip-wrapper">
              <template v-if="data.data.screen && Object.keys(data.data.screen).length > 0">
                <img class="h-10 -my-2.5 mx-auto"
                     :src="$axios.defaults.baseURL + (data.data.screen.formats.thumbnail?.url)?.substring(1)">
                <Tooltip color="our-gray-30" background="our-gray-170" position="bottom" imageClasses="w-48"
                         :image="data.data.screen ? $axios.defaults.baseURL + (data.data.screen.formats.small?.url)?.substring(1) : ''"
                         image-border-color="our-gray-220"></Tooltip>
              </template>
              <span v-else>&nbsp;</span>
            </div>
          </template>
          <!--<template #description="data">
            <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">
                      {{ data.data.description ? data.data.description : '&nbsp;' }}
                    </span>
            </span>
          </template>-->
        </Table>
      </div>
    </div>
  </div>
</template>

<script>
import Table from '@/components/Table/Table'
import Multiselect from '@/components/Multiselect'
import { useI18n } from 'vue-i18n'

export default {
  name: 'Displays',
  components: {
    Table,
    Multiselect
  },
  setup() {
    return useI18n()
  },
  data() {
    return {
      groupsList: [],
      timer: null,
      sort: this.$route.query.sort || 'name:ASC',
      status: this.$route.query.status || 'all',
      fulltextSearch: this.$route.query.search || null,
      selectedGroupsFilter: [],
      displaysList: [],
      isLoaded: false,
      tableOptions: {
        perPage: 25,
        total: 0
      },
      tableHeader: [
        {
          title: this.t('display.tableHeader.id'),
          col: 'customId'
        },
        {
          title: this.t('display.tableHeader.name'),
          col: 'name'
        },
        {
          title: this.t('display.tableHeader.resolution'),
          col: 'resolution'
        },
        {
          title: this.t('display.tableHeader.active'),
          col: 'active'
        },
        {
          title: this.t('display.tableHeader.status'),
          col: 'status'
        },
        {
          title: this.t('display.tableHeader.lastActive'),
          col: 'lastActive'
        }
      ]
    }
  },
  computed: {
    groupsListBetter() {
      return this.groupsList.map(key => {
        return { id: key.id, name: key.name }
      })
    }
  },
  watch: {
    '$store.state.Displays.displays': {
      immediate: true,
      handler(listOrig) {

        if (this.status && this.status !== 'all') {
          listOrig = listOrig.filter(i => i.displaystatus == this.status)
        }

        this.displaysList = JSON.parse(JSON.stringify(listOrig))
        this.tableOptions.total = this.displaysList.length
      }
    },
    '$store.state.Groups.groups': {
      immediate: true,
      handler(listOrig) {
        this.groupsList = JSON.parse(JSON.stringify(listOrig))
      }
    }
  },
  methods: {
    async sendFilter() {

      await this.changeUrlQuery()

      let params = {}
      if (this.fulltextSearch) {
        params['name_contains'] = this.fulltextSearch
      }
      if (this.sort) {
        params['_sort'] = this.sort
      }
      /*console.log('xx', this.selectedGroupsFilter.length)
      if (this.selectedGroupsFilter.length !== 0) {
        let iterator = 0
        this.selectedGroupsFilter.forEach(item => {
          params['_where[_or][' + (iterator++) + '][displaygroups.id]'] = item.id
        })
      }*/
      this.$store.dispatch('Displays/fetchAllDisplays', params).then(() => {
        this.isLoaded = true
      })
    },
    loadDisplayData({ type, id }) {
      if (type === 'enter') {
        this.timer = setTimeout(async () => {
          this.$store.dispatch('Displays/fetchDisplay', id)
        }, 500)
      }
      else if (type === 'leave') {
        clearTimeout(this.timer)
        this.timer = null
      }
    },
    changeUrlQuery() {
      let tmp = Object.assign({}, this.$route.query)
      tmp['sort'] = this.sort
      tmp['status'] = this.status
      if (this.fulltextSearch == null) {
        delete tmp['search']
      }
      else {
        tmp['search'] = this.fulltextSearch
      }
      this.$router.replace({ query: tmp })
    }
  },
  updated() {
    this.changeUrlQuery()
  },
  mounted() {
    this.sendFilter()
  }
}
</script>

<style>
.text-overflow-dynamic-container {
  position: relative;
  max-width: 100%;
  padding: 0 !important;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  vertical-align: text-bottom !important;
}

.text-overflow-dynamic-ellipsis {
  position: absolute;
  white-space: nowrap;
  overflow-y: visible;
  overflow-x: hidden;
  text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  max-width: 100%;
  min-width: 0;
  width: 100%;
  top: 0;
  left: 0;
}

.text-overflow-dynamic-container:after,
.text-overflow-dynamic-ellipsis:after {
  content: '-';
  display: inline;
  visibility: hidden;
  width: 0;
}
</style>
