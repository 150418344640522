<template>
<div class="flex flex-col gap-y-5">

    <div class="flex flex-col gap-y-6">
      <div class="flex flex-row items-center justify-between gap-x-3">



                
        <input type="search" v-model="fulltextSearch" @input="sendFilter"
               :placeholder="t('users.searchPlaceholder')"
               class="w-full max-w-sm rounded-level-3">

        <div class="flex flex-grow flex-row items-center justify-end gap-x-3">
          <select
              v-model="sort"
              @input="sendFilter"
              class="w-full max-w-sm rounded-level-3"
          >
            <option value="username:ASC">{{ t('users.sortingFilter.nameAsc') }}</option>
            <option value="username:DESC">{{ t('users.sortingFilter.nameDesc') }}</option>
            <option value="created_at:ASC">{{ t('users.sortingFilter.createdAtAsc') }}</option>
            <option value="created_at:DESC">{{ t('users.sortingFilter.createdAtDesc') }}</option>
          </select>

          <select
              v-model="role"
              @change="sendFilter"
              class="w-full max-w-sm rounded-level-3"
          >
            <option value="all">{{ t('users.roleFilter.all') }} </option>
            <option value="6">{{ t('users.roleFilter.onlyPlayables') }}</option>
            <option value="5">{{ t('users.roleFilter.standard') }}</option>
            <option value="1">{{ t('users.roleFilter.admin') }}</option>
          </select>

          <select
              v-model="status"
              @change="sendFilter"
              class="w-full max-w-sm rounded-level-3"
          >
            <option value="all">{{ t('users.statusFilter.all') }}</option>
            <option value="alow">{{ t('users.statusFilter.active') }}</option>
            <option value="blocked">{{ t('users.statusFilter.blocked') }}</option>
            <option value="confirmed">{{ t('users.statusFilter.confirmed') }}</option>
            <option value="unconfirmed">{{ t('users.statusFilter.unconfirmed') }}</option>
          </select>
        </div>
      </div>

      <div>
        <Table
            :tableHeads="tableHeader"
            :tableBody="usersList"
            detailComponent="User detail"
            :usePagination="true"
            :pagination="tableOptions"
        >
          <template #created_at="data">
            {{ $moment(data.data.created_at).format('DD/MM/YYYY HH:mm') }}
          </template>
          <template #resolution="data">
            <svg v-if="data.data.resolution === 'p2160'" class="-my-1 w-7 h-7" fill="currentColor" width="32" height="32" viewBox="0 0 32 32">
              <path d="M12 11.03L12 15.03 10 15.03 10 11.03 8 11.03 8 17.03 12 17.03 12 21.03 14 21.03 14 11.03 12 11.03zM24.19 11.03L22 11.03 19 15.42 19 11.03 17 11.03 17 21.03 19 21.03 19 18.3 19.91 16.97 22 21.03 24.19 21.03 21.2 15.41 24.19 11.03z"></path>
              <path d="M28,26H4a2,2,0,0,1-2-2V8A2,2,0,0,1,4,6H28a2,2,0,0,1,2,2V24A2,2,0,0,1,28,26ZM4,8V24H28V8Z"></path>
            </svg>
            <svg v-else-if="data.data.resolution === 'p1080'" class="-my-1 w-7 h-7" fill="currentColor" width="32" height="32" viewBox="0 0 32 32">
              <path d="M28,6H4A2,2,0,0,0,2,8V24a2,2,0,0,0,2,2H28a2,2,0,0,0,2-2V8A2,2,0,0,0,28,6ZM4,24V8H28V24Z"></path>
              <path d="M22 11H18V21h4a3 3 0 003-3V14A3 3 0 0022 11zm1 7a1 1 0 01-1 1H20V13h2a1 1 0 011 1zM13 11L13 15 10 15 10 11 8 11 8 21 10 21 10 17 13 17 13 21 15 21 15 11 13 11z"></path>
            </svg>
            <span v-else>
              {{ t('display.undefined') }}
            </span>
          </template>
          <template #customId="data">
            <div class="relative tooltip-wrapper inline-block">
              <span><strong>{{ data.data.id }}</strong></span>
              <Tooltip color="our-gray-30" background="our-gray-170" position="right" :text="t('global.menu.user') + '-ID: '+data.data.customId.toString()"></Tooltip>
            </div>
          </template>
          <template #lastActive="data">
            <template v-if="data.data.devices.length > 0">
              <span v-if="data.data.devices[0].lastactive">{{ $moment(new Date(parseInt(data.data.devices[0].lastactive))).fromNow() }}</span>
              <span v-else>{{ t('display.tableBody.unknown') }}</span>
            </template>
            <template v-else>
              <span>{{ t('display.tableBody.inactive') }}</span>
            </template>
          </template>
          <template #active="data">
            <span v-if="data.data.active === true" class="ml-2 text-sm font-medium tracking-wide">
              {{ t('display.tableBody.enabled') }}
            </span>
            <template v-else>
              {{ t('display.tableBody.inactive') }}
            </template>
            |
            <span v-if="data.data.displaygroups.length > 0">
              {{ t('display.tableBody.inGroup') }}
            </span>
            <span v-else>
              {{ t('display.tableBody.notInGroup') }}
            </span>
          </template>
          <template #screen="data">
            <div class="relative tooltip-wrapper">
              <template v-if="data.data.screen && Object.keys(data.data.screen).length > 0">
                <img class="h-10 -my-2.5 mx-auto" :src="$axios.defaults.baseURL + (data.data.screen.formats.thumbnail?.url)?.substring(1)">
                <Tooltip color="our-gray-30" background="our-gray-170" position="bottom" imageClasses="w-48" :image="data.data.screen ? $axios.defaults.baseURL + (data.data.screen.formats.small?.url)?.substring(1) : ''" image-border-color="our-gray-220"></Tooltip>
              </template>
              <span v-else>&nbsp;</span>
            </div>
          </template>
        </Table>
      </div>
    </div>
  </div>
</template>

<script>
import Table from '@/components/Table/Table'
import {useI18n} from "vue-i18n"

export default {
  name: "Users",
  components: {
    Table
  },
  setup() {
    return useI18n()
  },
  data() {
    return {
      usersList: [],
      timer: null,
      sort: this.$route.query.sort || 'username:ASC',
      fulltextSearch: this.$route.query.search || null,
      role: this.$route.query.role || 'all',
      status: this.$route.query.status || 'all',
      isLoaded: false,
      tableOptions: {
        perPage: 25,
        total: 0
      },
      tableHeader: [
        {
          title: this.t('users.tableHeader.id'),
          col: 'id'
        },
        {
          title: this.t('users.tableHeader.name'),
          col: 'fullname'
        },
        {
          title: this.t('users.tableHeader.email'),
          col: 'email'
        },
        {
          title: this.t('users.tableHeader.role'),
          col: 'role'
        },
        {
          title: this.t('users.tableHeader.status'),
          col: 'status'
        },
        {
          title: this.t('users.tableHeader.createdAt'),
          col: 'created_at'
        }
      ]

    }
  },
  watch: {
    '$store.state.Users.usersList': {
      immediate: true,
      handler(listOrig) {

        this.usersList = JSON.parse(JSON.stringify(listOrig))
        this.tableOptions.total = this.usersList.length;

        for (const r_usersList of this.usersList) {
          r_usersList.role = r_usersList.role.name
        }

        for (const r_usersList of this.usersList) {
          r_usersList.status = (r_usersList.blocked == 1 ? this.t('users.status.blocked') : this.t('users.status.active'))
        }

      }
    }
  },
  methods: {
    async sendFilter() {

      await this.changeUrlQuery()

      let params = {}
      if (this.fulltextSearch) {
        params['username_contains'] = this.fulltextSearch
      }
      if (this.sort) {
        params['_sort'] = this.sort
      }

      if (this.status) {
        switch(this.status){
          case 'alow':
            params['blocked'] = 0
            break
        case 'blocked':
            params['blocked'] = 1
            break
        case 'confirmed':
            params['confirmed'] = 1
            break
        case 'unconfirmed':
            params['confirmed'] = 0
            break
        }
      }

      if (this.role) {
        if(this.role !== 'all'){
          params['role'] = this.role
        }
      }

      this.$store.dispatch('Users/fetchAllUsers', params).then(() => {
        this.isLoaded = true;
      })
    },
    changeUrlQuery() {
      let tmp = Object.assign({}, this.$route.query)
      tmp['sort'] = this.sort
      tmp['role'] = this.role
      tmp['status'] = this.status
      if(this.fulltextSearch == null) {
        delete tmp['search']
      } else {
        tmp['search'] = this.fulltextSearch
      }
      this.$router.replace({ query: tmp })
    }
  },
  updated() {
    this.changeUrlQuery()
  },
  mounted() {
    this.sendFilter()
  }
}
</script>

<style scoped>

</style>
