<template>
  <div class="flex items-center justify-center">
    <div
        class="flex overflow-hidden w-10 pr-6 h-1.5 box-content rounded-full"
        :class="level === 2 ? 'bg-light-level-0 border border-light-level-1' : `bg-light-level-2`"
        style="content-visibility: auto">
      <div class="flex flex-grow animate-slide">
        <div class="w-6 rounded-full"
             :class="level === 2 ? 'bg-light-level-3' : `bg-light-level-3`"></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'LoaderVertical',
  props: {
    level: {
      default: 1,
      type: Number
    }
  }
}
</script>
