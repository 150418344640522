<template>
  <li class="relative inline-flex transition-all duration-500 ease-linear"
     > <!--col-span-1-->
    <router-link @mouseover="hover = true"
                 @mouseleave="hover = false"
        :to="{ name: 'Playable detail', params: {id: id}}"
                 class="relative flex w-full h-full transition-all duration-150 ease-linear bg-center bg-cover border outline-none cursor-pointer select-none border-our-gray-210 bg-our-gray-20 rounded-xs group r-our-cyan-40 focus:outline-none">
      <playable-card-detail :playable="playable" :hover="hover"></playable-card-detail>
    </router-link>
  </li>
</template>

<script>
import PlayableCardDetail from "@/components/PlayableCardDetail";
export default {
  name: "PlayableCard",
  components: {PlayableCardDetail},
  data() {
    return {
      hover: false
    }
  },
  props: {
    name: String,
    id: Number,
    type: String,
    img: String,
    playable: Object
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
