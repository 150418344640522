import axios from 'axios'

const END_POINT = '/calendar-items'
const END_POINT_ITEM = '/calendar-item-details';

const getAllCalendars = (_params = {}) => {
	return axios({
		url: END_POINT,
		method: 'GET',
        params: _params
	})
}

const getSingleCalendar = (calendarID) => {
    return axios({
        url: END_POINT + '/' + calendarID,
        method: 'GET'
    })
}

const getCalendarsCount = () => {
    return axios({
        url: END_POINT + '/count',
        method: 'GET'
    })
}

const getCalendarsPage = (_start, _limit) => {
    return axios({
        url: END_POINT,
        params: { _start, _limit },
        method: 'GET'
    })
}

const addNewCalendar = (data) => {
    return axios({
        url: END_POINT,
        data: data,
        method: 'POST'
    })
}

const updateExistingCalendar = (data) => {
    return axios({
        url: END_POINT + '/' + data.id,
        data: data,
        method: 'PUT'
    })
}

const deleteCalendar = (calendarID) => {
    return axios({
        url: END_POINT + '/' + calendarID,
        method: 'DELETE'
    })
}



const getCalendarItemsForOnePlaylist = (playlistID) => {
	return axios({
		url: END_POINT + '?playlist=' + playlistID,
		method: 'GET'
	})
}

const getAllCalendarItems = (_params = {}) => {
	return axios({
		url: END_POINT_ITEM,
		method: 'GET',
        params: _params
	})
}

const addNewCalendarItem = (data) => {
    return axios({
        url: END_POINT_ITEM,
        data: data,
        method: 'POST'
    })
}

const updateExistingCalendarItem = (data) => {
    return axios({
        url: END_POINT_ITEM + '/' + data.id,
        data: data,
        method: 'PUT'
    })
}

const deleteCalendarItem = (calendarID) => {
    return axios({
        url: END_POINT_ITEM + '/' + calendarID,
        method: 'DELETE'
    })
}

const getAllCalendarItemPreconditions = () => {
	return axios({
		url: '/calendar-item-detail-preconditions',
		method: 'GET'
	})
}

const getHighestCalendarItemId = () => {
	return axios({
		url: END_POINT_ITEM,
		params: {
			_sort: 'customid:DESC',
			_limit: 1
		},
		method: 'GET'
	})
}

export default {
    getAllCalendars,
    getSingleCalendar,
    getCalendarsCount,
    getCalendarsPage,
    addNewCalendar,
    updateExistingCalendar,
    deleteCalendar,

    getCalendarItemsForOnePlaylist,
	getAllCalendarItems,
    getAllCalendarItemPreconditions,
	addNewCalendarItem,
	updateExistingCalendarItem,
	deleteCalendarItem,
	getHighestCalendarItemId
}
