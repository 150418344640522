import axios from 'axios'


const getLastGeneratedTime = () => {
	return axios({
		url: '/last-generate-time',
		method: 'GET'
	})
}

const listReset = () => {
	return axios({
		url: '/event-lists-start',
		method: 'GET'
	})
}

export default {
	getLastGeneratedTime,
	listReset
}
