import displays from '@/api/modules/displays'

const state = {
	count: 0,
	displays: [],
	displayData: {},
	eventsDataPlaylists: null,
	eventsDataPlayables: null,
	uploading: false,
	uploadProgress: 0,
	fileUploadData: {
		name: null,
		size: null
	},
	actualFileData: {
		name: null,
		size: null
	},
}


const getters = {
	getAllDisplays(state) {
		return state.displays;
	},
	getDisplayData(state) {
		return state.displayData;
	},
	getActualFileData(state) {
		return state.actualFileData;
	}
}


const mutations = {
	SET_DISPLAYS_COUNT(state, data) {
		state.count = data
	},
	SET_DISPLAYS_LIST(state, data) {
		state.displays = data
	},
	SET_DISPLAY_DATA(state, data) {
		state.displayData = data
	},
	SET_EVENTS_DATA_PLAYLISTS(state, data) {
		state.eventsDataPlaylists = data
	},
	SET_EVENTS_DATA_PLAYABLES(state, data) {
		state.eventsDataPlayables = data
	},
	SET_FILE_UPLOAD_DATA(state, file) {
		state.fileUploadData = file
	},
	SET_UPLOADING_STATUS(state, status) {
		state.uploading = status
	},
	SET_UPLOADING_PROGRESS(state, progressPercentage) {
		state.uploadProgress = progressPercentage
	},
	SET_ACTUAL_FILE_DATA(state, file) {
		state.actualFileData = file
	},
}


const actions = {
	fetchDisplaysCount({ commit }, payload) {
		return new Promise((resolve, reject) => {
			displays.getDisplaysCount(payload).then(response => {
				commit('SET_DISPLAYS_COUNT', response.data)
				resolve(response)
			}, error => {
				reject(error);
			})
		})
	},
	fetchAllDisplays({ commit }, payload) {
		return new Promise((resolve, reject) => {
			displays.getAllDisplays(payload).then(response => {
				commit('SET_DISPLAYS_LIST', response.data)
				resolve(response)
			}, error => {
				reject(error);
			})
		})
	},
	fetchDisplaysPage({ commit }, payload) {
		return displays.getDisplaysPage(payload.start, payload.limit).then((response) => {
			commit('SET_DISPLAYS_LIST', response.data)
		})
	},
	fetchDisplay({ commit }, displayId) {
		return displays.getSingleDisplay(displayId).then((response) => {
			commit('SET_DISPLAY_DATA', response.data)
			return response
		})
	},
	createDisplay({ commit }, payload) {
		return new Promise((resolve, reject) => {
			displays.addNewDisplay(payload).then(response => {
				commit('SET_DISPLAY_DATA', response.data)
				resolve(response);
			}, error => {
				reject(error);
			})
		})
	},
	updateDisplay({ commit }, payload) {
		return new Promise((resolve, reject) => {
			displays.updateExistingDisplay(payload).then(response => {
				commit('SET_DISPLAY_DATA', response.data)
				resolve(response);
			}, error => {
				reject(error);
			})
		})
	},
	uploadFile({ commit }, { file, playable }) {
		return new Promise((resolve, reject) => {
			commit('SET_UPLOADING_STATUS', true)
			commit('SET_FILE_UPLOAD_DATA', { file, playable })
			displays.uploadNewFile(file).then((response) => {
				commit('SET_UPLOADING_STATUS', false)
				resolve(response)
			}, error => {
				reject(error);
			})
		})
	},
	setUploadProgress({ commit }, percentage) {
		commit('SET_UPLOADING_PROGRESS', percentage)
	},
	setUploadingStatus({ commit }, status) {
		commit('SET_UPLOADING_STATUS', status)
	},
	setActualFileData({ commit }, file){
		commit('SET_ACTUAL_FILE_DATA', file)
	},
	fetchEventsForOneDisplay({ commit }, displayId) {
		return displays.getEventsForOneDisplay(displayId).then((response) => {
			commit('SET_EVENTS_DATA', response.data)
			return response
		})
	},
	fetchEventsForOneDisplayUntil({ commit }, { displayID, untilDate }) {
		return displays.getEventsForOneDisplayUntil({ displayID, untilDate }).then((response) => {
			commit('SET_EVENTS_DATA', response.data)
			return response
		})
	},

	fetchEventsListItemsForDisplayAndPlaylist({ commit }, { displayID, playlistID }) {
		return displays.getEventsListItemsForDisplayAndPlaylist({ displayID, playlistID }).then(response => {
			commit('SET_EVENTS_DATA_PLAYABLES', response.data)
			return response
		})
	},

	fetchEventsListItemsForEventListPlaylist({ commit }, { eventListPlaylistID, currentTime }) {
			return displays.getEventsListItemsForEventListPlaylist({ eventListPlaylistID, currentTime }).then(response => {
				commit('SET_EVENTS_DATA_PLAYABLES', response.data)
				return response
			})
		},

	fetchEventListPlaylistForOneDisplayUntil({ commit }, { displayID, untilDate }) {
		return displays.getEventListPlaylistForOneDisplayUntil({ displayID, untilDate }).then((response) => {
			commit('SET_EVENTS_DATA_PLAYLISTS', response.data)
			return response
		})
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
