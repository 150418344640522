<template>
  <div class="gap-y-7">

    <form ref="form">
      <transition name="fade" mode="out-in">

        <div v-if="dataLoaded !== false" class="flex flex-col gap-y-5 p-5 border border-light-level-1 rounded-level-3">

          <div class="grid grid-cols-2 gap-5">

            <div class="flex flex-col">
              <div class="max-w-sm">
                <label for="fullname" class="block text-base font-medium leading-5 text-our-gray-160">
                  {{ t('users.form.name') }}
                </label>
                <div class="mt-1.5">
                  <input id="fullname" v-model="userData.fullname" type="text" class="input-light-level-1">
                </div>
              </div>
            </div>
            <div class="flex flex-col">
              <div class="max-w-sm">
                <label for="status" class="block text-base font-medium leading-5 text-our-gray-160">
                  {{ t('users.form.status') }}
                </label>
                <div class="mt-1.5">
                  <SwitchGroup as="div" class="flex items-center gap-x-4">
                    <SwitchLabel class="block text-base font-medium leading-5 text-our-gray-160">
                      {{ t('users.status.isBlocked') }}
                    </SwitchLabel>
                    <Switch
                        as="button"
                        v-model="userData.blocked"
                        class="relative inline-flex flex-shrink-0 h-6 transition-all duration-200 ease-in-out border-2 border-transparent rounded-full outline-none cursor-pointer w-11 focus:outline-none"
                        :class="userData.blocked ? 'bg-our-cyan-60' : 'bg-our-gray-200 border-light-high light-level-1'"
                        v-slot="{ checked }"
                    >
                      <span
                          class="inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full"
                          :class="{ 'translate-x-5': checked, 'translate-x-0': !checked }"
                      />
                    </Switch>
                  </SwitchGroup>
                  {{ userStatus }}
                </div>
              </div>
            </div>

            <div class="flex flex-col">
              <div class="max-w-sm">
                <label for="email" class="block text-base font-medium leading-5 text-our-gray-160">
                  {{ t('users.form.email') }}
                </label>
                <div class="mt-1.5">
                  <input id="email" disabled v-model="userData.email" required type="text" class="input-light-level-1">
                </div>
              </div>
            </div>
            <div class="flex flex-col">
              <div class="max-w-sm">
                <label for="lastUpdated" class="block text-base font-medium leading-5 text-our-gray-160">
                  {{ t('users.form.lastUpdated') }}
                </label>
                <div class="mt-1.5">
                  {{ $moment(userData.updated_at).format('DD/MM/YYYY HH:mm') }}
                </div>
              </div>
            </div>

            <div class="flex flex-col max-w-lg max-w-xl">
              <label class="block text-base font-medium leading-5 text-our-gray-160">
                {{ t('users.form.description') }}
              </label>
              <div class="mt-1.5">
                <textarea v-model="userData.description" placeholder="" type="text" rows="3" class="input-light-level-1">
                </textarea>
              </div>
            </div>
            <div class="flex flex-col">
              <div class="max-w-sm">
                <label for="createdAt" class="block text-base font-medium leading-5 text-our-gray-160">
                  {{ t('users.form.createdAt') }}
                </label>
                <div class="mt-1.5">
                  {{ $moment(userData.created_at).format('DD/MM/YYYY HH:mm') }}
                </div>
              </div>
            </div>

            <div class="flex flex-col">
              <div class="max-w-sm">
                <label for="role" class="block text-base font-medium leading-5 text-our-gray-160">
                  {{ t('users.form.role') }}
                </label>
                <div class="mt-1.5">
                  <select
                    required
                    v-model="userData.role"

                  >
                    <option value="6">{{ t('users.roleSelect.onlyPlayables') }}</option>
                    <option value="5">{{ t('users.roleSelect.standard') }}</option>
                    <option value="1">{{ t('users.roleSelect.admin') }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="flex flex-col">
            </div>

            <!-- implemented in the future <div v-if="userData.role == 6" class="flex flex-col">
              <div class="max-w-sm">
                <label for="playablefolder" class="block text-base font-medium leading-5 text-our-gray-160">
                   {{ t('users.form.allowedFolder') }}
                </label>
                <div class="mt-1.5">
                  <div v-if="loadTreeSelect">
                    <div class="mt-2 max-w-sm">
                      <Treeselect
                        v-model="userData.playablefolder"
                        :multiple="false"
                        :searchable="true"
                        :options="folders"
                        class="border"/>
                    </div>
                    Pokud není vybráno, uživatel bude mít přístup do všech složek TODO
                  </div>
                  <Loader v-else class="w-8 h-8 ml-2 text-our-cyan-40"></Loader>
                </div>
              </div>
            </div>-->
            <div class="flex flex-col">
            </div>

          </div>
          <br />


        </div>

        <div v-else class="w-5/6 gap-y-5 p-5 border border-light-level-1 rounded-level-3">
          <div class="w-full aspect-w-16 aspect-h-2 bg-our-gray-60 rounded-md">
            <Loader class="w-4 h-4 mx-auto realtive top-12"/>
          </div>
        </div>

      </transition>
    </form>

  </div>
</template>

<script>
/*import GroupCard from "@/components/GroupCard";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";*/

import { useI18n } from "vue-i18n";
import {Switch, SwitchGroup, SwitchLabel} from '@headlessui/vue'

function initialData() {
  return {
    dataLoaded: false,
    userData: {
      email: '',
      fullname: '',
      role: null,
      created_at: null,
      updated_at: null,
      playablefolder: null,
      blocked: false,
      description: '',
    },
    billingData: {
      name: '',
      billingDetails: [],
      price: 0,
      priceVAT: 0,
      priceWithoutVAT: 0,
      billingPeriodFrom: null,
      billingPeriodTo: null
    },
    selectedRole: [],
    folders: [],
    loadTreeSelect: false
  }
}


export default {
  name: "UserDetail",
  components: {
    Switch,
    SwitchGroup,
    SwitchLabel
  },
  setup() {
    return useI18n()
  },
  data() {
    return initialData()
  },
  watch: {
    '$store.state.Users.userData': {
      immediate: false,
      deep: true,
      handler(data) {
        this.userData = JSON.parse(JSON.stringify(data))
        if(this.userData.role?.id){
          this.userData.role = this.userData.role.id
        }
        if (!this.dataLoaded) {
          this.dataLoaded = true
        }
        document.title = data.email + ' | ' + document.title.split('|')[1]
        this.$store.dispatch('setDetailName', data.email)
      }
    },
    userData: {
      immediate: false,
      deep: true,
      handler(data) {
        if (this.dataLoaded) {
          if (this.$refs.form.reportValidity()) {
            let sendData = this.$helpers.purify(data)
            let filteredData = this.$helpers.objectFalseyAndEmptyFilter(sendData)
            this.$emit('save-changes', {...filteredData, _type: 'user', valid: true})
          } else if (!this.$refs.form.reportValidity()) {
            this.$emit('save-changes', {valid: false})
          }
        }
      }
    }
  },
  methods: {
    getServerIPv4(baseURL){
      let IPv4
      IPv4 = baseURL.replace('https://', '');
      IPv4 = IPv4.replace('http://', '');
      IPv4 = IPv4.replace('/', '');
      IPv4 = IPv4.split(':')[0] // port
      return IPv4
    }
  },
  computed: {
    userStatus(){
      return this.userData.confirmed == 1 ? this.t('users.status.confirmedEmail') : this.t('users.status.unconfirmedEmail')
    }
  },
  created() {

    this.$store.dispatch('Users/fetchUser', Number.parseInt(this.$route.params.id)).then((response) => {
      this.userData = response.data;
      /* implemented in the future if(this.userData.playablefolder){
        this.userData.playablefolder = this.userData.playablefolder.id
      }*/
      this.userData.role = this.userData.role.id
      this.dataLoaded = true
    }).catch(() => {
      this.$router.push('/404')
    })

    /* implemented in the future this.$store.dispatch('Playables/fetchAllPlayableFolder').then((response) => {
      let folders = [], pushedData, children = [], children1 = [], children2 = [], children3 = [];

      for(const r_folder of response.data){ // for level 0
        children = [];

        if(r_folder.parentid === null){

          for(const r_folder1 of response.data){ // for level 1
            if(r_folder1.parentid?.id === r_folder.id){
              children1 = [];

              for(const r_folder2 of response.data){ // for level 2
                if(r_folder2.parentid?.id === r_folder1.id){
                  children2 = [];

                  for(const r_folder3 of response.data){ // for level 3
                    if(r_folder3.parentid?.id === r_folder2.id){
                      children3 = [];

                      for(const r_folder4 of response.data){ // for level 4
                        if(r_folder4.parentid?.id === r_folder3.id){
                          children3.push({ id: r_folder4.id, label: r_folder4.name });
                        }
                      }

                      if (typeof children3 !== 'undefined' && children3.length > 0) {
                        pushedData = { id: r_folder3.id, label: r_folder3.name, children: children3 };
                      }else{
                        pushedData = { id: r_folder3.id, label: r_folder3.name }
                      }

                      children2.push(pushedData);
                    }
                  }

                  if (typeof children2 !== 'undefined' && children2.length > 0) {
                    pushedData = { id: r_folder2.id, label: r_folder2.name, children: children2 };
                  }else{
                    pushedData = { id: r_folder2.id, label: r_folder2.name }
                  }

                  children1.push(pushedData);
                }
              }

              if (typeof children1 !== 'undefined' && children1.length > 0) {
                pushedData = { id: r_folder1.id, label: r_folder1.name, children: children1 };
              }else{
                pushedData = { id: r_folder1.id, label: r_folder1.name }
              }

              children.push(pushedData);
            }
          }

          if (typeof children !== 'undefined' && children.length > 0) {
            pushedData = { id: r_folder.id, label: r_folder.name, children: children };
          }else{
            pushedData = { id: r_folder.id, label: r_folder.name }
          }

          folders.push(pushedData);
        }

      }
      this.folders = folders;
      this.loadTreeSelect = true;
    })*/

  },
}
</script>

<style scoped>
.form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='none'%3e%3cpath d='M7 7l3-3 3 3m0 6l-3 3-3-3' stroke='%23323130' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
}

.checked\:hover\:border-our-cyan-40:checked:focus {
  @apply border-our-cyan-40
}
</style>
