<template>
  <div class="grid grid-cols-1 grid-cols-2 gap-5 lg:grid-cols-3">
    <DashboardCard :title="t('global.totalDisplays') + ':'" :count="$store.state.Displays.count" path="/displays"
                   icon='<svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.25 10C3.00736 10 2 11.0074 2 12.25V16.75C2 17.9926 3.00736 19 4.25 19H5.5V20.5H4.75C4.33579 20.5 4 20.8358 4 21.25C4 21.6642 4.33579 22 4.75 22H11.25C11.6642 22 12 21.6642 12 21.25C12 20.8358 11.6642 20.5 11.25 20.5H10.5V19H11.75C12.9926 19 14 17.9926 14 16.75V12.25C14 11.0074 12.9926 10 11.75 10H4.25ZM9 19V20.5H7V19H9Z"/><path fill-rule="evenodd" clip-rule="evenodd" d="M6 4.25V8.5H7H12.25C14.0449 8.5 15.5 9.95507 15.5 11.75V13V14V16V17.5H18.25C18.6642 17.5 19 17.1642 19 16.75C19 16.3358 18.6642 16 18.25 16H17V14H19.75C20.9926 14 22 12.9926 22 11.75V4.25C22 3.00736 20.9926 2 19.75 2H8.25C7.00736 2 6 3.00736 6 4.25Z"/></svg>'></DashboardCard>
    <DashboardCard :title="t('global.totalGroups') + ':'" :count="$store.state.Groups.count" path="/groups"
                   icon='<svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path d="M18.25 3C19.7688 3 21 4.23122 21 5.75V18.25C21 19.7688 19.7688 21 18.25 21H5.75C4.23122 21 3 19.7688 3 18.25V5.75C3 4.23122 4.23122 3 5.75 3H18.25ZM18.25 4.5H5.75C5.05964 4.5 4.5 5.05964 4.5 5.75V18.25C4.5 18.9404 5.05964 19.5 5.75 19.5H18.25C18.9404 19.5 19.5 18.9404 19.5 18.25V5.75C19.5 5.05964 18.9404 4.5 18.25 4.5ZM9.7519 12.5C10.7184 12.5 11.5019 13.2835 11.5019 14.25V16.25C11.5019 17.2165 10.7184 18 9.7519 18H7.7519C6.7854 18 6.0019 17.2165 6.0019 16.25V14.25C6.0019 13.2835 6.7854 12.5 7.7519 12.5H9.7519ZM16.2494 12.5C17.2159 12.5 17.9994 13.2835 17.9994 14.25V16.25C17.9994 17.2165 17.2159 18 16.2494 18H14.2494C13.2829 18 12.4994 17.2165 12.4994 16.25V14.25C12.4994 13.2835 13.2829 12.5 14.2494 12.5H16.2494ZM9.75063 6C10.7171 6 11.5006 6.7835 11.5006 7.75V9.75C11.5006 10.7165 10.7171 11.5 9.75063 11.5H7.75063C6.78413 11.5 6.00063 10.7165 6.00063 9.75V7.75C6.00063 6.7835 6.78413 6 7.75063 6H9.75063ZM16.2481 6C17.2146 6 17.9981 6.7835 17.9981 7.75V9.75C17.9981 10.7165 17.2146 11.5 16.2481 11.5H14.2481C13.2816 11.5 12.4981 10.7165 12.4981 9.75V7.75C12.4981 6.7835 13.2816 6 14.2481 6H16.2481Z"/></svg>'></DashboardCard>
    <DashboardCard :title="t('global.totalPlaylists') + ':'" :count="$store.state.Playlists.count" path="/playlists"
                   icon='<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24" data-v-4bc3c99a=""><path fill-rule="evenodd" d="M11.263 8.126a.529.529 0 00-.056.236v4.633a.529.529 0 00.765.473l4.633-2.317a.529.529 0 000-.945L11.972 7.89a.528.528 0 00-.709.236zM5.393 6a3.25 3.25 0 013.25-3.25H18A3.25 3.25 0 0121.25 6v9.357a3.25 3.25 0 01-3.25 3.25H8.643a3.25 3.25 0 01-3.25-3.25V6zM2.75 8.829c0-1.22.636-2.291 1.593-2.9-.005.085-.007.17-.007.257V15.7A3.964 3.964 0 008.3 19.664h9.514l.129-.002.13-.006a3.433 3.433 0 01-2.902 1.594H8.3a5.55 5.55 0 01-5.55-5.55V8.829z" clip-rule="evenodd" data-v-4bc3c99a=""></path></svg>'></DashboardCard>
  </div>

  <!-- Unpaired displays list -->
    <transition-group enter-active-class="transition-opacity duration-300 ease-linear"
                      enter-from-class="opacity-0"
                      enter-to-class="opacity-100"
                      leave-active-class="transition-opacity duration-300 ease-linear"
                      leave-from-class="opacity-100"
                      leave-to-class="opacity-0"
                      tag="div"
                      mode="out-in"
                      class="mt-10 grid grid-cols-1 grid-cols-2 gap-5 lg:grid-cols-3">
      <router-link v-for="display in unpairedDisplays" :key="display.id"
                   :to="{ name: 'Display detail', params: { id: display.id }}"
                   class="overflow-hidden col-span-1 button bg-light-level-0 text-light-level-1 border border-light-level-1 hover:border-light-highlight-level-1 rounded-level-3">
        <div class="px-4 py-5">
          <div class="flex flex-row items-start gap-x-5">
            <div class="self-center flex-shrink-0 p-3 text-white bg-light-level-3 rounded-level-5">
              <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M6.75 22a.75.75 0 0 1-.102-1.493l.102-.007h1.749v-2.498H4.25a2.25 2.25 0 0 1-2.245-2.096L2 15.752V5.25a2.25 2.25 0 0 1 2.096-2.245L4.25 3h15.499a2.25 2.25 0 0 1 2.245 2.096l.005.154v10.502a2.25 2.25 0 0 1-2.096 2.245l-.154.005h-4.25V20.5h1.751a.75.75 0 0 1 .102 1.494L17.25 22H6.75Zm7.248-3.998h-4l.001 2.498h4l-.001-2.498Z"></path>
              </svg>
            </div>
            <div class="flex flex-col">
            <span class="text-sm font-medium leading-5 truncate text-our-gray-120">
                {{ t('global.unpairedDisplay') + ':' }}
            </span>
              <span class="text-xl font-semibold leading-8 text-light-level-1">
              {{ display.name }}
            </span>
            </div>
          </div>
        </div>
      </router-link>
    </transition-group>
</template>

<script>
import DashboardCard from '@/components/DashboardCard'
import { useI18n } from 'vue-i18n'

export default {
  name: 'Home',
  components: {
    DashboardCard
  },
  setup() {
    return useI18n()
  },
  data() {
    return {
      unpairedDisplays: []
    }
  },
  created() {
    this.$store.dispatch('Displays/fetchDisplaysCount')
    this.$store.dispatch('Groups/fetchGroupsCount')
    this.$store.dispatch('Playlists/fetchPlaylistsCount')

    this.$store.dispatch('Displays/fetchAllDisplays').then(response => {
      for (const r_displays of response.data) {
        if (r_displays.devices.length === 0) {
          this.unpairedDisplays.push({ 'id': r_displays.id, 'name': r_displays.name })
        }
      }
    })
  }
}
</script>

<style scoped>

</style>
