<template>
  <div :key="reload">
    <div class="flex flex-col">
      <div> <!--class="px-6 py-2 -mx-6 -my-2 lg:-mx-8 lg:px-8"-->
        <div class="max-w-full overflow-x-auto rounded-xl bg-light-level-2">

          <div v-if="pagination && (pagination.total >= pagination.perPage)">
            <Pagination
                :key="dataLoaded + currentPage + 'p'"
                :currentPage="currentPage"
                :perPage="pagination.perPage"
                :allResults="pagination.total"
                @new-page="newPage"
            />
          </div>

          <table
              class="w-full text-left"
              :class="(this.scroll === true) ? 'block max-h-96 overflow-y-auto' : ''"
          >
            <thead>
            <tr>
              <th
                  v-for="item in visibleHeadColumns"
                  :key="item.col"
                  class="px-7 pt-5 text-xs font-medium leading-4 tracking-wider text-left uppercase bg-light-level-2 text-our-gray-190"
              >
                <div class="flex w-full no-wrap">

                  <!-- May catch custom templated header -->
                  <slot
                      v-if="$slots[item.col + '-head']"
                      :name="item.col + '-head'"
                      :data="item"
                  />

                  <!-- Otherwise display title -->
                  <div v-else class="inline-flex flex-col w-full">
											<span class="w-full">
												{{ item.title || $t(item.title_i18n) }}
											</span>
                  </div>

                </div>
              </th>
            </tr>
            </thead>

            <Transition name="fade" mode="out-in" appear>
              <tbody v-if="pagedBody.length === 0" class="bg-light-level-2">
              <tr>
                <td colspan="100" class="text-center">
                  <div class="py-8 mx-3.5 my-4 bg-white rounded-xs">
                  <span v-if="dataLoaded">
                    {{ t('noData') }}
                  </span>
                    <LoaderVertical v-if="!dataLoaded"></LoaderVertical>
                    <!-- <div class="flex flex-col gap-y-2.5" v-else>
                      <LoaderVertical></LoaderVertical>
                      <span class="animate-pulse" v-text="t('loading') "></span>
                    </div>-->
                  </div>
                </td>
              </tr>
              </tbody>
              <tbody v-else class="bg-light-level-2">
              <tr v-for="(item, indexTr) in pagedBody"
                  :key="indexTr"
                  class="cursor-pointer group"
                  v-on:mouseenter="$emit('tr-hover', { type: 'enter', id: item.id })"
                  v-on:mouseleave="$emit('tr-hover', { type: 'leave' })">
                <!--v-on:click="this.$router.push({ path: '/displays/detail/' + item.id })"-->
                <td
                    v-for="(content, col, indexTd) in sortByHeads(item)"
                    :key="'bod' + col"
                    :class="'whitespace-nowrap text-sm leading-5 text-our-gray-140 p-0 ' + headHasClasses(col)"
                >
                  <router-link :to="{ name: detailComponent, params: { id: idPath ? $helpers.get(item, idPath) : item.id }}">
                    <div class="py-4 my-2 bg-white"
                         :class="tableItemClasses(Object.keys(pagedBody).length, Object.keys(this.sortByHeads(item)).length, indexTr, indexTd) + ' ' + 'transition-all duration-100 ease-linear'">
                      <!-- Catches <template #col>..</template> and replaces it here -->
                      <slot
                          v-if="$slots[col]"
                          :name="col"
                          :data="item"
                          :content="content"
                          :index="indexTr"
                      />
                      <!-- Else display pre-set col value -->
                      <span v-else>
                    {{ content || '&nbsp;' }}
                    </span>
                    </div>
                  </router-link>
                </td>
              </tr>
              </tbody>
            </Transition>

            <tfoot v-if="$slots['footer']">
            <slot name="footer"></slot>
            </tfoot>

          </table>

          <div v-if="pagination && (pagination.total >= pagination.perPage)">
            <Pagination
                :key="dataLoaded + currentPage"
                :currentPage="currentPage"
                :perPage="pagination.perPage"
                :allResults="pagination.total"
                @new-page="newPage"
            />
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Table/Pagination'
import { useI18n } from 'vue-i18n'
import LoaderVertical from '@/components/LoaderVertical'

export default {
  name: 'Table',
  components: {
    LoaderVertical,
    Pagination
  },
  setup() {
    return useI18n({
      messages: {
        en: {
          noData: 'No data',
          loading: 'Loading...',
        },
        cs: {
          noData: 'Žádné výsledky',
          loading: 'Načítání...',
        }
      }
    })
  },
  props: {
    tableHeads: Array,
    tableBody: Array,
    detailComponent: String,
    idPath: String,
    usePagination: {
      default: false,
      type: Boolean
    },
    pagination: Object,
    variableColumns: {
      default: false,
      type: Boolean
    },
    scroll: {
      default: false,
      type: Boolean
    }
  },
  data: () => ({
    reload: 1,
    currentPage: 1,
    sortKeeper: {},
    filterKeeper: {},
    dataLoaded: false
  }),
  computed: {
    visibleHeadColumns() {
      return this.variableColumns ? this.tableHeads.filter(item => item.display) : this.tableHeads
    },
    pagedBody() {
      let perPage = this.pagination.perPage,
          page = this.currentPage
      if(page < 1){
        page = 1
      }
      return perPage !== undefined ? this.tableBody.slice((page - 1) * perPage, page * perPage) : this.tableBody
    }
  },
  watch: {
    tableBody() {
      if (this.tableBody && !this.dataLoaded) {
        this.dataLoaded = true
      }
    },
    dataLoaded() {
      let maxPage = Math.ceil(this.pagination.total / this.pagination.perPage)
      if (this.currentPage < 1) {
        this.currentPage = 1
      }
      else if (this.currentPage > maxPage) {
        this.currentPage = maxPage
      }
    }
  },
  methods: {
    newPage(page) {
      this.currentPage = page
      //this.$router.push({ name: this.$route.path, params: { page: this.currentPage } })
      let tmp = Object.assign({}, this.$route.query)
      tmp['page'] = this.currentPage
      this.$router.replace({ query: tmp })

      let duration = 600 // default was 800
      // stolen from vue-simple-scroll-up
      if (document.scrollingElement.scrollTop === 0) {
        return
      }
      const totalScrollDistance = document.scrollingElement.scrollTop
      let scrollY = totalScrollDistance
      let oldTimestamp = null

      function step(newTimestamp) {
        if (oldTimestamp !== null) {
          scrollY -= totalScrollDistance * (newTimestamp - oldTimestamp) / duration
          if (scrollY <= 0) {
            return document.scrollingElement.scrollTop = 0
          }
          document.scrollingElement.scrollTop = scrollY
        }
        oldTimestamp = newTimestamp
        window.requestAnimationFrame(step)
      }

      window.requestAnimationFrame(step)
      // stolen
    },
    sortByHeads(item) {
      if (!item) {
        return this.visibleHeadColumns
      }
      let tmp = {}
      for (let col in this.visibleHeadColumns) {
        tmp[this.visibleHeadColumns[col].col] = item[this.visibleHeadColumns[col].col]
      }
      return tmp
    },
    headHasClasses(column) {
      for (let col in this.visibleHeadColumns) {
        if (this.visibleHeadColumns[col].col === column && this.visibleHeadColumns[col].class) {
          return this.visibleHeadColumns[col].class
        }
      }
      return ''
    },
    tableItemClasses(lengthTr, lengthTd, indexTr, indexTd) {
      if (indexTr === 0 && indexTd === 0) {
        return 'mt-3.5 ml-3.5 pl-3.5 rounded-l-sm border-l border-t border-b border-our-gray-50 group-hover:border-light-highlight-level-1'
      }
      else if (indexTr === 0 && lengthTd === indexTd + 1) {
        return 'mr-3.5 pl-7 mt-3.5 rounded-r-sm border-r border-t border-b border-our-gray-50 group-hover:border-light-highlight-level-1'
      }
      else if (indexTr === 0) {
        return 'mt-3.5 pl-7 border-t border-b border-our-gray-50 group-hover:border-light-highlight-level-1'
      }
      else if (lengthTr === indexTr + 1 && indexTd === 0) {
        return 'ml-3.5 pl-3.5 mb-3.5 rounded-l-sm border-l border-t border-b border-our-gray-50 group-hover:border-light-highlight-level-1'
      }
      else if (lengthTr === indexTr + 1 && lengthTd === indexTd + 1) {
        return 'mr-3.5 mb-3.5 pl-7 rounded-r-sm border-r border-t border-b border-our-gray-50 group-hover:border-light-highlight-level-1'
      }
      else if (indexTd === 0) {
        return 'ml-3.5 pl-3.5 rounded-l-sm border-l border-t border-b border-our-gray-50 group-hover:border-light-highlight-level-1'
      }
      else if (lengthTd === indexTd + 1) {
        return 'mr-3.5 pl-7 pr-6 rounded-r-sm border-r border-t border-b border-our-gray-50 group-hover:border-light-highlight-level-1'
      }
      else if (lengthTr === indexTr + 1) {
        return 'pl-7 mb-3.5 border-t border-b border-our-gray-50 group-hover:border-light-highlight-level-1'
      }
      else {
        return 'pl-7 border-t border-b border-our-gray-50 group-hover:border-light-highlight-level-1'
      }
    }
  },
  mounted() {
    if (this.usePagination && this.$route.query.page !== undefined) {
      this.currentPage = Number.parseInt(this.$route.query.page)
    }
    for (let item in this.visibleHeadColumns) {
      if (item.filter) {
        this.filterKeeper[item.col] = null
      }
    }
  },
}
</script>
