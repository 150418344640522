import devices from '@/api/modules/devices'

const state = {
	count: 0,
	devices: [],
	deviceData: {}
}


const getters = {
	getAllDevices(state) {
		return state.devices;
	},
	getDeviceData(state) {
		return state.deviceData;
	}
}


const mutations = {
	SET_DEVICES_COUNT(state, data) {
		state.count = data
	},
	SET_DEVICES_LIST(state, data) {
		state.devices = data
	},
	SET_DEVICE_DATA(state, data) {
		state.deviceData = data
	},
	SET_EVENTS_DATA_PLAYLISTS(state, data) {
		state.eventsDataPlaylists = data
	},
	SET_EVENTS_DATA_PLAYABLES(state, data) {
		state.eventsDataPlayables = data
	},
}


const actions = {
	fetchDevicesCount({ commit }, payload) {
		return new Promise((resolve, reject) => {
			devices.getDevicesCount(payload).then(response => {
				commit('SET_DEVICES_COUNT', response.data)
				resolve(response)
			}, error => {
				reject(error);
			})
		})
	},
	fetchAllDevices({ commit }, payload) {
		return new Promise((resolve, reject) => {
			devices.getAllDevices(payload).then(response => {
				commit('SET_DEVICES_LIST', response.data)
				resolve(response)
			}, error => {
				reject(error);
			})
		})
	},
	fetchDevicesPage({ commit }, payload) {
		return devices.getDevicesPage(payload.start, payload.limit).then((response) => {
			commit('SET_DEVICES_LIST', response.data)
		})
	},
	fetchDevice({ commit }, deviceId) {
		return devices.getSingleDevice(deviceId).then((response) => {
			commit('SET_DEVICE_DATA', response.data)
			return response
		})
	},
	createDevice({ commit }, payload) {
		return new Promise((resolve, reject) => {
			devices.addNewDevice(payload).then(response => {
				commit('SET_DEVICE_DATA', response.data)
				resolve(response);
			}, error => {
				reject(error);
			})
		})
	},
	updateDevice({ commit }, payload) {
		return new Promise((resolve, reject) => {
			devices.updateExistingDevice(payload).then(response => {
				commit('SET_DEVICE_DATA', response.data)
				resolve(response);
			}, error => {
				reject(error);
			})
		})
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
