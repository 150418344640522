import axios from 'axios'
import store from '../../store'

const END_POINT = '/displays'
const UPLOAD_END_POINT = '/upload';
const END_POINT_EVENT_LISTS = '/event-lists'
const END_POINT_EVENT_LIST_PLAYLISTS = '/event-list-playlists'
const END_POINT_EVENT_LIST_ITEMS = '/event-list-items'

const getAllDisplays = (_params = {}) => {
	return axios({
		url: END_POINT,
		method: 'GET',
		params: _params
	})
}

const getSingleDisplay = (displayID) => {
	return axios({
		url: END_POINT + '/' + displayID,
		method: 'GET'
	})
}

const getDisplaysCount = () => {
	return axios({
		url: END_POINT + '/count',
		method: 'GET'
	})
}

const getDisplaysPage = (_start, _limit) => {
	return axios({
		url: END_POINT,
		params: { _start, _limit },
		method: 'GET'
	})
}

const addNewDisplay = (data) => {
	return axios({
		url: END_POINT,
		data: data,
		method: 'POST'
	})
}

const updateExistingDisplay = (data) => {
	return axios({
		url: END_POINT + '/' + data.id,
		data: data,
		method: 'PUT'
	})
}

const deleteDisplay = (displayID) => {
	return axios({
		url: END_POINT + '/' + displayID,
		method: 'DELETE'
	})
}


const getEventsForOneDisplay = (displayID) => {
	return axios({
		url: END_POINT_EVENT_LISTS + '?display=' + displayID,
		method: 'GET'
	})
}

const getEventsForOneDisplayUntil = ({ displayID, untilDate }) => {
	return axios({
		url: END_POINT_EVENT_LISTS + '/display-until/' + displayID + '/' + untilDate,
		method: 'GET'
	})
}

const getEventsListItemsForDisplayAndPlaylist = ({ displayID, playlistID }) => {
	return axios({
		url: END_POINT_EVENT_LIST_ITEMS + `?_where[displayid]=${displayID}&[playlist]=${playlistID}`,
		/*params: {
			'_where': `[displayid]=${displayID}&[0][playlist]=${playlistID}`,
		},*/
		method: 'GET'
	})
}

const getEventsListItemsForEventListPlaylist = ({ eventListPlaylistID, currentTime }) => {
	return axios({
		url: END_POINT_EVENT_LIST_ITEMS + `?_where[eventlistplaylist]=${eventListPlaylistID}&[to_gte]=${currentTime}&_limit=500`,
		method: 'GET'
	})
}

const getEventListPlaylistForOneDisplayUntil = ({ displayID, untilDate }) => {
	return axios({
		url: END_POINT_EVENT_LIST_PLAYLISTS + '/display-until/' + displayID + '/' + untilDate,
		method: 'GET'
	})
}

const uploadNewFile = (file) => {

	const uploadFileRequestCancel = axios.CancelToken.source();

	const config = {
		onUploadProgress: function (progressEvent) {
			var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
			store.dispatch('Displays/setUploadProgress', percentCompleted)
		}
	}

	let data = new FormData()
	data.append('files', file)

	let request = axios({
		url: UPLOAD_END_POINT,
		data: data,
		method: 'POST',
		...config,
		cancelToken: uploadFileRequestCancel.token
	})

	return request
}

export default {
	getAllDisplays,
	getSingleDisplay,
	getDisplaysCount,
	getDisplaysPage,
	addNewDisplay,
	updateExistingDisplay,
	deleteDisplay,

	getEventsForOneDisplay,
	getEventsForOneDisplayUntil,
	getEventsListItemsForDisplayAndPlaylist,
	getEventsListItemsForEventListPlaylist,
	getEventListPlaylistForOneDisplayUntil,

	uploadNewFile
}
