<template>
  <div class="flex flex-col min-h-screen">

    <div class="relative inline-block text-left">
      <Menu class="bg-our-gray-200 flex justify-end p-4" as="div">
        <MenuButton class="button-light-level-0 rounded-level-4">
          <svg class="-ml-0.5 mr-2" width="24" height="24" fill="currentColor" viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg">
            <path
                d="m9.563 7.505.056.117 5.307 13.005a1 1 0 0 1-1.802.86l-.05-.105L11.693 18H5.407l-1.49 3.407a1 1 0 0 1-1.208.555l-.11-.04a1 1 0 0 1-.556-1.208l.04-.11L7.778 7.6c.336-.77 1.394-.795 1.786-.094ZM19 2a1 1 0 0 1 .993.883L20 3v4h1a1 1 0 0 1 .993.883L22 8a1 1 0 0 1-.883.993L21 9h-1v7a1 1 0 0 1-.883.993L19 17a1 1 0 0 1-.993-.883L18 16V3a1 1 0 0 1 1-1ZM8.66 10.567 6.282 16h4.595L8.66 10.567ZM11 2h5a1 1 0 0 1 .993.883L17 3v2.975a4 4 0 0 1-4 4 1 1 0 1 1 0-2 2 2 0 0 0 1.995-1.85l.005-.15V4h-4a1 1 0 0 1-.117-1.993L11 2h5-5Z"/>
          </svg>
          <span>{{ t('global.menu.language') }}</span>
          <svg
              class="w-5 h-5 ml-2 -mr-1"
              viewBox="0 0 20 20"
              fill="currentColor">
            <path fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd"></path>
          </svg>
        </MenuButton>

        <transition enter-active-class="transition duration-100 ease-in"
                    enter-from-class="transform scale-90 opacity-0"
                    enter-to-class="transform scale-100 opacity-100"
                    leave-active-class="transition duration-200 ease-out"
                    leave-from-class="transform scale-100 opacity-100"
                    leave-to-class="transform scale-90 opacity-0">
          <MenuItems
              class="absolute right-0 z-50 w-56 p-3 mt-2 flex flex-col gap-y-2 origin-top-right border rounded-sm outline-none bg-our-gray-190 border-our-gray-120">
            <MenuItem v-slot="{ active }" v-for="item in [{ 'en' : 'english' }, { 'cs' : 'czech' }]"
                      :key="Object.keys(item)[0]"
                      :disabled="locale === Object.keys(item)[0]">
              <button v-on:click="changeLanguage(Object.keys(item)[0])"
                      :class="[active ? 'bg-our-gray-150 border-our-gray-130' : 'border-our-gray-190', locale === Object.keys(item)[0] ? 'cursor-default' : 'cursor-pointer' ]"
                      class="flex items-center justify-between w-full px-4 py-2 text-sm leading-5 text-left transition-all duration-200 ease-linear border outline-none rounded-xs text-our-gray-10">
                                <span :class="locale === Object.keys(item)[0] && 'opacity-75'"
                                      v-text="t(`global.lang.${ Object.values(item)[0] }`)"></span>
                <span :class="locale === Object.keys(item)[0] ? 'border-our-cyan-40' : 'border-white'"
                      class="border-2 rounded-full">
                                  <img class="w-6 h-6" :src="require(`@/assets/langs/${ Object.keys(item)[0] }.svg`)">
                                </span>
              </button>
            </MenuItem>
          </MenuItems>
        </transition>
      </Menu>
    </div>

    <div id="authentication"
         class="relative flex items-center justify-center flex-grow bg-our-gray-200 p-4">

      <div class="w-auto min-w-lg mx-12">
        <div class="flex flex-col items-center justify-center gap-y-12 pb-20">

          <img class="w-auto h-24"
               src="@/assets/logo.svg">

          <div class="py-16 px-20 overflow-hidden bg-our-gray-180 flex flex-col items-center justify-center w-full">
            <router-view v-slot="{ Component }">
              <transition name="fade-router-view"
                          mode="out-in"
                          appear>

                <component class="w-full"
                           :is="Component"/>

              </transition>
            </router-view>
          </div>

        </div>
      </div>

      <Notification></Notification>

      <div v-if="false" class="absolute top-0 left-0 ml-4 mt-4">
        <a href="https://www.bohemicasignage.com/"
           type="button"
           class="px-2.5 py-1.5 text-xs rounded-sm button button-gray">
          <svg xmlns="http://www.w3.org/2000/svg"
               width="24"
               height="24"
               fill="currentColor"
               viewBox="0 0 24 24"
               class="mr-1.5 -ml-0.5 h-5 w-5">
            <path d="M9.79 17.613l-.083.094a1 1 0 01-1.32.083l-.094-.083-4.997-4.998a1 1 0 01-.083-1.32l.083-.093 4.996-5.004a1 1 0 011.499 1.32l-.083.094L6.419 11H20a1 1 0 01.993.883L21 12a1 1 0 01-.883.993L20 13H6.416l3.291 3.293a1 1 0 01.083 1.32l-.083.094.083-.094z"/>
          </svg>
          <span>Return to the homepage</span>
        </a>
      </div>

      <div v-if="false" class="absolute bottom-0 right-0 mr-4 mb-4">
        <transition name="fade"
                    mode="out-in">
          <div class="flex flex-row items-center justify-center gap-x-4"
               v-if="!redirecting && component === 'Signup'"
               key="login">
            <span class="text-our-gray-60">Already have an existing account?</span>
            <!--<router-link :to="{ name: 'Login' }"
                                     class="text-sm rounded-sm button button-light-gray-to-cyan">Log in
                        </router-link>-->
            <button v-on:click="redirectToLogIn()"
                    class="text-sm rounded-sm button button-light-gray-to-cyan">Log in
            </button>
          </div>

          <div class="flex flex-row items-center justify-center gap-x-4"
               v-else-if="!redirecting && component === 'Login'"
               key="signup">
            <span class="text-our-gray-60">Would you like to create an account instead?</span>
            <!--<router-link :to="{ name: 'Signup' }"
                                     class="text-sm rounded-sm button button-light-gray-to-cyan">Sign up
                        </router-link>-->
            <button v-on:click="redirectToSignUp()"
                    class="text-sm rounded-sm button button-light-gray-to-cyan">Sign up
            </button>
          </div>
        </transition>

      </div>

    </div>
  </div>
</template>

<script>
import Notification from "@/components/Notification";
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import {useI18n} from "vue-i18n";

export default {
  name: "Authentication",
  components: {
    Notification,
    Menu, MenuButton, MenuItems, MenuItem
  },
  setup() {
    return useI18n()
  },
  data() {
    return {
      redirecting: false,
      component: null
    }
  },
  methods: {
    changeLanguage(val) {
      //console.log('Language changed', this.$route.name)
      localStorage.language = val
      this.$moment.locale(localStorage.language || process.env.VUE_APP_I18N_LOCALE || 'en')
      this.$router.replace({ name: this.$route.name, params: { locale: val } })
    },
    redirectToLogIn() {
      this.redirecting = true
      setTimeout(() => {
        this.$router.push({ name: 'Login' })
      }, 250)
    },
    redirectToSignUp() {
      this.redirecting = true
      setTimeout(() => {
        this.$router.push({ name: 'Signup' })
      }, 250)
    },
  },
  updated() {
    console.log('updated', this)
  },
  beforeRouteEnter(to, from, next) {
    //console.log('beforeRouteEnter', to, from)
    next(vm => {
      vm.redirecting = false
      vm.component = to.name
    })
  },
  beforeRouteUpdate(to, from, next) {
    console.log('beforeRouteUpdate', to, from)
    this.redirecting = false
    this.component = to.name
    next()
  }
}
</script>
<style scoped>

</style>
