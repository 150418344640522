import lodash from 'lodash'
import moment from 'moment'
import store from '@/store'

const date = (date) => {
	return moment(date).format('yyyy-MM-DD') /* 'yyyy-MM-DD' <-- this format is required by HTML5 input type="date", otherwise 'DD/MM/YYYY' can be used */
}

const time = (time) => {
	/*console.log(time, moment(time, 'HH:mm').format('HH:mm:ss.SSS'))  moment(time).format('HH:mm:ss.SSS')) */
	return moment(time, 'HH:mm').format('HH:mm:ss.SSS')
}

const dateAndTime = (date) => {
	if (date !== false) {
		return moment(new Date(date)).format('DD.MM.YYYY, HH:mm:ss')
	}
	else {
		return 'Never'
	}
}


const millisecondsToMinutesAndSeconds = (time) => {

	/*let tempTime = moment.duration(parseInt(time))

	console.log(time, tempTime, tempTime.minutes() + ":" + tempTime.seconds())
	return tempTime.minutes() + ":" + tempTime.seconds()*/

	/*let split = time.match(/.{1,2}/g)*/

	let seconds = Math.floor(parseInt(time) / 1000)
	let minutes = '00'

	if (seconds >= 60) {

		minutes = Math.floor(seconds / 60)

		seconds = seconds % (minutes * 60)

		if (minutes < 9) {
			minutes = '0' + minutes
		}

		if (seconds < 9) {
			seconds = '0' + seconds
		}
	}

	/*

	let seconds = parseInt(time) % 1000 / 60*/

	return minutes + ':' + seconds
}

const minutesAndSecondsToMilliseconds = (time) => {
	let convertToSeconds = time.split(':')
	if (convertToSeconds.length === 1) {
		return Math.abs(parseInt((convertToSeconds[0]) || 0) * 1000) || 0
	}
	else if (convertToSeconds.length === 2) {
		convertToSeconds = (parseInt((convertToSeconds[0]) || 0) * 60) + Math.abs(parseInt(convertToSeconds[1]) || 0)
		return Math.abs(convertToSeconds) * 1000 || 0
	}
	else if (convertToSeconds.length === 3) {
		return Math.abs((parseInt((convertToSeconds[0]) || 0) * 60000) + parseInt((convertToSeconds[1]) || 0) * 1000 + parseInt(convertToSeconds[2]) || 0)
	}
	else if (convertToSeconds.length === 4) {
		return Math.abs((parseInt((convertToSeconds[0]) || 0) * 3600000) + parseInt((convertToSeconds[1]) || 0) * 60000
			+ parseInt((convertToSeconds[2]) || 0) * 1000 + parseInt((convertToSeconds[3]) || 0))
	}
	else if (convertToSeconds.length > 4) {
		return Math.abs((parseInt((convertToSeconds[0]) || 0) * 60000) + parseInt((convertToSeconds[1]) || 0) * 1000 + parseInt(convertToSeconds[2]) || 0)
	}
	else {
		return Math.abs(convertToSeconds) || 0
	}

}

const date2ISO = (date) => {
	// ISO 8601 'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
	let parts = date.split(' '),
		dates = parts[0].split('/'),
		times = parts[1].split(':'),

		day = dates[0],
		mon = dates[1],
		year = dates[2],

		hour = times[0],
		padded_hour = (hour < 10) ? '0' + hour : hour,
		min = times[1],
		sec = times[2]

	return `${year}-${mon}-${day}T${padded_hour}:${min}:${sec}.000Z`
}


const purify = (data) => {
	return JSON.parse(JSON.stringify(data))
}

const isEqual = (object1, object2) => {
	object1 = lodash.omit(object1, [ 'created_at', 'updated_at' ])
	object2 = lodash.omit(object2, [ 'created_at', 'updated_at' ])

	return lodash.isEqual(object1, object2)
}

const objectFalseyFilter = (object) => {
	return Object.fromEntries(
		Object.entries(object).filter(
			([ key, value ]) => {
				return !!value
			}
		)
	)
}

const objectFalseyAndEmptyFilter = (object) => {
	return Object.fromEntries(
		Object.entries(object).filter(
			([ key, value ]) => {
				return !!value || !lodash.isEmpty(value)
			}
		)
	)
}

const objectFalseyEmptyFilterAndRemoveObject = (object, remove) => {
	return Object.fromEntries(
		Object.entries(object).filter(([ key, value ]) => {
			//console.log(key, value, lodash.isObject(value), !value, lodash.isEmpty(value), remove.some(item => key === item))
			return !!value && (lodash.isObject(value) ? (!lodash.isEmpty(value)) : true) && !remove.some(item => key === item)
		})
	)
}

const replaceString = (str, pattern, replacement) => {
	return lodash.replace(str, pattern, replacement)
}

const replaceStringMultiple = (str, findArray, replaceArray) => {
	var i, regex = [], map = {}
	for (i = 0; i < findArray.length; i++) {
		regex.push(findArray[i].replace(/([-[\]{}()*+?.\\^$|#,])/g, '\\$1'))
		map[findArray[i]] = replaceArray[i]
	}
	regex = regex.join('|')
	str = str.replace(new RegExp(regex, 'g'), function (matched) {
		return map[matched]
	})
	return str
}

/**
 * Setup strapi filters from CoreUI table filters
 * @param params : object, in-out carrier
 * @param filters : object, coreUI table filters { page: INT, perPage: INT, sort: {column: NAME, asc: BOOL}, filters : {column: FILTER, ...}}
 * @param setup : array, extended specification [{tableName: Site, databaseName: Site.SiteName}, ...]
 */
const formatTableFilters = (filters, setup = []) => {

	let output = {}

	if (!filters) {
		return output
	}

	if (filters.page && filters.perPage) {
		output._start = (filters.page - 1) * filters.perPage
		output._limit = filters.perPage
	}

	if (filters.sort) {
		output._sort = filters.sort.column + ((filters.sort.asc) ? ':ASC' : ':DESC')
	}

	if (filters.filters) {
		for (let n in filters.filters) {
			if (filters.filters[n] !== undefined && filters.filters[n] !== null && filters.filters[n] !== '') {

				let found = false

				for (let j in setup) {
					// for example 'Client' -> 'Client.ClientName' to filter database correctly
					if (n === setup[j].tableName) {
						output[setup[j].databaseName + '_contains'] = filters.filters[n]
						found = true
					}
				}

				if (!found) {
					output[n + '_contains'] = filters.filters[n]
				}
			}
		}
	}

	if (filters.category) {
		for (let n in filters.category) {
			if (filters.category[n] !== undefined && filters.category[n] !== null && filters.category[n] !== '') {

				let found = false

				for (let j in setup) {
					// for example 'Client' -> 'Client.ClientName' to filter database correctly
					if (n === setup[j].tableName) {
						output[setup[j].databaseName + '_eq'] = filters.category[n]
						found = true
					}
				}

				if (!found) {
					if (!n.includes('_')) {
						output[n + '_eq'] = filters.category[n]
					}
					else {
						output[n] = filters.category[n]
					}
				}
			}
		}
	}

	if (filters.ignore) {
		for (let n in filters.ignore) {
			if (filters.ignore[n] !== undefined && filters.ignore[n] !== null && filters.ignore[n] !== '') {

				output[n] = filters.ignore[n]
			}
		}
	}

	return output
}

const formatPagination = (count, currentPage, perPage) => {

	let totalCount = (count < 1) ? 1 : count

	let pagesCount = Math.ceil(totalCount / perPage)

	let activePage = (currentPage > pagesCount)
		? pagesCount
		: ((currentPage < 1)
			? 1
			: currentPage)

	return {
		totalCount,
		pagesCount,
		activePage
	}
}

const generateList = (alertEventListCalendarUpdated, alertEventsGenerated, alertInProgress, alertEventsGeneratedServer) => {

	store.dispatch('Events/resetList').then(() => {
		setTimeout(function () {
			store.dispatch('Notifications/send', {
				variant: 'success',
				message1: alertEventListCalendarUpdated,
				message2: alertEventsGenerated,
				time: new Date().toISOString(),
				duration: 5000,
				overlay: false
			})
		}, 50)

	}).catch(e => {
		//console.log(e)
	})

	setTimeout(() => {
		store.dispatch('Notifications/send', {
			variant: 'info',
			message1: alertInProgress,
			message2: alertEventsGeneratedServer,
			time: new Date().toISOString(),
			duration: 5000,
			overlay: false
		})
	}, 10)
}

/* https://stackoverflow.com/a/69459511/11035513 */
const get = (t, path) => path.split('.').reduce((r, k) => r?.[k], t)

const helpers = {
	date,
	time,
	dateAndTime,
	millisecondsToMinutesAndSeconds,
	minutesAndSecondsToMilliseconds,
	date2ISO,

	purify,
	isEqual,
	objectFalseyFilter,
	objectFalseyAndEmptyFilter,
	objectFalseyEmptyFilterAndRemoveObject,

	replaceString,
	replaceStringMultiple,

	formatTableFilters,
	formatPagination,

	generateList,

	get
}

export default helpers
