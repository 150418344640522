const state = {
	showSlideover: false,
	slideoverTitle: null,
	slideoverComponent: null,
	slideoverData: null
}


const getters = {
	getSlideoverData(state) {
		return state.slideoverData
	},
}


const mutations = {
	TOGGLE_SLIDEOVER(state, data) {
		state.showSlideover = data
	},
	SET_SLIDEOVER_TITLE(state, data) {
		state.slideoverTitle = data
	},
	SET_SLIDEOVER_COMPONENT(state, data) {
		state.slideoverComponent = data
	},
	SET_SLIDEOVER_DATA(state, data) {
		state.slideoverData = data
	},
	SET_SLIDEOVER_PUSH_DATA(state, data) {
		state.slideoverData.push(data)
	}
}


const actions = {
	toggleSlideover({ commit }, data) {
		commit('TOGGLE_SLIDEOVER', data)
	},
	setSlideoverTitle({ commit }, data) {
		commit('SET_SLIDEOVER_TITLE', data)
	},
	setSlideoverComponent({ commit }, data) {
		commit('SET_SLIDEOVER_COMPONENT', data)
	},
	setSlideoverData({ commit }, data) {
		commit('SET_SLIDEOVER_DATA', data)
	},
	pushSlideoverData({ commit }, data) {
		commit('SET_SLIDEOVER_PUSH_DATA', data)
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
