<template>

  <transition name="fade">
    <div v-if="overlay" class="absolute inset-0 z-40 bg-black bg-opacity-50"></div>
  </transition>

  <!-- TODO notification without cancel option (for redirection/re-route) -->
  <!-- TODO animated progress bar -->
  <!-- TODO better animate in/out when more than 8 notifications -->

  <transition-group
      enter-active-class="transition-all duration-300 ease-in-out transform"
      enter-from-class="translate-x-8 opacity-0"
      enter-to-class="translate-x-0 opacity-100"
      leave-active-class="z-40 transition-all duration-300 ease-in-out transform"
      leave-from-class="translate-x-0 opacity-100"
      leave-to-class="-translate-x-4 opacity-0"
      tag="div"
      class="fixed inset-0 z-50 flex flex-col items-end justify-end mb-10 mr-8 gap-y-4 bg-transparent pointer-events-none"
  >
    <div class="box-content z-50 w-full max-w-md p-4 overflow-hidden text-white break-all transition-all duration-200 ease-linear border rounded-sm pointer-events-auto" :class="overlay ? 'border-our-gray-50 bg-white':'border-our-gray-210 bg-our-gray-160'" v-for="notification in notifications" :key="notification.time">
      <div class="flex flex-row items-start">
        <div class="p-1 border border-transparent rounded-full bg-success" v-if="notification.variant === 'success'">
          <svg class="text-success" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="20" height="20" viewBox="0 0 20 20">
            <path d="M10,1c-4.9,0-9,4.1-9,9s4.1,9,9,9s9-4,9-9S15,1,10,1z M8.7,13.5l-3.2-3.2l1-1l2.2,2.2l4.8-4.8l1,1L8.7,13.5z"></path>
          </svg>
        </div>
        <div class="p-1 border border-transparent rounded-full bg-info" v-if="notification.variant === 'info'">
          <svg class="text-info" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="20" height="20" viewBox="0 0 32 32">
            <path d="M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Zm0,6a1.5,1.5,0,1,1-1.5,1.5A1.5,1.5,0,0,1,16,8Zm4,16.125H12v-2.25h2.875v-5.75H13v-2.25h4.125v8H20Z"></path>
          </svg>
        </div>
        <div class="p-1 border border-transparent rounded-full bg-warning" v-if="notification.variant === 'warning'">
          <svg class="text-warning" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="20" height="20" viewBox="0 0 20 20">
            <path d="M10,1c-5,0-9,4-9,9s4,9,9,9s9-4,9-9S15,1,10,1z M9.2,5h1.5v7H9.2V5z M10,16c-0.6,0-1-0.4-1-1s0.4-1,1-1	s1,0.4,1,1S10.6,16,10,16z"></path>
          </svg>
        </div>
        <div class="p-1 border border-transparent rounded-full bg-error" v-if="notification.variant === 'error'">
          <svg class="text-error" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="20" height="20" viewBox="0 0 20 20">
            <path d="M10,1c-5,0-9,4-9,9s4,9,9,9s9-4,9-9S15,1,10,1z M13.5,14.5l-8-8l1-1l8,8L13.5,14.5z"></path>
          </svg>
        </div>
        <div class="flex-1 pt-1 ml-4">
          <p class="text-sm font-medium leading-5" :class="overlay ? 'text-our-gray-160':'text-white'" v-html="notification.message1"></p>
          <p class="mt-1.5 text-sm leading-5" :class="overlay ? 'text-our-gray-110':'text-our-gray-60'" v-html="notification.message2"></p>
        </div>
        <div class="ml-4">
          <button v-on:click="$store.dispatch('Notifications/remove', notification.time)"
                  class="flex inline-flex flex-col items-center p-1 transition duration-150 ease-linear border rounded-sm cursor-pointer focus:outline-none"
                  :class="overlay ? 'text-our-gray-120 bg-white border-white hover:bg-our-gray-40 hover:border-our-gray-50 hover:text-our-gray-160border-light-highlight-level-1':'text-our-gray-20 bg-our-gray-160 border-our-gray-160 hover:bg-our-gray-140 hover:border-our-gray-130border-light-highlight-level-1'">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 20 20">
              <path d="M3.897 4.054l.073-.084a.75.75 0 01.976-.073l.084.073L10 8.939l4.97-4.97a.75.75 0 01.976-.072l.084.073a.75.75 0 01.073.976l-.073.084L11.061 10l4.97 4.97a.75.75 0 01.072.976l-.073.084a.75.75 0 01-.976.073l-.084-.073L10 11.061l-4.97 4.97a.75.75 0 01-.976.072l-.084-.073a.75.75 0 01-.073-.976l.073-.084L8.939 10l-4.97-4.97a.75.75 0 01-.072-.976l.073-.084-.073.084z"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </transition-group>
</template>

<script>
export default {
  name: "Notification",
  computed: {
    notifications() {
      return this.$store.state.Notifications.notifications.slice(0).slice(-8)
    },
    overlay() {
      return this.$store.state.Notifications.notifications.some(item => item.overlay === true)
    }
  }
}
</script>

<style scoped>
@keyframes scale-x-frames {
  0% {
    transform: scaleX(1)
  }
  to {
    transform: scaleX(0)
  }
}

/*animation: scale-x-frames linear 1 forwards;*/
</style>
