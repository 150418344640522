<template>
  <div class="flex flex-row items-center justify-between w-full pr-2 gap-x-4 rounded-xl bg-our-gray-50">

    <template v-if="playableActiveData.playable !== null">

      <div
          class="flex items-center justify-center w-12 h-12 m-2 text-sm flex-shrink-0 font-semibold bg-white rounded-lg text-our-gray-140">
        <transition name="fade" mode="out-in">
          <span :key="index" v-text="playableActiveData.order + '.'"></span>
        </transition>
      </div>
      <div class="flex flex-grow min-w-0">
        <div class="relative w-full flex flex-row gap-x-2.5 tooltip-wrapper truncate">

          <div class="w-24 flex-shrink-0" v-if="thumbnail">
            <img alt="Playable screenshot" ref="image"
                 :src="$axios.defaults.baseURL + thumbnail"
                 class="flex object-contain w-24 max-h-14 rounded-xs">
          </div>

          <div :title="'ID '+playableActiveData.playable.id+': '+playableActiveData.playable.name"
               class="cursor-pointer my-auto inline-flex" target="_blank"
               @click="$router.push({ name: 'Playable detail', params: {id: playableActiveData.playable.id}})">
            <div>
              <svg v-if="playableActiveData.playable.type === 'image'" xmlns="http://www.w3.org/2000/svg" width="24"
                   height="24" fill="currentColor" viewBox="0 0 24 24">
                <path d="M2.25 2.25v19.5h19.5V2.25H2.25zm1.5 1.5h16.5v13.5H3.75V3.75z"/>
                <path
                    d="M14.25 12.75L9 7.5l-3.75 3.75v4.5h13.5v-3L16.5 10.5l-2.25 2.25zM17.25 8.25a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"/>
              </svg>
              <svg v-else-if="playableActiveData.playable.type === 'video'" xmlns="http://www.w3.org/2000/svg"
                   width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                <path d="M2.25 2.25v19.5h19.5V2.25H2.25zm1.5 1.5h16.5v13.5H3.75V3.75z"/>
                <path d="M7.5 15l9-4.5-9-4.5v9z"/>
              </svg>
              <svg v-else-if="playableActiveData.playable.type === 'web'" width="24" height="24" viewBox="0 0 24 24"
                   fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M15.98 2H2V11.948H4V3.98999H14V9.95898H20V11.949H22V8.40503L15.98 2.00098V2ZM13 17.027C12.9997 16.896 12.9737 16.7663 12.9232 16.6454C12.8728 16.5245 12.7991 16.4147 12.7062 16.3223C12.6133 16.2299 12.5032 16.1566 12.382 16.1068C12.2608 16.057 12.131 16.0316 12 16.032H11V18.022H12C12.552 18.022 13 17.576 13 17.027V17.027ZM13.724 19.384L15 21.896H13L12 19.906H11V21.896H9V13.938H12C13.657 13.938 15 15.326 15 16.974C15 17.983 14.494 18.844 13.724 19.384ZM18 13.938H16V21.896H22V19.906H18V13.938ZM6 13.938H8V19.016C7.99788 19.8096 7.68065 20.5699 7.11807 21.1296C6.55549 21.6894 5.79362 22.0028 5 22.001C3.343 22.001 2 20.664 2 19.016V13.938H4V19.016C4 19.566 4.448 20.011 5 20.011C5.552 20.011 6 19.565 6 19.016V13.938Z"/>
              </svg>
              <svg v-else-if="playableActiveData.playable.type === 'hls'" width="24" height="24" viewBox="0 0 24 24"
                   fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M18 8H16V4H2V6H14V8H2V20H18V17.62L22 19.62V8.38L18 10.38V8ZM16 11.38V18H4V10H16V11.38ZM20 11.62V16.38L18 15.38V12.62L20 11.62Z"/>
                <path d="M8 14H6V16H8V14Z"/>
              </svg>
            </div>
            <span class="truncate ml-2 border-b border-transparent hover:border-black">{{
                playableActiveData.playable.name
              }}</span>
          </div>
        </div>
      </div>

      <div class="relative flex tooltip-wrapper">
        <!--<input type="string" v-model="duration" :disabled="playableActiveData.playable.type === 'video'" :class="{ 'opacity-50 cursor-not-allowed' : playableActiveData.playable.type === 'video'}" class="block w-24 px-3 py-2 text-xs text-sm font-medium leading-5 text-center transition duration-150 ease-linear rounded-l-sm rounded-r-none form-input border-our-gray-120border-light-highlight-level-1 focus:border-our-gray-190 text-our-gray-160">
        --><!--{{ moment(playableActiveData.duration).format('mm:ss') }}-->

        <label>
          <input :value="$moment.duration(playableActiveData.duration, 'ms').format('hh:mm:ss:SSS', {trim:false})"
                 @change="playableActiveData.duration = $helpers.minutesAndSecondsToMilliseconds($event.target.value)"
                 placeholder="mm:ss"
                 :raw="false"
                 :class="{ 'opacity-50 cursor-not-allowed' : playableActiveData.playable.type === 'video',
                   'border-our-red bg-our-red text-white' : playableActiveData.duration === null || playableActiveData.duration === 0,
                   'border-our-gray-120 text-our-gray-160' : playableActiveData.duration !== null,
                    }"
                 :disabled="playableActiveData.playable.type === 'video'"
                 class="block w-32 px-3 py-2 text-xs text-sm font-medium leading-5 text-center transition duration-150 ease-linear rounded-md form-control form-inputborder-light-highlight-level-1 focus:border-our-gray-190"
          />
        </label>
        <!--:options="{ delimiter: ':', blocks: [2, 2] }"-->
        <!--<span class="inline-flex items-center px-3 -ml-px text-xs font-semibold text-center transition duration-150 ease-linear rounded-l-none rounded-r-sm form-input border-our-gray-120border-light-highlight-level-1 focus:border-our-gray-190 text-our-gray-160text-smleading-5">
              mm:ss
            </span>-->
        <Tooltip color="our-gray-30" background="our-gray-170" position="top"
                 :text="$moment.duration(playableActiveData.duration, 'ms').format('h [' + t('playlists.durationTime.hours') + ',] m [' + t('playlists.durationTime.minutes') + ',] s [' + t('playlists.durationTime.seconds') + ' ' + t('playlists.durationTime.and') + '] S [ms]', {trim:false})"
                 class="p-1 text-sm font-medium w-40"></Tooltip>
      </div>

      <div class="relative tooltip-wrapper">
        <input
            :disabled="playableActiveData.playable.type === 'video' ? false : 'disabled'"
            type="number" min='1' v-model="playableActiveData.repeat"
            class="block w-16 px-3 py-2 text-xs text-sm font-semibold leading-5 text-left transition duration-150 ease-linear rounded-md form-input border-our-gray-120border-light-highlight-level-1 focus:border-our-gray-190 text-our-gray-160">
        <Tooltip :text="playableActiveData.playable.type === 'video' ? t('playlists.repeat') : t('playlists.onlyVideosCanBeRepeated')"
                 color="our-gray-30"
                 background="our-gray-170" position="top" class="p-1 text-sm font-medium"></Tooltip>
      </div>


      <div class="flex flex-row items-center p-2 gap-x-2 bg-white rounded-lg">
        <button :disabled="index === 0" v-on:click="$emit('move-up', playableActiveData)"
                :class="{ 'opacity-50 cursor-not-allowed' : index === 0 }"
                class="flex items-center justify-center w-8 h-8 text-sm transition-all duration-150 ease-linear border rounded-md outline-none cursor-pointer text-our-gray-190 bg-our-gray-70 border-our-gray-70 hover:text-our-gray-190 hover:border-our-gray-170 focus:border-our-gray-170 focus:outline-none focus:bg-our-gray-170 focus:text-whiteborder-light-highlight-level-1">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 20 20">
            <path
                d="M3.202 8.735a.75.75 0 001.09 1.03l4.955-5.239V17.25a.75.75 0 001.5 0V4.524l4.957 5.241a.75.75 0 001.09-1.03l-6.07-6.418a.995.995 0 00-.566-.3.753.753 0 00-.328.002.995.995 0 00-.558.298l-6.07 6.418z"/>
          </svg>
        </button>

        <button :disabled="index === playablesOrderListActiveLength - 1"
                v-on:click="$emit('move-down', playableActiveData)"
                :class="{ 'opacity-50 cursor-not-allowed' : index === playablesOrderListActiveLength - 1 }"
                class="flex items-center justify-center w-8 h-8 text-sm transition-all duration-150 ease-linear border rounded-md outline-none cursor-pointer text-our-gray-190 bg-our-gray-70 border-our-gray-70 hover:text-our-gray-190 hover:border-our-gray-170 focus:border-our-gray-170 focus:outline-none focus:bg-our-gray-170 focus:text-whiteborder-light-highlight-level-1">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 20 20">
            <path
                d="M16.792 11.265a.75.75 0 00-1.09-1.03l-4.955 5.239V2.75a.75.75 0 00-1.5 0v12.726L4.29 10.235a.75.75 0 00-1.09 1.03l6.07 6.418a.995.995 0 00.566.3.753.753 0 00.329-.002.995.995 0 00.558-.298l6.07-6.418z"/>
          </svg>
        </button>

        <div class="relative tooltip-wrapper">
          <button v-on:click="$emit('remove-playable', playableActiveData)"
                  class="flex items-center justify-center w-8 h-8 text-sm transition-all duration-150 ease-linear border rounded-md outline-none cursor-pointer text-our-gray-190 bg-our-gray-70 border-our-gray-70 hover:text-our-gray-190 hover:border-our-gray-170 focus:border-our-gray-170 focus:outline-none focus:bg-our-gray-170 focus:text-whiteborder-light-highlight-level-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 20 20">
              <path
                  d="M3.897 4.054l.073-.084a.75.75 0 01.976-.073l.084.073L10 8.939l4.97-4.97a.75.75 0 01.976-.072l.084.073a.75.75 0 01.073.976l-.073.084L11.061 10l4.97 4.97a.75.75 0 01.072.976l-.073.084a.75.75 0 01-.976.073l-.084-.073L10 11.061l-4.97 4.97a.75.75 0 01-.976.072l-.084-.073a.75.75 0 01-.073-.976l.073-.084L8.939 10l-4.97-4.97a.75.75 0 01-.072-.976l.073-.084-.073.084z"/>
            </svg>
          </button>
          <Tooltip color="our-gray-30" background="our-gray-170" position="top" :text="t('playlists.remove')"
                   class="p-1 text-sm font-medium"></Tooltip>
        </div>

      </div>

    </template>

    <template v-else>
      <p>{{ t('playlists.errorPlayableOrderId') }} {{ playableActiveData.id }}</p>
    </template>

  </div>
</template>

<script>
import {useI18n} from "vue-i18n";

export default {
  name: "PlaylistRow",
  props: {
    playableData: Object,
    playablesOrderListActiveLength: Number,
    index: Number,
    actualPlayable: null
  },
  data() {
    return {
      thumbnail: null
    }
  },
  setup() {
    return useI18n()
  },
  computed: {
    playableActiveData() {
      //console.log("Playable data",this.playableData)
      return this.playableData
    }
  },
  methods: {
    getPlayableThumbnail(playable) {

      if (!playable) return null;

      if (playable.type === 'video') {
        return playable.media_thumb.url.substring(1);
      } else if (playable.type === 'image') {
        if (playable.original.formats != null) {
          if (typeof playable.original.formats.large !== 'undefined') {
            return (playable.original.formats.large.url).substring(1);
          }
          if (typeof playable.original.formats.medium !== 'undefined') {
            return (playable.original.formats.medium.url).substring(1);
          }
          if (typeof playable.original.formats.small !== 'undefined') {
            return (playable.original.formats.small.url).substring(1);
          }
          if (typeof playable.original.formats.thumbnail !== 'undefined') {
            return (playable.original.formats.thumbnail.url).substring(1);
          }
        } else {
          return (playable.original.url).substring(1);
        }
      } else if (playable.type === 'web') {
        return 'screenshots/' + playable.id + '.png';
      } else {
        return null;
      }
    }
  },
  async created() {
    let _this = this;
    this.thumbnail = await this.$axios.get('/playables?id=' + this.playableData.playable.id).then(res => {
      if (res.status === 200 && res.data.length > 0) {
        return _this.getPlayableThumbnail(res.data[0]);
      }
    });
  }
}
</script>

<style scoped>

</style>
