import events from "@/api/modules/events";

const state = {
	lastGeneratedTime: null
}

const mutations = {
	SET_LAST_GENERATED_TIME(state, data) {
		state.lastGeneratedTime = data
	}
}

const actions = {
	fetchLastGeneratedTime({ commit }) {
		return new Promise((resolve, reject) => {
			events.getLastGeneratedTime().then((response) => {
				commit('SET_LAST_GENERATED_TIME', response.data)
				resolve(response);
			}, error => {
				reject(error);
			})
		})
	},
	resetList() {
			return new Promise((resolve, reject) => {
				events.listReset().then((response) => {
					resolve(response);
				}, error => {
					reject(error);
				})
			})
		}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
