<template>
  <div class="flex h-screen overflow-hidden bg-white">

    <div class="flex flex-shrink-0">
      <div class="flex flex-col w-64 border-r border-our-gray-120">
        <div class="flex flex-col flex-1 h-0">

          <div class="flex flex-col flex-1 overflow-y-auto">
            <nav class="flex flex-col py-5 px-3 gap-y-2 bg-our-gray-180">

              <router-link :to="{ name: 'Overview' }"
                           v-if="['Admin', 'Standard', 'Authenticated'].indexOf($store.state.Users.loggedUser.role?.name)!==-1"
                           :class="{ 'router-link-exact-active' : $route.name === 'Overview' }"
                           class="menu-item">
                <div>

                  <svg v-if="$route.name === 'Overview'" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                       fill="currentColor" viewBox="0 0 24 24">
                    <path
                        d="M18.25 2.997A2.75 2.75 0 0121 5.747v12.5a2.75 2.75 0 01-2.75 2.75H5.75A2.75 2.75 0 013 18.247v-12.5a2.75 2.75 0 012.75-2.75h12.5zM7.75 9a.748.748 0 00-.75.747v6.506c0 .413.336.747.75.747s.75-.334.75-.747V9.747A.748.748 0 007.75 9zm8.5-2a.74.74 0 00-.75.73v8.54c0 .403.336.73.75.73a.74.74 0 00.75-.73V7.73a.74.74 0 00-.75-.73zm-4.275 4.997a.73.73 0 00-.725.733l.035 3.547a.73.73 0 00.74.72.73.73 0 00.725-.734l-.035-3.547a.73.73 0 00-.74-.72z"/>
                  </svg>

                  <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                       viewBox="0 0 24 24">
                    <path
                        d="M18.25 2.997A2.75 2.75 0 0121 5.747v12.5a2.75 2.75 0 01-2.75 2.75H5.75A2.75 2.75 0 013 18.247v-12.5a2.75 2.75 0 012.75-2.75h12.5zm0 1.5H5.75c-.69 0-1.25.56-1.25 1.25v12.5c0 .69.56 1.25 1.25 1.25h12.5c.69 0 1.25-.56 1.25-1.25v-12.5c0-.69-.56-1.25-1.25-1.25zM7.75 9c.38 0 .693.28.743.645l.007.102v6.506a.748.748 0 01-.75.747.75.75 0 01-.743-.646l-.007-.1V9.746C7 9.334 7.336 9 7.75 9zm8.5-2c.38 0 .694.274.743.63l.007.1v8.54a.74.74 0 01-.75.73.744.744 0 01-.743-.63l-.007-.1V7.73a.74.74 0 01.75-.73zm-4.275 4.997a.73.73 0 01.732.62l.008.099.035 3.547a.73.73 0 01-.725.734.731.731 0 01-.732-.621l-.008-.099-.035-3.547a.73.73 0 01.725-.733z"/>
                  </svg>

                </div>

                <span>{{ t('global.menu.overview') }}</span>

              </router-link>

              <router-link v-if="false" :to="{ name: 'Devices' }"
                           :class="{ 'router-link-exact-active' : $route.path.includes('device')}" class="menu-item">
                <!--v-on:mouseenter="preloadData({type: 'enter', section: 'displays'})" v-on:mouseleave="preloadData({type: 'leave'})"-->

                <div>

                  <svg v-if="$route.name === 'Devices'" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"
                       xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M4.25 10C3.00736 10 2 11.0074 2 12.25V16.75C2 17.9926 3.00736 19 4.25 19H5.5V20.5H4.75C4.33579 20.5 4 20.8358 4 21.25C4 21.6642 4.33579 22 4.75 22H11.25C11.6642 22 12 21.6642 12 21.25C12 20.8358 11.6642 20.5 11.25 20.5H10.5V19H11.75C12.9926 19 14 17.9926 14 16.75V12.25C14 11.0074 12.9926 10 11.75 10H4.25ZM9 19V20.5H7V19H9Z"/>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M6 4.25V8.5H7H12.25C14.0449 8.5 15.5 9.95507 15.5 11.75V13V14V16V17.5H18.25C18.6642 17.5 19 17.1642 19 16.75C19 16.3358 18.6642 16 18.25 16H17V14H19.75C20.9926 14 22 12.9926 22 11.75V4.25C22 3.00736 20.9926 2 19.75 2H8.25C7.00736 2 6 3.00736 6 4.25Z"/>
                  </svg>

                  <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="currentColor"
                       xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M4.25 11.5H11.75C12.1642 11.5 12.5 11.8358 12.5 12.25V16.75C12.5 17.1642 12.1642 17.5 11.75 17.5H4.25C3.83578 17.5 3.5 17.1642 3.5 16.75V12.25C3.5 11.8358 3.83578 11.5 4.25 11.5ZM2 12.25C2 11.0074 3.00735 10 4.25 10H11.75C12.9926 10 14 11.0074 14 12.25V16.75C14 17.9926 12.9926 19 11.75 19H10.5V20.5H11.25C11.6642 20.5 12 20.8358 12 21.25C12 21.6642 11.6642 22 11.25 22H4.75C4.33578 22 4 21.6642 4 21.25C4 20.8358 4.33578 20.5 4.75 20.5H5.5V19H4.25C3.00735 19 2 17.9926 2 16.75V12.25ZM9 20.5V19H7V20.5H9Z"/>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M19.75 3.5H8.25C7.83579 3.5 7.5 3.83579 7.5 4.25V8.5H6V4.25C6 3.00736 7.00736 2 8.25 2H19.75C20.9926 2 22 3.00736 22 4.25V11.75C22 12.9926 20.9926 14 19.75 14H17V16H18.25C18.6642 16 19 16.3358 19 16.75C19 17.1642 18.6642 17.5 18.25 17.5H15.5V16V14V12.5H19.75C20.1642 12.5 20.5 12.1642 20.5 11.75V4.25C20.5 3.83579 20.1642 3.5 19.75 3.5Z"/>
                  </svg>

                </div>

                <span>{{ t('global.menu.devices') }}</span>

              </router-link>

              <router-link :to="{ name: 'Displays' }"
                           v-if="['Admin', 'Standard', 'Authenticated'].indexOf($store.state.Users.loggedUser.role?.name)!==-1"
                           :class="{ 'router-link-exact-active' : $route.path.includes('display')}"
                           class="menu-item">
                <!--v-on:mouseenter="preloadData({type: 'enter', section: 'displays'})" v-on:mouseleave="preloadData({type: 'leave'})"-->

                <div>

                  <svg v-if="$route.name === 'Displays'" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"
                       xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M4.25 10C3.00736 10 2 11.0074 2 12.25V16.75C2 17.9926 3.00736 19 4.25 19H5.5V20.5H4.75C4.33579 20.5 4 20.8358 4 21.25C4 21.6642 4.33579 22 4.75 22H11.25C11.6642 22 12 21.6642 12 21.25C12 20.8358 11.6642 20.5 11.25 20.5H10.5V19H11.75C12.9926 19 14 17.9926 14 16.75V12.25C14 11.0074 12.9926 10 11.75 10H4.25ZM9 19V20.5H7V19H9Z"/>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M6 4.25V8.5H7H12.25C14.0449 8.5 15.5 9.95507 15.5 11.75V13V14V16V17.5H18.25C18.6642 17.5 19 17.1642 19 16.75C19 16.3358 18.6642 16 18.25 16H17V14H19.75C20.9926 14 22 12.9926 22 11.75V4.25C22 3.00736 20.9926 2 19.75 2H8.25C7.00736 2 6 3.00736 6 4.25Z"/>
                  </svg>

                  <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="currentColor"
                       xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M4.25 11.5H11.75C12.1642 11.5 12.5 11.8358 12.5 12.25V16.75C12.5 17.1642 12.1642 17.5 11.75 17.5H4.25C3.83578 17.5 3.5 17.1642 3.5 16.75V12.25C3.5 11.8358 3.83578 11.5 4.25 11.5ZM2 12.25C2 11.0074 3.00735 10 4.25 10H11.75C12.9926 10 14 11.0074 14 12.25V16.75C14 17.9926 12.9926 19 11.75 19H10.5V20.5H11.25C11.6642 20.5 12 20.8358 12 21.25C12 21.6642 11.6642 22 11.25 22H4.75C4.33578 22 4 21.6642 4 21.25C4 20.8358 4.33578 20.5 4.75 20.5H5.5V19H4.25C3.00735 19 2 17.9926 2 16.75V12.25ZM9 20.5V19H7V20.5H9Z"/>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M19.75 3.5H8.25C7.83579 3.5 7.5 3.83579 7.5 4.25V8.5H6V4.25C6 3.00736 7.00736 2 8.25 2H19.75C20.9926 2 22 3.00736 22 4.25V11.75C22 12.9926 20.9926 14 19.75 14H17V16H18.25C18.6642 16 19 16.3358 19 16.75C19 17.1642 18.6642 17.5 18.25 17.5H15.5V16V14V12.5H19.75C20.1642 12.5 20.5 12.1642 20.5 11.75V4.25C20.5 3.83579 20.1642 3.5 19.75 3.5Z"/>
                  </svg>

                </div>

                <span>{{ t('global.menu.displays') }}</span>

              </router-link>

              <router-link :to="{ name: 'Groups' }"
                           v-if="['Admin', 'Standard', 'Authenticated'].indexOf($store.state.Users.loggedUser.role?.name)!==-1"
                           :class="{ 'router-link-exact-active' : $route.path.includes('group')}"
                           class="menu-item">
                <!--v-on:mouseenter="preloadData({type: 'enter', section: 'groups'})" v-on:mouseleave="preloadData({type: 'leave'})"-->

                <div>

                  <svg v-if="$route.name === 'Groups'" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M18.25 3C19.7688 3 21 4.23122 21 5.75V18.25C21 19.7688 19.7688 21 18.25 21H5.75C4.23122 21 3 19.7688 3 18.25V5.75C3 4.23122 4.23122 3 5.75 3H18.25ZM18.25 4.5H5.75C5.05964 4.5 4.5 5.05964 4.5 5.75V18.25C4.5 18.9404 5.05964 19.5 5.75 19.5H18.25C18.9404 19.5 19.5 18.9404 19.5 18.25V5.75C19.5 5.05964 18.9404 4.5 18.25 4.5ZM9.7519 12.5C10.7184 12.5 11.5019 13.2835 11.5019 14.25V16.25C11.5019 17.2165 10.7184 18 9.7519 18H7.7519C6.7854 18 6.0019 17.2165 6.0019 16.25V14.25C6.0019 13.2835 6.7854 12.5 7.7519 12.5H9.7519ZM16.2494 12.5C17.2159 12.5 17.9994 13.2835 17.9994 14.25V16.25C17.9994 17.2165 17.2159 18 16.2494 18H14.2494C13.2829 18 12.4994 17.2165 12.4994 16.25V14.25C12.4994 13.2835 13.2829 12.5 14.2494 12.5H16.2494ZM9.75063 6C10.7171 6 11.5006 6.7835 11.5006 7.75V9.75C11.5006 10.7165 10.7171 11.5 9.75063 11.5H7.75063C6.78413 11.5 6.00063 10.7165 6.00063 9.75V7.75C6.00063 6.7835 6.78413 6 7.75063 6H9.75063ZM16.2481 6C17.2146 6 17.9981 6.7835 17.9981 7.75V9.75C17.9981 10.7165 17.2146 11.5 16.2481 11.5H14.2481C13.2816 11.5 12.4981 10.7165 12.4981 9.75V7.75C12.4981 6.7835 13.2816 6 14.2481 6H16.2481Z"/>
                  </svg>

                  <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="currentColor"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M18.25 3C19.7688 3 21 4.23122 21 5.75V18.25C21 19.7688 19.7688 21 18.25 21H5.75C4.23122 21 3 19.7688 3 18.25V5.75C3 4.23122 4.23122 3 5.75 3H18.25ZM18.25 4.5H5.75C5.05964 4.5 4.5 5.05964 4.5 5.75V18.25C4.5 18.9404 5.05964 19.5 5.75 19.5H18.25C18.9404 19.5 19.5 18.9404 19.5 18.25V5.75C19.5 5.05964 18.9404 4.5 18.25 4.5ZM9.7519 12.5C10.7184 12.5 11.5019 13.2835 11.5019 14.25V16.25C11.5019 17.2165 10.7184 18 9.7519 18H7.7519C6.7854 18 6.0019 17.2165 6.0019 16.25V14.25C6.0019 13.2835 6.7854 12.5 7.7519 12.5H9.7519ZM16.2494 12.5C17.2159 12.5 17.9994 13.2835 17.9994 14.25V16.25C17.9994 17.2165 17.2159 18 16.2494 18H14.2494C13.2829 18 12.4994 17.2165 12.4994 16.25V14.25C12.4994 13.2835 13.2829 12.5 14.2494 12.5H16.2494ZM9.7519 14H7.7519C7.61383 14 7.5019 14.1119 7.5019 14.25V16.25C7.5019 16.3881 7.61383 16.5 7.7519 16.5H9.7519C9.88997 16.5 10.0019 16.3881 10.0019 16.25V14.25C10.0019 14.1119 9.88997 14 9.7519 14ZM16.2494 14H14.2494C14.1113 14 13.9994 14.1119 13.9994 14.25V16.25C13.9994 16.3881 14.1113 16.5 14.2494 16.5H16.2494C16.3874 16.5 16.4994 16.3881 16.4994 16.25V14.25C16.4994 14.1119 16.3874 14 16.2494 14ZM9.75063 6C10.7171 6 11.5006 6.7835 11.5006 7.75V9.75C11.5006 10.7165 10.7171 11.5 9.75063 11.5H7.75063C6.78413 11.5 6.00063 10.7165 6.00063 9.75V7.75C6.00063 6.7835 6.78413 6 7.75063 6H9.75063ZM16.2481 6C17.2146 6 17.9981 6.7835 17.9981 7.75V9.75C17.9981 10.7165 17.2146 11.5 16.2481 11.5H14.2481C13.2816 11.5 12.4981 10.7165 12.4981 9.75V7.75C12.4981 6.7835 13.2816 6 14.2481 6H16.2481ZM9.75063 7.5H7.75063C7.61256 7.5 7.50063 7.61193 7.50063 7.75V9.75C7.50063 9.88807 7.61256 10 7.75063 10H9.75063C9.8887 10 10.0006 9.88807 10.0006 9.75V7.75C10.0006 7.61193 9.8887 7.5 9.75063 7.5ZM16.2481 7.5H14.2481C14.11 7.5 13.9981 7.61193 13.9981 7.75V9.75C13.9981 9.88807 14.11 10 14.2481 10H16.2481C16.3862 10 16.4981 9.88807 16.4981 9.75V7.75C16.4981 7.61193 16.3862 7.5 16.2481 7.5Z"/>
                  </svg>

                </div>

                <span>{{ t('global.menu.groups') }}</span>

              </router-link>

              <router-link :to="{ name: 'Locations' }"
                           v-if="['Admin', 'Standard', 'Authenticated'].indexOf($store.state.Users.loggedUser.role?.name)!==-1"
                           :class="{ 'router-link-exact-active' : $route.path.includes('location')}"
                           class="menu-item">
                <div>

                  <svg v-if="$route.name === 'Locations'" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M18.25 3C19.7688 3 21 4.23122 21 5.75V18.25C21 19.7688 19.7688 21 18.25 21H5.75C4.23122 21 3 19.7688 3 18.25V5.75C3 4.23122 4.23122 3 5.75 3H18.25ZM18.25 4.5H5.75C5.05964 4.5 4.5 5.05964 4.5 5.75V18.25C4.5 18.9404 5.05964 19.5 5.75 19.5H18.25C18.9404 19.5 19.5 18.9404 19.5 18.25V5.75C19.5 5.05964 18.9404 4.5 18.25 4.5ZM9.7519 12.5C10.7184 12.5 11.5019 13.2835 11.5019 14.25V16.25C11.5019 17.2165 10.7184 18 9.7519 18H7.7519C6.7854 18 6.0019 17.2165 6.0019 16.25V14.25C6.0019 13.2835 6.7854 12.5 7.7519 12.5H9.7519ZM16.2494 12.5C17.2159 12.5 17.9994 13.2835 17.9994 14.25V16.25C17.9994 17.2165 17.2159 18 16.2494 18H14.2494C13.2829 18 12.4994 17.2165 12.4994 16.25V14.25C12.4994 13.2835 13.2829 12.5 14.2494 12.5H16.2494ZM9.75063 6C10.7171 6 11.5006 6.7835 11.5006 7.75V9.75C11.5006 10.7165 10.7171 11.5 9.75063 11.5H7.75063C6.78413 11.5 6.00063 10.7165 6.00063 9.75V7.75C6.00063 6.7835 6.78413 6 7.75063 6H9.75063ZM16.2481 6C17.2146 6 17.9981 6.7835 17.9981 7.75V9.75C17.9981 10.7165 17.2146 11.5 16.2481 11.5H14.2481C13.2816 11.5 12.4981 10.7165 12.4981 9.75V7.75C12.4981 6.7835 13.2816 6 14.2481 6H16.2481Z"/>
                  </svg>

                  <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="currentColor"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M18.25 3C19.7688 3 21 4.23122 21 5.75V18.25C21 19.7688 19.7688 21 18.25 21H5.75C4.23122 21 3 19.7688 3 18.25V5.75C3 4.23122 4.23122 3 5.75 3H18.25ZM18.25 4.5H5.75C5.05964 4.5 4.5 5.05964 4.5 5.75V18.25C4.5 18.9404 5.05964 19.5 5.75 19.5H18.25C18.9404 19.5 19.5 18.9404 19.5 18.25V5.75C19.5 5.05964 18.9404 4.5 18.25 4.5ZM9.7519 12.5C10.7184 12.5 11.5019 13.2835 11.5019 14.25V16.25C11.5019 17.2165 10.7184 18 9.7519 18H7.7519C6.7854 18 6.0019 17.2165 6.0019 16.25V14.25C6.0019 13.2835 6.7854 12.5 7.7519 12.5H9.7519ZM16.2494 12.5C17.2159 12.5 17.9994 13.2835 17.9994 14.25V16.25C17.9994 17.2165 17.2159 18 16.2494 18H14.2494C13.2829 18 12.4994 17.2165 12.4994 16.25V14.25C12.4994 13.2835 13.2829 12.5 14.2494 12.5H16.2494ZM9.7519 14H7.7519C7.61383 14 7.5019 14.1119 7.5019 14.25V16.25C7.5019 16.3881 7.61383 16.5 7.7519 16.5H9.7519C9.88997 16.5 10.0019 16.3881 10.0019 16.25V14.25C10.0019 14.1119 9.88997 14 9.7519 14ZM16.2494 14H14.2494C14.1113 14 13.9994 14.1119 13.9994 14.25V16.25C13.9994 16.3881 14.1113 16.5 14.2494 16.5H16.2494C16.3874 16.5 16.4994 16.3881 16.4994 16.25V14.25C16.4994 14.1119 16.3874 14 16.2494 14ZM9.75063 6C10.7171 6 11.5006 6.7835 11.5006 7.75V9.75C11.5006 10.7165 10.7171 11.5 9.75063 11.5H7.75063C6.78413 11.5 6.00063 10.7165 6.00063 9.75V7.75C6.00063 6.7835 6.78413 6 7.75063 6H9.75063ZM16.2481 6C17.2146 6 17.9981 6.7835 17.9981 7.75V9.75C17.9981 10.7165 17.2146 11.5 16.2481 11.5H14.2481C13.2816 11.5 12.4981 10.7165 12.4981 9.75V7.75C12.4981 6.7835 13.2816 6 14.2481 6H16.2481ZM9.75063 7.5H7.75063C7.61256 7.5 7.50063 7.61193 7.50063 7.75V9.75C7.50063 9.88807 7.61256 10 7.75063 10H9.75063C9.8887 10 10.0006 9.88807 10.0006 9.75V7.75C10.0006 7.61193 9.8887 7.5 9.75063 7.5ZM16.2481 7.5H14.2481C14.11 7.5 13.9981 7.61193 13.9981 7.75V9.75C13.9981 9.88807 14.11 10 14.2481 10H16.2481C16.3862 10 16.4981 9.88807 16.4981 9.75V7.75C16.4981 7.61193 16.3862 7.5 16.2481 7.5Z"/>
                  </svg>

                </div>

                <span>{{ t('global.menu.locations') }}</span>

              </router-link>
              
              <router-link :to="{ name: 'Playlists' }"
                           v-if="['Admin', 'Standard', 'Authenticated'].indexOf($store.state.Users.loggedUser.role?.name)!==-1"
                           :class="{ 'router-link-exact-active' : $route.path.includes('playlist')}"
                           class="menu-item">
                <!--v-on:mouseenter="preloadData({type: 'enter', section: 'playlists'})" v-on:mouseleave="preloadData({type: 'leave'})"-->

                <div>

                  <svg v-if="$route.name === 'Playlists'" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15.2505 12.9999C16.217 12.9999 17.0005 13.7834 17.0005 14.7499V19.2531C17.0005 20.2196 16.217 21.0031 15.2505 21.0031H3.75048C2.78398 21.0031 2.00048 20.2196 2.00048 19.2531V14.7499C2.00048 13.7834 2.78398 12.9999 3.75048 12.9999H15.2505ZM21.001 14.8954L21.0005 20.2499C21.0005 20.6641 20.6647 20.9999 20.2505 20.9999C19.8708 20.9999 19.557 20.7177 19.5073 20.3516L19.5005 20.2499L19.5003 14.9061C19.7338 14.9645 19.9782 14.9954 20.2298 14.9954C20.4964 14.9954 20.7549 14.9606 21.001 14.8954ZM20.2507 10.0918C21.3045 10.0918 22.1588 10.9461 22.1588 11.9999C22.1588 13.0537 21.3045 13.9079 20.2507 13.9079C19.1969 13.9079 18.3427 13.0537 18.3427 11.9999C18.3427 10.9461 19.1969 10.0918 20.2507 10.0918ZM15.2461 2.99658C16.2126 2.99658 16.9961 3.78008 16.9961 4.74658V9.24985C16.9961 10.2164 16.2126 10.9999 15.2461 10.9999H3.74609C2.7796 10.9999 1.99609 10.2164 1.99609 9.24985V4.74658C1.99609 3.82841 2.7032 3.07539 3.60257 3.00238L3.74609 2.99658H15.2461ZM20.2511 2.99985C20.6308 2.99985 20.9446 3.28201 20.9943 3.64808L21.0011 3.74985L21.001 9.0955C20.7549 9.03022 20.4964 8.99543 20.2298 8.99543C19.9782 8.99543 19.7338 9.0264 19.5003 9.08473L19.5011 3.74985C19.5011 3.33564 19.8369 2.99985 20.2511 2.99985Z"/>
                  </svg>

                  <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="currentColor"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15.2505 13C16.217 13 17.0005 13.7835 17.0005 14.75V19.25C17.0005 20.2165 16.217 21 15.2505 21H3.75048C2.78398 21 2.00048 20.2165 2.00048 19.25V14.75C2.00048 13.7835 2.78398 13 3.75048 13H15.2505ZM21.0008 14.8989L21.0005 20.25C21.0005 20.6642 20.6647 21 20.2505 21C19.8708 21 19.557 20.7178 19.5073 20.3518L19.5005 20.25L19.5001 14.9117C19.7324 14.9694 19.9754 15 20.2255 15C20.4936 15 20.7535 14.9648 21.0008 14.8989ZM15.2505 14.5H3.75048C3.61241 14.5 3.50048 14.6119 3.50048 14.75V19.25C3.50048 19.3881 3.61241 19.5 3.75048 19.5H15.2505C15.3886 19.5 15.5005 19.3881 15.5005 19.25V14.75C15.5005 14.6119 15.3886 14.5 15.2505 14.5ZM20.2503 10.0919C21.3041 10.0919 22.1583 10.9462 22.1583 12C22.1583 13.0538 21.3041 13.9081 20.2503 13.9081C19.1965 13.9081 18.3422 13.0538 18.3422 12C18.3422 10.9462 19.1965 10.0919 20.2503 10.0919ZM15.2461 3C16.2126 3 16.9961 3.7835 16.9961 4.75V9.25C16.9961 10.2165 16.2126 11 15.2461 11H3.74609C2.7796 11 1.99609 10.2165 1.99609 9.25V4.75C1.99609 3.83183 2.7032 3.07881 3.60257 3.0058L3.74609 3H15.2461ZM15.2461 4.5H3.74609L3.68877 4.5066C3.57833 4.53251 3.49609 4.63165 3.49609 4.75V9.25C3.49609 9.38807 3.60802 9.5 3.74609 9.5H15.2461C15.3842 9.5 15.4961 9.38807 15.4961 9.25V4.75C15.4961 4.61193 15.3842 4.5 15.2461 4.5ZM20.2505 3C20.6302 3 20.944 3.28215 20.9937 3.64823L21.0005 3.75L21.0008 9.10113C20.7535 9.03516 20.4936 9 20.2255 9C19.9754 9 19.7324 9.03061 19.5001 9.08829L19.5005 3.75C19.5005 3.33579 19.8363 3 20.2505 3Z"/>
                  </svg>

                </div>


                <span>{{ t('global.menu.playlists') }}</span>

              </router-link>

              <router-link :to="{ name: 'Playables' }"
                           :class="{ 'router-link-exact-active' : $route.path.includes('playable')}" class="menu-item">
                <!--v-on:mouseenter="preloadData({type: 'enter', section: 'playables'})" v-on:mouseleave="preloadData({type: 'leave'})"-->

                <div>

                  <svg v-if="$route.name === 'Playables'" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                       fill="currentColor" viewBox="0 0 24 24">
                    <path fill-rule="evenodd"
                          d="M11.263 8.126a.529.529 0 00-.056.236v4.633a.529.529 0 00.765.473l4.633-2.317a.529.529 0 000-.945L11.972 7.89a.528.528 0 00-.709.236zM5.393 6a3.25 3.25 0 013.25-3.25H18A3.25 3.25 0 0121.25 6v9.357a3.25 3.25 0 01-3.25 3.25H8.643a3.25 3.25 0 01-3.25-3.25V6zM2.75 8.829c0-1.22.636-2.291 1.593-2.9-.005.085-.007.17-.007.257V15.7A3.964 3.964 0 008.3 19.664h9.514l.129-.002.13-.006a3.433 3.433 0 01-2.902 1.594H8.3a5.55 5.55 0 01-5.55-5.55V8.829z"
                          clip-rule="evenodd"/>
                  </svg>

                  <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                       viewBox="0 0 24 24">
                    <path fill-rule="evenodd"
                          d="M18 4.25H8.643A1.75 1.75 0 006.893 6v9.357c0 .967.783 1.75 1.75 1.75H18a1.75 1.75 0 001.75-1.75V6A1.75 1.75 0 0018 4.25zm-9.357-1.5A3.25 3.25 0 005.393 6v9.357a3.25 3.25 0 003.25 3.25H18a3.25 3.25 0 003.25-3.25V6A3.25 3.25 0 0018 2.75H8.643zm2.62 5.376a.529.529 0 01.62-.271l.09.035 4.632 2.316a.53.53 0 01.08.895l-.08.05-4.633 2.316a.528.528 0 01-.756-.377l-.009-.095V8.362c0-.082.02-.163.056-.236zm-8.513.703c0-1.22.636-2.291 1.593-2.9-.005.085-.007.17-.007.257V15.7A3.964 3.964 0 008.3 19.664h9.514l.129-.002.13-.006a3.433 3.433 0 01-2.902 1.594H8.3a5.55 5.55 0 01-5.55-5.55V8.829z"
                          clip-rule="evenodd"/>
                  </svg>

                </div>

                <span>{{ t('global.menu.playables') }}</span>

              </router-link>

              <router-link :to="{ name: 'Settings' }"
                           v-if="['Admin', 'Standard', 'Authenticated'].indexOf($store.state.Users.loggedUser.role?.name)!==-1"
                           :class="{ 'router-link-exact-active' : $route.path.includes('settings')}"
                           class="menu-item">

                <div>

                  <svg v-if="$route.name === 'Settings'" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                       fill="currentColor" viewBox="0 0 24 24">
                    <path
                        d="M12.013 2.25c.734.008 1.465.093 2.182.253a.75.75 0 01.582.649l.17 1.527a1.384 1.384 0 001.927 1.116l1.4-.615a.75.75 0 01.85.174 9.793 9.793 0 012.205 3.792.75.75 0 01-.272.825l-1.241.916a1.38 1.38 0 000 2.226l1.243.915a.75.75 0 01.272.826 9.798 9.798 0 01-2.204 3.792.75.75 0 01-.848.175l-1.407-.617a1.38 1.38 0 00-1.926 1.114l-.17 1.526a.75.75 0 01-.571.647 9.518 9.518 0 01-4.406 0 .75.75 0 01-.572-.647l-.169-1.524a1.382 1.382 0 00-1.925-1.11l-1.406.616a.75.75 0 01-.85-.175 9.798 9.798 0 01-2.203-3.796.75.75 0 01.272-.826l1.243-.916a1.38 1.38 0 000-2.226l-1.243-.914a.75.75 0 01-.272-.826A9.793 9.793 0 014.88 5.355a.75.75 0 01.85-.174l1.4.615a1.387 1.387 0 001.93-1.118l.17-1.526a.75.75 0 01.583-.65c.717-.159 1.449-.243 2.201-.252zM12.001 9a3 3 0 100 6 3 3 0 000-6z"/>
                  </svg>

                  <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                       viewBox="0 0 24 24">
                    <path
                        d="M12.013 2.25c.734.008 1.465.093 2.182.253a.75.75 0 01.582.649l.17 1.527a1.384 1.384 0 001.927 1.116l1.4-.615a.75.75 0 01.85.174 9.793 9.793 0 012.205 3.792.75.75 0 01-.272.825l-1.241.916a1.38 1.38 0 000 2.226l1.243.915a.75.75 0 01.272.826 9.798 9.798 0 01-2.204 3.792.75.75 0 01-.848.175l-1.407-.617a1.38 1.38 0 00-1.926 1.114l-.17 1.526a.75.75 0 01-.571.647 9.518 9.518 0 01-4.406 0 .75.75 0 01-.572-.647l-.169-1.524a1.382 1.382 0 00-1.925-1.11l-1.406.616a.75.75 0 01-.85-.175 9.798 9.798 0 01-2.203-3.796.75.75 0 01.272-.826l1.243-.916a1.38 1.38 0 000-2.226l-1.243-.914a.75.75 0 01-.272-.826A9.793 9.793 0 014.88 5.355a.75.75 0 01.85-.174l1.4.615a1.387 1.387 0 001.93-1.118l.17-1.526a.75.75 0 01.583-.65c.717-.159 1.449-.243 2.201-.252zm0 1.5a9.135 9.135 0 00-1.354.117l-.11.977A2.886 2.886 0 016.527 7.17l-.899-.394A8.293 8.293 0 004.28 9.092l.798.587a2.881 2.881 0 01.001 4.643l-.799.588c.32.842.776 1.626 1.348 2.322l.905-.397a2.882 2.882 0 014.017 2.318l.109.984c.89.15 1.798.15 2.688 0l.109-.984a2.88 2.88 0 014.019-2.322l.904.396a8.299 8.299 0 001.348-2.318l-.798-.588a2.88 2.88 0 01-.001-4.643l.797-.587a8.293 8.293 0 00-1.348-2.317l-.897.393a2.884 2.884 0 01-4.023-2.324l-.109-.976a8.991 8.991 0 00-1.334-.117zm-.012 4.5a3.75 3.75 0 110 7.5 3.75 3.75 0 010-7.5zm0 1.5a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z"/>
                  </svg>

                </div>

                <span>{{ t('global.menu.settings') }}</span>

              </router-link>

              <hr v-if="$store.state.Users.loggedUser.role?.name === 'Admin'"/>

              <router-link :to="{ name: 'Billings' }"
                           v-if="['Admin', 'Authenticated'].indexOf($store.state.Users.loggedUser.role?.name)!==-1"
                           :class="{ 'router-link-exact-active' : $route.path.includes('billings')}"
                           class="menu-item">

                <div>

                  <svg v-if="$route.name === 'Billings'" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                       fill="currentColor" viewBox="0 0 24 24">
                    <path
                        d="M5 6.25A2.25 2.25 0 0 1 7.25 4h8.5A2.25 2.25 0 0 1 18 6.25V14h3.5v3.25a3.25 3.25 0 0 1-3.25 3.25H13v-4h1.25a.75.75 0 1 0 0-1.5H12.5a2.497 2.497 0 0 0-2-1H5V6.25ZM18 19h.25A1.75 1.75 0 0 0 20 17.25V15.5h-2V19ZM8.75 8a.75.75 0 0 0 0 1.5h5.5a.75.75 0 1 0 0-1.5h-5.5Zm0 3.5a.75.75 0 1 0 0 1.5h5.5a.75.75 0 1 0 0-1.5h-5.5ZM1 16.5A1.5 1.5 0 0 1 2.5 15h8a1.5 1.5 0 0 1 1.5 1.5v4a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 1 20.5v-4Zm10 .5a1 1 0 0 1-1-1H9a2 2 0 0 0 2 2v-1Zm0 2a2 2 0 0 0-2 2h1a1 1 0 0 1 1-1v-1Zm-8-3a1 1 0 0 1-1 1v1a2 2 0 0 0 2-2H3Zm1 5a2 2 0 0 0-2-2v1a1 1 0 0 1 1 1h1Zm4.25-2.5a1.75 1.75 0 1 0-3.5 0 1.75 1.75 0 0 0 3.5 0Z"/>
                  </svg>

                  <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                       viewBox="0 0 24 24">
                    <path
                        d="M5 6.25A2.25 2.25 0 0 1 7.25 4h8.5A2.25 2.25 0 0 1 18 6.25V14h3.5v3.25a3.25 3.25 0 0 1-3.25 3.25H13V19h3.5V6.25a.75.75 0 0 0-.75-.75h-8.5a.75.75 0 0 0-.75.75V14H5V6.25ZM18 19h.25A1.75 1.75 0 0 0 20 17.25V15.5h-2V19Zm-3.75-2.5H13a2.49 2.49 0 0 0-.5-1.5h1.75a.75.75 0 1 1 0 1.5ZM8 8.75A.75.75 0 0 1 8.75 8h5.5a.75.75 0 1 1 0 1.5h-5.5A.75.75 0 0 1 8 8.75Zm0 3.5a.75.75 0 0 1 .75-.75h5.5a.75.75 0 1 1 0 1.5h-5.5a.75.75 0 0 1-.75-.75ZM1 16.5A1.5 1.5 0 0 1 2.5 15h8a1.5 1.5 0 0 1 1.5 1.5v4a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 1 20.5v-4Zm10 .5a1 1 0 0 1-1-1H9a2 2 0 0 0 2 2v-1Zm0 2a2 2 0 0 0-2 2h1a1 1 0 0 1 1-1v-1Zm-8-3a1 1 0 0 1-1 1v1a2 2 0 0 0 2-2H3Zm1 5a2 2 0 0 0-2-2v1a1 1 0 0 1 1 1h1Zm4.25-2.5a1.75 1.75 0 1 0-3.5 0 1.75 1.75 0 0 0 3.5 0Z"/>
                  </svg>

                </div>

                <span>{{ t('global.menu.billings') }}</span>

              </router-link>

              <router-link :to="{ name: 'Users' }"
                           v-if="['Admin', 'Authenticated'].indexOf($store.state.Users.loggedUser.role?.name)!==-1"
                           :class="{ 'router-link-exact-active' : $route.path.includes('users')}"
                           class="menu-item">

                <div>

                  <svg v-if="$route.name === 'Users'" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                       fill="currentColor" viewBox="0 0 24 24">
                    <path
                        d="M14.75 15c.966 0 1.75.784 1.75 1.75l-.001.962c.117 2.19-1.511 3.297-4.432 3.297-2.91 0-4.567-1.09-4.567-3.259v-1c0-.966.784-1.75 1.75-1.75h5.5Zm-11-5h4.376a4.007 4.007 0 0 0 1.067 3.85l.162.151L9.25 14a2.75 2.75 0 0 0-2.649 2.008l-.034.001C3.657 16.009 2 14.919 2 12.75v-1c0-.966.784-1.75 1.75-1.75Zm16.5 0c.966 0 1.75.784 1.75 1.75l-.001.962c.117 2.19-1.511 3.297-4.432 3.297l-.169-.002a2.756 2.756 0 0 0-2.451-2L14.75 14l-.105.001a3.99 3.99 0 0 0 1.229-4L20.25 10ZM12 8a3 3 0 1 1 0 6 3 3 0 0 1 0-6ZM6.5 3a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm11 0a3 3 0 1 1 0 6 3 3 0 0 1 0-6Z"/>
                  </svg>

                  <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                       viewBox="0 0 24 24">
                    <path
                        d="M14.75 15c.966 0 1.75.784 1.75 1.75l-.001.962c.117 2.19-1.511 3.297-4.432 3.297-2.91 0-4.567-1.09-4.567-3.259v-1c0-.966.784-1.75 1.75-1.75h5.5Zm0 1.5h-5.5a.25.25 0 0 0-.25.25v1c0 1.176.887 1.759 3.067 1.759 2.168 0 2.995-.564 2.933-1.757V16.75a.25.25 0 0 0-.25-.25Zm-11-6.5h4.376a4.007 4.007 0 0 0-.095 1.5H3.75a.25.25 0 0 0-.25.25v1c0 1.176.887 1.759 3.067 1.759.462 0 .863-.026 1.207-.077a2.743 2.743 0 0 0-1.173 1.576l-.034.001C3.657 16.009 2 14.919 2 12.75v-1c0-.966.784-1.75 1.75-1.75Zm16.5 0c.966 0 1.75.784 1.75 1.75l-.001.962c.117 2.19-1.511 3.297-4.432 3.297l-.169-.002a2.755 2.755 0 0 0-1.218-1.606c.387.072.847.108 1.387.108 2.168 0 2.995-.564 2.933-1.757V11.75a.25.25 0 0 0-.25-.25h-4.28a4.05 4.05 0 0 0-.096-1.5h4.376ZM12 8a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM6.5 3a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm11 0a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm-11 1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm11 0a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z"/>
                  </svg>

                </div>

                <span>{{ t('global.menu.users') }}</span>

              </router-link>

              <router-link v-if="nodeEnv === 'development'" :to="{ name: 'Styleguide' }"
                           :class="{ 'router-link-exact-active bg-our-gray-150' : $route.path.includes('styleguide')}"
                           class="menu-item">

                <span class="px-2 py-2.5">Styleguide</span>

              </router-link>

            </nav>

            <div class="flex-1 bg-our-gray-180">

            </div>

            <div
                class="flex flex-col items-center flex-shrink-0 px-3.5 py-7 border-t bg-our-gray-200 border-our-gray-160">
              <div class="flex flex-col items-center flex-shrink-0 block">
                <div class="p-2 rounded-full text-success bg-success">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M17.5004 12C20.5379 12 23.0004 14.4624 23.0004 17.5C23.0004 20.5376 20.5379 23 17.5004 23C14.4628 23 12.0004 20.5376 12.0004 17.5C12.0004 14.4624 14.4628 12 17.5004 12ZM12.0226 13.9993C11.3755 15.0098 11.0004 16.2111 11.0004 17.5C11.0004 19.1303 11.6006 20.6205 12.5921 21.7615C11.7966 21.9216 10.9317 22.0011 10.0004 22.0011C6.5794 22.0011 4.05643 20.9289 2.51427 18.7646C2.18231 18.2987 2.00391 17.7409 2.00391 17.1688V16.2488C2.00391 15.0068 3.01076 13.9999 4.25278 13.9999L12.0226 13.9993ZM14.8539 17.1465C14.6587 16.9512 14.3421 16.9512 14.1468 17.1465C13.9515 17.3417 13.9515 17.6583 14.1468 17.8536L16.1468 19.8536C16.3421 20.0488 16.6587 20.0488 16.8539 19.8536L20.8539 15.8536C21.0492 15.6583 21.0492 15.3417 20.8539 15.1465C20.6587 14.9512 20.3421 14.9512 20.1468 15.1465L16.5004 18.7929L14.8539 17.1465ZM10.0004 2.00464C12.7618 2.00464 15.0004 4.24321 15.0004 7.00464C15.0004 9.76606 12.7618 12.0046 10.0004 12.0046C7.23894 12.0046 5.00036 9.76606 5.00036 7.00464C5.00036 4.24321 7.23894 2.00464 10.0004 2.00464Z"/>
                  </svg>
                </div>

                <p class="mt-3 text-base font-medium leading-5 text-our-gray-30">
                  {{ $store.state.Users.loggedUser.username }}
                </p>

                <p class="mt-1 text-xs font-medium leading-4 tracking-wide text-our-gray-60">
                  {{ $store.state.Users.loggedUser.email }}
                </p>

              </div>

              <span class="inline-flex mt-4">
                  <button v-on:click="signOut()" type="button"
                          class="px-2.5 py-1.5 text-xs rounded-sm button button-gray">
                    <span>{{ t('login.logOut') }}</span>
                    <svg class="-mr-0.5 ml-2 h-5 w-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"
                         xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M12 4.35417V4.99999L13.7453 5C14.1245 5 14.4381 5.28152 14.4883 5.64712L14.4953 5.74876L14.502 10H13.002L12.9965 6.5L12 6.49999L12.0005 11.0049L13.002 11.004L13.003 11H14.504L14.503 11.004H19.442L17.7196 9.28024C17.4534 9.01393 17.4292 8.59726 17.6471 8.30369L17.7198 8.21958C17.9861 7.95336 18.4027 7.92923 18.6963 8.14713L18.7804 8.21976L21.777 11.2174C22.043 11.4835 22.0674 11.8997 21.85 12.1933L21.7775 12.2774L18.7809 15.2808C18.4884 15.574 18.0135 15.5746 17.7203 15.282C17.4537 15.0161 17.429 14.5994 17.6465 14.3056L17.7191 14.2214L19.432 12.504L12.0005 12.5049L12 17.001L13.0139 17.0015L13.007 13.5H14.508L14.5152 17.7502C14.5158 18.1303 14.2335 18.4448 13.867 18.4946L13.7652 18.5015L12 18.501V19.25C12 19.7164 11.5788 20.0697 11.1196 19.9886L2.61955 18.4873C2.26121 18.424 2 18.1126 2 17.7487V5.75C2 5.3827 2.26601 5.06944 2.62847 5.00992L11.1285 3.61408C11.5851 3.5391 12 3.89144 12 4.35417ZM8.50215 11.5C7.94868 11.5 7.5 11.9487 7.5 12.5022C7.5 13.0556 7.94868 13.5043 8.50215 13.5043C9.05562 13.5043 9.5043 13.0556 9.5043 12.5022C9.5043 11.9487 9.05562 11.5 8.50215 11.5Z"/>
                    </svg>
                  </button>
                </span>

              <p class="mt-6 text-xs font-medium leading-none tracking-wide text-our-gray-120">
                {{ $axios.defaults.baseURL }}
              </p>
            </div>

            <div
                class="flex flex-col items-center flex-shrink-0 px-4 py-4 border-t bg-our-gray-220 border-our-gray-160">
              <span class="text-our-gray-100 text-xs font-semibold leading-6" v-text="time"></span>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col flex-1 w-0 overflow-hidden">

      <!--<div class="relative z-50 flex flex-shrink-0 h-16 bg-white border-b border-our-gray-70">
        <button class="hidden px-4 text-gray-500 border-r border-gray-200 outline-none focus:outline-none focus:bg-gray-100 focus:text-gray-600" aria-label="Open sidebar">
          <svg class="w-6 h-6" stroke="currentColor" fill="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h7"/>
          </svg>
        </button>
        <div class="flex justify-between flex-1 px-4">
          <div class="flex items-center flex-1">
            <div class="w-full px-4 lg:max-w-md">
              <label for="search" class="sr-only">Search</label>
              <div class="relative text-our-gray-120 focus-within:text-our-gray-140">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg class="transition duration-150 ease-linear" width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.5 3C11.5376 3 14 5.46243 14 8.5C14 9.74832 13.5841 10.8995 12.8834 11.8226L17.0303 15.9697C17.3232 16.2626 17.3232 16.7374 17.0303 17.0303C16.7641 17.2966 16.3474 17.3208 16.0538 17.1029L15.9697 17.0303L11.8226 12.8834C10.8995 13.5841 9.74832 14 8.5 14C5.46243 14 3 11.5376 3 8.5C3 5.46243 5.46243 3 8.5 3ZM8.5 4.5C6.29086 4.5 4.5 6.29086 4.5 8.5C4.5 10.7091 6.29086 12.5 8.5 12.5C10.7091 12.5 12.5 10.7091 12.5 8.5C12.5 6.29086 10.7091 4.5 8.5 4.5Z"/>
                  </svg>
                </div>
                <input id="search" class="block w-full h-8 pl-10 leading-5 transition-all duration-150 ease-linear border outline-none rounded-xs border-our-gray-80 text-our-gray-160 placeholder-our-gray-120text-sm focus:outline-none focus:border-our-gray-130border-light-highlight-level-1 placeholder-our-gray-100" placeholder="Search for displays, groups, playlists or playables">
              </div>
            </div>
          </div>
          <div class="flex items-center justify-end w-64 ml-4 ml-6">
            <div class="flex flex-row px-4 gap-x-3">
              <button type="button" class="inline-flex items-center h-10 px-3 text-sm font-medium leading-4 tracking-wide transition-all duration-150 ease-linear bg-white border border-transparent rounded-sm outline-none text-our-gray-160 border-our-gray-130 hover:bg-our-gray-160 hover:text-white hover:border-our-gray-160 focus:outline-none focus:border-our-gray-190border-light-highlight-level-1 active:bg-our-gray-190 active:border-our-gray-220 active:text-white">
                <svg class="-ml-0.5 mr-2" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.0132 2.25C12.7471 2.25846 13.4782 2.34326 14.1947 2.50304C14.5074 2.57279 14.7413 2.83351 14.7768 3.15196L14.947 4.67881C15.024 5.37986 15.6159 5.91084 16.3216 5.91158C16.5113 5.91188 16.6989 5.87238 16.8742 5.79483L18.2748 5.17956C18.5661 5.05159 18.9064 5.12136 19.1239 5.35362C20.1361 6.43464 20.8899 7.73115 21.3287 9.14558C21.4233 9.45058 21.3144 9.78203 21.0573 9.9715L19.8159 10.8866C19.4617 11.1468 19.2526 11.56 19.2526 11.9995C19.2526 12.4389 19.4617 12.8521 19.8166 13.1129L21.0592 14.0283C21.3163 14.2177 21.4253 14.5492 21.3307 14.8543C20.8921 16.2685 20.1387 17.5649 19.1271 18.6461C18.9098 18.8783 18.5697 18.9483 18.2785 18.8206L16.8721 18.2045C16.4698 18.0284 16.0077 18.0542 15.6275 18.274C15.2473 18.4937 14.9942 18.8812 14.9459 19.3177L14.7769 20.8444C14.742 21.1592 14.5131 21.4182 14.205 21.4915C12.7566 21.8361 11.2475 21.8361 9.79901 21.4915C9.49088 21.4182 9.26202 21.1592 9.22716 20.8444L9.05834 19.32C9.00875 18.8843 8.75532 18.498 8.3754 18.279C7.99549 18.06 7.53418 18.0343 7.13318 18.2094L5.72655 18.8256C5.4352 18.9533 5.09501 18.8833 4.87776 18.6509C3.8656 17.5685 3.11217 16.2705 2.67418 14.8548C2.57984 14.5499 2.68884 14.2186 2.94582 14.0293L4.18915 13.1133C4.54329 12.8531 4.75245 12.4399 4.75245 12.0005C4.75245 11.561 4.54329 11.1478 4.18869 10.8873L2.94614 9.97285C2.68877 9.78345 2.57967 9.45178 2.67434 9.14658C3.1131 7.73215 3.86692 6.43564 4.8791 5.35462C5.09657 5.12236 5.43691 5.05259 5.72822 5.18056L7.1286 5.79572C7.53154 5.97256 7.99478 5.94585 8.37675 5.72269C8.75707 5.50209 9.01026 5.11422 9.05914 4.67764L9.22922 3.15196C9.26474 2.83335 9.49884 2.57254 9.81178 2.50294C10.5291 2.34342 11.2609 2.25865 12.0132 2.25ZM12.0007 8.99995C10.3438 8.99995 9.00067 10.3431 9.00067 12C9.00067 13.6568 10.3438 15 12.0007 15C13.6575 15 15.0007 13.6568 15.0007 12C15.0007 10.3431 13.6575 8.99995 12.0007 8.99995Z"/>
                </svg>
                <span>Settings</span>
              </button>

            </div>
          </div>
        </div>
      </div>-->

      <main id="main" class="flex-1 overflow-x-hidden overflow-y-auto outline-none focus:outline-none bg-our-gray-10"
            tabindex="0">
        <div class="relative min-h-full">
          <div class="sticky top-0 z-30 px-6 mx-auto bg-our-gray-10">

            <transition name="fade-router-view" mode="out-in" appear>
              <div class="flex items-center h-24 px-2 py-6 gap-x-4 border-b border-light-level-0" :key="$route.name">
                <div class="flex flex-row items-center justify-between flex-grow">

                  <div class="flex flex-row items-center gap-x-8">

                    <!-- OLD getParentRoute -->
                    <!--                    <template v-if="getParentRoute() !== null">
                                          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                            <path fill-rule="evenodd"
                                                  d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z"
                                                  clip-rule="evenodd"/>
                                          </svg>
                                          <router-link :to="{ name: getParentRoute()?.name }"
                                                       class="text-base font-medium leading-5 border-b border-our-cyan-50 text-our-cyan-60"
                                                       style="margin-right: 9px;">
                                            {{ locale === 'en' ? getParentRoute().meta.name_en : getParentRoute().meta.name_cs }}
                                          </router-link>
                                          |
                                        </template>-->
                    <!-- END OLD getParentRoute -->

                    <!-- NEW getPreviousRouteName -->
                    <template
                        v-if="$router.options.history.state.back !== null && $router.options.history.state.current.split('/')[3] === 'detail'">
                      <button v-on:click="goBack()"
                                   class="button relative inline-flex flex-row items-center justify-center gap-x-2 p-2 tooltip-wrapper rounded-level-4 text-light-level-1 border border-light-level-1 hover:border-light-highlight-level-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M8.735 16.798a.75.75 0 0 0 1.03-1.09l-5.239-4.955H17.25a.75.75 0 0 0 0-1.5H4.524l5.241-4.957a.75.75 0 0 0-1.03-1.09l-6.418 6.07a.995.995 0 0 0-.3.566.753.753 0 0 0 .002.329.995.995 0 0 0 .298.557l6.418 6.07Z"/>
                        </svg>

                        <Tooltip :text="t('global.returnTo') + ` ${ getPreviousRouteName() }`"
                                 color="our-gray-30"
                                 background="our-gray-170" position="right"
                                 class="p-1 text-sm font-medium whitespace-nowrap"></Tooltip>
                      </button>
                    </template>
                    <!-- END NEW getPreviousRouteName -->

                    <div class="flex flex-row items-center justify-center rounded-full bg-light-level-2 px-1.5">
                      <h3 class="px-3 py-3 text-lg text-light-level-2 font-medium leading-none pl-3">
                        {{ locale === 'en' ? $route.meta.name_en : $route.meta.name_cs }}
                      </h3>
                      <span v-if="$route.path.includes('detail')"
                           class="px-3.5 py-2 text-sm font-medium leading-none text-white rounded-full bg-light-level-4 text-light-level-4">
                        {{ $store.state.detail.name || $route.params.id }}
                      </span>
                      <div class="text-our-gray-190" v-if="$route.name === 'File detail'">
                        <svg v-if="false" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                             viewBox="0 0 24 24">
                          <path d="M2.25 2.25v19.5h19.5V2.25H2.25zm1.5 1.5h16.5v13.5H3.75V3.75z"/>
                          <path
                              d="M14.25 12.75L9 7.5l-3.75 3.75v4.5h13.5v-3L16.5 10.5l-2.25 2.25zM17.25 8.25a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"/>
                        </svg>
                        <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                             viewBox="0 0 24 24">
                          <path d="M2.25 2.25v19.5h19.5V2.25H2.25zm1.5 1.5h16.5v13.5H3.75V3.75z"/>
                          <path d="M7.5 15l9-4.5-9-4.5v9z"/>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div class="flex items-center gap-x-6">
                    <!--<button type="button" v-if="$route.name === 'Overview'" v-on:click="$store.dispatch('Notifications/send', { variant: 'warning', message1: 'message 1', message2: 'message 2', time: new Date().toISOString(), overlay: true })" class="button-light-highlight-level-1 rounded-level-4">
                      Test notification
                    </button>

                    <button type="button" v-if="$route.name === 'Overview'" v-on:click="throw {name : 'NotImplementedError', message : 'too lazy to implement'}; " class="button-light-highlight-level-1 rounded-level-4">
                      Test error
                    </button>-->


                    <div class="relative inline-block text-left"
                         v-if="$route.name === 'Overview' || $route.name === 'Settings'">
                      <Menu>
                        <MenuButton class="button-light-level-0 rounded-level-4">
                          <!-- <svg class="-ml-0.5 mr-2" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                      <path
                                                          d="M12.87 15.07L10.33 12.56L10.36 12.53C12.1 10.59 13.34 8.36 14.07 6H17V4H10V2H8V4H1V5.99H12.17C11.5 7.92 10.44 9.75 9 11.35C8.07 10.32 7.3 9.19 6.69 8H4.69C5.42 9.63 6.42 11.17 7.67 12.56L2.58 17.58L4 19L9 14L12.11 17.11L12.87 15.07ZM18.5 10H16.5L12 22H14L15.12 19H19.87L21 22H23L18.5 10ZM15.88 17L17.5 12.67L19.12 17H15.88V17Z"/>
                                                    </svg>-->
                          <svg class="-ml-0.5 mr-2" width="24" height="24" fill="currentColor" viewBox="0 0 24 24"
                               xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="m9.563 7.505.056.117 5.307 13.005a1 1 0 0 1-1.802.86l-.05-.105L11.693 18H5.407l-1.49 3.407a1 1 0 0 1-1.208.555l-.11-.04a1 1 0 0 1-.556-1.208l.04-.11L7.778 7.6c.336-.77 1.394-.795 1.786-.094ZM19 2a1 1 0 0 1 .993.883L20 3v4h1a1 1 0 0 1 .993.883L22 8a1 1 0 0 1-.883.993L21 9h-1v7a1 1 0 0 1-.883.993L19 17a1 1 0 0 1-.993-.883L18 16V3a1 1 0 0 1 1-1ZM8.66 10.567 6.282 16h4.595L8.66 10.567ZM11 2h5a1 1 0 0 1 .993.883L17 3v2.975a4 4 0 0 1-4 4 1 1 0 1 1 0-2 2 2 0 0 0 1.995-1.85l.005-.15V4h-4a1 1 0 0 1-.117-1.993L11 2h5-5Z"/>
                          </svg>
                          <span>{{ t('global.menu.language') }}</span>
                          <svg
                              class="w-5 h-5 ml-2 -mr-1"
                              viewBox="0 0 20 20"
                              fill="currentColor">
                            <path fill-rule="evenodd"
                                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                  clip-rule="evenodd"></path>
                          </svg>
                        </MenuButton>

                        <transition enter-active-class="transition duration-100 ease-in"
                                    enter-from-class="transform scale-90 opacity-0"
                                    enter-to-class="transform scale-100 opacity-100"
                                    leave-active-class="transition duration-200 ease-out"
                                    leave-from-class="transform scale-100 opacity-100"
                                    leave-to-class="transform scale-90 opacity-0">
                          <MenuItems
                              class="absolute right-0 z-50 w-56 p-3 mt-2 flex flex-col gap-y-2 origin-top-right border rounded-sm outline-none bg-our-gray-190 border-our-gray-120">
                            <MenuItem v-slot="{ active }" v-for="item in [{ 'en' : 'english' }, { 'cs' : 'czech' }]"
                                      :key="Object.keys(item)[0]"
                                      :disabled="locale === Object.keys(item)[0]">
                              <button v-on:click="changeLanguage(Object.keys(item)[0])"
                                      :class="[active ? 'bg-our-gray-150 border-our-gray-130' : 'border-our-gray-190', locale === Object.keys(item)[0] ? 'cursor-default' : 'cursor-pointer' ]"
                                      class="flex items-center justify-between w-full px-4 py-2 text-sm leading-5 text-left transition-all duration-200 ease-linear border outline-none rounded-xs text-our-gray-10">
                                <span :class="locale === Object.keys(item)[0] && 'opacity-75'"
                                      v-text="t(`global.lang.${ Object.values(item)[0] }`)"></span>
                                <span :class="locale === Object.keys(item)[0] ? 'border-our-cyan-40' : 'border-white'"
                                      class="border-2 rounded-full">
                                  <img class="w-6 h-6" :src="require(`@/assets/langs/${ Object.keys(item)[0] }.svg`)">
                                </span>
                              </button>
                            </MenuItem>
                          </MenuItems>
                        </transition>
                      </Menu>
                    </div>


                    <!-- Display buttons -->

                    <button v-if="$route.name === 'Displays' || $route.name === 'Display detail'" type="button"
                            v-on:click="$store.dispatch('Slideover/toggleSlideover', true), $store.dispatch('Slideover/setSlideoverTitle', t('global.buttons.addNewDisplay')), $store.dispatch('Slideover/setSlideoverComponent', 'AddDisplay')"
                            class="button-light-highlight-level-1 rounded-level-4">
                      {{ t('global.buttons.addNewDisplay') }}
                    </button>

                    <template v-if="$route.name === 'Display detail'">
                      <div class="flex flex-row gap-x-3">

                        <button type="button"
                                @click="restartDisplay($route.params.id)"
                                class="button-light-highlight-level-1 rounded-level-4">
                          {{ t('global.buttons.restartDisplay') }}
                        </button>

                        <div class="relative">
                          <button type="button"
                                  v-on:click="showDisplayDeleteModal === false && (showDisplayDeleteModal = !showDisplayDeleteModal)"
                                  class="button-light-highlight-level-1 rounded-level-4">
                            {{ t('global.buttons.deleteDisplay') }}
                          </button>

                          <transition name="fade">
                            <!-- TODO change tooltip to dark -->
                            <TooltipModal position="bottom" v-if="showDisplayDeleteModal" color="light-level-0"
                                          background="light-level-0" border="light-level-0"
                                          v-click-outside="function() { showDisplayDeleteModal = false }">
                              <div class="flex flex-col items-center w-full max-w-lg p-4 gap-y-3">
                          <span class="whitespace-normal">{{ t('global.confirmToDeleteDisplay') }}
                          <span class="whitespace-nowrap">
                            <i>{{ $store.state.detail.name }}</i> (id:<i>{{ $route.params.id }}</i>)
                          </span></span>
                                <div class="flex flex-row gap-x-2">
                                  <button type="button" v-on:click="showDisplayDeleteModal = false"
                                          :class="{'opacity-50 cursor-not-allowed' : processingDisplayDelete}"
                                          :disabled="processingDisplayDelete"
                                          class="button-light-highlight-level-1 rounded-level-4">
                                    {{ t('global.buttons.cancel') }}
                                  </button>
                                  <button type="button" v-on:click="deleteDisplay(), processingDisplayDelete = true"
                                          :disabled="processingDisplayDelete"
                                          class="button-light-action-level-1 rounded-level-4">
                                    <Loader class="w-4 h-4 mr-2" v-show="processingDisplayDelete"></Loader>
                                    <span>{{ t('global.buttons.delete') }} <i>{{ $store.state.detail.name }}</i></span>
                                  </button>
                                </div>
                              </div>
                            </TooltipModal>
                          </transition>
                        </div>

                        <button v-if="$route.name === 'Display detail'" type="button"
                                @click="activateDisplay($route.params.id)"
                                class="button-light-highlight-level-1 rounded-level-4">
                          <span v-if="$store.state.detail.active">
                            {{ t('billing.activate.deactivateDisplay') }}
                          </span>
                          <span v-else>
                            {{ t('billing.activate.activateDisplay') }}
                          </span>
                        </button>

                      </div>
                    </template>


                    <!-- Group buttons -->

                    <button v-if="$route.name === 'Groups' || $route.name === 'Group detail'" type="button"
                            v-on:click="$store.dispatch('Slideover/toggleSlideover', true), $store.dispatch('Slideover/setSlideoverTitle', t('global.buttons.addNewGroup')), $store.dispatch('Slideover/setSlideoverComponent', 'AddGroup')"
                            class="button-light-highlight-level-1 rounded-level-4">
                      {{ t('global.buttons.addNewGroup') }}
                    </button>

                    <div v-if="$route.name === 'Group detail'" class="relative">

                      <button type="button" v-on:click="showGroupDeleteModal = !showGroupDeleteModal"
                              class="button-light-highlight-level-1 rounded-level-4">
                        {{ t('global.buttons.deleteGroup') }}
                      </button>

                      <transition name="fade">
                        <TooltipModal position="bottom" v-if="showGroupDeleteModal" color="our-gray-160"
                                      background="white" border="our-gray-120"
                                      v-click-outside="function() { showGroupDeleteModal = false }">
                          <div class="flex flex-col items-center w-full max-w-lg p-4 gap-y-3">
                                              <span class="whitespace-normal">{{ t('global.confirmToDeleteGroup') }}
                                              <span class="whitespace-nowrap">
                                                <i>{{ $store.state.detail.name }}</i> (id:<i>{{ $route.params.id }}</i>)
                                              </span></span>
                            <div class="flex flex-row gap-x-2">
                              <button type="button" v-on:click="showGroupDeleteModal = false"
                                      :class="{'opacity-50 cursor-not-allowed' : processingGroupDelete}"
                                      :disabled="processingGroupDelete"
                                      class="button-light-highlight-level-1 rounded-level-4">
                                {{ t('global.buttons.cancel') }}
                              </button>
                              <button type="button" v-on:click="deleteGroup(), processingGroupDelete = true"
                                      :disabled="processingGroupDelete"
                                      class="button-light-action-level-1 rounded-level-4">
                                <Loader class="w-4 h-4 mr-2" v-show="processingGroupDelete"></Loader>
                                {{ t('global.buttons.delete') }} <i>{{ $store.state.detail.name }}</i>
                              </button>
                            </div>
                          </div>
                        </TooltipModal>
                      </transition>
                    </div>


                    <!-- Location buttons -->

                    <button v-if="$route.name === 'Locations' || $route.name === 'Location detail'" type="button"
                            v-on:click="$store.dispatch('Slideover/toggleSlideover', true), $store.dispatch('Slideover/setSlideoverTitle', t('global.buttons.addNewLocation')), $store.dispatch('Slideover/setSlideoverComponent', 'AddLocation')"
                            class="button-light-highlight-level-1 rounded-level-4">
                      {{ t('global.buttons.addNewLocation') }}
                    </button>

                    <div v-if="$route.name === 'Location detail'" class="relative">

                      <button type="button" v-on:click="showLocationDeleteModal = !showLocationDeleteModal"
                              class="button-light-highlight-level-1 rounded-level-4">
                        {{ t('global.buttons.deleteLocation') }}
                      </button>

                      <transition name="fade">
                        <TooltipModal position="bottom" v-if="showLocationDeleteModal" color="our-gray-160"
                                      background="white" border="our-gray-120"
                                      v-click-outside="function() { showLocationDeleteModal = false }">
                          <div class="flex flex-col items-center w-full max-w-lg p-4 gap-y-3">
                                              <span class="whitespace-normal">{{ t('global.confirmToDeleteLocation') }}
                                              <span class="whitespace-nowrap">
                                                <i>{{ $store.state.detail.name }}</i> (id:<i>{{ $route.params.id }}</i>)
                                              </span></span>
                            <div class="flex flex-row gap-x-2">
                              <button type="button" v-on:click="showLocationDeleteModal = false"
                                      :class="{'opacity-50 cursor-not-allowed' : processingLocationDelete}"
                                      :disabled="processingLocationDelete"
                                      class="button-light-highlight-level-1 rounded-level-4">
                                {{ t('global.buttons.cancel') }}
                              </button>
                              <button type="button" v-on:click="deleteLocation(), processingLocationDelete = true"
                                      :disabled="processingLocationDelete"
                                      class="button-light-action-level-1 rounded-level-4">
                                <Loader class="w-4 h-4 mr-2" v-show="processingLocationDelete"></Loader>
                                {{ t('global.buttons.delete') }} <i>{{ $store.state.detail.name }}</i>
                              </button>
                            </div>
                          </div>
                        </TooltipModal>
                      </transition>
                    </div>


                    <!-- Playlists buttons -->

                    <button v-if="$route.name === 'Playlists' || $route.name === 'Playlist detail'" type="button"
                            v-on:click="$store.dispatch('Slideover/toggleSlideover', true), $store.dispatch('Slideover/setSlideoverTitle', translatedPlaylist), $store.dispatch('Slideover/setSlideoverComponent', 'AddPlaylist')"
                            class="button-light-highlight-level-1 rounded-level-4">
                      {{ t('global.buttons.addNewPlaylist') }}
                    </button>

                    <div v-if="$route.name === 'Playlist detail'" class="relative">
                      <button type="button" v-on:click="showPlaylistDuplicateModal = !showPlaylistDuplicateModal"
                              class="button-light-highlight-level-1 rounded-level-4">
                        {{ t('global.buttons.duplicatePlaylist') }}
                      </button>

                      <transition name="fade">
                        <TooltipModal position="bottom" v-if="showPlaylistDuplicateModal" color="our-gray-160"
                                      background="white" border="our-gray-120"
                                      v-click-outside="function() { showPlaylistDeleteModal = false }">
                          <div class="flex flex-col items-center w-full max-w-lg p-4 gap-y-3">
                                                                                     <span class="whitespace-normal">{{
                                                                                         t('global.confirmToDeletePlaylist')
                                                                                       }}
                                                                                     <span class="whitespace-nowrap">
                                                                                       <i>{{
                                                                                           $store.state.detail.name
                                                                                         }}</i> (id:<i>{{
                                                                                         $route.params.id
                                                                                       }}</i>)
                                                                                     </span></span>
                            <div class="flex flex-row gap-x-2">
                              <button type="button" v-on:click="showPlaylistDuplicateModal = false"
                                      :class="{'opacity-50 cursor-not-allowed' : processingPlaylistDuplicate}"
                                      :disabled="processingPlaylistDuplicate"
                                      class="button-light-highlight-level-1 rounded-level-4">
                                {{ t('global.buttons.cancel') }}
                              </button>
                              <button type="button" v-on:click="duplicatePlaylist(), processingPlaylistDuplicate = true"
                                      :disabled="processingPlaylistDuplicate"
                                      class="button-light-action-level-1 rounded-level-4">
                                <Loader class="w-4 h-4 mr-2" v-show="processingPlaylistDuplicate"></Loader>
                                {{ t('global.buttons.duplicate') }} <i>{{ $store.state.detail.name }}</i>
                              </button>
                            </div>
                          </div>
                        </TooltipModal>
                      </transition>
                    </div>

                    <div v-if="$route.name === 'Playlist detail'" class="relative">
                      <button type="button" v-on:click="showPlaylistDeleteModal = !showPlaylistDeleteModal"
                              class="button-light-highlight-level-1 rounded-level-4">
                        {{ t('global.buttons.deletePlaylist') }}
                      </button>

                      <transition name="fade">
                        <TooltipModal position="bottom" v-if="showPlaylistDeleteModal" color="our-gray-160"
                                      background="white" border="our-gray-120"
                                      v-click-outside="function() { showPlaylistDeleteModal = false }">
                          <div class="flex flex-col items-center w-full max-w-lg p-4 gap-y-3">
                                                                                     <span class="whitespace-normal">{{
                                                                                         t('global.confirmToDeletePlaylist')
                                                                                       }}
                                                                                     <span class="whitespace-nowrap">
                                                                                       <i>{{
                                                                                           $store.state.detail.name
                                                                                         }}</i> (id:<i>{{
                                                                                         $route.params.id
                                                                                       }}</i>)
                                                                                     </span></span>
                            <div class="flex flex-row gap-x-2">
                              <button type="button" v-on:click="showPlaylistDeleteModal = false"
                                      :class="{'opacity-50 cursor-not-allowed' : processingPlayableDelete}"
                                      :disabled="processingPlayableDelete"
                                      class="button-light-highlight-level-1 rounded-level-4">
                                {{ t('global.buttons.cancel') }}
                              </button>
                              <button type="button" v-on:click="deletePlaylist(), processingPlayableDelete = true"
                                      :disabled="processingPlayableDelete"
                                      class="button-light-action-level-1 rounded-level-4">
                                <Loader class="w-4 h-4 mr-2" v-show="processingPlayableDelete"></Loader>
                                {{ t('global.buttons.delete') }} <i>{{ $store.state.detail.name }}</i>
                              </button>
                            </div>
                          </div>
                        </TooltipModal>
                      </transition>
                    </div>


                    <!-- Playable buttons -->
                    <button v-if="$route.name === 'Playables'" type="button"
                            v-on:click="$store.dispatch('Slideover/toggleSlideover', true), $store.dispatch('Slideover/setSlideoverData', {action: 'addNew', folder: parseInt($route.query.folder)}), $store.dispatch('Slideover/setSlideoverTitle', t('global.buttons.addNewFolder')), $store.dispatch('Slideover/setSlideoverComponent', 'AddFolder')"
                            class="button-light-highlight-level-1 rounded-level-4">
                      {{ t('global.buttons.addNewFolder') }}
                    </button>

                    <button v-if="$route.name === 'Playables' || $route.name === 'Playable detail'" type="button"
                            v-on:click="$store.dispatch('Slideover/toggleSlideover', true), $store.dispatch('Slideover/setSlideoverTitle', t('global.buttons.addNewPlayable')), $store.dispatch('Slideover/setSlideoverComponent', 'AddPlayable')"
                            class="button-light-highlight-level-1 rounded-level-4">
                      {{ t('global.buttons.addNewPlayable') }}
                    </button>
                    <div v-if="$route.name === 'Playable detail'" class="relative">

                      <button type="button" v-on:click="showPlayableDeleteModal = !showPlayableDeleteModal"
                              class="button-light-highlight-level-1 rounded-level-4">
                        {{ t('global.buttons.deletePlayable') }}
                      </button>

                      <transition name="fade">
                        <TooltipModal position="bottom" v-if="showPlayableDeleteModal" color="our-gray-160"
                                      background="white" border="our-gray-120"
                                      v-click-outside="function() { showPlayableDeleteModal = false }">
                          <div class="flex flex-col items-center w-full max-w-lg p-4 gap-y-3">
                                                                 <span class="whitespace-normal">{{
                                                                     t('global.confirmToDeletePlayable')
                                                                   }}
                                                                 <span class="">
                                                                   <i>{{
                                                                       $store.state.detail.name
                                                                     }}</i> (id:<i>{{ $route.params.id }}</i>)
                                                                 </span></span>
                            <div class="flex flex-row gap-x-2">
                              <button type="button" v-on:click="showPlayableDeleteModal = false"
                                      :class="{'opacity-50 cursor-not-allowed' : processingPlayableDelete}"
                                      :disabled="processingPlayableDelete"
                                      class="button-light-highlight-level-1 rounded-level-4">
                                {{ t('global.buttons.cancel') }}
                              </button>
                              <button type="button" v-on:click="deletePlayable(), processingPlayableDelete = true"
                                      :disabled="processingPlayableDelete"
                                      class="button-light-action-level-1 rounded-level-4">
                                <Loader class="w-4 h-4 mr-2" v-show="processingPlayableDelete"></Loader>
                                {{ t('global.buttons.deleteFile') }}
                              </button>
                            </div>
                          </div>
                        </TooltipModal>
                      </transition>
                    </div>


                    <!-- Upload progress -->

                    <div
                        v-on:click="$store.dispatch('Slideover/toggleSlideover', true), $store.dispatch('Slideover/setSlideoverTitle', t('global.buttons.addNewPlayable')), $store.dispatch('Slideover/setSlideoverComponent', 'AddPlayable')"
                        class="relative ml-auto cursor-pointer tooltip-wrapper" v-if="$store.state.Playables.uploading">

                      <div
                          class="flex flex-row items-center px-4 py-2 gap-x-4 bg-white border rounded-sm border-our-cyan-50">
                        <span class="text-xs font-medium leading-5 tracking-wide text-our-cyan-70">
                          {{ t('global.uploadInProgress') }}
                        </span>
                        <div class="w-32 overflow-hidden rounded-full bg-our-gray-70" style="height: 5px">
                          <div class="h-full transition-all duration-1000 ease-out bg-our-cyan-50"
                               :style="`width: ${$store.state.Playables.uploadProgress}%`"></div>
                        </div>
                      </div>
                      <Tooltip
                          :text="$store.state.Playables.fileUploadData.name + ' - ' + $store.state.Playables.uploadProgress + '%'"
                          color="white text-sm py-1" background="our-gray-170" position="bottom"></Tooltip>
                    </div>


                    <!-- Billing buttons -->

                    <button v-if="$route.name === 'Billing detail'" type="button"
                            class="button-light-highlight-level-1 rounded-level-4">
                      {{ t('global.buttons.exportTo') }} pdf TODO
                    </button>

                    <button v-if="$route.name === 'Billing detail'" type="button"
                            class="button-light-highlight-level-1 rounded-level-4">
                      Another export btn..
                    </button>


                    <!-- User buttons -->

                    <button v-if="$route.name === 'Users' || $route.name === 'User detail'" type="button"
                            v-on:click="$store.dispatch('Slideover/toggleSlideover', true), $store.dispatch('Slideover/setSlideoverTitle', t('global.buttons.addNewUser')), $store.dispatch('Slideover/setSlideoverComponent', 'AddUser')"
                            class="button-light-highlight-level-1 rounded-level-4">
                      {{ t('global.buttons.addNewUser') }}
                    </button>

                    <div v-if="$route.name === 'User detail'" class="relative">
                      <button type="button" v-on:click="showUserDeleteModal = !showUserDeleteModal"
                              class="button-light-highlight-level-1 rounded-level-4">
                        {{ t('global.buttons.deleteUser') }}
                      </button>

                      <transition name="fade">
                        <TooltipModal position="bottom" v-if="showUserDeleteModal" color="our-gray-160"
                                      background="white" border="our-gray-120"
                                      v-click-outside="function() { showUserDeleteModal = false }">
                          <div class="flex flex-col items-center w-full max-w-lg p-4 gap-y-3">
                          <span class="whitespace-normal">{{ t('global.confirmToDeleteUser') }}
                          <span class="whitespace-nowrap">
                            <i>{{ $store.state.detail.name }}</i> (id:<i>{{ $route.params.id }}</i>)
                          </span></span>
                            <div class="flex flex-row gap-x-2">
                              <button type="button" v-on:click="showUserDeleteModal = false"
                                      :class="{'opacity-50 cursor-not-allowed' : processingUserDelete}"
                                      :disabled="processingUserDelete"
                                      class="button-light-highlight-level-1 rounded-level-4">
                                {{ t('global.buttons.cancel') }}
                              </button>
                              <button type="button" v-on:click="deleteUser(), processingUserDelete = true"
                                      :disabled="processingUserDelete"
                                      class="button-light-action-level-1 rounded-level-4">
                                <Loader class="w-4 h-4 mr-2" v-show="processingUserDelete"></Loader>
                                {{ t('global.buttons.delete') }} <i>{{ $store.state.detail.name }}</i>
                              </button>
                            </div>
                          </div>
                        </TooltipModal>
                      </transition>
                    </div>

                    <div v-if="$route.path.includes('detail') && !$route.path.includes('billings')"
                         class="relative tooltip-wrapper">
                      <button type="button" :class="{ 'opacity-50 cursor-not-allowed' : !itemsChanged || !dataValid }"
                              :disabled="!itemsChanged || !dataValid" v-on:click="saveChanges"
                              class="button-light-action-level-1 rounded-level-4">
                        <Loader class="w-4 h-4 mr-2" v-show="savingChanges"></Loader>
                        {{ t('global.buttons.saveChanges') }}
                      </button>
                      <Tooltip v-if="showTooltip" text="No unsaved changes or invalid data"
                               class="w-32 text-xs font-medium py-2.5" color="our-gray-30" background="our-gray-170"
                               position="bottom"></Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>


          <div class="px-10 py-8 mx-auto">

            <router-view v-slot="{ Component }">
              <transition name="fade-router-view-2" mode="out-in" appear>

                <div v-if="$route.name === 'Overview'">
                  <Home></Home>
                </div>

                <component v-else :is="Component" v-on:save-changes="incomingChanges($event)"/>

              </transition>
            </router-view>

            <!--<vue-json-pretty
                class="mt-5 mb-4"
                :data="$store.state"
                showLine
                showDoubleQuotes
                highlightMouseoverNode
                collapsedOnClickBrackets>
            </vue-json-pretty>-->
          </div>

          <transition name="fade">
            <div v-if="$store.state.Slideover.showSlideover" class="absolute inset-0 z-40 bg-black bg-opacity-50"></div>
          </transition>

        </div>

        <!-- Footer display status -->
        <!--<div class="fixed bottom-0 right-0 w-full h-10 max-w-lg px-3 mr-8 bg-white border-t border-l border-r rounded-t-sm lg:max-w-xs border-our-cyan-60">
        </div>-->

        <transition
            enter-active-class="transition duration-200 ease-out transform"
            leave-active-class="transition duration-300 ease-in transform"
            enter-from-class="translate-x-full"
            enter-to-class="translate-x-0"
            leave-from-class="translate-x-0"
            leave-to-class="translate-x-full"
        >
          <Slideover v-show="$store.state.Slideover.showSlideover" :open="$store.state.Slideover.showSlideover"
                     :title="$store.state.Slideover.slideoverTitle"
                     :component="$store.state.Slideover.slideoverComponent"></Slideover>
        </transition>

        <Notification></Notification>

      </main>
    </div>

    <transition name="fade">
      <div v-if="$store.state.Modal.showModal" class="absolute inset-0 z-40 bg-black opacity-50"></div>
    </transition>

    <transition
        enter-active-class="duration-150 ease-out transform"
        enter-from-class="scale-95 translate-y-4 opacity-0"
        enter-to-class="scale-100 translate-y-0 opacity-100"
        leave-active-class="duration-200 ease-in transform"
        leave-from-class="scale-100 translate-y-0 opacity-100"
        leave-to-class="scale-95 translate-y-0 translate-y-4 opacity-0"
    >
      <Modal v-if="$store.state.Modal.showModal"></Modal>
    </transition>

  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'

import Home from '@/components/Home'
import Modal from '@/components/Modal'
import Slideover from '@/components/Slideover'
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import Notification from '@/components/Notification'
import store from '@/store'
import EventBus from '@/eventBus'

// for resetting data on $route.fullPath change, also check the watcher
function initialData() {
  return {
    itemsChanged: false,
    dataValid: false,
    changesData: [],
    savingChanges: false,
    showModal: true,
    showDisplayDeleteModal: false,
    processingDisplayDelete: false,
    showGroupDeleteModal: false,
    processingGroupDelete: false,
    showLocationDeleteModal: false,
    processingLocationDelete: false,
    showPlayableDeleteModal: false,
    processingPlayableDelete: false,
    showUserDeleteModal: false,
    processingUserDelete: false,
    showPlaylistDeleteModal: false,
    processingPlaylistDelete: false,
    showPlaylistDuplicateModal: false,
    processingPlaylistDuplicate: false,
    showTooltip: true,
    deleteAction: false,
    time: null
  }
}

export default {
  name: 'Overview',
  setup() {
    return useI18n()
  },
  components: {
    Modal,
    Home,
    Slideover,
    Notification,
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
  },
  data() {
    return initialData()
  },
  watch: {
    '$route.fullPath': {
      handler() {
        Object.assign(this.$data, initialData())
      }
    },
    '$route'() {
      this.setPageTitle()
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (this.itemsChanged && !this.deleteAction) {
      this.$store.dispatch('Modal/setModalData', {
        message1: this.t('global.modal.unsavedChanges'),
        message2: this.t('global.modal.unsavedChangesAlert'),
        variant: 'info',
        buttonAction: () => {
          this.$store.dispatch('Modal/toggleModal', false)
          next()
        }
      })
      this.$store.dispatch('Modal/toggleModal', true)
    }
    else if (to.name !== from.name) {
      this.deleteAction = false
      setTimeout(() => {
        document.getElementById('main').scroll({ top: 0 })
      }, 275)
      next()
    }
    else {
      next()
    }
  },
  computed: {
    nodeEnv: () => process.env.NODE_ENV,
    translatedPlaylist() {
      return this.$route.params.locale == 'en' ? 'Add new playlist' : 'Vytvořit nový playlist'
    }
  },
  methods: {
    setPageTitle() {
      document.title = (this.locale === 'en' ? this.$route.meta.name_en : this.$route.meta.name_cs) + ' | ' + (localStorage.signageServerName ? localStorage.signageServerName : 'Bohemica Signage')
    },
    changeLanguage(val) {
      localStorage.language = val
      this.$moment.locale(localStorage.language || process.env.VUE_APP_I18N_LOCALE || 'en')
      this.$router.replace({ name: 'Overview', params: { locale: val } })
    },
    getPreviousRouteName() {
      let arr_url = this.$router.options.history.state.back.split('/')
      let routeName = arr_url[2]?.charAt(0).toUpperCase() + arr_url[2]?.slice(1)

      if(routeName){
        if (arr_url[3] === 'detail') {
          routeName = routeName.slice(0, -1)
          routeName += ' detail'
        } else {
          routeName = routeName.split('?')[0]
        }
      }else{
        routeName = 'Overview'
      }

      return routeName
    },
    goBack(){
      window.history.back()
    },
    getParentRoute() {
      let parentName = this.$route.meta.parentName
      let routes = this.$router.getRoutes()

      let overview = routes.find(route => route.name === 'Overview').children
      let parentRoute = overview.find(route => route.name === parentName)
      if (typeof parentRoute === 'undefined') {
        return null
      }
      return parentRoute
    },
    /*preloadData({ type, section }) {

      if (type === 'enter') {
        this.timer = setTimeout(async () => {
          switch (section) {
            case 'displays':
              this.$store.dispatch('Displays/fetchAllDisplays')
              break
            case 'groups':
              this.$store.dispatch('Groups/fetchAllGroups')
              break
            case 'playlists':
              this.$store.dispatch('Playlists/fetchAllPlaylists')
              break
            case 'playables':
              this.$store.dispatch('Playables/fetchAllPlayables')
              break
          }
        }, 300)
      }
      else if (type === 'leave') {
        clearTimeout(this.timer)
        this.timer = null
      }
    },*/
    async saveChanges() {
      this.savingChanges = true
      this.itemsChanged = false

      switch (this.changesData._type) {
        case 'display': {

          delete this.changesData.displaystatus
          delete this.changesData._type
          delete this.changesData.created_at
          delete this.changesData.updated_at

          if (this.changesData.screen?.id === undefined && this.changesData.screen !== null) {
            let responseDispatch = await this.$store.dispatch('Displays/uploadFile', {
              file: this.changesData.screen
            }).catch(e => {
              console.log('err', e)
            })

            if (typeof responseDispatch === 'undefined') {
              console.warn('File upload cancelled')
              this.$store.dispatch('Notifications/send', {
                variant: 'warning',
                message1: this.t('playables.notification.uploadCancelledBrowser'),
                message2: this.t('playables.notification.ifDidntCancelUploadTryDifferentBrowser'),
                time: new Date().toISOString(),
                duration: 5000,
                overlay: false
              })
            }
            else {
              if (responseDispatch.status === 200) {
                this.changesData.screen = { 'id': responseDispatch.data[0].id }
              }
            }
          }else{
            this.changesData.screen = this.changesData.screen?.id
          }

          this.changesData.active = this.changesData.active ? 'true' : 'false' // Strapi fix - raw false value gets rejected

          this.$store.dispatch('Displays/updateDisplay', this.changesData).then(response => {
            this.$store.dispatch('Notifications/send', {
              variant: 'success',
              message1: this.t('global.notification.successfullyUpdated'),
              message2: this.t('global.menu.display') +
                  ` <i>${response.data.name}</i> (id:<i>${response.data.id}</i>) ` +
                  this.t('global.notification.wasUpdated'),
              time: new Date().toISOString(),
              duration: 5000,
              overlay: false
            })

            this.savingChanges = false
            this.showTooltip = true

            // generate eventList after save
            this.$helpers.generateList(
                this.t('settings.notification.eventListCalendarUpdated'),
                this.t('settings.notification.eventsGenerated'),
                this.t('settings.notification.inProgress'),
                this.t('settings.notification.eventsGeneratedServer')
            )
          })
          break
        }
        case 'group': {

          delete this.changesData._type
          delete this.changesData.created_at
          delete this.changesData.updated_at

          this.$store.dispatch('Groups/updateGroup', this.changesData).then(response => {
            this.$store.dispatch('Notifications/send', {
              variant: 'success',
              message1: this.t('global.notification.successfullyUpdated'),
              message2: this.t('global.menu.group') +
                  ` <i>${response.data.name}</i> ` +
                  this.t('global.notification.wasUpdated'),
              time: new Date().toISOString(),
              duration: 5000,
              overlay: false
            })

            this.savingChanges = false
            this.showTooltip = true

            // generate eventList after save
            this.$helpers.generateList(
                this.t('settings.notification.eventListCalendarUpdated'),
                this.t('settings.notification.eventsGenerated'),
                this.t('settings.notification.inProgress'),
                this.t('settings.notification.eventsGeneratedServer')
            )
          })
          break
        }
        case 'location': {

          delete this.changesData._type
          delete this.changesData.created_at
          delete this.changesData.updated_at

          this.$store.dispatch('Locations/updateLocation', this.changesData).then(response => {
            this.$store.dispatch('Notifications/send', {
              variant: 'success',
              message1: this.t('global.notification.successfullyUpdated'),
              message2: this.t('global.menu.location') +
                  ` <i>${response.data.name}</i> ` +
                  this.t('global.notification.wasUpdated'),
              time: new Date().toISOString(),
              duration: 5000,
              overlay: false
            })

            this.savingChanges = false
            this.showTooltip = true
          })
          break
        }
        case 'playable': {

          delete this.changesData._type
          delete this.changesData.created_at
          delete this.changesData.updated_at

          !this.changesData.folder ? this.changesData.folder = null : ''
          !this.changesData.description ? this.changesData.description = null : ''

          this.$store.dispatch('Playables/updatePlayable', this.changesData).then(response => {
            this.$store.dispatch('Notifications/send', {
              variant: 'success',
              message1: this.t('global.notification.successfullyUpdated'),
              message2: this.t('global.menu.playable') +
                  ` <i>${response.data.name}</i> ` +
                  this.t('global.notification.wasUpdated'),
              time: new Date().toISOString(),
              duration: 5000,
              overlay: false
            })

            this.savingChanges = false
            this.showTooltip = true

          })
          break
        }
        case 'playlist&calendar': {
          let promisesOuter = []
          let promisesInner = []
          let promisesLast = []

          if (!this.validDataCalendarItems(this.changesData)) {

            this.$store.dispatch('Notifications/send', {
              variant: 'warning',
              message1: this.t('global.notification.invalidData'),
              message2: this.t('global.notification.invalidDataInCalendarConditions'),
              time: new Date().toISOString(),
              duration: 5000,
              overlay: false
            })

            this.savingChanges = false
            this.itemsChanged = true
            return
          }

          /* Update playlist ordered playable items */
          if (this.changesData.playablesOrderList.length > 0) {
            promisesOuter.push(new Promise((resolve, reject) => {
              this.$api.playlists.getPlayableOrderForOnePlaylist(this.$store.state.Playlists.playlistData.id).then(currentPlayableOrderItems => {
                this.changesData.playablesOrderList.forEach((playableOrderItem, index) => {

                  if (playableOrderItem.duration) {
                    //playableOrderItem.duration = this.$helpers.minutesAndSecondsToMilliseconds(playableOrderItem.duration)
                    // this conversion is no longer needed. Duration is always saved as a number of milliseconds.
                  }

                  if (currentPlayableOrderItems.data.some(currentPlayableOrder => {
                    return playableOrderItem.customid === currentPlayableOrder.customid && !playableOrderItem.new && !(playableOrderItem.order === null)
                  })) {
                    promisesInner.push(this.$api.playlists.updateExistingPlayableOrder(playableOrderItem))
                    /*promises.push(new Promise(resolve => {
                          this.$api.playlists.updateExistingPlayableOrder(playableOrderItem).then(response => {
                            resolve(response)
                          }, error => {
                            throw error
                          })
                        })
                    )*/
                  }
                  else if (playableOrderItem.new) {
                    promisesInner.push(
                        this.$api.playlists.createNewPlayableOrder({
                          customid: parseInt(this.$helpers.replaceString(playableOrderItem.customid, '-new', '')),
                          order: playableOrderItem.order,
                          duration: playableOrderItem.duration,
                          repeat: playableOrderItem.repeat,
                          playlist: this.$store.state.Playlists.playlistData.id,
                          playable: playableOrderItem.playable.id
                        })
                    )
                  }
                })
                delete this.changesData.playlistData.playableorders
                resolve()
              })
            }))
          }

          this.changesData.playablesOrderIdsDelete.forEach(id => {
            if (id !== undefined) {
              promisesInner.push(this.$api.playlists.deletePlayableOrder(id))
            }
          })

          /*else {
            resolve()
          }*/

          /* Update calendar data + ordered items/conditions */

          let promisesCalendar = []

          // promise that waits before all other promises are pushed to 'promisesInner'
          promisesOuter.push(new Promise((resolve, reject) => {
            if (this.changesData.calendarData.id === 'new') {
              promisesCalendar.push(this.$api.calendars.addNewCalendar({
                'name': this.changesData.calendarData.name,
                'playlist': this.changesData.calendarData.playlist.id
              }).then(response => {
                if (this.changesData.calendarData.id === 'new') {
                  this.changesData.calendarData.calendaritemdetails.forEach((calendarItem) => {

                    calendarItem.customid = parseInt(this.$helpers.replaceString(calendarItem.customid, '-new', ''))
                    calendarItem.calendaritem = response.data.id
                    this.$api.calendars.addNewCalendarItem(calendarItem)

                  })
                }
              }))
            }
            else if (this.changesData.calendarData.id) {
              promisesCalendar.push(this.$api.calendars.updateExistingCalendar({
                'id': this.changesData.calendarData.id,
                'name': this.changesData.calendarData.name,
                'enabled': this.changesData.calendarData.enabled,
                'playlist': this.changesData.calendarData.playlist.id,
                /*'calendaritemdetails': this.changesData.calendarData.calendaritemdetails*/
              }))
            }

            this.$store.state.Calendars.calendars.forEach(calendar => {
              if (calendar.id === 'delete') {
                promisesCalendar.push(this.$api.calendars.deleteCalendar(calendar.calendarID))
              }
            })

            if (this.changesData.calendarData.calendaritemdetails.length > 0) {
              Promise.all(promisesCalendar).then(() => {
                this.$store.dispatch('Calendars/fetchAllCalendars').then(() => {

                  if (this.changesData.calendarData.id !== 'new') {
                    this.$api.calendars.getSingleCalendar(this.changesData.calendarData.id).then(currentCalendar => {

                      this.changesData.calendarData.calendaritemdetails.forEach((calendarItem, index) => {

                        if (calendarItem.customid.toString().includes('new')) {
                          calendarItem.customid = parseInt(this.$helpers.replaceString(calendarItem.customid, '-new', ''))
                          calendarItem.calendaritem = currentCalendar.data.id
                          promisesInner.push(this.$api.calendars.addNewCalendarItem(calendarItem)
                          )
                        }
                        /* TODO duplicate to others for delete (to check db that it exists there) */
                        else if (calendarItem.id.toString().includes('delete') || calendarItem.order === null) {

                          calendarItem.customid = parseInt(this.$helpers.replaceString(calendarItem.customid, '-delete', ''))

                          if (currentCalendar.data.calendaritemdetails.some(currentCalendarItem => {
                            return calendarItem.id === currentCalendarItem.id
                          })) {
                            promisesInner.push(this.$api.calendars.deleteCalendarItem(this.$helpers.replaceString(calendarItem.id, '-delete', '')))
                          }
                        }
                        else if (currentCalendar.data.calendaritemdetails.some(currentCalendarItem => {
                          return calendarItem.id === currentCalendarItem.id && !(calendarItem.order === null)
                        })) {
                          promisesInner.push(
                              this.$api.calendars.updateExistingCalendarItem(calendarItem)
                          )
                        }
                      })
                      resolve()
                    })
                  }
                  else {
                    resolve()
                  }
                })
              })
            }
            else {
              resolve()
            }
          }))


          Promise.all(promisesOuter).then(() => {
            Promise.all(promisesInner).then(() => {

              //console.log([ ...promisesOuter, ...promisesInner ])

              this.$store.dispatch('Notifications/send', {
                variant: 'success',
                message1: this.t('global.notification.successfullyUpdated'),
                message2: this.t('global.menu.playlist') + ' ' + this.t('global.notification.wasUpdated'), /*<i>${ response.data.name }</i> (id:<i>${ response.data.id }</i>)*/
                time: new Date().toISOString(),
                duration: 5000,
                overlay: false
              })

              promisesLast.push(this.$store.dispatch('Playlists/updatePlaylist', this.changesData.playlistData))
              promisesLast.push(this.$store.dispatch('Playlists/fetchPlayableOrderForOnePlaylist', this.$route.params.id))
              promisesLast.push(this.$store.dispatch('Playlists/fetchPlayableOrderLastSavedForOnePlaylist', this.$route.params.id))
              promisesLast.push(this.$store.dispatch('Calendars/fetchCalendarItemsForOnePlaylist', this.$route.params.id))
              promisesLast.push(this.$store.dispatch('Calendars/fetchAllCalendars'))

              /* TODO fetching all calendar items or only selected ? */
              /*promisesInner.push(this.$store.dispatch('Calendars/fetchAllCalendarItems'))*/
              /*promisesLast.push(this.$store.dispatch('Calendars/fetchAllCalendars'))*/
              /*promises.push(this.$store.dispatch('Playlists/fetchAllPlaylists'))
                                            promises.push(this.$store.dispatch('Playables/fetchAllPlayables'))*/

              Promise.all(promisesLast).then(() => {
                this.savingChanges = false
                this.showTooltip = true

                // generate eventList after save
                this.$helpers.generateList(
                    this.t('settings.notification.eventListCalendarUpdated'),
                    this.t('settings.notification.eventsGenerated'),
                    this.t('settings.notification.inProgress'),
                    this.t('settings.notification.eventsGeneratedServer')
                )
              })
            })
          })

          break
        }
        case 'user': {

          delete this.changesData._type
          delete this.changesData.created_at
          delete this.changesData.updated_at

          this.$store.dispatch('Users/updateUser', this.changesData).then(response => {
            this.$store.dispatch('Notifications/send', {
              variant: 'success',
              message1: this.t('global.notification.successfullyUpdated'),
              message2: this.t('global.menu.user') +
                  ` <i>${response.data.username}</i> ` +
                  this.t('global.notification.wasUpdated'),
              time: new Date().toISOString(),
              duration: 5000,
              overlay: false
            })

            this.savingChanges = false
            this.showTooltip = true

          })
          break
        }
      }
    },
    incomingChanges(data) {
      //console.info('Incoming changes...')
      let loadData, changesNotEqual = false

      switch (data._type) {
        case 'display': {

          loadData = this.$helpers.purify(this.$store.getters['Displays/getDisplayData'])
          !data.description ? data.description = '' : ''
          !loadData.location ? loadData.location = 0 : ''
          // !data.active ? data.active = false : ''; // active solo ukladani

          let isScreenChange = false
          
          // for better readability (combination - screen as DB item or File object)
          if(loadData.screen?.id){
            if(loadData.screen?.id !== data.screen?.id){
              isScreenChange = true
            }
          }else{
            if(loadData.screen !== data.screen){
              isScreenChange = true
            }
          }

          if (loadData.customId === data.customId &&
              JSON.stringify(loadData.displaygroups.map(({ id }) => id)) === JSON.stringify(data.displaygroups.map(({ id }) => id)) &&
              loadData.name === data.name &&
              loadData.orientation === data.orientation &&
              loadData.location == data.location &&
              loadData.description === data.description &&
              loadData.resolution === data.resolution &&
              // loadData.active === data.active && // active solo ukladani
              !isScreenChange) {
            changesNotEqual = false
          }
          else {
            changesNotEqual = true
          }

          break
        }
        case 'group': {

          let dataDisplayId = [], loadDataDisplayId = []
          loadData = this.$helpers.purify(this.$store.getters['Groups/getGroupData'])
          !data.description ? data.description = '' : ''

          for (let r_display of loadData.displays) {
            loadDataDisplayId.push(r_display.id)
          }

          for (let r_display of data.displays) {
            dataDisplayId.push(r_display.id)
          }

          if (loadData.name === data.name &&
              JSON.stringify(loadDataDisplayId.sort((a, b) => a - b)) === JSON.stringify(dataDisplayId.sort((a, b) => a - b)) &&
              loadData.description === data.description &&
              loadData.orientation === data.orientation) {
            changesNotEqual = false
          }
          else {
            changesNotEqual = true
          }

          break
        }
        case 'location': {

          let dataDisplayId = [], loadDataDisplayId = []
          loadData = this.$helpers.purify(this.$store.getters['Locations/getLocationData'])

          !data.monday_closed ? data.monday_closed = false : ''
          !data.tuesday_closed ? data.tuesday_closed = false : ''
          !data.wednesday_closed ? data.wednesday_closed = false : ''
          !data.thursday_closed ? data.thursday_closed = false : ''
          !data.friday_closed ? data.friday_closed = false : ''
          !data.saturday_closed ? data.saturday_closed = false : ''
          !data.sunday_closed ? data.sunday_closed = false : ''

          !data.monday_nonstop ? data.monday_nonstop = false : ''
          !data.tuesday_nonstop ? data.tuesday_nonstop = false : ''
          !data.wednesday_nonstop ? data.wednesday_nonstop = false : ''
          !data.thursday_nonstop ? data.thursday_nonstop = false : ''
          !data.friday_nonstop ? data.friday_nonstop = false : ''
          !data.saturday_nonstop ? data.saturday_nonstop = false : ''
          !data.sunday_nonstop ? data.sunday_nonstop = false : ''

          !data.monday_start ? data.monday_start = null : ''
          !data.tuesday_start ? data.tuesday_start = null : ''
          !data.wednesday_start ? data.wednesday_start = null : ''
          !data.thursday_start ? data.thursday_start = null : ''
          !data.friday_start ? data.friday_start = null : ''
          !data.saturday_start ? data.saturday_start = null : ''
          !data.sunday_start ? data.sunday_start = null : ''

          !data.monday_end ? data.monday_end = null : ''
          !data.tuesday_end ? data.tuesday_end = null : ''
          !data.wednesday_end ? data.wednesday_end = null : ''
          !data.thursday_end ? data.thursday_end = null : ''
          !data.friday_end ? data.friday_end = null : ''
          !data.saturday_end ? data.saturday_end = null : ''
          !data.sunday_end ? data.sunday_end = null : ''

          for (let r_display of loadData.displays) {
            loadDataDisplayId.push(r_display.id)
          }

          for (let r_display of data.displays) {
            dataDisplayId.push(r_display.id)
          }

          if (loadData.name === data.name &&
              JSON.stringify(loadDataDisplayId.sort((a, b) => a - b)) === JSON.stringify(dataDisplayId.sort((a, b) => a - b)) &&
              loadData.monday_start === data.monday_start &&
              loadData.monday_end === data.monday_end &&
              loadData.monday_closed === data.monday_closed &&
              loadData.monday_nonstop === data.monday_nonstop &&
              loadData.tuesday_start === data.tuesday_start &&
              loadData.tuesday_end === data.tuesday_end &&
              loadData.tuesday_closed === data.tuesday_closed &&
              loadData.tuesday_nonstop === data.tuesday_nonstop &&
              loadData.wednesday_start === data.wednesday_start &&
              loadData.wednesday_end === data.wednesday_end &&
              loadData.wednesday_closed === data.wednesday_closed &&
              loadData.wednesday_nonstop === data.wednesday_nonstop &&
              loadData.thursday_start === data.thursday_start &&
              loadData.thursday_end === data.thursday_end &&
              loadData.thursday_closed === data.thursday_closed &&
              loadData.thursday_nonstop === data.thursday_nonstop &&
              loadData.friday_start === data.friday_start &&
              loadData.friday_end === data.friday_end &&
              loadData.friday_closed === data.friday_closed &&
              loadData.friday_nonstop === data.friday_nonstop &&
              loadData.saturday_start === data.saturday_start &&
              loadData.saturday_end === data.saturday_end &&
              loadData.saturday_closed === data.saturday_closed &&
              loadData.saturday_nonstop === data.saturday_nonstop &&
              loadData.sunday_start === data.sunday_start &&
              loadData.sunday_end === data.sunday_end &&
              loadData.sunday_closed === data.sunday_closed &&
              loadData.sunday_nonstop === data.sunday_nonstop) {
            changesNotEqual = false
          }
          else {
            changesNotEqual = true
          }

          break
        }
        case 'playable': {

          loadData = this.$helpers.purify(this.$store.getters['Playables/getPlayableData'])
          !data.description || data.description === '' ? data.description = '' : ''
          !loadData.description || loadData.description === '' ? loadData.description = '' : ''

          if (loadData.name === data.name &&
              loadData.description === data.description &&
              loadData.folder?.id === data.folder) {
            changesNotEqual = false
          }
          else {
            changesNotEqual = true
          }
          break
        }
        case 'playlist&calendar': {

          //console.log('Doing wierd shit')

          let dataDisplayGroupsId = [], loadDataDisplayGroupsId = [], change = false
          loadData = []
          loadData.playlistData = this.$helpers.purify(this.$store.getters['Playlists/getPlaylistData'])
          loadData.calendarData = this.$helpers.purify(this.$store.getters['Calendars/getCalendarData'])
          loadData.playablesOrderList = this.$helpers.purify(this.$store.getters['Playlists/getPlaylistOrderLastSavedData'])

          for (let r_displayGroup of data.playlistData.displaygroups) {
            dataDisplayGroupsId.push(r_displayGroup.id)
          }

          for (let r_displayGroup of loadData.playlistData.displaygroups) {
            loadDataDisplayGroupsId.push(r_displayGroup.id)
          }

          for (let r_playablesOrderList of data.playablesOrderList) {
            r_playablesOrderList.repeat = parseInt(r_playablesOrderList.repeat)
            if (!r_playablesOrderList.id) {
              change = true
            }
          }

          let simplifiedPlayablesOrderList_load = [ ...loadData.playablesOrderList ]
          for (let item of simplifiedPlayablesOrderList_load) {
            //delete item.playable;
          }
          simplifiedPlayablesOrderList_load = simplifiedPlayablesOrderList_load.sort((a, b) => a.order - b.order)

          let simplifiedPlayablesOrderList_actual = [ ...data.playablesOrderList ]
          for (let item of simplifiedPlayablesOrderList_actual) {
            //delete item.playable;
          }
          simplifiedPlayablesOrderList_actual = simplifiedPlayablesOrderList_actual.sort((a, b) => a.order - b.order)

          /*
          console.log('simplified', simplifiedPlayablesOrderList_load, simplifiedPlayablesOrderList_actual,
              JSON.stringify(simplifiedPlayablesOrderList_load) === JSON.stringify(simplifiedPlayablesOrderList_actual))

          console.log('saved', loadData)
          console.log('actual data', data)
          console.log('groups ids', JSON.stringify(dataDisplayGroupsId) === JSON.stringify(loadDataDisplayGroupsId))
          console.log('calendaritemdetails', JSON.stringify(loadData.calendarData.calendaritemdetails) === JSON.stringify(data.calendarData.calendaritemdetails))
          */

          if (loadData.playlistData.enabled === data.playlistData.enabled &&
              loadData.playlistData.name === data.playlistData.name &&
              JSON.stringify(dataDisplayGroupsId) === JSON.stringify(loadDataDisplayGroupsId) &&
              JSON.stringify(loadData.calendarData.calendaritemdetails) === JSON.stringify(data.calendarData.calendaritemdetails) && // TODO neni dokonale
              JSON.stringify(simplifiedPlayablesOrderList_load.sort((a, b) => a.order - b.order)) === JSON.stringify(simplifiedPlayablesOrderList_actual.sort((a, b) => a.order - b.order)) &&
              !change
          ) {
            changesNotEqual = false
          }
          else {
            changesNotEqual = true
          }

          // this doesn't work as it should :(
          // store - getPlaylistOrderData changes after each change, therefore the condition did not know change (Michal)
          //       - now getPlaylistOrderLastSavedData, only changes when created or saved

          // changesNotEqual = true;

          break
        }
        case 'user': {

          loadData = this.$helpers.purify(this.$store.getters['Users/getUser'])

          !data.description ? data.description = '' : ''
          !loadData.description ? loadData.description = '' : ''
          !data.blocked ? data.blocked = false : ''
          !loadData.blocked ? loadData.blocked = false : ''
          !data.fullname ? data.fullname = '' : ''
          !loadData.fullname ? loadData.fullname = '' : ''

          if(typeof loadData.role === 'object' && loadData.role !== null){
            loadData.role = loadData.role.id
          }
          
          // !data.playablefolder ? data.playablefolder = null : ''
          // !loadData.playablefolder ? loadData.playablefolder = null : ''

          if (loadData.fullname === data.fullname &&
              loadData.blocked === data.blocked &&
              loadData.description === data.description &&
              // loadData.playablefolder === data.playablefolder &&
              loadData.role == data.role) {
            changesNotEqual = false
          }
          else {
            changesNotEqual = true
          }

          break
        }
      }

      //console.log('Changes to be saved?', changesNotEqual)

      if (data.valid === true && !this.savingChanges && changesNotEqual) {
        //console.info('Data are OK valid.')
        this.showTooltip = false
        this.itemsChanged = true
        this.dataValid = true
        this.changesData = data
      }
      else if (data.valid === false) {
        //console.debug('Data are not valid.')
        this.dataValid = false
        this.itemsChanged = false
      }
      else {
        this.itemsChanged = false
      }
    },
    deleteDisplay() {
      /* Display delete - interaction protocol */
      this.$api.displays.deleteDisplay(this.$route.params.id).then(response => {

        this.showDisplayDeleteModal = false
        this.processingDisplayDelete = false
        this.deleteAction = true

        if (response.status === 200) {
          new Promise((resolve) => {

            this.$store.dispatch('Notifications/send', {
              variant: 'success',
              message1: this.t('global.notification.successfullyDeleted'),
              message2: this.t('global.menu.display') +
                  ` <i>${response.data.name}</i> (id:<i>${response.data.id}</i>) ` +
                  this.t('global.notification.wasDeleted'),
              time: new Date().toISOString(),
              duration: 3000,
              overlay: true
            })

            setTimeout(() => {
              resolve()
            }, 500)

          }).then(() => {
            setTimeout(() => {
              this.$router.push('/displays')
            }, 5000)
            this.$store.dispatch('Notifications/send', {
              variant: 'info',
              message1: this.t('global.notification.redirectingInMoment'),
              message2: this.t('global.notification.thisPageRedirectedTo') +
                  '<i>' + this.t('global.menu.displays') + '</i>',
              time: new Date().toISOString(),
              duration: 5250,
              overlay: true
            })
            // generate eventList after delete
            this.$helpers.generateList(
                this.t('settings.notification.eventListCalendarUpdated'),
                this.t('settings.notification.eventsGenerated'),
                this.t('settings.notification.inProgress'),
                this.t('settings.notification.eventsGeneratedServer')
            )
          })
        }
      })
    },
    deleteGroup() {
      /* Group delete - interaction protocol */
      this.$api.groups.deleteGroup(this.$route.params.id).then(response => {

        this.showGroupDeleteModal = false
        this.processingGroupDelete = false
        this.deleteAction = true

        if (response.status === 200) {
          new Promise((resolve) => {

            this.$store.dispatch('Notifications/send', {
              variant: 'success',
              message1: this.t('global.notification.successfullyDeleted'),
              message2: this.t('global.menu.group') +
                  ` <i>${response.data.name}</i> (id:<i>${response.data.id}</i>) ` +
                  this.t('global.notification.wasDeleted'),
              time: new Date().toISOString(),
              duration: 3000,
              overlay: true
            })

            setTimeout(() => {
              resolve()
            }, 500)

          }).then(() => {
            setTimeout(() => {
              this.$router.push('/groups')
            }, 5000)
            this.$store.dispatch('Notifications/send', {
              variant: 'info',
              message1: this.t('global.notification.redirectingInMoment'),
              message2: this.t('global.notification.thisPageRedirectedTo') +
                  '<i>' + this.t('global.menu.groups') + '</i>',
              time: new Date().toISOString(),
              duration: 5250,
              overlay: true
            })
            // generate eventList after delete
            this.$helpers.generateList(
                this.t('settings.notification.eventListCalendarUpdated'),
                this.t('settings.notification.eventsGenerated'),
                this.t('settings.notification.inProgress'),
                this.t('settings.notification.eventsGeneratedServer')
            )
          })
        }
      })
    },
    deleteLocation() {
      /* Location delete - interaction protocol */
      this.$api.locations.deleteLocation(this.$route.params.id).then(response => {

        this.showLocationDeleteModal = false
        this.processingLocationDelete = false
        this.deleteAction = true

        if (response.status === 200) {
          new Promise((resolve) => {

            this.$store.dispatch('Notifications/send', {
              variant: 'success',
              message1: this.t('global.notification.successfullyDeleted'),
              message2: this.t('global.menu.location') +
                  ` <i>${response.data.name}</i> (id:<i>${response.data.id}</i>) ` +
                  this.t('global.notification.wasDeleted'),
              time: new Date().toISOString(),
              duration: 3000,
              overlay: true
            })

            setTimeout(() => {
              resolve()
            }, 500)

          }).then(() => {
            setTimeout(() => {
              this.$router.push('/locations')
            }, 5000)
            this.$store.dispatch('Notifications/send', {
              variant: 'info',
              message1: this.t('global.notification.redirectingInMoment'),
              message2: this.t('global.notification.thisPageRedirectedTo') +
                  '<i>' + this.t('global.menu.groups') + '</i>',
              time: new Date().toISOString(),
              duration: 5250,
              overlay: true
            })
            // generate eventList after delete
            this.$helpers.generateList(
                this.t('settings.notification.eventListCalendarUpdated'),
                this.t('settings.notification.eventsGenerated'),
                this.t('settings.notification.inProgress'),
                this.t('settings.notification.eventsGeneratedServer')
            )
          })
        }
      })
    },
    deletePlayable() {
      /* Playable delete - interaction protocol */

      // Check if it is in playlist
      this.$api.playlists.getPlaylistsByPlayableId(this.$route.params.id).then(res => {

        if (typeof res.data !== 'undefined' && res.data.length > 0) {

          let playListText = ''
          for (let r_data of res.data) {
            playListText += r_data.name + ', '
          }
          playListText = playListText.slice(0, -2)

          this.$store.dispatch('Notifications/send', {
            variant: 'error',
            message1: this.t('global.notification.unableToDelete'),
            message2: this.t('global.notification.playableCannotBeDeleted') + `<i>${playListText}</i>`,
            time: new Date().toISOString(),
            duration: 10000,
            overlay: true
          })

          this.processingPlayableDelete = false
          this.showPlayableDeleteModal = false

        }
        else {

          this.$api.playables.deletePlayable(this.$route.params.id).then(response1 => {

            this.showPlayableDeleteModal = false
            this.processingPlayableDelete = false
            this.deleteAction = true

            if (response1.status === 200) {
              new Promise((resolve) => {

                this.$store.dispatch('Notifications/send', {
                  variant: 'success',
                  message1: this.t('global.notification.successfullyDeleted'),
                  message2: this.t('global.menu.file') +
                      ` <i>${response1.data.name}</i> (id:<i>${response1.data.id}</i>) ` +
                      this.t('global.notification.wasDeleted'),
                  time: new Date().toISOString(),
                  duration: 2000,
                  overlay: true
                })

                setTimeout(() => {
                  resolve()
                }, 500)

              }).then(() => {
                setTimeout(() => {
                  this.$router.push('/playables')
                }, 1500)
                this.$store.dispatch('Notifications/send', {
                  variant: 'info',
                  message1: this.t('global.notification.redirectingInMoment'),
                  message2: this.t('global.notification.thisPageRedirectedTo') +
                      '<i>' + this.t('global.menu.playables') + '</i>',
                  time: new Date().toISOString(),
                  duration: 2000,
                  overlay: true
                })
              })
            }
          }).catch(e => {
            console.error({ e })
            this.$store.dispatch('Notifications/send', {
              variant: 'error',
              message1: this.t('global.notification.unableToDelete') + ' ' + this.t('global.menu.playable'),
              message2: this.t('global.notification.checkTheConsole'),
              time: new Date().toISOString(),
              duration: 3000,
              overlay: false
            })
          })
        }
      })
    },
    deletePlaylist() {
      /* Playlist delete - interaction protocol */
      this.showTooltip = false
      this.$api.playlists.deletePlaylist(this.$route.params.id).then(response => {

        this.$api.calendars.deleteCalendar(this.$store.state.Calendars.calendarData.id).then(response_calendar => {

          this.showPlaylistDeleteModal = false
          this.processingPlaylistDelete = false
          this.deleteAction = true

          if (response.status === 200) {
            new Promise((resolve) => {

              this.$store.dispatch('Notifications/send', {
                variant: 'success',
                message1: this.t('global.notification.successfullyDeleted'),
                message2: this.t('global.menu.playlist') +
                    ` <i>${response.data.name}</i> (id:<i>${response.data.id}</i>) ` +
                    this.t('global.notification.wasDeleted'),
                time: new Date().toISOString(),
                duration: 3000,
                overlay: true
              })

              setTimeout(() => {
                resolve()
              }, 500)

            }).then(() => {
              setTimeout(() => {
                this.$router.push('/playlists')
              }, 5000)
              this.$store.dispatch('Notifications/send', {
                variant: 'info',
                message1: this.t('global.notification.redirectingInMoment'),
                message2: this.t('global.notification.thisPageRedirectedTo') +
                    '<i>' + this.t('global.menu.playlists') + '</i>',
                time: new Date().toISOString(),
                duration: 4268,
                overlay: true
              })
              // generate eventList after delete
              this.$helpers.generateList(
                  this.t('settings.notification.eventListCalendarUpdated'),
                  this.t('settings.notification.eventsGenerated'),
                  this.t('settings.notification.inProgress'),
                  this.t('settings.notification.eventsGeneratedServer')
              )
            })
          }
        })
      })
    },
    deleteUser() {
      this.$api.users.deleteUser(this.$route.params.id).then(response => {

        this.showUserDeleteModal = false
        this.processingUserDelete = false
        this.deleteAction = true

        if (response.status === 200) {
          new Promise((resolve) => {

            this.$store.dispatch('Notifications/send', {
              variant: 'success',
              message1: this.t('global.notification.successfullyDeleted'),
              message2: this.t('global.menu.user') +
                  ` <i>${response.data.name}</i> (id:<i>${response.data.id}</i>) ` +
                  this.t('global.notification.wasDeleted'),
              time: new Date().toISOString(),
              duration: 3000,
              overlay: true
            })

            setTimeout(() => {
              resolve()
            }, 500)

          }).then(() => {
            setTimeout(() => {
              this.$router.push('/users')
            }, 5000)
            this.$store.dispatch('Notifications/send', {
              variant: 'info',
              message1: this.t('global.notification.redirectingInMoment'),
              message2: this.t('global.notification.thisPageRedirectedTo') +
                  '<i>' + this.t('global.menu.users') + '</i>',
              time: new Date().toISOString(),
              duration: 5250,
              overlay: true
            })
          })
        }
      })
    },
    duplicatePlaylist() {

      this.$api.playlists.duplicatePlaylist(this.$route.params.id).then(response => {

        this.showPlaylistDuplicateModal = false
        this.processingPlaylistDuplicate = false

        if (response.status === 200) {
          new Promise((resolve) => {

            this.$store.dispatch('Notifications/send', {
              variant: 'success',
              message1: this.t('global.notification.successfullyDuplicated'),
              message2: this.t('global.menu.playlist') +
                  ` <i>${response.data.name}</i> (id:<i>${response.data.id}</i>) ` +
                  this.t('global.notification.wasDuplicated'),
              time: new Date().toISOString(),
              duration: 3000,
              overlay: true
            })

            setTimeout(() => {
              resolve()
            }, 500)

          }).then(() => {
            setTimeout(() => {
              this.$store.dispatch('Playlists/fetchPlaylist', response.data.id)
              this.$store.dispatch('Calendars/fetchCalendarItemsForOnePlaylist', response.data.id)
              this.$store.dispatch('Playlists/fetchHighestPlayableOrderId', response.data.id)
              this.$store.dispatch('Playlists/fetchPlayableOrderLastSavedForOnePlaylist', response.data.id)
              this.$router.push('/playlists/detail/' + response.data.id)
              this.$store.dispatch('Playlists/fetchPlayableOrderForOnePlaylist', response.data.id)
              this.$store
            }, 5000)
            this.$store.dispatch('Notifications/send', {
              variant: 'info',
              message1: this.t('global.notification.redirectingInMoment'),
              message2: this.t('global.notification.thisPageRedirectedTo') +
                  this.t('global.notification.duplicated') +
                  ' <i>' + this.t('global.menu.playlists') + '</i>',
              time: new Date().toISOString(),
              duration: 5250,
              overlay: true
            })
          })
        }
      })
    },
    signOut() {
      this.$store.dispatch('Login/signOut').then(() => {
        this.$router.push({ name: 'Login' })
      })
    },
    restartDisplay(id) {
      this.$store.dispatch('Modal/setModalData', {
        message1: this.t('global.modal.restartDisplay'),
        message2: this.t('global.modal.areYouSureRestartDisplayDevice') + id + '?',
        variant: 'info',
        buttonAction: () => {
          this.$store.dispatch('Modal/toggleModal', false)
          this.$store.dispatch('Displays/updateDisplay', { id: id, action: 'restart', 'screen': true /* remains original */ }).then(response => {
            this.itemsChanged = false
            this.$store.dispatch('Notifications/send', {
              variant: 'success',
              message1: this.t('global.notification.RestartrRequestSendSuccessfully'),
              message2: this.t('global.menu.display') +
                  ` <i>${response.data.name}</i> (id:<i>${response.data.id}</i>) ` +
                  this.t('global.notification.sendRequestToRestart'),
              time: new Date().toISOString(),
              duration: 3000,
              overlay: true
            })

          })
        }
      })
      this.$store.dispatch('Modal/toggleModal', true)
    },
    activateDisplay(id){

      let isActiveDisplays, message2, acivateDisplay
      let trialVersionState, currencyName, predictedPriceData, isFreeDisplay

      let loadData = this.$helpers.purify(this.$store.getters['Displays/getDisplayData'])

        if(!loadData.active){ // if it was inactive and now be active

          this.$store.dispatch('Displays/fetchAllDisplays').then((response) => {
            for (const display of response.data) {
              if (display.active) {
                isActiveDisplays = true
              }
            }

            this.$api.billings.getServerPricingData({
              'serverIPv4': this.getServerIPv4(this.$axios.defaults.baseURL),
              'displayId': this.$route.params.id
            }).then(response => {
              trialVersionState = response.data.trialState
              currencyName = response.data?.currency?.name
              predictedPriceData = response.data
              isFreeDisplay = response.data.isFreeDisplay

              if (response.data === false) {
                trialVersionState = false
              }

              if (trialVersionState === 0) { // showBilling
                message2 = this.t('billing.activate.doYouWantToActivateDisplay')
                acivateDisplay = 'acivateDisplay'
              } else {
                if (isActiveDisplays) { // if at least one display is active
                  if (trialVersionState === 1 || (trialVersionState === 2 && !response.data.creditCardDetails)) {
                    message2 = this.t('billing.trialVersion.trialVersion') + ' - ' + this.t('billing.activate.alertNecessaryFillInfo')
                    acivateDisplay = 'acivateDisplayDisabled'
                  }
                  else {
                    message2 = this.t('billing.activate.doYouWantToActivateDisplay') + '<br />' +
                        this.t('billing.activate.PriceForDisplayIs') + ' ' + predictedPriceData.pricePerMonth + ' ' + currencyName + this.t('billing.activate.perMonth') + `<br />` +
                        this.t('billing.activate.priceEndOfMonthWillBe') + ' ' + predictedPriceData.endMonthPrice + ' ' + currencyName + this.t('billing.activate.perMonth')
                    if (trialVersionState === false) { // !billing
                      message2 = this.t('billing.activate.doYouWantToActivateDisplay') + '<br />' +
                          this.t('billing.activate.alertNotPossibleToPredictPrice')
                    }
                    else if (isFreeDisplay) { // first free display
                      message2 = this.t('billing.activate.doYouWantToActivateDisplay') + '<br /><br />' +
                          this.t('billing.activate.thisDisplayIsFreeForYou') + '<br />' +
                          this.t('billing.activate.priceCurrentNextMonthStillSame')
                    }
                    else {
                      if (trialVersionState === 3) {
                        message2 += `<br /><br />` +
                            this.t('billing.activate.youPayNow') + ' ' + predictedPriceData.endMonthPriceWithoutDipslay + ' ' + currencyName + this.t('billing.activate.perMonth') +
                            this.t('billing.activate.afterAddingDisplayThereWillBe') + ' ' + predictedPriceData.endMonthPriceWitDipslay + ' ' + currencyName + this.t('billing.activate.untilEndOfMonth') + ' ' +
                            this.t('billing.activate.priceForNextMonth') + ' ' + predictedPriceData.endNextMonthPrice + ' ' + currencyName + `.`
                      }
                    }
                    acivateDisplay = 'acivateDisplay'
                  }

                }
                else { // if no display is active
                  message2 = this.t('billing.activate.activateFirstDisplay')
                  acivateDisplay = 'acivateDisplay'
                }
              }
              
              if (!loadData.devices[0]) {
                message2 += '<br /><br /> <strong>' + this.t('billing.activate.displayNotConnectedToDevice') + '</strong>'
              }

              this.$store.dispatch('Modal/setModalData', {
                message1: 'Activate display',
                message2: message2,
                variant: acivateDisplay,
                buttonAction: () => { // Activate display

                  if ((trialVersionState === 2 || trialVersionState === 3 || trialVersionState === 0) || !isActiveDisplays) {
                    this.updateActivateDisplay()

                    this.savingChanges = false
                    this.$helpers.generateList(
                        this.t('settings.notification.eventListCalendarUpdated'),
                        this.t('settings.notification.eventsGenerated'),
                        this.t('settings.notification.inProgress'),
                        this.t('settings.notification.eventsGeneratedServer')
                    )
                  }
                  this.$store.dispatch('Modal/toggleModal', false)
                },
                buttonCancelAction: () => {
                  this.$store.dispatch('Modal/toggleModal', false)
                }
              })
              this.$store.dispatch('Modal/toggleModal', true)

              })
          })

        }else{ // deactivate modal

          this.$store.dispatch('Modal/setModalData', {
            message1: this.t('billing.activate.deactivateDisplay'),
            message2: this.t('billing.activate.youSureWantToDeactivateDisplay'),
            variant: 'deactivateDisplay',
            buttonAction: () => {

              this.$store.dispatch('Modal/toggleModal', false)
              this.$store.dispatch('Displays/updateDisplay', {
                'id': this.$route.params.id,
                'active': 0,
                'screen': true // remains original
              }).then(response => {

                this.$store.dispatch('setDetailActive', false)
                this.$store.dispatch('Notifications/send', {
                  variant: 'success',
                  message1: this.t('global.notification.successfullyDeactivated'),
                  message2: this.t('global.menu.display') +
                      ` <i>${response.data.name}</i> (id:<i>${response.data.id}</i>) ` +
                      this.t('global.notification.wasDeactivated'),
                  time: new Date().toISOString(),
                  duration: 3000,
                  overlay: false
                })
                this.savingChanges = false
                this.$helpers.generateList(
                  this.t('settings.notification.eventListCalendarUpdated'),
                  this.t('settings.notification.eventsGenerated'),
                  this.t('settings.notification.inProgress'),
                  this.t('settings.notification.eventsGeneratedServer')
                )
              })

            },
            buttonCancelAction: () => {
              this.$store.dispatch('Modal/toggleModal', false)
            }
          })
          this.$store.dispatch('Modal/toggleModal', true)
        }

    },
    getServerIPv4(baseURL) {
      let IPv4
      IPv4 = baseURL.replace('https://', '')
      IPv4 = IPv4.replace('http://', '')
      IPv4 = IPv4.replace('/', '')
      IPv4 = IPv4.split(':')[0] // port
      return IPv4
    },
    async updateActivateDisplay() {

      await this.$store.dispatch('Displays/updateDisplay', {
        'id': this.$route.params.id,
        'active': 1,
        'screen': true // remains original
      }).then(response => {

        this.$store.dispatch('setDetailActive', true)
        this.$store.dispatch('Notifications/send', {
          variant: 'success',
          message1: this.t('global.notification.successfullyActivated'),
          message2: this.t('global.menu.display') +
              ` <i>${response.data.name}</i> (id:<i>${response.data.id}</i>)  ` +
              this.t('global.notification.wasActivated'),
          time: new Date().toISOString(),
          duration: 3000,
          overlay: false
        })
      })

    },
    validDataCalendarItems(data) {
      let isError = false

      data.calendarData.calendaritemdetails.forEach((calendaritemdetailsItem, index) => {
        if (!calendaritemdetailsItem.customid.toString().includes('delete')) {
          switch (calendaritemdetailsItem.precondition) {
            case 1: // date
              (!calendaritemdetailsItem.postconditiondate || calendaritemdetailsItem.postconditiondate == 'Invalid date') ? isError = true : ''
              break
            case 2: // time
              if (calendaritemdetailsItem.condition == 1 || calendaritemdetailsItem.condition == 3) { // before || after
                (!calendaritemdetailsItem.postconditiontime1 || calendaritemdetailsItem.postconditiontime1 == 'Invalid date') ? isError = true : ''
              }
              else if (calendaritemdetailsItem.condition == 4) { // between
                (!calendaritemdetailsItem.postconditiontime1 || calendaritemdetailsItem.postconditiontime1 == 'Invalid date') ? isError = true : '';
                (!calendaritemdetailsItem.postconditiontime2 || calendaritemdetailsItem.postconditiontime2 == 'Invalid date') ? isError = true : ''
              }
              break
            case 3: // weekday
              if (!(calendaritemdetailsItem.condition >= 5 && calendaritemdetailsItem.condition <= 11)) {
                isError = true
              }
              break
          }
        }
      })
      return (isError) ? false : true
    }
  },
  created() {
    this.$store.dispatch('Users/fetchLoggedUserInformation')
    this.setPageTitle()
  },
  mounted() {
    EventBus.on('callActivateModal', () => {
      this.activateDisplay()
    })

    window.requestAnimationFrame = (function () {
      return (
          window.webkitRequestAnimationFrame ||
          window.mozRequestAnimationFrame ||
          window.oRequestAnimationFrame ||
          window.msRequestAnimaationFrame ||
          function (callback, element) {
            window.setTimeout(callback, 1000 / 60)
          }
      )
    })()

    /* window.performance = window.performance || {}
    performance.now = (function () {
      return performance.now ||
          performance.mozNow ||
          performance.msNow ||
          performance.oNow ||
          performance.webkitNow ||
          function () {
            return new Date().getTime()
          }
    })() */

    const timer = () => {
      requestAnimationFrame(timer)
      /* console.log(performance.now()) */
      this.time = this.$moment(Date.now()).format('DD/MM/YYYY HH:mm:ss')
    }

    timer()
  }
}
</script>

<style lang="postcss" scoped>
.router-link-exact-active {
  @apply text-our-cyan-20
}

.router-link-exact-active svg {
  @apply text-our-cyan-30
}

.menu-item {
  @apply flex items-center p-1 text-sm font-medium leading-5 tracking-wide transition-all duration-150 ease-linear border rounded-sm outline-none select-none border-our-gray-180 text-our-gray-30;
}

.menu-item:hover {
  @apply text-white bg-our-gray-150 border-our-gray-120
}

.menu-item:focus {
  @apply bg-our-gray-150
}

.menu-item div:first-child {
  @apply p-2 mr-3 rounded-xs bg-our-gray-150
}

</style>
