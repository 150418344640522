import axios from 'axios'

const END_POINT = 'https://errorlogger.bohemicastudio.com/';


const send = (data, user) => {
	console.log('data:', JSON.stringify(data), 'user:', user)
	let payload = JSON.stringify(data)

	user = { user: user }

	console.log('user:', JSON.stringify(user))

	if (user) {
		payload = payload + JSON.stringify(user)
		console.log('payload', payload)
	}

	return axios({
		baseURL: END_POINT + '?site=lancaster-maloney',
		headers: {
			common: {
				Authorization: ''
			}
		},
		data: payload,
		method: 'POST'
	})
}


export default {
	send
}
