import billings from '@/api/modules/billings'

const state = {
	billingData: {},
	billingDetail: {}
}


const getters = {
	getBillingData(state) {
		return state.billingData;
	},
	getCustomerDetails(state) {
		return state.billingData.customerDetail;
	}
}


const mutations = {
	SET_BILLING_DATA(state, data) {
		state.billingData = data
	},
	SET_BILLING_DETAIL(state, data){
		state.billingDetail = data
	},
	SET_CUSTOMER_DETAIL_DATA(state, data) {
		state.billingData.customerDetail = data
	},
}


const actions = {
	fetchBillingData({ commit }, payload) {
		return billings.getBillingsDataByIPv4(payload).then((response) => {
			commit('SET_BILLING_DATA', response.data)
			return response
		})
	},
	fetchBillingDetail({ commit }, payload) {
		return billings.getBillingDataDetail(payload).then((response) => {
			commit('SET_BILLING_DETAIL', response.data)
			return response
		})
	},
	updateCustomerDetails({ commit }, payload) {
		return new Promise((resolve, reject) => {
			billings.updateCustomerDetails(payload).then(response => {
				commit('SET_CUSTOMER_DETAIL_DATA', response.data)
				resolve(response);
			}, error => {
				reject(error);
			})
		})
	},
	createNewCustomerDetails({ commit }, payload) {
		return new Promise((resolve, reject) => {
			billings.createCustomerDetails(payload).then(response => {
				commit('SET_CUSTOMER_DETAIL_DATA', response.data)
				resolve(response);
			}, error => {
				reject(error);
			})
		})
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
