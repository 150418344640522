<template>
  <div class="gap-y-5">
    <!--<div>
      <form class="mt-4" @submit.stop.prevent>
        <input class="px-4 py-2 m-2 border border-black" type="file" name="files" @change="handleFileChange($event)"/>
        <button class="px-4 py-2 m-2 border border-black" type="submit" value="Submit" @click="sendForm">
          [ Upload video, create playable ]
        </button>
      </form>
      <pre>
        File loaded: {{ fileLoaded }}
        <hr>
        FFprobe run: {{ ffprobe }}
      </pre>
      <br>
      <button class="px-4 py-2 m-2 border border-black" type="submit" value="Submit" @click="test">[test]</button>
    </div>-->

    <div class="select-list-horizontal">
      <button v-on:click="filter = 'all'"
              :class="['button text-sm rounded-lg leading-5 mt-2 mr-2 px-2 py-1 '] + [filter === 'all' ? 'button-dark-gray' : 'button-light-gray']">
        {{ t('playables.filter.all') }}
      </button>
      <button v-on:click="filter = 'image'"
              :class="['button text-sm rounded-md leading-5 mt-2 mr-2 px-2 py-1 '] + [filter === 'image' ? 'button-dark-gray' : 'button-light-gray']">
        {{ t('playables.filter.images') }}
      </button>
      <button v-on:click="filter = 'video'"
              :class="['button text-sm rounded-md leading-5 mt-2 mr-2 px-2 py-1 '] + [filter === 'video' ? 'button-dark-gray' : 'button-light-gray']">
        {{ t('playables.filter.videos') }}
      </button>
      <button v-on:click="filter = 'web'"
              :class="['button text-sm rounded-md leading-5 mt-2 mr-2 px-2 py-1 '] + [filter === 'web' ? 'button-dark-gray' : 'button-light-gray']">
        {{ t('playables.filter.web') }}
      </button>
      <!--<button v-on:click="filter = null" :class="['button text-sm rounded-md leading-5 '] + [filter === null ? 'button-dark-gray' : 'button-light-gray']">
        null
      </button>-->
    </div>


    <div class="flex justify-between mt-3">
      <div class="w-full flex flex-row lg:max-w-md">
        <label for="search" class="sr-only">{{ t('playables.filter.search') }}</label>
        <div class="relative w-auto text-our-gray-120 focus-within:text-our-gray-140">
          <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg class="transition duration-150 ease-linear" width="20" height="20" viewBox="0 0 20 20"
                 fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M8.5 3C11.5376 3 14 5.46243 14 8.5C14 9.74832 13.5841 10.8995 12.8834 11.8226L17.0303 15.9697C17.3232 16.2626 17.3232 16.7374 17.0303 17.0303C16.7641 17.2966 16.3474 17.3208 16.0538 17.1029L15.9697 17.0303L11.8226 12.8834C10.8995 13.5841 9.74832 14 8.5 14C5.46243 14 3 11.5376 3 8.5C3 5.46243 5.46243 3 8.5 3ZM8.5 4.5C6.29086 4.5 4.5 6.29086 4.5 8.5C4.5 10.7091 6.29086 12.5 8.5 12.5C10.7091 12.5 12.5 10.7091 12.5 8.5C12.5 6.29086 10.7091 4.5 8.5 4.5Z"/>
            </svg>
          </div>
          <input type="search" id="search" v-model="fulltextSearch" size="30"
                 class="h-10 pl-10 pr-3 transition-all duration-150 ease-linear border rounded-md outline-none placeholder-our-gray-120 border-our-gray-130border-light-highlight-level-1 focus:border-our-gray-190 hover:border-our-gray-160 text-our-gray-160text-smleading-5 focus:outline-none"
                 :placeholder="searchPlaceholder">
        </div>
        <div class="h-10 pl-5 pr-3" @click="toggleSearchActive = !toggleSearchActive, fulltextSearchWatch()">
          <div class="w-16 h-10 bg-gray-300 rounded-full p-1 duration-300 ease-in-out"
               :class="{ 'bg-our-gray-180': toggleSearchActive}">
            <div class="bg-white w-8 h-8 rounded-full shadow-md transform duration-300 ease-in-out"
                 :class="{ 'translate-x-6': toggleSearchActive,}"></div>
          </div>
        </div>
      </div>


      <div class="flex flex-grow flex-row items-center justify-end gap-x-3">
        <select v-model="sort" class="w-full max-w-sm rounded-level-3">
          <option value="created_at:DESC">{{ t('playables.filter.firstUploaded') }}</option>
          <option value="created_at:ASC">{{ t('playables.filter.lastUploaded') }}</option>
          <option value="name:ASC">{{ t('playables.filter.AtoZ') }}</option>
          <option value="name:DESC">{{ t('playables.filter.ZtoA') }}</option>
        </select>
      </div>
    </div>

    <nav class="flex pt-4" aria-label="Breadcrumb">
      <ol class="flex items-center gap-x-4">

        <li>
          <div @click="selectRootFolder()" class="cursor-pointer">
            <a class="text-gray-400 hover:text-gray-500">
              <svg class="flex-shrink-0 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                   fill="currentColor" aria-hidden="true">
                <path
                    d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"/>
              </svg>
              <span class="sr-only">{{ t('playables.home') }}</span>
            </a>
          </div>
        </li>

        <li v-for="(folder, index) in arr_folderUrl" :key="folder.id">
          <div class="flex items-center" v-if="index != Object.keys(arr_folderUrl).length - 1"
               @click="selectFolderById(folder.id)">
            <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                 fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"/>
            </svg>
            <a class="cursor-pointer ml-4 text-sm font-medium text-gray-300 hover:text-gray-700 no-underline hover:underline">{{
                folder.name
              }}</a>
          </div>
          <div class="flex items-center" v-else>
            <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                 fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"/>
            </svg>
            <a class="ml-4 text-sm font-medium text-gray-300 hover:text-gray-700">{{ folder.name }}</a>
          </div>
        </li>

      </ol>
    </nav>

    <div class="flex flex-col px-5 pt-5 gap-y-9 pb-7 rounded-xl bg-our-gray-50 mt-7">

      <div name="list" tag="ul" class="flex flex-wrap -mx-2" v-if="(playablesList.length > 0 || arr_folderUrl) && ready">
        <!--<transition-group name="list" tag="ul" class="flex flex-wrap -mx-2" v-if="playablesList.length > 0 || parentIdUrl">-->
        <!--grid grid-cols-4 gap-4 pt-2 xl:gap-5 xl:grid-cols-5-->

        <div
            v-if="Array.isArray(arr_folderUrl) && arr_folderUrl.length && (fulltextSearch === null || fulltextSearch === '' )"
            class="w-1/4 p-3"> <!-- back -->
          <div
              class="relative flex w-full h-full transition-all duration-150 ease-linear bg-center bg-cover border outline-none cursor-pointer select-none border-our-gray-210 bg-our-gray-20 rounded-xs group r-our-cyan-40 focus:outline-none"
              @click="backSelectFolder(playable)"
          >
            <div
                class="absolute top-0 z-10 w-full h-full transition-all duration-150 ease-linear bg-black bg-opacity-0 group-hover:bg-opacity-50"></div>
            <div
                class="absolute z-10 flex inline-flex flex-row items-center p-1 mt-2 ml-2 gap-x-2 text-sm font-medium bg-white bg-opacity-75 border rounded-sm text-our-gray-190 border-our-gray-190"
                style="backdrop-filter: blur(2px)">
              <div class="relative flex flex-row tooltip-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd"
                        d="M2 6a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1H8a3 3 0 00-3 3v1.5a1.5 1.5 0 01-3 0V6z"
                        clip-rule="evenodd"/>
                  <path d="M6 12a2 2 0 012-2h8a2 2 0 012 2v2a2 2 0 01-2 2H2h2a2 2 0 002-2v-2z"/>
                </svg>
              </div>
            </div>

            <div
                class="absolute bottom-0 left-0 right-0 z-20 flex inline-flex flex-row items-center px-2 py-1 text-sm font-medium bg-white bg-opacity-75 border-t tooltip-wrapper border-our-gray-190"
                style="backdrop-filter: blur(2px)">
              <span class="leading-6 truncate text-our-gray-190">{{ t('playables.back') }}</span>
            </div>

            <div class="z-0 flex flex-grow aspect-[16/9]"></div>

          </div>
        </div>


        <transition-group name="fade" mode="out-in" tag="div"
               v-for="folder in viableFolder"
               :class="[' '] + [(fulltextSearch === null || fulltextSearch === '' ) ? 'w-1/4 p-3' : '']"
               :key="folder.id">
            <div v-if="(fulltextSearch === null || fulltextSearch === '' )"
                 class="relative flex flex-col w-full h-full aspect-[16/9]"
                 @click="selectFolder(folder)">

              <div class="relative flex flex-row">
                <div class="relative flex flex-row group/folder-menu tooltip-wrapper"
                     @click.stop="toggleDropdownMenu(folder.id)"
                     ref="btnDropdownRef">
                  <div class="flex items-center justify-start h-7 cursor-pointer transition duration-75 ease-linear"
                       :class="folder.hover ? 'bg-light-level-4 text-white' : 'group-hover/folder-menu:bg-light-level-4 group-hover/folder-menu:text-white bg-light-level-3 text-our-gray-50'">
                    <svg class="flex-shrink-0 ml-2 mr-12" width="24" height="24" fill="currentColor" viewBox="0 0 24 24"
                         xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M8 12a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM14 12a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM18 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"/>
                    </svg>
                  </div>
                  <div class="relative">
                    <div
                        class="w-7 h-7 cursor-pointer overflow-visible transition duration-75 ease-linear"
                        :class="folder.hover ? 'bg-light-level-4' : 'group-hover/folder-menu:bg-light-level-4 bg-light-level-3'"
                        style="clip-path: polygon(0 0, 0% 100%, 100% 100%);"></div>
                    <Tooltip :text="'Folder actions'" color="our-gray-30" position="right" background="our-gray-170"
                             classes="text-sm px-2 py-1 font-medium text-center whitespace-nowrap"></Tooltip>
                  </div>


                  <!--                <div
                                      class="absolute top-0 z-10 w-full h-full transition-all duration-75 ease-linear bg-black bg-opacity-0 group-hover:bg-opacity-50"></div>

                                  <div
                                      class="absolute z-10 flex inline-flex flex-row items-center p-1 mt-2 ml-2 gap-x-2 text-sm font-medium bg-white bg-opacity-75 border rounded-sm text-our-gray-190 border-our-gray-190"
                                      style="backdrop-filter: blur(2px)">

                                    <div class="relative flex flex-row tooltip-wrapper">
                                      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd"
                                              d="M2 6a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1H8a3 3 0 00-3 3v1.5a1.5 1.5 0 01-3 0V6z"
                                              clip-rule="evenodd"/>
                                        <path d="M6 12a2 2 0 012-2h8a2 2 0 012 2v2a2 2 0 01-2 2H2h2a2 2 0 002-2v-2z"/>
                                      </svg>
                                    </div>

                                    <div @click.stop="toggleDropdownMenu(folder.id)" ref="btnDropdownRef"
                                         class="relative flex flex-row tooltip-wrapper ">
                                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                                           stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                              d="M4 6h16M4 12h16M4 18h16"/>
                                      </svg>
                                      <Tooltip :text="t('global.menu.menu')" color="our-gray-30" position="bottom"
                                               background="our-gray-170"
                                               classes="text-sm px-2 py-1 font-medium text-center" class="z-40"></Tooltip>
                                    </div>
                                    <div class="hidden bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1"
                                         style="min-width:4rem;max-height:6rem" :ref="'drobdownDiv'+ folder.id"></div>
                                  </div>-->


                  <div class="absolute bottom-0 top-full hidden z-50"
                       :ref="'dropdownEl'+ folder.id">
                    <div class="flex flex-col bg-light-level-4 border border-light-level-4 m-2 p-2 gap-y-2">
                      <a @click.stop="toggleDropdownMenu(folder.id), $store.dispatch('Slideover/toggleSlideover', true), $store.dispatch('Slideover/setSlideoverData', {action: 'edit', folder: folder.id}), $store.dispatch('Slideover/setSlideoverTitle', t('playables.buttons.editFolder')), $store.dispatch('Slideover/setSlideoverComponent', 'AddFolder')"
                         class="text-sm px-2 py-1 text-our-gray-40 hover:bg-our-gray-150 border border-transparent hover:border-our-gray-130 cursor-pointer select-none transition duration-100 ease-linear">
                        {{ t('playables.dropdown.edit') }}
                      </a>
                      <a @click.stop="removePlayableFolder(folder.id, folder.name)"
                         class="text-sm px-2 py-1 text-our-gray-40 hover:bg-our-gray-150 border border-transparent hover:border-our-gray-130 cursor-pointer select-none transition duration-100 ease-linear">
                        {{ t('playables.dropdown.delete') }}
                      </a>
                    </div>
                  </div>
                </div>
                <div></div>
              </div>

              <div class="flex flex-col flex-grow cursor-pointer group"
                   @mouseenter="folder.hover = true"
                   @mouseleave="folder.hover = false">

                <div
                    class="flex flex-col items-start flex-grow bg-light-level-3 group-hover:bg-light-level-4 transition duration-75 ease-linear min-h-[95px]">

                  <div class="absolute right-0 top-8 bottom-9 w-8/12 grid grid-cols-3 grid-flow gap-2">

                    <template v-for="(innerPlayable,index) in getSlicedFolder(folder)" :key="innerPlayable.id">
                        <template v-if="index === 0"></template>
                        <template v-else>
                          <div class="border aspect-w-16 aspect-h-9 display-placeholder flex items-center justify-center">
                            <img v-if="innerPlayable.media_thumb !== null" :src="$axios.defaults.baseURL + innerPlayable.media_thumb.formats.thumbnail.url.substring(1)" class="object-contain">
                            <img v-else-if="innerPlayable.type === 'web'" :src="this.$axios.defaults.baseURL + 'screenshots/' + innerPlayable.id + '.png'" class="object-contain">
                            <template v-else-if="innerPlayable.type === 'image'">
                              <img v-if="innerPlayable.original.formats.thumbnail !== null" :src="$axios.defaults.baseURL + innerPlayable.original.formats.thumbnail.url.substring(1)" class="object-contain">
                              <img v-else :src="$axios.defaults.baseURL + innerPlayable.original.url.substring(1)" class="object-contain">
                            </template>
                          </div>
                        </template>
                      </template>
                  </div>

                  <div
                      class="flex flex-col flex-grow gap-1.5 p-3">

                    <div class="inline-flex gap-x-1 items-center flex-grow-0 tracking-spaced text-our-gray-50">
                      <span class="py-0.5 px-1 bg-light-level-4 group-hover:bg-light-level-3 transition duration-75 ease-linear rounded-level-4 text-xxs font-semibold">
                        <span v-if="isFilesInFilesLoaded">{{folder.fileText}}</span>
                        <Loader v-else class="w-4 h-4 text-our-gray-40"></Loader>
                      </span>
                      <span class="pr-1 text-xs font-medium">{{ t('playables.files') }}</span>
                    </div>

                    <div class="inline-flex gap-x-1 items-center flex-grow-0 tracking-spaced text-our-gray-50">
                      <span class="py-0.5 px-1 bg-light-level-4 group-hover:bg-light-level-3 transition duration-75 ease-linear rounded-level-4 text-xxs font-semibold">
                          <span v-if="isFoldersInFoldersLoaded">{{folder.folderText}}</span>
                          <Loader v-else class="w-4 h-4 text-our-gray-40"></Loader>
                      </span>
                      <span class="pr-1 text-xs font-medium">{{ t('playables.folders') }}</span>
                    </div>

                    <!-- <div
                                          class="inline-flex gap-x-1 items-center bg-light-level-4 rounded-level-5 flex-grow-0 p-1 tracking-spaced text-our-gray-50">
                                        <span class="py-0.5 px-1 bg-light-level-3 rounded-level-4 text-xxs font-semibold">2</span>
                                        <span class="pr-1 text-xs font-medium">Folders</span>
                                      </div>-->
                  </div>

                  <!-- <div v-if="isFoldersInFoldersLoaded">
                                  <p class="text-xs">{{ t('playables.folders') }} {{ playable.folderText }}</p>
                                </div>
                                <Loader v-else class="w-4 h-4 mr-2 text-our-cyan-40"></Loader>

                                <div v-if="isFilesInFilesLoaded">
                                  <p class="text-xs">{{ t('playables.files') }} {{ playable.fileText }}</p>
                                </div>-->
                </div>

                <!-- ease-linear transition-all duration-150 border border-our-gray-210 bg-our-gray-20 rounded-xs group r-our-cyan-40 focus:outline-none -->

                <!--            <div class="relative flex flex-col w-full h-full select-none bg-light-level-3">

                              <div
                                  class="absolute top-0 z-10 w-full h-full transition-all duration-150 ease-linear bg-black bg-opacity-0 group-hover:bg-opacity-50"></div>

                              <div class="flex w-full ml-2 mr-2 justify-end flex-col mb-12 text-sm relative">
                                <div class="absolute right-0 pt-2 w-full h-full grid grid-cols-3 grid-flow gap-2"
                                     v-if="playable.playables?.length && isFilesInFilesLoaded">
                                  <div v-for="(innerPlayable, index) in [...playable.playables].reverse().slice(0,6)"
                                       :key="innerPlayable.id" class="">
                                    <template v-if="index === 0"></template>
                                    <template v-else>
                                      <div class="border aspect-[16/9] display-placeholder">
                                        <img v-if="innerPlayable.media_thumb !== null"
                                             :src="$axios.defaults.baseURL + innerPlayable.media_thumb.formats.thumbnail.url.substring(1)"
                                             class="object-contain">
                                        <img v-else-if="innerPlayable.type === 'web'"
                                             :src="this.$axios.defaults.baseURL + 'screenshots/' + innerPlayable.id + '.png'"
                                             class="object-contain">
                                        <template v-else-if="innerPlayable.type === 'image'">
                                          <img v-if="innerPlayable.original.formats.thumbnail !== null"
                                               :src="$axios.defaults.baseURL + innerPlayable.original.formats.thumbnail.url.substring(1)"
                                               class="object-contain">
                                          <img v-else :src="$axios.defaults.baseURL + innerPlayable.original.url.substring(1)"
                                               class="object-contain">
                                        </template>
                                      </div>
                                    </template>
                                  </div>
                                </div>

                                <div v-if="isFoldersInFoldersLoaded">
                                  <p class="text-xs">{{ t('playables.folders') }} {{ playable.folderText }}</p>
                                </div>
                                <Loader v-else class="w-4 h-4 mr-2 text-our-cyan-40"></Loader>

                                <div v-if="isFilesInFilesLoaded">
                                  <p class="text-xs">{{ t('playables.files') }} {{ playable.fileText }}</p>
                                </div>

                                <Loader v-else class="w-4 h-4 mr-2 text-our-cyan-40"></Loader>
                              </div>

                            </div>-->

                <div class="flex flex-row items-center px-2 py-1 bg-light-level-4">
                  <span class="text-sm font-medium leading-6 truncate text-white">{{ folder.name }}</span>
                  <Tooltip :text="folder.name" color="our-gray-30" position="bottom" background="our-gray-170"
                           classes="text-sm px-2 py-1 font-medium text-center" class="z-40"></Tooltip>
                </div>
              </div>
            </div>
        </transition-group>

        <PlayableCard v-for="playable in playablesListFiltered" class="w-1/4 p-3" :key="playable.id"
                      :playable="playable" :img="
        playable?.playablescreenshots[0]?.image ? $axios.defaults.baseURL + (playable.playablescreenshots[0].image.formats.thumbnail?.url)?.substring(1) : require('@/assets/display-placeholder.webp')"
                      :name="playable.name" :type="playable.type" :id="playable.id"></PlayableCard>
        <!--</transition-group-->
      </div>
      <template v-else>
        <Loader class="w-4 h-4 mx-auto mb-16 realtive top-12"/>
      </template>
      <!--<PlayableCard img="display-placeholder-2.webp" name="Video file" type="video" :id="2"></PlayableCard>-->

      <div
          class="flex flex-col items-center self-center gap-y-4 text-sm">
        <div class="flex flex-col items-center gap-y-2 font-medium text-our-gray-190">
          <span>
            {{ playablesListFiltered.length }} {{ t('playables.outOf') }}
            {{ $store.state.Playables.playablesCount }} {{ t('playables.outOfFiles') }}
          </span>
          <div class="w-40 overflow-hidden rounded-full bg-our-gray-70" style="height: 5px">
            <div class="h-full transition-all duration-1000 ease-out bg-our-cyan-50"
                 :style="`width: ${ (playablesListFiltered.length / $store.state.Playables.playablesCount) * 100}%`"></div>
          </div>
        </div>
        <transition name="fade">
          <button
              :class="{ 'cursor-not-allowed opacity-50' : ((playablesList.length === $store.state.Playables.playablesCount) && !loadingPlayables)}"
              :disabled="((playablesList.length === $store.state.Playables.playablesCount) && !loadingPlayables)"
              v-on:click="limit += increaseLimitBy, loadingPlayables = true" type="button"
              class="inline-flex items-center h-10 px-3 text-sm font-medium leading-4 tracking-wide transition-all duration-150 ease-linear border border-transparent rounded-sm outline-none bg-our-gray-50 text-our-gray-190 border-our-gray-160 hover:bg-our-gray-190 hover:text-white hover:border-our-gray-190 focus:outline-none focus:border-our-gray-220border-light-highlight-level-1 active:bg-our-gray-220 active:border-our-gray-220 active:text-white">
            <transition name="fade" mode="out-in">
              <svg v-if="!loadingPlayables" class="-ml-0.5 mr-2" xmlns="http://www.w3.org/2000/svg" width="24"
                   height="24" fill="currentColor" viewBox="0 0 24 24">
                <path
                    d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zM8.73 10.75H10a.75.75 0 010 1.5H7.25a.75.75 0 01-.75-.75V8.25a.75.75 0 011.5 0V9a4.991 4.991 0 014-2c1.537 0 2.904.66 3.827 1.77a.75.75 0 01-1.154.96C14.037 8.963 13.093 8.5 12 8.5a3.502 3.502 0 00-3.27 2.25zm7.27 5V15a5.013 5.013 0 01-7.821.237.75.75 0 111.142-.972 3.513 3.513 0 005.842-.765H14a.75.75 0 010-1.5h2.75a.75.75 0 01.75.75v3a.75.75 0 01-1.5 0z"/>
              </svg>
              <Loader v-else class="w-4 h-4 mr-2 text-our-cyan-40"></Loader>
            </transition>
            <span>{{ t('playables.loadMore') }}</span>
          </button>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import PlayableCard from '@/components/PlayableCard'
import { useI18n } from 'vue-i18n'
import Loader from '../components/Loader.vue'
import eventBus from '@/eventBus'

export default {
  name: 'Playables',
  components: {
    PlayableCard,
    Loader
  },
  setup() {
    return useI18n()
  },
  data() {
    return {
      video: {},
      fileLoaded: false,
      ffprobe: false,
      playablesCount: 0,
      playablesList: [],
      playableFolders: [ {
        id: null
      } ],
      ready: false,
      fulltextSearch: this.$route.query.search || null,
      filter: this.$route.query.filter || 'all',
      sort: this.$route.query.sort || 'created_at:DESC',
      limit: parseInt(this.$route.query.limit) || 50,
      parentid: parseInt(this.$route.query.folder) || null,
      increaseLimitBy: 50,
      loadingPlayables: false,
      arr_folderUrl: [],
      dropdownPopoverMenuShow: false,
      toggleSearchActive: this.$route.query.search_folder || true,
      isFoldersInFoldersLoaded: false,
      isFilesInFilesLoaded: false,
    }
  },
  computed: {
    limitAndFilterBy() {
      return `${this.limit}|${this.filter}`
    },
    playablesListFiltered() {
      if (this.filter === 'all') {
        //return this.playablesList.filter(i => i.folder?.id == this.parentid);
        return this.playablesList

      }
      else {
        return this.playablesList.filter(playable => playable.type === this.filter)
      }
    },
    viableFolder() {
      return this.playableFolders.filter(i => i.parentid?.id == this.parentid)
    },
    searchPlaceholder() {
      return this.toggleSearchActive ? this.t('playables.form.searchAllMediaFiles') : this.t('playables.form.searchMediaFilesInFolder')
    }
  },
  watch: {
    '$store.state.Playables.playableFolderList': {
      immediate: false,
      deep: true,
      handler(data) {

        this.playableFolders = data
        this.ready = true

      }
    },
    /*fulltextSearch: {
      immediate: true,
      handler() {
        console.log('fulltextSearch')
        this.fulltextSearchWatch()
      }
    },*/
    limit: {
      immediate: true,
      handler() {
        this.limitWatch()
      }
    },
    sort: {
      immediate: false,
      handler() {

        this.ready = false

        let filters = {
          _sort: this.sort,
          _start: 0,
          name_contains: this.fulltextSearch
        }

        if (!(this.fulltextSearch === null || this.fulltextSearch === '')) {
          filters._limit = -1
          if (!this.toggleSearchActive) {
            this.parentid != null ? filters.folder = this.parentid : filters.folder_null = true
          }
        }
        else {
          this.parentid != null ? filters.folder = this.parentid : filters.folder_null = true
          filters._limit = this.limit
        }

        this.$api.playables.getSomePlayables(filters).then(response => {
          this.playablesList = response.data
        })

        store.dispatch('Playables/fetchAllPlayableFolder').then(res => {

          this.playableFolders = res.data
          this.ready = true
          this.getFoldersInFoldersCount()
          this.getFilesInFolderCount()

        }).catch(err => {
          console.error(err)
        })

        this.changeUrlQuery()
      }
    },
    filter: {
      immediate: false,
      handler() {

        console.log('filter')

        this.ready = false
        let folderFilter = {
          _sort: this.sort,
          _start: 0
        }

        if (this.filter !== 'all') {
          this.parentid != null ? folderFilter.folder = this.parentid : folderFilter.folder_null = true
          this.$api.playables.getSomePlayables(folderFilter).then(response => {
            this.playablesList = response.data
            this.limit = this.playablesList.length
          })
        }

        this.fulltextSearch = null

        /*this.$api.playables.getSomePlayables({
          type_eq: this.filter,
          _sort: this.sort,
          _start: 0,
          _limit: this.limit
        }).then(response => {
          this.playablesList = response.data
        })*/

        //getAllPlayableFolder();

        store.dispatch('Playables/fetchAllPlayableFolder').then(res => {

          this.playableFolders = res.data
          this.ready = true
          this.getFoldersInFoldersCount()
          this.getFilesInFolderCount()
        }).catch(err => {
          console.error(err)
        })

        this.changeUrlQuery()
      }
    }
  },
  methods: {
    getSlicedFolder(folder){
      let newObj = JSON.parse(JSON.stringify(folder.playables))
      return newObj.reverse().slice(0,3)
    },
    changeUrlQuery() {
      this.$router.replace(
          this.fulltextSearch === null
              ? {
                query: {
                  sort: this.sort,
                  limit: this.limit,
                  filter: this.filter,
                  folder: this.parentid,
                  search_folder: this.toggleSearchActive
                }
              }
              : {
                query: {
                  sort: this.sort,
                  limit: this.limit,
                  filter: this.filter,
                  folder: this.parentid,
                  search_folder: this.toggleSearchActive,
                  search: this.fulltextSearch
                }
              })
    },
    selectFolder(folder) {

      this.arr_folderUrl.push({
        id: folder.id,
        name: folder.name,
      })

      this.parentid = folder.id

      this.limitWatch()
    },
    backSelectFolder() {

      this.arr_folderUrl.pop()

      if (Array.isArray(this.arr_folderUrl) && this.arr_folderUrl.length) {
        this.parentid = this.arr_folderUrl.slice(-1).pop().id
      }
      else {
        this.parentid = null
      }

      this.limitWatch()
    },
    async limitWatch() {

      this.ready = false
      let filters = {
        _sort: this.sort,
        _start: 0,
        name_contains: this.fulltextSearch
      }

      if (!(this.fulltextSearch === null || this.fulltextSearch === '')) {
        filters._limit = -1
        if (!this.toggleSearchActive) {
          this.parentid != null ? filters.folder = this.parentid : filters.folder_null = true
        }
      }
      else {
        this.parentid != null ? filters.folder = this.parentid : filters.folder_null = true
        filters._limit = this.limit
      }

      this.$api.playables.getSomePlayables(filters).then(response => {
        this.playablesList = response.data
      })

      await store.dispatch('Playables/fetchAllPlayableFolder').then(res => {
        this.playableFolders = res.data
        this.ready = true
      }).catch(err => {
        console.error(err)
      })

      this.changeUrlQuery()

      this.$store.dispatch('Playables/fetchFilteredPlayablesCount', filters).then(() => {
        setTimeout(() => {
          this.loadingPlayables = false
        }, 300)
      })

      this.getFoldersInFoldersCount()
      this.getFilesInFolderCount()
    },
    fulltextSearchWatch() {

      console.log('fulltextSearchWatch')

      let filters = {
        _sort: this.sort,
        _start: 0,
        name_contains: this.fulltextSearch
      }

      if (!(this.fulltextSearch === null || this.fulltextSearch === '')) {
        filters._limit = -1
        if (!this.toggleSearchActive) {
          this.parentid != null ? filters.folder = this.parentid : filters.folder_null = true
        }
      }
      else {
        this.parentid != null ? filters.folder = this.parentid : filters.folder_null = true
        filters._limit = this.limit
      }

      this.$api.playables.getSomePlayables(filters).then(response => {
        this.playablesList = response.data
      })

      this.changeUrlQuery()

      this.$store.dispatch('Playables/fetchFilteredPlayablesCount', filters).then(() => {
        setTimeout(() => {
          this.loadingPlayables = false
        }, 300)
      })

    },
    selectFolderById(folderId) {

      let isFolderFound = false
      let removeIndex = []

      this.arr_folderUrl.forEach((item, index) => {
        if (isFolderFound) {
          removeIndex.push(index)
        }
        if (folderId == item.id) {
          isFolderFound = true
        }
      })

      for (var i = removeIndex.length - 1; i >= 0; i--) {
        this.arr_folderUrl.splice(removeIndex[i], 1)
      }

      this.parentid = folderId
      this.limitWatch()
    },
    selectRootFolder() {
      this.arr_folderUrl = []
      this.parentid = null
      this.limitWatch()

    },
    toggleDropdownMenu(playableid) {

      console.log(this.$refs['dropdownEl' + playableid].length)

      if (this.$refs['dropdownEl' + playableid][0].classList.contains('block')) {
        this.$refs['dropdownEl' + playableid][0].classList.add('hidden')
        this.$refs['dropdownEl' + playableid][0].classList.remove('block')
      }
      else {
        this.$refs['dropdownEl' + playableid][0].classList.add('block')
        this.$refs['dropdownEl' + playableid][0].classList.remove('hidden')
      }
    },
    removePlayableFolder(playableFolderId, playableFolderName) {

      this.toggleDropdownMenu(playableFolderId)

      this.$store.dispatch('Modal/setModalData', {
        message1: this.t('global.modal.deleteFolder'),
        message2: this.t('global.modal.areYouSureDeleteFolder') + playableFolderName,
        variant: 'info',
        buttonAction: () => {
          this.$store.dispatch('Modal/toggleModal', false)

          this.$api.playables.deletePlayableFolder(playableFolderId).then(response => {

            if (response.status === 200) {
              new Promise((resolve) => {

                this.$store.dispatch('Notifications/send', {
                  variant: 'success',
                  message1: this.t('global.notification.successfullyDeleted'),
                  message2: this.t('global.menu.folder') +
                      `Folder <i>${response.data.name}</i> (id:<i>${response.data.id}</i>) ` +
                      this.t('global.notification.wasDeleted'),
                  time: new Date().toISOString(),
                  duration: 3000,
                  overlay: true
                })

                setTimeout(() => {
                  this.limitWatch()
                  resolve()
                }, 500)

              })
            }
          })
        }
      })
      this.$store.dispatch('Modal/toggleModal', true)
    },
    async getFoldersInFoldersCount() {

      this.isFoldersInFoldersLoaded = false
      let arr_id_playableFolders = [], foldersInFolders = [], count, playableFolders
      let playableFoldersFilter = this.playableFolders.filter(i => i.parentid?.id == this.parentid)
      arr_id_playableFolders = playableFoldersFilter.map(({ id }) => id)
      playableFolders = this.playableFolders

      await this.$api.playables.getPlayablesFolderByParentIdIn(arr_id_playableFolders).then(response => {
        foldersInFolders = response.data
      })

      for (let r_playableFolders of playableFolders) {
        count = 0
        for (const r_foldersInFolders of foldersInFolders) {
          if (r_foldersInFolders.parentid?.id === r_playableFolders.id) {
            count++
          }
        }
        r_playableFolders.folderText = count
      }
      this.isFoldersInFoldersLoaded = true
    },
    async getFilesInFolderCount() {

      this.isFilesInFilesLoaded = false
      let arr_id_playablesList = [], filesInFolders = [], playableFolders, count
      let playableFoldersFilter = this.playableFolders.filter(i => i.parentid?.id == this.parentid)
      arr_id_playablesList = playableFoldersFilter.map(({ id }) => id)
      playableFolders = this.playableFolders

      if ((Array.isArray(arr_id_playablesList) && arr_id_playablesList.length)) {

        await this.$api.playables.getPlayablesByParentIdIn(arr_id_playablesList).then(response => {
          filesInFolders = response.data
        })

        for (let r_playableFolders of playableFolders) {
          count = 0
          for (const r_filesInFolders of filesInFolders) {
            if (r_filesInFolders.folder?.id === r_playableFolders.id) {
              count++
            }
          }
          r_playableFolders.fileText = count
        }
        this.isFilesInFilesLoaded = true
      } else {
        return false
      }

    },
    /* old solution - async getFoldersInFolders() {

      const textMoreFolderInfoLenght = 20
      this.isFoldersInFoldersLoaded = false

      let arr_id_playableFolders = [], foldersInFolders = [], is_count, count, folderText, playableFolders
      let playableFoldersFilter = this.playableFolders.filter(i => i.parentid?.id == this.parentid)

      for (const r_playableFolders of playableFoldersFilter) {
        arr_id_playableFolders.push(r_playableFolders.id)
      }

      await this.$api.playables.getPlayablesFolderByParentIdIn(arr_id_playableFolders).then(response => {
        foldersInFolders = response.data
      })

      playableFolders = this.playableFolders

      for (let r_playableFolders of playableFolders) {

        folderText = ''
        is_count = false
        count = 0

        for (const r_foldersInFolders of foldersInFolders) {
          if (r_foldersInFolders.parentid?.id === r_playableFolders.id) {
            folderText += r_foldersInFolders.name + ', '
            is_count ? count++ : ''
            folderText.length > textMoreFolderInfoLenght ? is_count = true : ''
          }
        }

        if (folderText === '') { // no folders in folder
          folderText = this.t('playables.noFolders')
        }
        else {
          if (count > 1) {
            folderText = folderText.slice(0, textMoreFolderInfoLenght)
            folderText += '... ' + this.t('global.and') + ' ' + count + ' ' + this.t('global.more')
          }
          else {
            folderText = folderText.substring(0, folderText.length - 2)
          }
        }
        r_playableFolders.folderText = folderText
      }
      this.isFoldersInFoldersLoaded = true
    },*/
    /* old solution - async getFilesInFolder() {

      const textMoreFileInfoLenght = 22
      this.isFilesInFilesLoaded = false

      let arr_id_playablesList = [], filesInFolders = [], playableFolders, fileText, is_count, count
      let playableFoldersFilter = this.playableFolders.filter(i => i.parentid?.id == this.parentid)

      for (const r_playableFolders of playableFoldersFilter) {
        arr_id_playablesList.push(r_playableFolders.id)
      }

      if ((Array.isArray(arr_id_playablesList) && arr_id_playablesList.length)) {

        await this.$api.playables.getPlayablesByParentIdIn(arr_id_playablesList).then(response => {
          filesInFolders = response.data
        })

        playableFolders = this.playableFolders

        for (let r_playableFolders of playableFolders) {

          fileText = ''
          is_count = false
          count = 0

          for (const r_filesInFolders of filesInFolders) {
            if (r_filesInFolders.folder?.id === r_playableFolders.id) {
              fileText += r_filesInFolders.name + ', '
              is_count ? count++ : ''
              fileText.length > textMoreFileInfoLenght ? is_count = true : ''
            }
          }

          if (fileText === '') { // no files in folder
            fileText = this.t('playables.noFiles')
          }
          else {
            if (count > 1) {
              fileText = fileText.slice(0, textMoreFileInfoLenght)
              fileText += '... ' + this.t('global.and') + ' ' + count + ' ' + this.t('global.more')
            }
            else {
              fileText = fileText.substring(0, fileText.length - 2)
            }
          }
          r_playableFolders.fileText = fileText
        }
        this.isFilesInFilesLoaded = true

      }
      else {
        return false
      }

    },*/
    /*handleFileChange(e) {
          console.log(e)
          this.video = e.target.files[0]
        },
    sendForm() {
      this.saveFiles().then(res => {
        //console.log('upload response', res)
        return axios({
          method: 'POST',
          url: '/playables',
          data: {
            /!*name: res.data[0].name,
            hash: res.data[0].url.slice(9), // removes "/uploads/" from filepath*!/
            name: res.data[0].url.slice(9),
            original: res.data[0].id
          }
        }).then(res => {
          this.fileLoaded = true;
          console.log(res)

          axios({
            method: 'GET',
            url: '/convert-files/' + res.data.name + '/' + res.data.id
          }).then(res => {
            this.ffprobe = true;
          }).catch(e => {
            this.ffprobe = e;
          })
        })
      })
    },
    saveFiles() {
      let data = new FormData()
      data.append('files', this.video)
      return axios({
        method: 'POST',
        url: '/upload',
        data
      })
    },
    test() {
      return axios({
        method: 'GET',
        url: '/playables'
      })
    }*/
  },
  updated() {
    this.changeUrlQuery()
  },
  async created() {


    // pokud neni parent id a v url je folder, nutno dopocitat predchozi slozky
    if (this.parentid != null && !(Array.isArray(this.arr_folderUrl) && this.arr_folderUrl.length)) {

      let doWhileRun = true, firstIterate = true
      let query, lastParentid

      do {

        (firstIterate) ? query = this.parentid : query = lastParentid
        await this.$api.playables.getPlayablesFolder(query).then(response => {

          if (firstIterate) {
            this.arr_folderUrl.push({
              id: response.data.id,
              name: response.data.name
            })
            firstIterate = false
          }

          if (response.data.parentid) {
            this.arr_folderUrl.unshift({
              id: response.data.parentid.id,
              name: response.data.parentid.name
            })
            lastParentid = response.data.parentid.id
          }
          else {
            doWhileRun = false
          }
        })
      } while (doWhileRun)
    }

  },
  mounted() {
    eventBus.on('reload-content', () => {
      this.limitWatch()
    })
  },
  beforeUnmount() {
    eventBus.off('add-playable-cancel')
  }
}
</script>

<style scoped>
.display-placeholder {
  @apply bg-repeat bg-light-level-2;
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='%23000000' fill-opacity='0.125' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
  /*background-image: url("data:image/svg+xml,%3Csvg width='64' height='64' viewBox='0 0 64 64' fill='%23000000' fill-opacity='0.25' fill-rule='evenodd' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 16c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm0-2c3.314 0 6-2.686 6-6s-2.686-6-6-6-6 2.686-6 6 2.686 6 6 6zm33.414-6l5.95-5.95L45.95.636 40 6.586 34.05.636 32.636 2.05 38.586 8l-5.95 5.95 1.414 1.414L40 9.414l5.95 5.95 1.414-1.414L41.414 8zM40 48c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm0-2c3.314 0 6-2.686 6-6s-2.686-6-6-6-6 2.686-6 6 2.686 6 6 6zM9.414 40l5.95-5.95-1.414-1.414L8 38.586l-5.95-5.95L.636 34.05 6.586 40l-5.95 5.95 1.414 1.414L8 41.414l5.95 5.95 1.414-1.414L9.414 40z' /%3E%3C/svg%3E");*/
}
</style>
