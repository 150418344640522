<template>
  <div class="flex flex-col gap-y-5">
    <div class="grid grid-cols-2 gap-5">
      <div class="flex flex-col relative gap-5">
        <!-- left-section-1 -->
        <div class="relative group">

          <transition name="fade" mode="out-in">
            <div class="relative w-full aspect-[16/9] rounded-level-2 overflow-hidden">
              <!--v-if="$store.state.Displays.uploading"-->
              <!-- <div class="absolute inset-0 backdrop-blur-sm z-10"></div>-->
              <img v-if="displayData.screen?.formats?.small" ref="image"
                   :src="$axios.defaults.baseURL + (displayData.screen.formats?.medium?.url ? displayData.screen.formats.medium.url : displayData.screen.formats.small.url).substring(1)"
                   :class="[(isDisplayActive || isDisplayActive === undefined) || 'grayscale opacity-75']"
                   class="w-full bg-our-gray-50 object-cover">
              <img v-if="fileResult !== null" ref="image"
                   :src="fileResult"
                   :class="[(isDisplayActive || isDisplayActive === undefined) || 'grayscale opacity-75']"
                   class="w-full bg-our-gray-50 object-cover">
              <div v-else class="display-placeholder w-full h-full"></div>
            </div>
          </transition>

          <div class="absolute top-0 right-0 z-20 flex flex-col gap-3.5 m-level-2">
            <!-- TODO: encapsulate classes? -->
            <div class="flex relative tooltip-wrapper">
              <component
                  :to="displayData.displaystatus !== 'offline' && { name: 'Display live', params: { locale: 'en', id: $route.params.id } }"
                  class="text-light-level-2 p-1.5 gap-x-2 text-sm font-medium rounded-level-4 bg-white bg-opacity-50 backdrop-blur-md"
                  :is="displayData.displaystatus === 'offline' ? 'span' : 'router-link'"
                  :class="displayData.displaystatus === 'offline' && 'cursor-not-allowed'">
                <template v-if="displayData.displaystatus === 'offline'">
                  <svg class="opacity-50" width="24" height="24" fill="currentColor"
                       viewBox="0 0 24 24"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M3.28 2.22a.75.75 0 1 0-1.06 1.06l2.202 2.203c-3.392 3.93-3.223 9.872.506 13.601a1 1 0 0 0 1.415-1.414A8.004 8.004 0 0 1 5.84 6.902l1.521 1.52a5.922 5.922 0 0 0 .533 7.763A1 1 0 0 0 9.31 14.77a3.922 3.922 0 0 1-.513-4.913l1.836 1.836a1.5 1.5 0 0 0 1.838 1.838l8.25 8.25a.75.75 0 0 0 1.06-1.061L3.28 2.22ZM19.028 15.846l1.461 1.462c2.414-3.861 1.943-9.012-1.415-12.37a1 1 0 1 0-1.414 1.415 8.006 8.006 0 0 1 1.368 9.493Z"/>
                    <path
                        d="m15.93 12.748 1.59 1.591a5.922 5.922 0 0 0-1.252-6.527 1 1 0 1 0-1.415 1.414 3.916 3.916 0 0 1 1.077 3.522Z"/>
                  </svg>
                </template>
                <template v-else>
                  <svg width="24" height="24" fill="currentColor"
                       viewBox="0 0 24 24"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.343 4.938a1 1 0 0 1 0 1.415 8.003 8.003 0 0 0 0 11.317 1 1 0 1 1-1.415 1.414c-3.906-3.906-3.906-10.24 0-14.146a1 1 0 0 1 1.415 0Zm12.731 0c3.906 3.907 3.906 10.24 0 14.146a1 1 0 0 1-1.414-1.414 8.003 8.003 0 0 0 0-11.317 1 1 0 0 1 1.414-1.415ZM9.31 7.812a1 1 0 0 1 0 1.414 3.92 3.92 0 0 0 0 5.544 1 1 0 1 1-1.414 1.414 5.92 5.92 0 0 1 0-8.372 1 1 0 0 1 1.414 0Zm6.959 0a5.92 5.92 0 0 1 0 8.372 1 1 0 0 1-1.415-1.414 3.92 3.92 0 0 0 0-5.544 1 1 0 0 1 1.415-1.414Zm-4.187 2.77a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z"/>
                  </svg>
                </template>
              </component>
              <Tooltip :text="t('display.liveDisplay')"
                       color="our-gray-30"
                       background="our-gray-170" arrow-background="our-gray-170" position="bottom"
                       class="p-1 text-sm font-medium whitespace-nowrap"
                       v-if="displayData.displaystatus !== 'offline'"></Tooltip>
            </div>
          </div>

          <div class="absolute bottom-0 right-0 z-20 flex flex-row gap-2 m-level-2">
            <input id="file-upload"
                   class="hidden"
                   type="file"
                   accept=".png,.jpg,.jpeg"
                   @change="handleFileChange($event)"/>
            <!-- TODO: encapsulate classes? -->
            <div v-if="displayData.screen"
                 class="flex relative tooltip-wrapper opacity-0 transition-opacity duration-100 ease-linear group-hover:opacity-100 pointer-events-none group-hover:pointer-events-auto">
              <label for="file-upload"
                     class="button m-0 text-light-level-2 p-1.5 gap-x-2 text-sm font-medium rounded-level-4 bg-white bg-opacity-50 backdrop-blur-md cursor-pointer">
                <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M20.998 6.25A3.25 3.25 0 0 0 17.748 3H6.25A3.25 3.25 0 0 0 3 6.25v11.499a3.25 3.25 0 0 0 3.25 3.25h4.914l.356-1.424.02-.076H6.25c-.204 0-.4-.035-.582-.1l5.807-5.685.083-.07a.75.75 0 0 1 .966.07l2.079 2.036 1.06-1.06-2.09-2.048-.128-.116a2.25 2.25 0 0 0-3.02.116l-5.822 5.7a1.746 1.746 0 0 1-.103-.593v-11.5c0-.966.783-1.75 1.75-1.75h11.499c.966 0 1.75.784 1.75 1.75v4.983c.478-.19.993-.264 1.5-.22V6.25Zm-3.495 2.502a2.252 2.252 0 1 0-4.504 0 2.252 2.252 0 0 0 4.504 0Zm-3.004 0a.752.752 0 1 1 1.504 0 .752.752 0 0 1-1.504 0Zm4.6 3.917-5.902 5.901a2.685 2.685 0 0 0-.707 1.248l-.457 1.83c-.2.797.522 1.518 1.318 1.319l1.83-.458a2.685 2.685 0 0 0 1.248-.706L22.33 15.9a2.286 2.286 0 0 0-3.233-3.232Z"/>
                </svg>
              </label>
              <Tooltip :text="t('display.form.changeDisplayImage')"
                       color="our-gray-30"
                       background="our-gray-170" position="bottom"
                       class="p-1 text-sm font-medium whitespace-nowrap"></Tooltip>
            </div>

            <div v-if="displayData.screen"
                 class="flex relative tooltip-wrapper opacity-0 transition-opacity duration-100 ease-linear group-hover:opacity-100 pointer-events-none group-hover:pointer-events-auto">
              <label for="display-image"
                     class="button m-0 text-light-level-2 p-1.5 gap-x-2 text-sm font-medium rounded-level-4 bg-white bg-opacity-50 backdrop-blur-md cursor-pointer">
                <svg v-on:click="removeImage()" width="24" height="24" fill="currentColor" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M12 1.75a3.25 3.25 0 0 1 3.245 3.066L15.25 5h5.25a.75.75 0 0 1 .102 1.493L20.5 6.5h-.796l-1.28 13.02a2.75 2.75 0 0 1-2.561 2.474l-.176.006H8.313a2.75 2.75 0 0 1-2.714-2.307l-.023-.174L4.295 6.5H3.5a.75.75 0 0 1-.743-.648L2.75 5.75a.75.75 0 0 1 .648-.743L3.5 5h5.25A3.25 3.25 0 0 1 12 1.75Zm6.197 4.75H5.802l1.267 12.872a1.25 1.25 0 0 0 1.117 1.122l.127.006h7.374c.6 0 1.109-.425 1.225-1.002l.02-.126L18.196 6.5ZM13.75 9.25a.75.75 0 0 1 .743.648L14.5 10v7a.75.75 0 0 1-1.493.102L13 17v-7a.75.75 0 0 1 .75-.75Zm-3.5 0a.75.75 0 0 1 .743.648L11 10v7a.75.75 0 0 1-1.493.102L9.5 17v-7a.75.75 0 0 1 .75-.75Zm1.75-6a1.75 1.75 0 0 0-1.744 1.606L10.25 5h3.5A1.75 1.75 0 0 0 12 3.25Z"/>
                </svg>
              </label>
              <Tooltip :text="t('display.form.removeDisplayImage')"
                       color="our-gray-30"
                       background="our-gray-170" position="bottom"
                       class="p-1 text-sm font-medium whitespace-nowrap"></Tooltip>
            </div>

            <div v-if="displayData.screen === null"
                 class="flex relative tooltip-wrapper opacity-0 transition-opacity duration-100 ease-linear group-hover:opacity-100 pointer-events-none group-hover:pointer-events-auto">
              <label for="file-upload"
                     class="button m-0 text-light-level-2 p-1.5 gap-x-2 text-sm font-medium rounded-level-4 bg-white bg-opacity-50 backdrop-blur-md cursor-pointer">
                <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M18.75 4A3.25 3.25 0 0 1 22 7.25v11.5A3.25 3.25 0 0 1 18.75 22H7.25A3.25 3.25 0 0 1 4 18.75v-6.248c.474.198.977.34 1.5.422v5.826c0 .208.036.408.103.594l5.823-5.701a2.25 2.25 0 0 1 3.02-.116l.128.116 5.822 5.702c.067-.186.104-.386.104-.595V7.25a1.75 1.75 0 0 0-1.75-1.75h-5.826a6.457 6.457 0 0 0-.422-1.5h6.248Zm-6.191 10.644-.084.07-5.807 5.687c.182.064.378.099.582.099h11.5c.203 0 .399-.035.58-.099l-5.805-5.686a.75.75 0 0 0-.966-.071ZM16.252 7.5a2.252 2.252 0 1 1 0 4.504 2.252 2.252 0 0 1 0-4.504ZM6.5 1a5.5 5.5 0 1 1 0 11 5.5 5.5 0 0 1 0-11Zm9.752 8a.752.752 0 1 0 0 1.504.752.752 0 0 0 0-1.504ZM6.5 3l-.09.007a.5.5 0 0 0-.402.402L6 3.5V6L3.498 6l-.09.008a.5.5 0 0 0-.402.402l-.008.09.008.09a.5.5 0 0 0 .402.402l.09.008H6v2.503l.008.09a.5.5 0 0 0 .402.402l.09.009.09-.009a.5.5 0 0 0 .402-.402l.008-.09V7L9.505 7l.09-.008a.5.5 0 0 0 .402-.402l.008-.09-.008-.09a.5.5 0 0 0-.403-.402L9.504 6H7V3.5l-.008-.09a.5.5 0 0 0-.402-.403L6.5 3Z"/>
                </svg>
              </label>
              <Tooltip :text="t('display.form.addDisplayImage')"
                       color="our-gray-30"
                       background="our-gray-170" position="bottom"
                       class="p-1 text-sm font-medium whitespace-nowrap"></Tooltip>
            </div>

            <div v-if="$store.state.Displays.uploading"
                 class="flex items-center justify-center button m-0 text-light-highlight-level-0 px-3 gap-x-3 text-sm font-medium rounded-level-4 bg-white bg-opacity-50 backdrop-blur-md cursor-pointer">
              <div
                  class="flex-shrink-0 text-xs tracking-spaced font-semibold whitespace-nowrap">
                {{ t('playables.form.uploadInProgress') }}
              </div>
              <div class="w-32 overflow-hidden rounded-full bg-light-level-0 h-[0.3rem]">
                <div class="h-full transition-all duration-1000 ease-out bg-light-highlight-level-1"
                     :style="`width: ${$store.state.Displays.uploadProgress}%`"></div>
              </div>
              <span class="text-xxs tracking-spaced font-semibold">{{ $store.state.Displays.uploadProgress }}%</span>
            </div>

          </div>

          <template v-if="displayData.devices !== null">
            <div class="absolute top-0 left-0 z-20 flex flex-col items-start gap-3.5 m-level-2">
              <transition-group name="fade" mode="out-in">
                <template v-if="displayData.devices.length > 0">

                  <div v-if="isDisplayActive"
                       class="inline-flex flex-row items-center px-2.5 py-1.5 gap-x-2 text-sm font-medium rounded-level-4 bg-white bg-opacity-50 backdrop-blur-md">
                    <div class="border-3 border-white w-3 h-3 rounded-full bg-online"></div>
                    <span
                        class="ml-2 text-light-level-2 text-xs uppercase font-bold tracking-wider select-none">
                          {{ t('display.displayState.displayActive') }}
                    </span>
                  </div>

                  <div v-else
                       class="inline-flex flex-row items-center px-2.5 py-1.5 gap-x-2 text-sm font-medium rounded-level-4 bg-white bg-opacity-50 backdrop-blur-md">
                    <div class="w-3 h-3 rounded-full bg-light-level-2"></div>
                    <span
                        class="ml-2 text-light-level-2 !text-opacity-75 text-xs uppercase font-bold tracking-wider select-none">
                          {{ t('display.displayState.displayInactive') }}
                    </span>
                  </div>

                  <div v-if="eventsDataPlayablesLoaded"
                       class="inline-flex flex-row items-center px-2.5 py-1.5 gap-x-2 text-sm font-medium rounded-level-4 bg-white bg-opacity-75 backdrop-blur-md">
                    <div class="relative overflow-visible w-3 h-3">
                      <div class="border-3 border-white w-full h-full rounded-full bg-light-highlight-level-1">
                      </div>
                    </div>
                    <span
                        class="ml-2 text-light-level-2 text-xs uppercase font-bold tracking-wider select-none">
                          {{ t('display.displayState.playlistLive') }}
                    </span>
                  </div>

                </template>
                <div v-else
                     class="inline-flex flex-row items-center px-2.5 py-1.5 gap-x-2 text-sm font-medium rounded-level-4 bg-white bg-opacity-50 backdrop-blur-md">
                  <div class="border-[0.2rem] border-white w-3 h-3 rounded-full bg-offline"></div>
                  <span
                      class="ml-3 text-light-level-2 text-xs uppercase font-mono font-bold tracking-widest select-none">
                  {{ t('display.screen.unpaired') }}
                </span>
                </div>
              </transition-group>
            </div>
          </template>
        </div>
        <!-- left-section-2 -->
        <transition name="fade" mode="out-in">

          <div v-if="displayData.devices === null"
               class="flex items-center justify-center mt-5 bg-light-level-2 w-full h-48 rounded-level-3">
            <LoaderVertical :level="2"></LoaderVertical>
          </div>

          <div v-else class="flex-grow p-5 border border-light-level-1 rounded-level-3">

            <!-- TODO: for one device show all info, for more devices have an expandable list or similar -->

            <transition name="fade" mode="out-in">

              <div v-if="displayData.devices.length > 0" class="flex flex-col gap-y-5">
                <h4 class="text-lg text-light-level-1 pb-3">{{ t('display.devices.title') + ':' }}</h4>
                <span v-for="dev in displayData.devices" :key="dev.id" class="text-black">
                  <router-link :to="{ name: 'Device detail', params: { id: dev.id } }"
                               class="text-base font-medium leading-5 border-b border-our-cyan-50 text-our-cyan-60">
                    {{ dev.manufacturer }}, {{ dev.productName }}, {{ dev.mac }}
                  </router-link>
                </span>

                <div>
                  <legend>{{ t('display.technicalInfo.publicip') }}</legend>
                  <label v-if="displayData.devices.length">
                    <template v-if="displayData.devices[0].publicip !== null">
                      {{ displayData.devices[0].publicip }}
                    </template>
                    <template v-else>{{ t('display.technicalInfo.noDataAvailable') }}</template>
                  </label>
                  <label v-else>{{ t('display.technicalInfo.noDataAvailable') }}</label>
                </div>

                <div>
                  <legend>{{ t('display.technicalInfo.localip') }}</legend>
                  <label v-if="displayData.devices.length">
                    <template v-if="displayData.devices[0].localip !== null">
                      {{ displayData.devices[0].localip }}
                    </template>
                    <template v-else>{{ t('display.technicalInfo.noDataAvailable') }}</template>
                  </label>
                  <label v-else>{{ t('display.technicalInfo.noDataAvailable') }}</label>
                </div>

                <div>
                  <legend>{{ t('display.technicalInfo.macaddress') }}</legend>
                  <label v-if="displayData.devices.length">
                    <template v-if="displayData.devices[0].mac !== null">
                      {{ displayData.devices[0].mac }}
                    </template>
                    <template v-else>{{ t('display.technicalInfo.noDataAvailable') }}</template>
                  </label>
                  <label v-else>{{ t('display.technicalInfo.noDataAvailable') }}</label>
                </div>

                <div>
                  <legend>{{ t('display.technicalInfo.currentresolution') }}</legend>
                  <label v-if="displayData.devices.length">
                    <template v-if="displayData.devices[0].currentresolution !== null">
                      {{ displayData.devices[0].currentresolution }}
                    </template>
                    <template v-else>{{ t('display.technicalInfo.noDataAvailable') }}</template>
                  </label>
                  <label v-else>{{ t('display.technicalInfo.noDataAvailable') }}</label>
                </div>

                <div>
                  <legend>{{ t('display.technicalInfo.devicename') }}</legend>
                  <label v-if="displayData.devices.length">
                    <template v-if="displayData.devices[0].productName !== null">
                      {{ displayData.devices[0].productName }}
                    </template>
                    <template v-else>{{ t('display.technicalInfo.noDataAvailable') }}</template>
                  </label>
                  <label v-else>{{ t('display.technicalInfo.noDataAvailable') }}</label>
                </div>

                <div>
                  <legend>{{ t('display.technicalInfo.timefromstart') }}</legend>
                  <label v-if="displayData.devices.length">
                    <template v-if="displayData.devices[0].timefromstart !== null">
                      {{ $moment(new Date().setSeconds(-displayData.devices[0].timefromstart)).fromNow() }}
                    </template>
                    <template v-else>{{ t('display.technicalInfo.noDataAvailable') }}</template>
                  </label>
                  <label v-else>{{ t('display.technicalInfo.noDataAvailable') }}</label>
                </div>

                <template>
                  <div>
                    <legend>{{ t('display.technicalInfo.ram') }}</legend>
                    <label>TODO chart</label>
                  </div>

                  <div>
                    <legend>{{ t('display.technicalInfo.cpu') }}</legend>
                    <label>TODO chart</label>
                  </div>

                  <div>
                    <legend>{{ t('display.technicalInfo.gpu') }}</legend>
                    <label>TODO chart</label>
                  </div>
                </template>

              </div>

              <div v-else>
                <h4>{{ t('display.devices.howToTitle') }}</h4>
                <ol>
                  <li v-html="t('display.devices.howToOne')"></li>
                  <li v-html="t('display.devices.howToTwo')"></li>
                  <li v-html="t('display.devices.howToThree')"></li>
                  <li v-html="t('display.devices.howToFour')"></li>
                </ol>
                <div class="grid grid-cols-2 gap-4">
                  <div class="mt-8">
                    <label for="id">
                      {{ t('display.devices.id') }}
                    </label>
                    <div class="mt-2 justify-start">
                      <input :placeholder="t('display.devices.randomlyGeneratedPhrase')" id="connectDisplay"
                             v-model="connectString"
                             type="text" required class="form-input w-full mr-2 mb-2">
                      <button @click="connectDisplay" class="button button-outline-gray" :disabled="!connectString">
                        {{ t('display.devices.connectDisplay') }}
                        <Loader class="w-4 h-4 text-our-cyan-60 mx-auto ml-2"
                                v-if="deviceConnectLoading"></Loader>
                      </button>
                    </div>
                  </div>
                  <div class="">
                    <img :src="require('@/assets/search.svg')" alt="Connect procedure logo" class="pt-12 px-12 pb-8">
                  </div>
                </div>
                <a class="text-base font-medium leading-5 border-b border-our-cyan-50 text-our-cyan-60" target="_blank"
                   href="https://www.bohemicasignage.com/faq">{{ t('display.devices.havingTroubles') }}</a>
              </div>
            </transition>
          </div>
        </transition>
      </div>

      <transition name="fade" mode="out-in">
        <div class="flex flex-col gap-y-6">

          <div class="flex flex-col gap-y-5" v-if="trialVersionState === 1 || (trialVersionState === 2 && !cardDetail)">
            <div class="gap-5 p-5 border border-light-level-1 rounded-level-3">
              <p>
                <strong>{{ t('billing.trialVersion.trialVersion') }} - {{
                    t('billing.trialVersion.yourAccountNowFree')
                  }}</strong>
                {{ t('billing.trialVersion.youCanUseOneDisplay') }}
              </p>
              <p>{{ t('billing.activate.alertNecessaryFillInfo') }} </p>
            </div>
          </div>

          <div class="flex flex-col gap-y-5" v-if="trialVersionState === 2 && cardDetail">
            <div class="gap-5 p-5 border border-light-level-1 rounded-level-3">
              <p><strong>{{ t('billing.trialVersion.trialVersion') }} -
                {{ t('billing.trialVersion.yourAccountNowFree') }}</strong></p>
              <p>{{ t('billing.activate.alertYouCanStart') }}
                <a class="text-base font-medium leading-5 border-b border-our-cyan-50 text-our-cyan-60"
                   target="_blank" href="https://www.bohemicasignage.com/faq">
                  {{ t('billing.activate.alertHavingTroubles') }}
                </a>
              </p>
            </div>
          </div>

          <div class="flex flex-col gap-y-5" v-if="trialVersionState === 3 && !displayData.active">
            <div class="gap-5 p-5 border border-light-level-1 rounded-level-3">
              <p><strong> {{ t('billing.activate.alertDisplayIsNotActive') }}</strong></p>
              <p>{{ t('billing.activate.alertMustActivateItToUseIt') }}</p>
            </div>
          </div>

          <form v-if="displayData.id !== null" ref="form"
                class="flex flex-col gap-y-5 p-5 border border-light-level-1 rounded-level-3">
            <h4 class="text-lg font-normal text-light-level-1 pb-3">Display:</h4>
            <div class="w-full flex flex-row justify-between gap-x-4">
              <div class="flex flex-row items-center gap-x-3">
                <label for="name" class="m-0">
                  {{ t('display.form.databaseId') }}
                </label>
                <span
                    class="py-1 px-2 bg-light-level-2 text-light-level-2 rounded-level-4 tracking-spaced text-xs font-semibold"
                    v-text="displayData.id"></span>
              </div>
            </div>
            <div>
              <label for="name">
                {{ t('display.form.name') }}
              </label>
              <div class="mt-1.5">
                <input id="name" v-model="displayData.name" required type="text" class="input-light-level-1">
              </div>
            </div>
            <div>
              <label for="description">
                {{ t('display.form.description') }}
              </label>
              <div class="mt-1.5">
                <textarea id="description" v-model="displayData.description"
                          :placeholder="t('display.form.descriptionPlaceholder')" rows="4" class="input-light-level-1">
                </textarea>
              </div>
            </div>
            <div>
              <label for="groups">{{ t('display.form.groups') }}</label>
              <!-- TODO: pass in ID -->
              <Multiselect :placeholder="t('display.form.multiselectPlaceholderMultiple')"
                           :items="displayGroupsSelected"
                           ref="multiselectGroups"
                           classes="mt-1.5"></Multiselect>
            </div>
            <div>
              <label for="locations">{{ t('display.form.locations') }}</label>
              <select v-model="displayLocationSelected" class="w-auto form-select h-10 mt-1.5">
                <option value="0">{{ t('display.form.multiselectPlaceholderSelect') }}</option>
                <option v-for="location in displayLocations" :value="location.id" :key="location.id">
                  {{ location.name }}
                </option>
              </select>
            </div>
            <div>
              <legend>{{ t('display.form.resolution') }}</legend>

              <div class="flex flex-col mt-3 gap-y-2">
                <div class="flex items-center">
                  <input id="resolution-p1080" name="resolution-p1080" value="p1080" v-model="displayData.resolution"
                         type="radio" class="form-radio">
                  <label for="resolution-p1080">
                    <span>{{ t('display.form.1080p') }}</span>
                  </label>
                </div>
                <div class="flex items-center">
                  <input id="resolution-p2160" name="resolution-p2160" value="p2160" v-model="displayData.resolution"
                         type="radio" class="form-radio">
                  <label for="resolution-p2160">
                    <span>{{ t('display.form.2160p') }}</span>
                  </label>
                </div>
                <div class="flex items-center">
                  <input id="resolution-system_default" name="resolution-system_default" value="system_default"
                         v-model="displayData.resolution"
                         type="radio" class="form-radio">
                  <label for="resolution-system_default">
                    <span>{{ t('display.form.systemDefault') }}</span>
                  </label>
                </div>
              </div>
            </div>
            <div>
              <legend>{{ t('display.form.orientation') }}</legend>

              <div class="flex flex-col mt-3 gap-y-2">
                <div class="flex items-center">
                  <input id="orientation-horizontal" name="orientation-horizontal" value="horizontal"
                         v-model="displayData.orientation" type="radio" class="form-radio">
                  <label for="orientation-horizontal">
                    <span>{{ t('display.form.orientationHorizontal') }}</span>
                  </label>
                </div>
                <div class="flex items-center">
                  <input id="orientation-vertical_left" name="orientation-vertical_left" value="vertical_left"
                         v-model="displayData.orientation" type="radio" class="form-radio">
                  <label for="orientation-vertical_left">
                    <span>{{ t('display.form.orientationVerticalLeft') }}</span>
                  </label>
                </div>
                <div class="flex items-center">
                  <input id="orientation-vertical_right" name="orientation-vertical_right" value="vertical_right"
                         v-model="displayData.orientation" type="radio" class="form-radio">
                  <label for="orientation-vertical_right">
                    <span>{{ t('display.form.orientationVerticalRight') }}</span>
                  </label>
                </div>
                <div class="flex items-center">
                  <input id="orientation-upside_down" name="orientation-upside_down" value="upside_down"
                         v-model="displayData.orientation" type="radio" class="form-radio">
                  <label for="orientation-upside_down">
                    <span>{{ t('display.form.orientationUpsideDown') }}</span>
                  </label>
                </div>
              </div>
            </div>

            <!-- old display image/screen upload functionality
            <div>
              <label>
                {{ t('display.form.upload') }}
              </label>
              <div class="mt-2 flex" @dragover.prevent @drop.prevent>

                <label
                    :class="{
                  'w-full': displayData.screen === null,
                  'w-1/2': displayData.screen !== null,
                      }"
                    class="relative block py-8 transition-all duration-100 ease-linear border-2 border-dashed rounded-md cursor-pointer border-our-gray-100 hover:border-our-cyan-60">
                  <input id="file-upload"
                         class="absolute inset-0 invisible w-full focus:outline-none active:outline-none"
                         type="file" accept=".png,.jpg,.jpeg"
                         @change="handleFileChange($event)"/>
                  <transition name="fade" mode="out-in" @drop="handleDragFile">
                    <div class="gap-y-1 text-center cursor-pointer" v-if="displayData.screen === null">
                      <svg class="w-12 h-12 mx-auto text-our-gray-160" stroke="currentColor" fill="none"
                           viewBox="0 0 48 48"
                           aria-hidden="true">
                        <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                      <div class="text-sm text-our-gray-160">
                        <span class="font-medium">
                          {{ t('display.form.upload') }}
                        </span>
                        {{ t('playables.form.orDragAndDrop') }}
                      </div>
                    </div>
                    <div v-else class="flex flex-col items-center justify-center gap-y-1 text-center">
                      <p class="font-medium text-our-gray-160 break-words">{{
                          shorten(displayData.screen.name, 26)
                        }}</p>
                      <div class="relative w-full pt-5 tooltip-wrapper" v-if="$store.state.Displays.uploading">
                        <div
                            class="flex flex-row items-center px-4 py-2 mx-8 gap-x-4 border rounded-sm border-our-gray-80">
                          <div
                              class="flex-shrink-0 text-sm font-medium leading-5 tracking-wide whitespace-nowrap text-our-cyan-70">
                            {{ t('playables.form.uploadInProgress') }}
                          </div>
                          <div class="w-full overflow-hidden rounded-full bg-our-gray-70" style="height: 5px">
                            <div class="h-full transition-all duration-1000 ease-out bg-our-cyan-50"
                                 :style="`width: ${$store.state.Displays.uploadProgress}%`"></div>
                          </div>
                        </div>
                        <Tooltip
                            :text="$store.state.Displays.fileUploadData.name + ' - ' + $store.state.Displays.uploadProgress + '%'"
                            color="white text-sm py-1" background="our-gray-170" width="w-96"
                            position="bottom"></Tooltip>
                      </div>
                    </div>
                  </transition>
                </label>
              </div>
              <div v-if="displayData.screen" class="mt-3">
                <button v-on:click="removeImage()" type="button"
                        class="px-2.5 py-1.5 text-xs rounded-sm button button-outline-gray">
                  <span>{{ t('display.form.removeImage') }}</span>
                </button>
              </div>
            </div>-->

          </form>
          <div v-else class="gap-y-5 p-5 border border-light-level-1 rounded-level-3">
            <div class="w-full aspect-[16/9] bg-our-gray-60 rounded-md">
              <Loader class="w-4 h-4 mx-auto realtive top-32"/>
            </div>
          </div>
        </div>
      </transition>

    </div>

    <!-- Old tabs   -->
    <!--    <div class="sm:hidden">
          <label for="tabs" class="sr-only">{{ t('display.selectTab') }}</label>
          <select id="tabs" name="tabs"
                  class="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md">
            <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.namedisplayName }}</option>
          </select>
        </div>
        <div class="hidden sm:block">
          <div class="border-b border-gray-200">
            <nav class="-mb-px flex gap-x-8" aria-label="Tabs">
              <a v-for="tab in tabs" :key="tab.name" v-on:click="setActiveTabName(tab.name)"
                 :class="[displayContents(tab.name) ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm tab-link']"
                 :aria-current="displayContents(tab.name) ? 'page' : undefined">
                <component :is="tab.icon"
                           :class="[tab.current ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500', '-ml-0.5 mr-2 h-5 w-5']"
                           aria-hidden="true"/>
                <span :class="[displayContents(tab.name) ? 'font-bold' : '' ]">{{ tab.displayName }}</span>
              </a>
            </nav>
          </div>
        </div>-->


    <div class="flex flex-col gap-y-5 p-5 border border-light-level-1 bg-light-level-2 rounded-level-3">

      <h4 class="text-lg font-normal text-light-level-2 pb-3">Playlist(s):</h4>

      <template v-if="currentlyPlayingPlaylist[0]">
        <div class="flex flex-row justify-between">
          <!-- TODO: link should have hover state - remove underline -->
          <div class="relative flex flex-row items-center h-10 px-4 rounded-sm bg-our-gray-10 tooltip-wrapper">
                <span class="relative flex w-2 h-2 animate-pulse">
                                    <span class="relative inline-flex w-2 h-2 rounded-full bg-our-cyan-50"></span>
                                  </span>
            <router-link :to="{ name: 'Playlist detail', params: { id: currentlyPlayingPlaylist[0].playlist.id } }"
                         class="ml-3 text-base font-medium leading-5 border-b border-our-cyan-50 text-our-cyan-60">
              {{ currentlyPlayingPlaylist[0].playlist.name }}
            </router-link>
            <Tooltip :text="t('global.menu.playlist')" color="our-gray-30" background="our-gray-170" position="right"
                     classes="text-sm px-2 py-1 font-medium"></Tooltip>
            <!--<span class="flex flex-row items-center ml-3 gap-x-2 font-medium text-our-gray-160">
            <span class="text-sm font-semibold">
              in
            </span>
              <router-link to="/groups/detail/1" class="flex items-center justify-center text-sm px-1.5 h-8 transition-all duration-150 ease-linear border rounded-xs outline-none bg-our-gray-40 border-our-gray-40 focus:border-our-gray-180 focus:outline-none focus:bg-our-gray-180 focus:text-whiteborder-light-highlight-level-1 cursor-pointer text-our-gray-180 hover:text-our-gray-180 hover:border-our-gray-180 ">
                Group 1
              </router-link>
          </span>-->
          </div>

          <div class="relative tooltip-wrapper">
            <div
                class="flex flex-row gap-x-3.5 items-center text-light-level-2 p-level-2 border border-light-level-2 rounded-level-4 bg-light-level-2">
                  <span class="ml-3">{{
                      $moment(currentlyPlayingPlaylist[0].from).format('DD/MM/YYYY HH:mm')
                    }}</span>
              <div class="w-64 mx-5 overflow-hidden rounded-full bg-light-level-1 h-[0.3rem]"
                   style="content-visibility: auto">
                <div class="h-full transition-all duration-1000 ease-linear bg-light-highlight-level-1"
                     :style="`width: ${untilNextPlaylistPercentage}%`"></div>
              </div>
              <span class="mr-3">{{
                  $moment(currentlyPlayingPlaylist[0].to).format('DD/MM/YYYY HH:mm')
                }}</span>
            </div>
            <Tooltip position="bottom" :text="`${untilNextPlaylistTime} ` + t('display.leftOutOfTotal') + ` ${totalPlaylistTime}`"
                     color="our-gray-30"
                     background="our-gray-170" classes="text-sm px-2 py-1 font-medium"></Tooltip>
          </div>
        </div>
      </template>

      <div v-if="currentlyPlayingPlayable[0]">
        <legend class="mt-6 mb-2">{{ t('display.calendar.currentlyPlayingPlayable') }}</legend>
        <transition name="fade" mode="out-in">
          <router-link :to="{ name: 'Playable detail', params: { id: currentlyPlayingPlayable[0].playable.id } }">
            <div
                class="button flex flex-row gap-x-7 items-center justify-between p-level-2 px-3 py-6 mt-3 text-light-level-1 border border-light-highlight-level-1 rounded-level-4 bg-light-level-0">
              <div class="flex flex-row items-center">
                <!--<router-link to="/playlists/detail/1" class="ml-3 text-base font-medium leading-5 border-b border-our-cyan-50 text-our-cyan-60">-->
                <span>
                      {{ currentlyPlayingPlayable[0].playable.name }}
                    </span>
              </div>

              <div class="w-full overflow-hidden rounded-full bg-light-level-2 h-[0.35rem]"
                   style="content-visibility: auto">
                <div class="h-full transition-all duration-1000 ease-linear bg-light-highlight-level-1"
                     :style="`width: ${untilNextPlayablePercentage}%`"></div>
              </div>

              <div class="relative tooltip-wrapper">
                <div class="flex flex-row items-center">
                  <!--<span class="ml-3">dd/mm/yyyy 00:00:00</span>
                  <div class="w-64 mx-5 overflow-hidden rounded-full bg-our-gray-70" style="height: 5px">
                    <div class="w-1/2 h-full bg-our-cyan-50"></div>
                  </div>-->
                  <span>{{ t('display.calendar.finishesIn') }} in {{
                      untilNextPlayableSeconds
                    }}</span>
                </div>
                <Tooltip :text="'00:00:00 ' + t('display.outOf') + ' 00:00:00'" color="our-gray-30" background="our-gray-170" position="bottom"
                         classes="text-sm px-2 py-1 font-medium"></Tooltip>
              </div>
            </div>
          </router-link>
        </transition>
      </div>

      <transition name="fade" mode="out-in">
        <div v-if="upcomingPlayables.length === 0 && eventsDataPlayablesLoaded"
             class="flex flex-col p-2 gap-y-4 border rounded-sm border-our-gray-140">
          <div class="flex items-center justify-center flex-grow-0 h-12 text-our-gray-220">
            {{ t('display.calendar.noUpcomingPlayables') }}
          </div>
        </div>

        <div v-else>
          <legend class="mt-6 mb-2">{{ t('display.calendar.upcomingPlayables') }}</legend>
          <div class="p-2 border rounded-sm border-our-gray-140">
            <Table
                :tableHeads="tableHeadsPlaylistPlayables"
                :tableBody="upcomingPlayables"
                :usePagination="true"
                detailComponent="Playable detail"
                idPath="playable.id"
                :pagination="tableOptionsPlayables"
            >
              <!--detailComponent="Playable detail"-->
              <template #active><!--#active="data">
                    <div class="-mt-1">
                      <svg v-if="data.content == true" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                      </svg>
                      <svg v-if="data.content == false" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                      </svg>
                    </div>-->
                {{ t('display.calendar.active') }}
              </template>
              <template #playableName="data">
                {{ data.data.playable.name }}
              </template>
              <template #from="data">
                {{ $helpers.dateAndTime(data.data.from) }}
              </template>
              <template #to="data">
                {{ $helpers.dateAndTime(data.data.to) }}
              </template>
            </Table>
          </div>
        </div>
      </transition>

      <transition name="fade" mode="out-in">
        <div v-if="upcomingPlaylists.length === 0 && eventsDataPlaylistsLoaded"
             class="flex flex-col p-2 gap-y-4 border rounded-sm border-our-gray-140">
          <div class="flex items-center justify-center flex-grow-0 h-12 text-our-gray-220">
            {{ t('display.calendar.noUpcomingPlayables24') }}
          </div>
        </div>

        <div v-else>
          <legend class="mt-6 mb-2">{{ t('display.calendar.upcomingPlaylists') }}</legend>
          <div class="p-2 border rounded-sm border-our-gray-140">

            <!--<div class="flex flex-row items-center justify-between h-10 p-1 rounded-sm bg-our-gray-10">
                        <div class="flex flex-row items-center">
                          <router-link to="/playlists/detail/1" class="ml-3 text-base font-medium leading-5 border-b border-our-cyan-50 text-our-cyan-60">
                            Playlist 1
                          </router-link>
                          <span class="flex flex-row items-center ml-3 gap-x-2 font-medium">
                                    <span class="text-sm font-semibold">
                                      in
                                    </span>
                                    <router-link to="/groups/detail/1" class="flex items-center justify-center text-sm px-1.5 h-8 transition-all duration-150 ease-linear border rounded-md outline-none bg-our-gray-40 border-our-gray-40 focus:border-our-gray-180 focus:outline-none focus:bg-our-gray-180 focus:text-whiteborder-light-highlight-level-1 cursor-pointer text-our-gray-180 hover:text-our-gray-180 hover:border-our-gray-180 ">
                                      Group 1
                                    </router-link>
                                  </span>
                        </div>

                        <div class="relative tooltip-wrapper">
                          <div class="flex flex-row items-center">
                            <span class="ml-3 text-sm font-medium text-our-gray-140">dd/mm/yyyy 00:00:00</span>
                            <div class="w-64 mx-5 overflow-hidden rounded-full bg-our-gray-70" style="height: 5px">
                              <div class="w-1/2 h-full bg-our-cyan-50"></div>
                            </div>
                            <span class="mr-3 text-sm font-medium text-our-gray-140">dd/mm/yyyy 00:00:00</span>
                          </div>
                          <Tooltip text="00:00:00 out of 00:00:00" color="our-gray-30" background="our-gray-170" classes="text-sm px-2 py-1 font-medium"></Tooltip>
                        </div>
                      </div>-->

            <Table
                :tableHeads="tableHeadsPlaylists"
                :tableBody="upcomingPlaylists"
                :usePagination="true"
                :pagination="tableOptionsPlaylists"
                detailComponent="Playlist detail"
                idPath="playlist.id"
            >
              <template #playlistName="data">
                {{ data.data.playlist.name }}
              </template>
              <template #from="data">
                {{ $helpers.dateAndTime(data.data.from) }}
              </template>
              <template #to="data">
                {{ $helpers.dateAndTime(data.data.to) }}
              </template>
            </Table>

            <!--<div class="flex flex-col items-center self-center mt-6 gap-y-4 text-sm">
              <div class="flex flex-col items-center gap-y-2 font-medium text-our-gray-190">
                      <span>{{
                          t('resultCount', { current: eventsDataPlaylists.length, total: $store.state.Playables.playablesCount })
                            }}</span>
                <div class="w-40 overflow-hidden rounded-full bg-our-gray-70" style="height: 5px">
                  <div class="h-full transition-all duration-1000 ease-out bg-our-cyan-50" :style="`width: ${ (eventsDataPlaylists.length / $store.state.Playables.playablesCount) * 100}%`"></div>
                </div>
              </div>
              <transition name="fade">
                <button :class="{ 'cursor-not-allowed opacity-50' : ((eventsDataPlaylists.length === $store.state.Playables.playablesCount) && !loadingPlayables)}" :disabled="((eventsDataPlaylists.length === $store.state.Playables.playablesCount) && !loadingPlayables)" v-on:click="limit += increaseLimitBy, loadingPlayables = true" type="button" class="inline-flex items-center h-10 px-3 text-sm font-medium leading-4 tracking-wide transition-all duration-150 ease-linear border border-transparent rounded-sm outline-none bg-our-gray-50 text-our-gray-190 border-our-gray-160 hover:bg-our-gray-190 hover:text-white hover:border-our-gray-190 focus:outline-none focus:border-our-gray-220border-light-highlight-level-1 active:bg-our-gray-220 active:border-our-gray-220 active:text-white">
                  <transition name="fade" mode="out-in">
                    <svg v-if="!loadingPlayables" class="-ml-0.5 mr-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zM8.73 10.75H10a.75.75 0 010 1.5H7.25a.75.75 0 01-.75-.75V8.25a.75.75 0 011.5 0V9a4.991 4.991 0 014-2c1.537 0 2.904.66 3.827 1.77a.75.75 0 01-1.154.96C14.037 8.963 13.093 8.5 12 8.5a3.502 3.502 0 00-3.27 2.25zm7.27 5V15a5.013 5.013 0 01-7.821.237.75.75 0 111.142-.972 3.513 3.513 0 005.842-.765H14a.75.75 0 010-1.5h2.75a.75.75 0 01.75.75v3a.75.75 0 01-1.5 0z"/>
                    </svg>
                    <Loader v-else class="w-4 h-4 mr-2 text-our-cyan-40"></Loader>
                  </transition>
                  <span>Load more</span>
                </button>
              </transition>
            </div>-->
          </div>
        </div>
      </transition>

    </div>


  </div>
</template>

<script>
import Multiselect from '@/components/Multiselect'
import Table from '@/components/Table/Table'
import { useI18n } from 'vue-i18n'
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'
import LoaderVertical from '@/components/LoaderVertical'
import EventBus from '@/eventBus'

export default {
  name: 'DisplayDetail',
  components: {
    LoaderVertical,
    Table,
    Multiselect,
    Switch,
    SwitchGroup,
    SwitchLabel
  },
  setup() {
    return useI18n()
  },
  data() {
    return {
      file: null,
      fileResult: null,
      now: this.$moment(Date.now()).toISOString(),
      dataLoaded: false,
      displayData: {
        id: null,
        screen: null,
        customId: null,
        active: null,
        name: null,
        description: null,
        orientation: null,
        displaystatus: null,
        displaygroups: [],
        devices: null,
        location: 0
      },
      trialVersionState: null,
      cardDetail: null,
      isActiveDisplays: false,
      predictedPriceData: null,
      currencyName: null,
      isFreeDisplay: false,
      connectString: null,
      deviceConnectLoading: false,
      displayGroupsSelected: null,
      displayLocationSelected: null,
      displayLocations: [],
      eventsDataPlaylists: [],
      eventsDataPlaylistsLoaded: false,
      currentlyPlayingPlaylistID: null,
      eventsDataPlayables: [],
      eventsDataPlayablesLoaded: false,
      tableHeadsPlaylistPlayables: [
        {
          title: this.t('display.tableHeader.playableName'),
          col: 'playableName'
        },
        {
          title: this.t('display.tableHeader.from'),
          col: 'from'
        },
        {
          title: this.t('display.tableHeader.to'),
          col: 'to'
        }
      ],
      tableHeadsPlaylists: [
        {
          title: this.t('display.tableHeader.playlistName'),
          col: 'playlistName'
        },
        {
          title: this.t('display.tableHeader.group'),
          col: 'group'
        },
        {
          title: this.t('display.tableHeader.from'),
          col: 'from'
        },
        {
          title: this.t('display.tableHeader.to'),
          col: 'to'
        }
      ],
      tableOptionsPlaylists: {
        perPage: 5,
        total: 0
      },
      tableOptionsPlayables: {
        perPage: 5,
        total: 0
      }
    }
  },
  methods: {
    connectDisplay() {
      let _this = this
      // remove spaces before and after
      this.connectString = this.connectString.trim()
      // verify connect string
      let parts = this.connectString.split(' ')
      if (parts.length !== 3) {
        this.$store.dispatch('Notifications/send', {
          variant: 'error',
          message1: this.t('display.notification.invalidConnectStringFormat'),
          time: new Date().toISOString(),
          duration: 5000,
          overlay: false
        })
        return
      }

      _this.deviceConnectLoading = true

      this.$axios.post('device-connect', {
        connectString: this.connectString,
        displayId: this.displayData.id
      }).then(res => {
        _this.displayData.devices.push(res.data)
        _this.deviceConnectLoading = false
        this.displayLoad().then( () => {
          setTimeout(() => {
            if(!this.displayData.active){
              this.callActivateModal();
            }
          }, 100)
        })
      }).catch(e => {
        console.error(e)
        this.$store.dispatch('Notifications/send', {
          variant: 'error',
          message1: this.t('display.notification.connectStringNotVerified'),
          time: new Date().toISOString(),
          duration: 5000,
          overlay: false
        })
        _this.deviceConnectLoading = false
      })
    },
    handleTypeChange() {
      this.displayData.screen = null
      this.fileResult = null
      this.newPlayableData.name = null
    },
    fileChange() {

      let _this = this

      if (this.displayData.screen.size > 100 * 1000 * 1000) {

        _this.$store.dispatch('Notifications/send', {
          variant: 'warning',
          message1: this.t('display.notification.fileTooLargeMoreThan') + '100MB',
          time: new Date().toISOString(),
          duration: 10000,
          overlay: false
        })
      }

      let extension = this.displayData.screen.name.split('.').pop()
      let allowed_extensions_image = [ 'jpg', 'jpeg', 'jpe', 'jif', 'jfif', 'jfi', 'png', 'gif', 'webp', 'tiff', 'tif', 'bmp', 'jp2', 'jpx' ]

      if (!allowed_extensions_image.includes(extension.toLowerCase())) {
        console.warn('MIME err')
      }

      var fileReader = new FileReader()
      fileReader.onloadend = function (e) {
        _this.fileResult = e.target.result
      }

      fileReader.readAsDataURL(this.displayData.screen)

      let processedName = this.displayData.screen.name.split('.')
      processedName.pop()
      processedName = processedName.join('.')
      processedName = processedName.replace('_', ' ')
    },
    handleDragFile(event) {
      if (typeof event.dataTransfer.files !== 'undefined') {
        if (event.dataTransfer.files.length > 0) {
          this.displayData.screen = event.dataTransfer.files[0]
          this.fileChange()
        }
      }
    },
    handleFileChange(event) {
      if (typeof event.target.files !== 'undefined') {
        if (event.target.files.length > 0) {
          this.displayData.screen = event.target.files[0]
          this.fileChange()
        }
      }
    },
    shorten(text, lim = 20) {
      if (text.length > lim) {
        return text.substring(0, 19) + '...'
      }
      else {
        return text
      }
    },
    removeImage() {
      this.displayData.screen = null
      this.file = null
      this.fileResult = null
    },
    getServerIPv4(baseURL) {
      let IPv4
      IPv4 = baseURL.replace('https://', '')
      IPv4 = IPv4.replace('http://', '')
      IPv4 = IPv4.replace('/', '')
      IPv4 = IPv4.split(':')[0] // port
      return IPv4
    },
    async displayLoad(){
      this.$store.dispatch('Displays/fetchDisplay', Number.parseInt(this.$route.params.id)).then(response => {

        if (_.isEmpty(response.data)) {
          this.$router.push('/404')
          exit()
        }

        this.displayLocationSelected = response.data.location?.id ? response.data.location?.id : 0

        this.$store.dispatch('Groups/fetchAllGroups').then(() => {

          this.displayGroupsSelected = this.$store.state.Groups.groups.map(group => {

            if (response.data.displaygroups.some(selectedGroup => selectedGroup.id === group.id)) {
              group['selected'] = true
            }
            else {
              group['selected'] = false
            }
            return group
          })

          this.displayData.displaygroups = this.displayGroupsSelected

          this.$store.dispatch('Locations/fetchAllLocations').then(() => {

            this.displayLocations = this.$store.state.Locations.locations;
            this.$nextTick(() => {
              this.dataLoaded = true
            })
          })  

        })
        this.$store.dispatch('setDetailName', this.$store.state.Displays.displayData.name)
        this.$store.dispatch('setDetailActive', this.$store.state.Displays.displayData.active)
      }).catch(() => {
        this.$router.push('/404')
      })
    },
    callActivateModal() {
      EventBus.emit('callActivateModal')
    }
  },
  computed: {
    isDisplayActive() {
      return this.displayData.active
    },
    currentlyPlayingPlayable() {
      return this.eventsDataPlayables.filter(item => item.from <= this.now && item.to >= this.now)
    },
    upcomingPlayables() {
      return this.currentlyPlayingPlayable[0]
          ? this.eventsDataPlayables.filter(item => item.from > this.currentlyPlayingPlayable[0].from)
          : []
    },
    untilNextPlayableSeconds() {
      return this.currentlyPlayingPlayable[0]
          ? this.$moment(this.$moment(this.currentlyPlayingPlayable[0].to).diff(this.$moment(this.now))).format('mm:ss')
          : []
    },
    untilNextPlayablePercentage() {
      return this.currentlyPlayingPlaylist[0]
          ? parseFloat((this.$moment(this.now).diff(this.$moment(this.currentlyPlayingPlayable[0].from)) / this.$moment(this.currentlyPlayingPlayable[0].to).diff(this.$moment(this.currentlyPlayingPlayable[0].from))) * 100)
          : []
    },
    currentlyPlayingPlaylist() {
      return this.eventsDataPlaylists.filter(item => item.from <= this.now && item.to >= this.now)
    },
    upcomingPlaylists() {
      return this.eventsDataPlaylists.filter(item => item.from >= this.now)
    },
    untilNextPlaylistTime() {
      return this.currentlyPlayingPlaylist[0]
          ? this.$moment(this.$moment(this.currentlyPlayingPlaylist[0].to).diff(this.$moment(this.now))).format('HH:mm:ss')
          : []
    },
    totalPlaylistTime() {
      return this.currentlyPlayingPlaylist[0]
          ? this.$moment(this.$moment(this.currentlyPlayingPlaylist[0].to).diff(this.currentlyPlayingPlaylist[0].from)).format('HH:mm:ss')
          : []
    },
    untilNextPlaylistPercentage() {
      return parseInt((this.$moment(this.now).diff(this.$moment(this.currentlyPlayingPlaylist[0].from)) / this.$moment(this.currentlyPlayingPlaylist[0].to).diff(this.$moment(this.currentlyPlayingPlaylist[0].from))) * 100)
    }
  },
  watch: {
    '$store.state.Displays.eventsDataPlaylists': {
      immediate: false,
      handler(listOrig) {
        this.eventsDataPlaylists = JSON.parse(JSON.stringify(listOrig))
        this.tableOptionsPlaylists.total = this.eventsDataPlaylists.length

        this.eventsDataPlaylistsLoaded = true

        if (this.currentlyPlayingPlaylist.length === 0) {
          this.eventsDataPlayablesLoaded = true
        }
      }
    },
    '$store.state.Displays.eventsDataPlayables': {
      immediate: false,
      handler(listOrig) {
        this.eventsDataPlayables = JSON.parse(JSON.stringify(listOrig))
        this.tableOptionsPlayables.total = this.eventsDataPlayables.length
      }
    },
    '$store.state.Displays.displayData': {
      immediate: false,
      deep: true,
      handler(data) {

        /* Mostly for initial data load - to wait for async data from API / via Vuex Store */
        if (!this.$helpers.isEqual(data, this.displayData) && parseInt(this.$route.params.id) === data.id) {

          if (Object.keys(data).length > 0) {

            this.displayData = this.$helpers.purify(data)

            document.title = data.name + ' | ' + document.title.split('|')[1]
            this.$store.dispatch('setDetailName', data.name)
          }
        }
      }
    },
    '$store.state.Groups.groups': {
      immediate: false,
      deep: true,
      handler(data) {
        this.displayGroupsSelected = data
      }
    },
    displayData: {
      immediate: false,
      deep: true,
      handler(data) { // TODO add timeout for typing ?

        if (this.dataLoaded) {

          /* Outgoing data controller */
          if (this.$refs.form.reportValidity()) {

            let sendData = this.$helpers.purify(data)


            let filteredData = this.$helpers.objectFalseyAndEmptyFilter(sendData)

            filteredData.screen = data.screen
            filteredData.displaygroups = this.displayGroupsSelected.filter(group => group.selected)
            filteredData.location = this.displayLocationSelected

            this.$emit('save-changes', { ...filteredData, _type: 'display', valid: true })
          }
          else if (!this.$refs.form.reportValidity()) {
            this.$emit('save-changes', { valid: false })
          }
        }
      }
    },
    displayGroupsSelected: {
      immediate: false,
      deep: true,
      handler(data) {
        if (this.dataLoaded) {
          this.displayData.displaygroups = data
        }
      }
    },
    displayLocationSelected: {
      immediate: false,
      deep: true,
      handler(data) {
        if (this.dataLoaded) {
          this.displayData.location = data
        }
      }
    },
    currentlyPlayingPlaylist: {
      immediate: false,
      deep: false,
      handler(data) {
        if (data[0] && this.currentlyPlayingPlaylistID !== data[0].id) {
          this.currentlyPlayingPlaylistID = data[0].id
        }
      }
    },
    currentlyPlayingPlaylistID: {
      immediate: false,
      deep: false,
      handler() {
        this.$store.dispatch('Displays/fetchEventsListItemsForEventListPlaylist', {
          eventListPlaylistID: this.currentlyPlayingPlaylist[0].id, currentTime: this.now
        }).then(response => {
          this.eventsDataPlayables = response.data
          this.eventsDataPlayablesLoaded = true
        })

        /*this.$store.dispatch('Displays/fetchEventsListItemsForDisplayAndPlaylist', {
          displayID: parseInt(this.$route.params.id),
          playlistID: this.currentlyPlayingPlaylist[0].playlist.id
        }).then(response => {
          this.eventsDataPlayables = response.data
        })*/
      }
    }
  },
  created() {

    this.displayLoad()

    this.$api.billings.getServerPricingData({
      'serverIPv4': this.getServerIPv4(this.$axios.defaults.baseURL),
      'displayId': this.$route.params.id
    }).then(response => {
      this.trialVersionState = response.data.trialState
      this.cardDetail = response?.data?.creditCardDetails
      this.currencyName = response.data?.currency?.name
      this.predictedPriceData = response.data
      this.isFreeDisplay = response.data.isFreeDisplay

      if (response.data === false) {
        this.trialVersionState = false
      }
    })

    this.$store.dispatch('Displays/fetchEventListPlaylistForOneDisplayUntil', {
      'displayID': Number.parseInt(this.$route.params.id),
      'untilDate': this.$moment(Date.now()).add(2, 'days').toISOString()
    })

    setInterval(() => {
      this.now = this.$moment(Date.now()).toISOString()
    }, 1000)

  }
}
</script>

<style>

a.tab-link:hover {
  cursor: pointer;
}

.display-placeholder {
  @apply bg-repeat bg-light-level-2;
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='%23000000' fill-opacity='0.25' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
}

</style>
