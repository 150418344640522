<template>
  <div class="flex flex-col items-center">
    <template v-if="false">
        <button @click="step++">Step++</button>
        <button @click="step--">Step--</button>
    </template>
    <h1 v-if="step !==3" class="capsize text-our-gray-40 text-xl font-medium text-center">
      {{ t('signUp.signUpForAnAccount') }}
    </h1>

    <h1 v-else class="capsize text-our-gray-40 text-xl font-medium text-center">
      {{ t('signUp.thankYouForRegistering') }}
    </h1>

    <div v-if="false" class="py-3 px-5 bg-our-gray-140 rounded-2xl mt-8">
      <ol class="flex flex-row items-center justify-center gap-x-1.5">
        <li>
          <button
              class="focus:outline-none"
              :class="step > 1 ? 'cursor-pointer' : 'cursor-default'"
              v-on:click="() => { if(this.step > 1) { this.step = 1 } }">
            <span
                class="group flex items-center gap-x-2 border border-transparent py-1.5 px-2 transition-colors duration-100 ease-linear rounded-sm"
                :class="step === 1 ? '' : 'hover:border-our-gray-120 hover:bg-our-gray-160'">
              <span
                  class="flex-shrink-0 relative h-5 w-5 rounded-full text-our-gray-160 flex items-center justify-center transition-colors duration-100 ease-linear"
                  :class="step === 1 ? 'bg-white' : 'group-hover:bg-white bg-our-gray-20'">
                <svg
                    v-if="step !== 1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    viewBox="0 0 24 24">
                  <path
                      d="M10 17.414l-5-5.001L6.413 11 10 14.586 17.585 7 19 8.415l-9 8.999z"
                  />
                </svg>
                <svg
                    v-else
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    viewBox="0 0 24 24">
                  <path d="M13 15V7h-2v1H9v2h2v5H9v2h6v-2h-2z"/>
                </svg>
              </span>
              <span
                  class="text-sm transition-colors duration-100 ease-linear"
                  :class="step === 1 ? 'text-white font-medium' : 'group-hover:text-white text-our-gray-20'"
              >{{ t('signUp.accountDetails') }}</span
              >
            </span>
          </button>
        </li>
        <li><span class="text-our-gray-40">—</span></li>
        <li>
          <button
              class="focus:outline-none"
              :class="step > 2 ? 'cursor-pointer' : 'cursor-default'"
              v-on:click="() => { if(this.step > 2) { this.step = 2 } }">
            <span
                class="group flex items-center gap-x-2 border border-transparent py-1.5 px-2 transition-colors duration-100 ease-linear rounded-sm"
                :class="step > 2 ? 'hover:border-our-gray-120 hover:bg-our-gray-160' : ''">
              <span
                  class="flex-shrink-0 relative h-5 w-5 rounded-full text-our-gray-160 flex items-center justify-center transition-colors duration-100 ease-linear"
                  :class="step === 2 ? 'bg-white' : step > 2 ? 'group-hover:bg-white bg-our-gray-20' : 'bg-our-gray-60'">
                <svg
                    v-if="step > 2"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    viewBox="0 0 24 24">
                  <path
                      d="M10 17.414l-5-5.001L6.413 11 10 14.586 17.585 7 19 8.415l-9 8.999z"
                  />
                </svg>
                <svg
                    v-else
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    viewBox="0 0 24 24">
                  <path d="M13 15V7h-2v1H9v2h2v5H9v2h6v-2h-2z"/>
                </svg>
              </span>
              <span
                  class="text-sm transition-colors duration-100 ease-linear"
                  :class="step === 2 ? 'text-white font-medium' : step > 2 ? 'group-hover:text-white text-our-gray-20' : 'text-our-gray-60'">Payment details</span>
            </span>
          </button>
        </li>
        <li><span class="text-our-gray-40">—</span></li>
        <li>
          <button
              class="focus:outline-none"
              :class="step > 3 ? 'cursor-pointer' : 'cursor-default'"
              v-on:click="() => { if(this.step > 3) { this.step = 3 } }">
            <span
                class="group flex items-center gap-x-2 border border-transparent py-1.5 px-2 transition-colors duration-100 ease-linear rounded-sm"
                :class="step > 3 ? 'hover:border-our-gray-120 hover:bg-our-gray-160' : ''">
              <span
                  class="flex-shrink-0 relative h-5 w-5 rounded-full text-our-gray-160 flex items-center justify-center transition-colors duration-100 ease-linear"
                  :class="step === 3 ? 'bg-white' : step > 3 ? 'group-hover:bg-white bg-our-gray-20' : 'bg-our-gray-60'"
              >
                <svg
                    v-if="step > 3"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    viewBox="0 0 24 24">
                  <path
                      d="M10 17.414l-5-5.001L6.413 11 10 14.586 17.585 7 19 8.415l-9 8.999z"
                  />
                </svg>
                <svg
                    v-else
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                >
                  <path d="M13 15V7h-2v1H9v2h2v5H9v2h6v-2h-2z"/>
                </svg>
              </span>
              <span
                  class="text-sm transition-colors duration-100 ease-linear"
                  :class="step === 3 ? 'text-white font-medium' : step > 3 ? 'group-hover:text-white text-our-gray-20' : 'text-our-gray-60'"
              >{{ t('signUp.confirmation') }}</span
              >
            </span>
          </button>
        </li>
      </ol>
    </div>

    <div class="mt-8 w-full mx-auto">
      <transition name="fade"
                  mode="out-in">
        <div v-if="step === 1">
          <div class="grid grid-flow-col grid-cols-2 gap-x-12">
            <div class="flex min-w-xs flex-col gap-y-5 w-full">

              <div>
                <label for="fullname"
                       class="form-label-auth">{{ t('signUp.form.name') }}</label>
                <div class="relative">
                  <input v-model="newUser.fullname"
                         id="fullname"
                         type="text"
                         required
                         autocomplete="name"
                         class="form-input-auth"/>

                </div>
              </div>

              <div>
                <label for="email"
                       class="form-label-auth">{{ t('signUp.form.email') }}<sup>*</sup></label>
                <div class="relative">
                  <input v-model="newUser.email"
                         v-on:input="validErrorEmail = false"
                         id="email"
                         type="email"
                         required
                         autocomplete="email"
                         class="form-input-auth"/>

                  <div class="absolute inset-y-0 right-0 flex items-center pr-3 z-50 group">
                    <div class="p-1 rounded-full bg-error text-error pointer-events-none"
                        v-show="validErrorEmail">
                      <svg width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12 2C17.523 2 22 6.478 22 12C22 17.522 17.523 22 12 22C6.477 22 2 17.522 2 12C2 6.478 6.477 2 12 2ZM12.0018 15.0037C11.4503 15.0037 11.0031 15.4508 11.0031 16.0024C11.0031 16.5539 11.4503 17.001 12.0018 17.001C12.5533 17.001 13.0005 16.5539 13.0005 16.0024C13.0005 15.4508 12.5533 15.0037 12.0018 15.0037ZM11.9996 7C11.4868 7.00018 11.0643 7.38638 11.0067 7.88374L11 8.00036L11.0018 13.0012L11.0086 13.1179C11.0665 13.6152 11.4893 14.0011 12.0022 14.0009C12.515 14.0007 12.9375 13.6145 12.9951 13.1171L13.0018 13.0005L13 7.99964L12.9932 7.88302C12.9353 7.3857 12.5125 6.99982 11.9996 7Z"/>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="flex min-w-xs flex-col gap-y-5 w-full">

              <div>
                <label for="password"
                       class="form-label-auth">{{ t('signUp.form.password') }}<sup>*</sup></label>
                <div class="relative">
                  <input v-model="newUser.password"
                        v-on:input="validErrorPass = false; passwordsMustMatchError = false"
                        id="password"
                        v-bind:type="showPassword ? 'text' : 'password'"
                        required
                         autocomplete="new-password"
                        class="form-input-auth"/>

                  <div class="absolute inset-y-0 right-0 flex items-center pr-3 z-50 group">
                    <div class="p-1 rounded-full bg-error text-error pointer-events-none"
                        v-show="validErrorPass">
                      <svg width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12 2C17.523 2 22 6.478 22 12C22 17.522 17.523 22 12 22C6.477 22 2 17.522 2 12C2 6.478 6.477 2 12 2ZM12.0018 15.0037C11.4503 15.0037 11.0031 15.4508 11.0031 16.0024C11.0031 16.5539 11.4503 17.001 12.0018 17.001C12.5533 17.001 13.0005 16.5539 13.0005 16.0024C13.0005 15.4508 12.5533 15.0037 12.0018 15.0037ZM11.9996 7C11.4868 7.00018 11.0643 7.38638 11.0067 7.88374L11 8.00036L11.0018 13.0012L11.0086 13.1179C11.0665 13.6152 11.4893 14.0011 12.0022 14.0009C12.515 14.0007 12.9375 13.6145 12.9951 13.1171L13.0018 13.0005L13 7.99964L12.9932 7.88302C12.9353 7.3857 12.5125 6.99982 11.9996 7Z"/>
                      </svg>
                    </div>

                    <svg v-show="!showPassword && !validErrorPass"
                        v-on:click="showPassword = true"
                        class="text-our-gray-40 cursor-pointer select-none group-hover:text-white transition-colors ease-linear duration-150"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        viewBox="0 0 24 24">
                      <path d="M12 9.005a4 4 0 110 8 4 4 0 010-8zm0 1.5a2.5 2.5 0 100 5 2.5 2.5 0 000-5zM12 5.5c4.614 0 8.596 3.15 9.701 7.564a.75.75 0 11-1.455.365 8.504 8.504 0 00-16.493.004.75.75 0 01-1.455-.363A10.003 10.003 0 0112 5.5z"/>
                    </svg>

                    <svg v-show="showPassword && !validErrorPass"
                        v-on:click="showPassword = false"
                        class="text-our-gray-40 cursor-pointer select-none group-hover:text-white transition-colors ease-linear duration-150"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        viewBox="0 0 24 24">
                      <path d="M2.22 2.22a.75.75 0 00-.073.976l.073.084 4.034 4.035a9.986 9.986 0 00-3.955 5.75.75.75 0 001.455.364 8.49 8.49 0 013.58-5.034l1.81 1.81A4 4 0 0014.8 15.86l5.919 5.92a.75.75 0 001.133-.977l-.073-.084-6.113-6.114.001-.002-6.95-6.946.002-.002-1.133-1.13L3.28 2.22a.75.75 0 00-1.06 0zM12 5.5c-1 0-1.97.148-2.889.425l1.237 1.236a8.503 8.503 0 019.899 6.272.75.75 0 001.455-.363A10.003 10.003 0 0012 5.5zm.195 3.51l3.801 3.8a4.003 4.003 0 00-3.801-3.8z"/>
                    </svg>

                  </div>
                </div>
              </div>

              <div>
                <label for="confirm-password"
                       class="form-label-auth">{{ t('signUp.form.confirmPassword') }}<sup>*</sup></label>
                <div class="relative">
                  <input v-model="newUser.password2"
                        v-on:input="validErrorPass = false; passwordsMustMatchError = false"
                        id="confirm-password"
                        v-bind:type="showPassword2 ? 'text' : 'password'"
                        required
                         autocomplete="new-password"
                        class="form-input-auth"/>

                <div class="absolute inset-y-0 right-0 flex items-center pr-3 z-50 group">
                    <div class="p-1 rounded-full bg-error text-error pointer-events-none"
                        v-show="validErrorPass">
                      <svg width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12 2C17.523 2 22 6.478 22 12C22 17.522 17.523 22 12 22C6.477 22 2 17.522 2 12C2 6.478 6.477 2 12 2ZM12.0018 15.0037C11.4503 15.0037 11.0031 15.4508 11.0031 16.0024C11.0031 16.5539 11.4503 17.001 12.0018 17.001C12.5533 17.001 13.0005 16.5539 13.0005 16.0024C13.0005 15.4508 12.5533 15.0037 12.0018 15.0037ZM11.9996 7C11.4868 7.00018 11.0643 7.38638 11.0067 7.88374L11 8.00036L11.0018 13.0012L11.0086 13.1179C11.0665 13.6152 11.4893 14.0011 12.0022 14.0009C12.515 14.0007 12.9375 13.6145 12.9951 13.1171L13.0018 13.0005L13 7.99964L12.9932 7.88302C12.9353 7.3857 12.5125 6.99982 11.9996 7Z"/>
                      </svg>
                    </div>

                    <svg v-show="!showPassword2 && !validErrorPass"
                        v-on:click="showPassword2 = true"
                        class="text-our-gray-40 cursor-pointer select-none group-hover:text-white transition-colors ease-linear duration-150"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        viewBox="0 0 24 24">
                      <path d="M12 9.005a4 4 0 110 8 4 4 0 010-8zm0 1.5a2.5 2.5 0 100 5 2.5 2.5 0 000-5zM12 5.5c4.614 0 8.596 3.15 9.701 7.564a.75.75 0 11-1.455.365 8.504 8.504 0 00-16.493.004.75.75 0 01-1.455-.363A10.003 10.003 0 0112 5.5z"/>
                    </svg>

                    <svg v-show="showPassword2 && !validErrorPass"
                        v-on:click="showPassword2 = false"
                        class="text-our-gray-40 cursor-pointer select-none group-hover:text-white transition-colors ease-linear duration-150"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        viewBox="0 0 24 24">
                      <path d="M2.22 2.22a.75.75 0 00-.073.976l.073.084 4.034 4.035a9.986 9.986 0 00-3.955 5.75.75.75 0 001.455.364 8.49 8.49 0 013.58-5.034l1.81 1.81A4 4 0 0014.8 15.86l5.919 5.92a.75.75 0 001.133-.977l-.073-.084-6.113-6.114.001-.002-6.95-6.946.002-.002-1.133-1.13L3.28 2.22a.75.75 0 00-1.06 0zM12 5.5c-1 0-1.97.148-2.889.425l1.237 1.236a8.503 8.503 0 019.899 6.272.75.75 0 001.455-.363A10.003 10.003 0 0012 5.5zm.195 3.51l3.801 3.8a4.003 4.003 0 00-3.801-3.8z"/>
                    </svg>

                  </div>
                </div>
              </div>
              <transition name="fade">
                <div class="px-3 py-1 mx-auto mt-4 text-sm font-medium rounded-md bg-error text-error"
                    v-show="passwordsMustMatchError">
                  {{ t('tokenLogin.passwordsMustMatch') }}
                </div>
              </transition>
            </div>
          </div>
        </div>

        <form v-else-if="step === 2"
              ref="form"
              class="flex flex-col w-full">
          <div class="mb-8"><p class="text-white">{{ t('signUp.importantDocuments') }}:
          <br>
          <a href="https://www.bohemicasignage.com/tos" target="_blank" class="text-our-gray-70 hover:underline"><svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
          </svg> {{ t('signUp.termsOfService') }}</a>
          <br>
          <a href="https://www.bohemicasignage.com/privacy" target="_blank" class="text-our-gray-70 hover:underline"><svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
          </svg> {{ t('signUp.privacyPolicy') }}</a>
          <br>
          </p></div>

          <div class="">
            <div class="relative border-b-0 flex items-start form-input-auth rounded-none rounded-t">
              <div class="flex items-center h-5">
                <input id="termsOfService"
                       v-model="newUser.termsOfService"
                       v-on:input="validErrorTermsOfService = false"
                       aria-describedby="termsOfService-description"
                       name="termsOfService"
                       type="checkbox"
                       required
                       class="mt-0.5 h-6 w-6 text-our-cyan-50 border-gray-300 rounded">
              </div>
              <div class="ml-3 text-sm mt-1">
                <label for="termsOfService" class="font-medium text-gray-700">{{ t('signUp.termsOfService') }} *</label>
                  <p id="termsOfService-description" class="font-normal text-our-gray-70 leading-tight">&nbsp;{{ t('signUp.confirmTerms') }}</p>
              </div>
              <div class="absolute inset-y-0 right-0 flex items-center pr-3 z-50 group">
                <div class="p-1 rounded-full bg-error text-error pointer-events-none"
                     v-show="validErrorTermsOfService">
                  <svg width="24"
                       height="24"
                       viewBox="0 0 24 24"
                       fill="currentColor"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12 2C17.523 2 22 6.478 22 12C22 17.522 17.523 22 12 22C6.477 22 2 17.522 2 12C2 6.478 6.477 2 12 2ZM12.0018 15.0037C11.4503 15.0037 11.0031 15.4508 11.0031 16.0024C11.0031 16.5539 11.4503 17.001 12.0018 17.001C12.5533 17.001 13.0005 16.5539 13.0005 16.0024C13.0005 15.4508 12.5533 15.0037 12.0018 15.0037ZM11.9996 7C11.4868 7.00018 11.0643 7.38638 11.0067 7.88374L11 8.00036L11.0018 13.0012L11.0086 13.1179C11.0665 13.6152 11.4893 14.0011 12.0022 14.0009C12.515 14.0007 12.9375 13.6145 12.9951 13.1171L13.0018 13.0005L13 7.99964L12.9932 7.88302C12.9353 7.3857 12.5125 6.99982 11.9996 7Z"/>
                  </svg>
                </div>
              </div>
            </div>
            <div class="relative border-b-0 flex items-start form-input-auth rounded-none">
              <div class="flex items-center h-5">
                <input id="privacyPolicy"
                       v-model="newUser.privacyPolicy"
                       v-on:input="validErrorPrivacyPolicy = false"
                       aria-describedby="privacyPolicy-description"
                       name="privacyPolicy"
                       type="checkbox"
                       required
                       class="mt-1 h-6 w-6 text-our-cyan-50 border-gray-300 rounded">
              </div>
              <div class="ml-3 text-sm mt-1">
                <label for="privacyPolicy" class="font-medium text-gray-700">{{ t('signUp.privacyPolicy') }} *</label>
                  <p id="privacyPolicy-description" class="font-normal text-our-gray-70 leading-tight mr-10">&nbsp;{{ t('signUp.confirmPrivacyPolicy') }} </p>
              </div>
              <div class="absolute inset-y-0 right-0 flex items-center pr-3 z-50 group">
                <div class="p-1 rounded-full bg-error text-error pointer-events-none"
                     v-show="validErrorPrivacyPolicy">
                  <svg width="24"
                       height="24"
                       viewBox="0 0 24 24"
                       fill="currentColor"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12 2C17.523 2 22 6.478 22 12C22 17.522 17.523 22 12 22C6.477 22 2 17.522 2 12C2 6.478 6.477 2 12 2ZM12.0018 15.0037C11.4503 15.0037 11.0031 15.4508 11.0031 16.0024C11.0031 16.5539 11.4503 17.001 12.0018 17.001C12.5533 17.001 13.0005 16.5539 13.0005 16.0024C13.0005 15.4508 12.5533 15.0037 12.0018 15.0037ZM11.9996 7C11.4868 7.00018 11.0643 7.38638 11.0067 7.88374L11 8.00036L11.0018 13.0012L11.0086 13.1179C11.0665 13.6152 11.4893 14.0011 12.0022 14.0009C12.515 14.0007 12.9375 13.6145 12.9951 13.1171L13.0018 13.0005L13 7.99964L12.9932 7.88302C12.9353 7.3857 12.5125 6.99982 11.9996 7Z"/>
                  </svg>
                </div>
              </div>
            </div>
            <div class="relative flex items-start form-input-auth rounded-none rounded-b">
              <div class="flex items-center h-5">
                <input id="marketingEmails" v-model="newUser.marketingEmails" aria-describedby="marketingEmails-description"
                       name="marketingEmails" type="checkbox" class="mt-0.5 h-6 w-6 text-our-cyan-50 border-gray-300 rounded">
              </div>
              <div class="ml-3 text-sm mt-1">
                <div class="font-medium text-gray-700">
                  <label for="marketingEmails">{{ t('signUp.receiveMarketingEmails') }}</label>
                  <p id="marketingEmails-description" class="font-normal text-our-gray-70 leading-tight">&nbsp;{{ t('signUp.occasionalPromotionEmails') }}</p></div>
              </div>
            </div>
          </div>

          <transition v-show="registerError" name="fade">
            <div class="px-3 py-1 mx-auto mt-4 text-sm font-medium rounded-md bg-error text-error">
              {{ t('signUp.registerError') }} - {{ registerErrorMsg }}
            </div>
          </transition>

          <div class="mt-4 flex flex-col gap-y-4">
            <transition name="fade">
              <div class="text-center">
                <button 
                    type="button" 
                    v-if="!isOpenRegistrationCode"
                    @click="isOpenRegistrationCode=true"
                    class="text-our-gray-100 mt-4 mb-4 text-center hover:underline"
                >
                  {{ t('signUp.doYouHaveRegistrationCode') }}
                </button>

                <div class="mt-4" v-if="isOpenRegistrationCode">
                  <div>
                    <label for="registrationCode"
                          class="form-label-auth">{{ t('signUp.registrationCode') }}</label>
                    <div class="relative">
                      <input v-model="newUser.registrationCode"
                            v-on:input="validErrorRegistrationCode = false"
                            id="registrationCode"
                            type="text"
                            required
                            autocomplete="registrationCode"
                            class="form-input-auth"/>

                      <div class="absolute inset-y-0 right-0 flex items-center pr-3 z-50 group">
                        <div class="p-1 rounded-full bg-error text-error pointer-events-none"
                            v-show="validErrorRegistrationCode">
                          <svg width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M12 2C17.523 2 22 6.478 22 12C22 17.522 17.523 22 12 22C6.477 22 2 17.522 2 12C2 6.478 6.477 2 12 2ZM12.0018 15.0037C11.4503 15.0037 11.0031 15.4508 11.0031 16.0024C11.0031 16.5539 11.4503 17.001 12.0018 17.001C12.5533 17.001 13.0005 16.5539 13.0005 16.0024C13.0005 15.4508 12.5533 15.0037 12.0018 15.0037ZM11.9996 7C11.4868 7.00018 11.0643 7.38638 11.0067 7.88374L11 8.00036L11.0018 13.0012L11.0086 13.1179C11.0665 13.6152 11.4893 14.0011 12.0022 14.0009C12.515 14.0007 12.9375 13.6145 12.9951 13.1171L13.0018 13.0005L13 7.99964L12.9932 7.88302C12.9353 7.3857 12.5125 6.99982 11.9996 7Z"/>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                <button 
                      type="button" 
                      v-if="isOpenRegistrationCode"
                      @click="isOpenRegistrationCode=false; newUser.registrationCode=''"
                      class="text-our-gray-100 mt-2 mb-4 text-center hover:underline"
                  >
                    {{ t('signUp.iDontHaveCode') }}
                </button>
              </div>
            </transition>

            <button
                type="button"
                @click="signUp(newUser)"
                class="text-base relative justify-center mx-auto w-1/2 h-8 px-4 rounded-sm button bg-our-cyan-50 px-4 py-1 text-white"
            >
              <span class="absolute inset-y-0 left-0 flex items-center pl-3">
                <transition name="fade"
                            mode="out-in">
                  <Loader
                      class="w-4 h-4 text-our-cyan-40 group-hover:text-white"
                      v-if="sending"
                  ></Loader>
                  <svg
                      v-else
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      class="transition duration-100 ease-linear group-hover:text-white"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                  >
                    <path
                        d="M14.75 16.5c1.31 0 1.819.582 2.206 1.874l.068.238c.182.657.292.854.513.945.259.107.431.091.703-.048l.147-.082c.053-.032.11-.069.176-.112l.663-.451c.616-.405 1.17-.673 1.843-.842a.75.75 0 11.364 1.456 4.03 4.03 0 00-1.146.489l-.298.19-.48.329a5.45 5.45 0 01-.583.357c-.643.33-1.27.385-1.961.1-.745-.306-1.045-.78-1.326-1.721l-.156-.542c-.181-.59-.305-.68-.732-.68-.31 0-.63.155-1.069.523l-.184.16-.921.876c-1.408 1.324-2.609 1.966-4.328 1.966-1.686 0-3.144-.253-4.368-.768l2.947-.805c.447.05.921.073 1.421.073 1.183 0 2.032-.414 3.087-1.362l.258-.239.532-.51c.236-.228.414-.39.592-.54.684-.574 1.305-.874 2.033-.874zm4.281-13.53a3.579 3.579 0 010 5.06l-.288.289c1.151 1.401 1.11 2.886.039 3.96l-2.001 2.002a.75.75 0 01-1.06-1.062l1.999-1.999c.485-.486.54-1.09-.04-1.838L9.063 18a2.25 2.25 0 01-1 .58l-5.115 1.395a.75.75 0 01-.92-.921l1.394-5.116a2.25 2.25 0 01.58-.999L13.97 2.97a3.578 3.578 0 015.061 0zm-4 1.06L5.063 14a.75.75 0 00-.193.333l-1.05 3.85 3.85-1.05a.75.75 0 00.332-.194l9.969-9.968a2.078 2.078 0 10-2.94-2.94z"
                    />
                  </svg>
                </transition>
              </span>
              <span>{{ t('signUp.signUp') }}</span>
            </button>
            <p class="text-center text-sm text-our-gray-60">
              {{ t('signUp.clickingSignUpAgree') }}
              <a href="https://www.bohemicasignage.com/tos" target="_blank"
                 class="underline">{{ t('signUp.termsAndConditions') }}</a> {{ t('signUp.and') }}
              <a href="https://www.bohemicasignage.com/privacy" target="_blank"
                 class="underline">{{ t('signUp.privacyPolicy') }}</a>.
            </p>
            <p class="text-center text-sm text-our-gray-40">
              {{ t('signUp.requiredInput') }}
            </p>
            <p class="text-center text-sm text-our-gray-40">
              <span @click="goBack()" class="hover:underline cursor-pointer">{{ t('signUp.goBack') }}</span>
            </p>
          </div>
        </form>

        <div v-else-if="step === 3">

          <div class="mb-8 mt-2">
            <p class="text-white text-center">
              {{ t('signUp.signupWasSuccessful') }}
            </p>
            <p class="text-white text-center mt-2">
              {{ t('signUp.signupWasSuccessfulAlert') }}
            </p>
          </div>

        </div>

      </transition>
    </div>

    <div v-if="step === 1" class="mt-10 w-full gap-x-4 inline-flex justify-center">
      <button
          type="button"
          v-on:click="goToStepTwo(newUser)"
          class="text-base relative justify-start w-1/2 px-4 leading-7 rounded-sm button bg-our-cyan-50 px-4 py-1 text-white"
      >
        <span class="absolute inset-y-0 right-0 flex items-center pr-3">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              viewBox="0 0 24 24"
          >
            <path
                fill-rule="evenodd"
                d="M8.293 4.293a1 1 0 000 1.414L14.586 12l-6.293 6.293a1 1 0 101.414 1.414l7-7a1 1 0 000-1.414l-7-7a1 1 0 00-1.414 0z"
                clip-rule="evenodd"
            />
          </svg>
        </span>
        <span>{{ t('signUp.nextStep') }}</span>
      </button>
    </div>

    <div v-if="step !== 3" class="text-our-gray-100 mt-4 text-center">{{ t('signUp.registrationIsFreeOfCharge') }}<br>
      <router-link class="hover:underline" :to="{ name: 'Login' }">{{ t('signUp.alreadyRegistred') }}</router-link>
    </div>

    <!--    <teleport to="#authentication">

        </teleport>-->
  </div>
</template>

<script>
import {useI18n} from 'vue-i18n'
import store from "@/store";
import axios from "axios";

export default {
  name: "Signup",
  components: {
    Notification
  },
  setup() {
    return useI18n()
  },
  data() {
    return {
      loaded: false,
      sending: false,
      step: 1,
      newUser: {
        email: null,
        fullname: null,
        password: '',
        password2: '',
        termsOfService: false,
        privacyPolicy: false,
        marketingEmails: false,
        registrationCode: ''
      },
      showPassword: false,
      showPassword2: false,
      registerError: false,
      registerErrorMsg: '',
      validErrorEmail: false,
      validErrorPass: false,
      validErrorTermsOfService: false,
      validErrorPrivacyPolicy: false,
      passwordsMustMatchError: false,
      validErrorRegistrationCode: false,
      isOpenRegistrationCode: false,
      one: {
        email: null,
        name: null,
        password: null,
        password2: null,
      },
      two: {
        businessName: null,
        streetAddress: null,
        postcode: null,
        country: "Country",
      },
    }
  },
  methods: {
    goToStepTwo(newUser) {

      this.passwordsMustMatchError = false

      if(!this.validEmail(newUser.email)){
        this.validErrorEmail = true
      }

      if (!newUser.password || !newUser.password2){
          this.validErrorPass = true
      }else if (newUser.password != newUser.password2){
        this.validErrorPass = true
        this.passwordsMustMatchError = true
      }else if(newUser.password.length < 8){
        this.validErrorPass = true
      }

      if (!(this.validErrorEmail || this.validErrorPass)) {
        this.step++
      }

    },
    goBack(){
      this.registerError = false
      this.registerErrorMsg = ''
      this.step--
    },
    validEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    async signUp(newUser){

      this.registerError = false
      this.validErrorRegistrationCode = false
      this.registerErrorMsg = ''

      if(localStorage.getItem('strapi-token') || localStorage.getItem('strapi-auth-token')){
        this.$store.dispatch('Login/signOut')
      }

      if (!newUser.termsOfService) {
        this.validErrorTermsOfService = true
      }

      if (!newUser.privacyPolicy) {
        this.validErrorPrivacyPolicy = true
      }

      if(this.isOpenRegistrationCode && !newUser.registrationCode){
        this.validErrorRegistrationCode = true
      }

      if (!(this.validErrorTermsOfService ||
          this.validErrorPrivacyPolicy ||
          this.validErrorRegistrationCode)) {

        this.$axios.defaults.baseURL = process.env.VUE_APP_AUTH_SERVER
        this.sending = true

        let _this = this;
        await this.$axios.post('/auth/local/register', newUser,{withCredentials:false}).then(async res => {

          setTimeout(() => {
            this.step = 3
            this.sending = false
          }, 1000)

        }).catch(e => {
          _this.registerError = true
          _this.registerErrorMsg = e.response.data.data[0]?.messages[0]?.message
          _this.sending = false
          //_this.notifBadLogin(e);
        })
      }
    }
  },
  computed: {},
  mounted() {
    this.loaded = true
  },
  beforeUnmount() {
    this.loaded = false
  },
  created(){
    if(this.$route.query.code){
      this.isOpenRegistrationCode = true;
      this.newUser.registrationCode = this.$route.query.code
    }
  }
}
</script>

<style scoped>
  input:-webkit-autofill::first-line,
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-transition: background-color 5000s ease-in-out 0s !important;
    transition: background-color 500000s ease-in-out 0s !important;
    -webkit-box-shadow: 0 0 0 30px #323130 inset !important;
    -webkit-text-fill-color: white !important;
    color: #FFF !important;
  }
</style>
