<template>

  <div class="min-w-xs">

    <transition v-if="$route.query.confirmation === 'true'" name="fade" class="mb-6">
      <div class="px-3 py-1 mx-auto text-sm font-medium rounded-md bg-success text-success text-center">
        <p>{{ t('global.notification.emailSuccessfullyConfirmed') }}</p>
      </div>
    </transition>

        <transition v-if="$route.query.resetPassword === 'true'" name="fade" class="mb-6">
      <div class="px-3 py-1 mx-auto text-sm font-medium rounded-md bg-success text-success text-center">
        <p>{{ t('login.notification.resetPasswordSuccessfulCreated') }}</p>
      </div>
    </transition>

    <transition v-if="$route.query.availableServer === 'false'" name="fade" class="mb-6">
      <div class="px-3 py-1 mx-auto text-sm font-medium rounded-md bg-info text-info text-center">
        <p><strong>{{ t('settings.notification.newServerNotAvailable') }}</strong></p>
        <p>{{ t('settings.notification.whenAvailableSendEmail') }}</p>
      </div>
    </transition>

    <h1 v-if="!resetPassword" class="capsize text-our-gray-40 text-xl font-medium text-center">{{ t('login.logInToYourDashboard') }}</h1>
    <h1 v-else class="capsize text-our-gray-40 text-xl font-medium text-center">{{ t('login.forgotPassword') }}</h1>

    <div class="mt-8 w-full">
      <div v-if="!chooseServer && !resetPassword"
           class="flex flex-col justify-center items-center w-full">

        <div class="-gap-y-px w-full">
          <div class="relative w-full">
            <label for="username"
                   class="sr-only">{{ t('login.emailAddressOrUsername') }}</label>
            <input v-model="email"
                   v-on:input="authError = false"
                   v-on:keyup.enter="login()"
                   id="username"
                   name="email"
                   type="text"
                   required
                   autocomplete="username"
                   class="form-input-auth rounded-none rounded-t-sm mb-[-1px]"
                   placeholder="Email address">

            <div class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
                 :class="{ 'hidden' : !authError }">
              <div class="p-1 rounded-full bg-error text-error">
                <svg width="24"
                     height="24"
                     viewBox="0 0 24 24"
                     fill="currentColor"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M12 2C17.523 2 22 6.478 22 12C22 17.522 17.523 22 12 22C6.477 22 2 17.522 2 12C2 6.478 6.477 2 12 2ZM12.0018 15.0037C11.4503 15.0037 11.0031 15.4508 11.0031 16.0024C11.0031 16.5539 11.4503 17.001 12.0018 17.001C12.5533 17.001 13.0005 16.5539 13.0005 16.0024C13.0005 15.4508 12.5533 15.0037 12.0018 15.0037ZM11.9996 7C11.4868 7.00018 11.0643 7.38638 11.0067 7.88374L11 8.00036L11.0018 13.0012L11.0086 13.1179C11.0665 13.6152 11.4893 14.0011 12.0022 14.0009C12.515 14.0007 12.9375 13.6145 12.9951 13.1171L13.0018 13.0005L13 7.99964L12.9932 7.88302C12.9353 7.3857 12.5125 6.99982 11.9996 7Z"/>
                </svg>
              </div>
            </div>

          </div>
          <div class="relative">
            <label for="password"
                   class="sr-only">{{ t('login.password') }}</label>
            <input v-model="password"
                   v-on:input="authError = false"
                   v-on:keyup.enter="login()"
                   v-bind:type="showPassword ? 'text' : 'password'"
                   id="password"
                   name="password"
                   required
                   autocomplete="current-password"
                   class="form-input-auth rounded-none rounded-b-sm"
                   placeholder="Password">

            <div class="absolute inset-y-0 right-0 flex items-center pr-3 z-50 group">
              <div class="p-1 rounded-full bg-error text-error pointer-events-none"
                   v-show="authError">
                <svg width="24"
                     height="24"
                     viewBox="0 0 24 24"
                     fill="currentColor"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M12 2C17.523 2 22 6.478 22 12C22 17.522 17.523 22 12 22C6.477 22 2 17.522 2 12C2 6.478 6.477 2 12 2ZM12.0018 15.0037C11.4503 15.0037 11.0031 15.4508 11.0031 16.0024C11.0031 16.5539 11.4503 17.001 12.0018 17.001C12.5533 17.001 13.0005 16.5539 13.0005 16.0024C13.0005 15.4508 12.5533 15.0037 12.0018 15.0037ZM11.9996 7C11.4868 7.00018 11.0643 7.38638 11.0067 7.88374L11 8.00036L11.0018 13.0012L11.0086 13.1179C11.0665 13.6152 11.4893 14.0011 12.0022 14.0009C12.515 14.0007 12.9375 13.6145 12.9951 13.1171L13.0018 13.0005L13 7.99964L12.9932 7.88302C12.9353 7.3857 12.5125 6.99982 11.9996 7Z"/>
                </svg>
              </div>

              <svg v-show="!showPassword && !authError"
                   v-on:click="showPassword = true"
                   class="text-our-gray-40 cursor-pointer select-none group-hover:text-white transition-colors ease-linear duration-150"
                   xmlns="http://www.w3.org/2000/svg"
                   width="24"
                   height="24"
                   fill="currentColor"
                   viewBox="0 0 24 24">
                <path d="M12 9.005a4 4 0 110 8 4 4 0 010-8zm0 1.5a2.5 2.5 0 100 5 2.5 2.5 0 000-5zM12 5.5c4.614 0 8.596 3.15 9.701 7.564a.75.75 0 11-1.455.365 8.504 8.504 0 00-16.493.004.75.75 0 01-1.455-.363A10.003 10.003 0 0112 5.5z"/>
              </svg>

              <svg v-show="showPassword && !authError"
                   v-on:click="showPassword = false"
                   class="text-our-gray-40 cursor-pointer select-none group-hover:text-white transition-colors ease-linear duration-150"
                   xmlns="http://www.w3.org/2000/svg"
                   width="24"
                   height="24"
                   fill="currentColor"
                   viewBox="0 0 24 24">
                <path d="M2.22 2.22a.75.75 0 00-.073.976l.073.084 4.034 4.035a9.986 9.986 0 00-3.955 5.75.75.75 0 001.455.364 8.49 8.49 0 013.58-5.034l1.81 1.81A4 4 0 0014.8 15.86l5.919 5.92a.75.75 0 001.133-.977l-.073-.084-6.113-6.114.001-.002-6.95-6.946.002-.002-1.133-1.13L3.28 2.22a.75.75 0 00-1.06 0zM12 5.5c-1 0-1.97.148-2.889.425l1.237 1.236a8.503 8.503 0 019.899 6.272.75.75 0 001.455-.363A10.003 10.003 0 0012 5.5zm.195 3.51l3.801 3.8a4.003 4.003 0 00-3.801-3.8z"/>
              </svg>

              <!--              <svg class="h-6 w-6 text-our-gray-100"
                                 fill="none"
                                 x-on:click="show = !show"
                                 xmlns="http://www.w3.org/2000/svg"
                                 viewbox="0 0 576 512">
                              <path fill="currentColor"
                                    d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z">
                              </path>
                            </svg>

                            <svg class="h-6 text-our-gray-100"
                                 fill="none"
                                 @click="show = !show"
                                 :class="{'block': !show, 'hidden':show }"
                                 xmlns="http://www.w3.org/2000/svg"
                                 viewbox="0 0 640 512">
                              <path fill="currentColor"
                                    d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z">
                              </path>
                            </svg>-->

            </div>
          </div>
        </div>

        <button v-on:click="resetPassword = true"
                     class="cursor-pointer mt-2 text-sm text-center text-our-gray-80 border border-transparent
                      py-1.5 px-2 transition-colors duration-100 ease-linear rounded-sm hover:text-our-gray-40
                       hover:border-our-gray-120 hover:bg-our-gray-160">
          {{ t('login.resetPassword') }}
        </button>

        <transition name="fade">
          <div class="px-3 py-1 mx-auto mt-4 text-sm font-medium rounded-md bg-error text-error"
               v-show="authError">
            {{ t('login.incorrectAlert') }}
          </div>
        </transition>

        <div class="mt-6 w-full">
          <button type="button"
                  v-on:click="login()"
                  class="text-base relative justify-center w-full px-4 leading-7 rounded-sm button bg-our-cyan-50 text-white px-4 py-0.5">
                <span class="absolute inset-y-0 left-0 flex items-center pl-3">
                  <transition name="fade"
                              mode="out-in">
                  <Loader class="w-4 h-4 text-white"
                          v-if="sending"></Loader>
                  <svg v-else
                       width="24"
                       height="24"
                       class="transition duration-100 ease-linear group-hover:text-white"
                       viewBox="0 0 24 24"
                       fill="currentColor"
                       xmlns="http://www.w3.org/2000/svg">
                                  <path
                                      d="M19.0715 19.071C18.681 19.4616 18.0478 19.4616 17.6573 19.071L16.9502 18.3639L16.2431 19.071C15.8526 19.4616 15.2194 19.4616 14.8289 19.071C14.4663 18.7084 14.4403 18.1366 14.7512 17.744L14.8289 17.6568L15.536 16.9497L14.8289 16.2426L13.4147 17.6568C13.0241 18.0473 12.391 18.0473 12.0005 17.6568C11.6378 17.2942 11.6119 16.7223 11.9227 16.3298L12.0005 16.2426L13.4147 14.8284L9.87913 11.2929C9.84926 11.263 9.82168 11.2317 9.79638 11.1992C8.26177 12.1103 6.24917 11.9055 4.92939 10.5858C3.36729 9.02365 3.36729 6.49099 4.92939 4.9289C6.49148 3.3668 9.02414 3.3668 10.5862 4.9289C11.906 6.24868 12.1108 8.26128 11.2004 9.796L11.2476 9.83557L19.0715 17.6568C19.462 18.0473 19.462 18.6805 19.0715 19.071ZM9.17203 9.17154C9.95307 8.39049 9.95307 7.12416 9.17203 6.34311C8.39098 5.56206 7.12465 5.56206 6.3436 6.34311C5.56255 7.12416 5.56255 8.39049 6.3436 9.17154C7.12465 9.95259 8.39098 9.95259 9.17203 9.17154Z"/>
                                  </svg>
                  </transition>
                </span>
            <span>{{ t('login.logIn') }}</span>
          </button>
        </div>
      </div>

      <div v-else-if="chooseServer"
           class="mt-8">
        <template v-for="(server, index) in servers"
                  :key="server.id">
          <div @click="localLogin(index)"

               :class="{
                'rounded-t-md': index === 0,
                'rounded-b-md': index === servers.length-1,
                   }"

               class="relative rounded-none -mb-0.5 justify-center w-full h-12 px-4 text-sm button button-gray group border-our-gray-140 text-our-gray-40 cursor-pointer">
            <span>{{ server.Name }}</span>
          </div>
        </template>
      </div>

      <div v-else-if="resetPassword"
          class="flex flex-col justify-center items-center w-full">
        <div class="-gap-y-px w-full">
          <div class="relative w-full">
            <label for="email-address"
                   class="sr-only">{{ t('login.emailAddressOrUsername') }}</label>
            <input v-model="email"
                   v-on:input="authError = false"
                   v-on:keyup.enter="forgotPassword(email)"
                   id="email-address"
                   name="email"
                   type="text"
                   required
                   class="form-input-auth rounded-none rounded-t-sm"
                   placeholder="Email address">

            <div class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
                 :class="{ 'hidden' : !authError }">
              <div class="p-1 rounded-full bg-error text-error">
                <svg width="24"
                     height="24"
                     viewBox="0 0 24 24"
                     fill="currentColor"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M12 2C17.523 2 22 6.478 22 12C22 17.522 17.523 22 12 22C6.477 22 2 17.522 2 12C2 6.478 6.477 2 12 2ZM12.0018 15.0037C11.4503 15.0037 11.0031 15.4508 11.0031 16.0024C11.0031 16.5539 11.4503 17.001 12.0018 17.001C12.5533 17.001 13.0005 16.5539 13.0005 16.0024C13.0005 15.4508 12.5533 15.0037 12.0018 15.0037ZM11.9996 7C11.4868 7.00018 11.0643 7.38638 11.0067 7.88374L11 8.00036L11.0018 13.0012L11.0086 13.1179C11.0665 13.6152 11.4893 14.0011 12.0022 14.0009C12.515 14.0007 12.9375 13.6145 12.9951 13.1171L13.0018 13.0005L13 7.99964L12.9932 7.88302C12.9353 7.3857 12.5125 6.99982 11.9996 7Z"/>
                </svg>
              </div>
            </div>

          </div>
        </div>

        <transition name="fade">
          <div class="px-3 py-1 mx-auto mt-4 text-sm font-medium rounded-md bg-error text-error"
               v-show="authError">
            {{ t('login.incorrectAlert') }}
          </div>
        </transition>

        <div class="mt-6 w-full">
          <button type="button"
                  v-on:click="forgotPassword(email)"
                  class="text-base relative justify-center w-full px-4 leading-7 rounded-sm button bg-our-cyan-50 px-4 py-0.5 text-white">
                <span class="absolute inset-y-0 left-0 flex items-center pl-3">
                  <transition name="fade"
                              mode="out-in">
                  <Loader class="w-4 h-4 text-white"
                          v-if="sending"></Loader>
                  <svg v-else
                       width="24"
                       height="24"
                       class="transition duration-100 ease-linear group-hover:text-white"
                       viewBox="0 0 24 24"
                       fill="currentColor"
                       xmlns="http://www.w3.org/2000/svg">
                                  <path
                                      d="M19.0715 19.071C18.681 19.4616 18.0478 19.4616 17.6573 19.071L16.9502 18.3639L16.2431 19.071C15.8526 19.4616 15.2194 19.4616 14.8289 19.071C14.4663 18.7084 14.4403 18.1366 14.7512 17.744L14.8289 17.6568L15.536 16.9497L14.8289 16.2426L13.4147 17.6568C13.0241 18.0473 12.391 18.0473 12.0005 17.6568C11.6378 17.2942 11.6119 16.7223 11.9227 16.3298L12.0005 16.2426L13.4147 14.8284L9.87913 11.2929C9.84926 11.263 9.82168 11.2317 9.79638 11.1992C8.26177 12.1103 6.24917 11.9055 4.92939 10.5858C3.36729 9.02365 3.36729 6.49099 4.92939 4.9289C6.49148 3.3668 9.02414 3.3668 10.5862 4.9289C11.906 6.24868 12.1108 8.26128 11.2004 9.796L11.2476 9.83557L19.0715 17.6568C19.462 18.0473 19.462 18.6805 19.0715 19.071ZM9.17203 9.17154C9.95307 8.39049 9.95307 7.12416 9.17203 6.34311C8.39098 5.56206 7.12465 5.56206 6.3436 6.34311C5.56255 7.12416 5.56255 8.39049 6.3436 9.17154C7.12465 9.95259 8.39098 9.95259 9.17203 9.17154Z"/>
                                  </svg>
                  </transition>
                </span>
            <span>{{ t('login.resetPasswordBtn') }}</span>
          </button>
        </div>

        <button v-on:click="resetPassword = false"
                class="cursor-pointer mt-2 text-sm text-center text-our-gray-80 border border-transparent py-1.5 px-2 transition-colors duration-100 ease-linear rounded-sm hover:text-our-gray-40 hover:border-our-gray-120 hover:bg-our-gray-160">
        {{ t('login.goBack') }}
        </button>
      </div>

      <div v-if="chooseServer" class="mt-4 text-center mx-auto w-full h-6">
      <Loader class="w-4 h-4 text-white mx-auto"
              v-if="sending"></Loader>
      </div>
    </div>

  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import store from "@/store";
import Notification from "@/components/Notification";
import axios from "axios";

export default {
  name: "Login",
  components: {
    Notification
  },
  setup() {
    return useI18n()
  },
  data() {
    return {
      loaded: false,
      email: '',
      password: '',
      showPassword: false,
      sending: false,
      authError: false,
      servers: [],
      chooseServer: false,
      resetPassword: false,
    }
  },
  methods: {
    hideError(e) {
      if (e.key == 'Enter') return;
      this.$store.dispatch("resetAuthError");
    },
    async forgotPassword(email){

      this.sending = true

      if(localStorage.getItem('strapi-token') || localStorage.getItem('strapi-auth-token')){
        store.dispatch('Login/signOut')
      }

      await this.$axios.post('/auth/forgot-password',
          { email: email },{withCredentials:false}).then(async res => {

        if (res.status === 200) {
          setTimeout(() => {
            this.sending = false
            store.dispatch('Notifications/send', {
              variant: 'success',
              message1: this.t('login.notification.sendEmailResetPasswordSuccessful'),
              message2: this.t('login.notification.sendEmailResetPasswordSuccessfulAlert'),
              time: new Date().toISOString(),
              duration: 7000,
              overlay: false
            })
            this.email = ''
            this.resetPassword = false
          }, 1000)
        }

      }).catch(e => {
        setTimeout(() => {
            store.dispatch('Notifications/send', {
              variant: 'error',
              message1: this.t('login.notification.didntFindEnvironmentUnderEmail'),
              time: new Date().toISOString(),
              duration: 10000,
              overlay: false
            })
          }, 300)
        this.sending = false
      })
    },
    localLogin(serverIndex) {
      let _this = this;
      let serverToUse;
      setTimeout(function () {
        _this.sending = true;
      },250);

      if (localStorage.signageBaseUrl === 'null') {
        localStorage.signageBaseUrl = 'undefined';
      }

      if (serverIndex === null) {
        serverToUse = localStorage.signageBaseUrl;
        if (serverToUse === 'undefined') {
          this.login();
        }
      }
      else {
        serverToUse = this.servers[serverIndex];
        localStorage.signageServerName = serverToUse.Name
        if (!!localStorage.signageBaseUrl && localStorage.signageBaseUrl !== 'undefined') { // if localStorage exists...
          serverToUse = localStorage.signageBaseUrl;
        }
        else {
          localStorage.signageBaseUrl = serverToUse.Domain + '/';
        }
      }

      this.$axios.defaults.baseURL = localStorage.signageBaseUrl
      this.$axios.defaults.withCredentials = true


      this.$store.dispatch("Login/logIn", { identifier: this.email, password: this.password }).then(response => {
        setTimeout(() => {
          if (response.status === 200) {
            _this.$router.push('/')
          }
        }, 500)
      }).catch(e => {
        this.sending = false;
        console.log("error.response");
        console.error(e.response);
        setTimeout(() => {
          delete localStorage.signageBaseUrl;
          delete localStorage.signageServerName;
          axios.defaults.baseURL = process.env.VUE_APP_AUTH_SERVER
          _this.authError = true
          _this.sending = false

          console.log("e",{e});

          if (e.response.status !== 400) {
            store.dispatch('Notifications/send', {
              variant: 'error',
              message1: this.t('login.notification.serverDoesntSeemRightNow'),
              message2: this.t('login.notification.tryRefreshBrowserOrContactSupport'),
              time: new Date().toISOString(),
              duration: 5000,
              overlay: false
            })
          }
          _this.notifBadLogin(e);
        }, 300)
      })
    },
    async login() {
        //if (this.$refs.form.reportValidity()) { TODO musel jsem to zakomentovat, protože to házelo error (form byl undefined)

        this.sending = true

        if(localStorage.getItem('strapi-token') || localStorage.getItem('strapi-auth-token')){
          store.dispatch('Login/signOut')
        }

        if (!!localStorage.signageBaseUrl && localStorage.signageBaseUrl !== 'undefined') { // if localStorage exists...
          this.localLogin(null)
        }
        else {
          let _this = this;
          let authLogin = await this.$axios.post('/auth/local',
              { identifier: _this.email, password: _this.password },{withCredentials:false}).then(async res => {
            let authReady = false;

            if(this.$axios.defaults.baseURL === process.env.VUE_APP_AUTH_SERVER){
              let token = 'Bearer ' + res.data.jwt
							localStorage.setItem('strapi-auth-token', token)
						}

            this.$axios.defaults.withCredentials = false
            let authResponse = await this.$axios.get('/servers-user/' + this.email).then(res => {
              _this.servers = res.data;
              if (_this.servers.length > 1) {
                _this.sending = false;
                _this.chooseServer = true;
              }
              else {
                _this.localLogin(0);
              }
            }).catch(e => {
              console.warn({ e });

              setTimeout(() => {
                this.authError = true
                this.sending = false
                store.dispatch('Notifications/send', {
                  variant: 'error',
                  message1: this.t('login.notification.loginError'),
                  message2: this.t('login.notification.didntFindEnvironmentUnderEmail'),
                  time: new Date().toISOString(),
                  duration: 7000,
                  overlay: false
                })
              }, 300)

            });
            console.log(authResponse);
          }).catch(e => {
            _this.authError = true
            _this.sending = false
            _this.notifBadLogin(e);
          })
        }
      //}
    },
    notifBadLogin(e) {
      store.dispatch('Notifications/send', {
        variant: 'error',
        message1: e.response.data.error,
        message2: e.response.data.message[0]?.messages[0]?.message, // Strapi way of doing things
        time: new Date().toISOString(),
        duration: 5000,
        overlay: false
      })
    }
  },
  mounted() {
    this.loaded = true
    if (this.$route.query.email) {
      this.email = this.$route.query.email;
    }
  },
  beforeUnmount() {
    this.loaded = false
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated
    },
    /*authError() {
     return this.$store.getters['Login/hasAuthError']
     },*/
    /*subtitlesToken: {
     get: function () {
     return this.$store.getters.getSubtitlesToken
     },
     set: function (val) {
     console.log('Token: ', val)
     this.$store.dispatch("saveSubtitlesToken", val);
     }
     }*/
  },
  /*watch: {
   isAuthenticated: {
   immediate: true,
   handler (auth) {
   /!*console.log(auth)*!/
   this.$router.push(this.$route.query.return_url || '/');
   }
   }
   }*/
}
</script>

<style scoped>
  input:-webkit-autofill::first-line,
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-transition: background-color 5000s ease-in-out 0s !important;
    transition: background-color 500000s ease-in-out 0s !important;
    -webkit-box-shadow: 0 0 0 30px #323130 inset !important;
    -webkit-text-fill-color: white !important;
    color: #FFF !important;
  }
</style>
