<template>

  <div class="min-w-xs">
    <div v-if="loaded">

      <div v-if="resetPassword">
        <h1 class="capsize text-our-gray-40 text-xl font-medium text-center">{{ t('tokenLogin.pleaseEnterNewPasswordBelow') }}</h1>
      </div>

      <div v-else-if="!isExistUser">
        <h1 class="capsize text-our-gray-40 text-xl font-medium text-center mt-4">{{ createdByUserFullname }}{{ t('tokenLogin.inviteYouToJoin') }}{{ serverName }}</h1>

        <div class="mt-4 w-full">
          <div class="mb-4 mt-4 text-center">
              <p class="text-white">{{ t('signUp.importantDocuments') }}:
              <br>
              <a href="https://www.bohemicasignage.com/tos" target="_blank" class="text-our-gray-70 hover:underline">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                </svg> {{ t('signUp.termsOfService') }}
              </a>
              <br>
              <a href="https://www.bohemicasignage.com/privacy" target="_blank" class="text-our-gray-70 hover:underline">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                </svg> {{ t('signUp.privacyPolicy') }}
                </a>
                <br>
              </p>
            </div>

            <div class="">
              <div class="relative flex items-start form-input-auth rounded-none rounded-t">
                <div class="flex items-center h-5">
                  <input id="termsOfService"
                        v-model="termsOfService"
                        v-on:input="validErrorTermsOfService = false"
                        aria-describedby="termsOfService-description"
                        name="termsOfService"
                        type="checkbox"
                        required
                        class="mt-3 h-6 w-6 text-our-cyan-50 border-gray-300 rounded">
                </div>
                <div class="ml-3 text-sm mt-1">
                  <label for="termsOfService" class="font-medium text-gray-700">{{ t('signUp.termsOfService') }} * </label>
                    <p id="termsOfService-description" class="font-normal text-our-gray-70 leading-tight">{{ t('signUp.confirmTerms') }}</p>
                </div>
                <div class="absolute inset-y-0 right-0 flex items-center pr-3 z-50 group">
                  <div class="p-1 rounded-full bg-error text-error pointer-events-none"
                      v-show="validErrorTermsOfService">
                    <svg width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12 2C17.523 2 22 6.478 22 12C22 17.522 17.523 22 12 22C6.477 22 2 17.522 2 12C2 6.478 6.477 2 12 2ZM12.0018 15.0037C11.4503 15.0037 11.0031 15.4508 11.0031 16.0024C11.0031 16.5539 11.4503 17.001 12.0018 17.001C12.5533 17.001 13.0005 16.5539 13.0005 16.0024C13.0005 15.4508 12.5533 15.0037 12.0018 15.0037ZM11.9996 7C11.4868 7.00018 11.0643 7.38638 11.0067 7.88374L11 8.00036L11.0018 13.0012L11.0086 13.1179C11.0665 13.6152 11.4893 14.0011 12.0022 14.0009C12.515 14.0007 12.9375 13.6145 12.9951 13.1171L13.0018 13.0005L13 7.99964L12.9932 7.88302C12.9353 7.3857 12.5125 6.99982 11.9996 7Z"/>
                    </svg>
                  </div>
                </div>
              </div>
              <div class="relative flex items-start form-input-auth rounded-none">
                <div class="flex items-center h-5">
                  <input id="privacyPolicy"
                        v-model="privacyPolicy"
                        v-on:input="validErrorPrivacyPolicy = false"
                        aria-describedby="privacyPolicy-description"
                        name="privacyPolicy"
                        type="checkbox"
                        required
                        class="mt-3 h-6 w-6 text-our-cyan-50 border-gray-300 rounded">
                </div>
                <div class="ml-3 text-sm mt-1">
                  <label for="privacyPolicy" class="font-medium text-gray-700">{{ t('signUp.privacyPolicy') }} *</label>
                    <p id="privacyPolicy-description" class="font-normal text-our-gray-70 leading-tight mr-10">{{ t('signUp.confirmPrivacyPolicy') }} </p>
                </div>
                <div class="absolute inset-y-0 right-0 flex items-center pr-3 z-50 group">
                  <div class="p-1 rounded-full bg-error text-error pointer-events-none"
                      v-show="validErrorPrivacyPolicy">
                    <svg width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12 2C17.523 2 22 6.478 22 12C22 17.522 17.523 22 12 22C6.477 22 2 17.522 2 12C2 6.478 6.477 2 12 2ZM12.0018 15.0037C11.4503 15.0037 11.0031 15.4508 11.0031 16.0024C11.0031 16.5539 11.4503 17.001 12.0018 17.001C12.5533 17.001 13.0005 16.5539 13.0005 16.0024C13.0005 15.4508 12.5533 15.0037 12.0018 15.0037ZM11.9996 7C11.4868 7.00018 11.0643 7.38638 11.0067 7.88374L11 8.00036L11.0018 13.0012L11.0086 13.1179C11.0665 13.6152 11.4893 14.0011 12.0022 14.0009C12.515 14.0007 12.9375 13.6145 12.9951 13.1171L13.0018 13.0005L13 7.99964L12.9932 7.88302C12.9353 7.3857 12.5125 6.99982 11.9996 7Z"/>
                    </svg>
                  </div>
                </div>
              </div>
              <div class="relative flex items-start form-input-auth rounded-none rounded-b">
                <div class="flex items-center h-5">
                  <input id="marketingEmails" v-model="marketingEmails" aria-describedby="marketingEmails-description" name="marketingEmails" type="checkbox" class="mt-3 h-6 w-6 text-our-cyan-50 border-gray-300 rounded">
                </div>
                <div class="ml-3 text-sm">
                  <label for="marketingEmails" class="font-medium text-gray-700">{{ t('signUp.receiveMarketingEmails') }}</label>
                    <p id="marketingEmails-description" class="font-normal text-our-gray-70 leading-tight">{{ t('signUp.occasionalPromotionEmails') }}</p>
                </div>
              </div>
            </div>
        </div>

        <h1 class="capsize text-our-gray-40 text-xl font-medium text-center mt-8">{{ t('tokenLogin.pleaseEnterNewPasswordBelow') }}</h1>
      </div>
      <div v-else-if="isExistUser">
        <h1 class="capsize text-our-gray-40 text-xl font-medium text-center">{{ createdByUserFullname }}{{ t('tokenLogin.inviteYouToJoin') }}{{ serverName }}</h1>
        <h2 class="capsize text-our-gray-40 text font-medium text-center mt-4">{{ t('tokenLogin.youAlreadyHaveBohemicaSignageAccount') }}</h2>
        <h2 class="capsize text-our-gray-40 text font-medium text-center ">{{ t('tokenLogin.nextTimeYouLogIn') }}</h2>
      </div>

      <div class="mt-8 w-full">
        <div v-if="!isExistUser" class="flex flex-col justify-center items-center w-full">

          <div class="-gap-y-px w-full">
            <div class="relative">
              <label for="password"
                    class="sr-only">{{ t('login.password') }}</label>
              <input v-model="password"
                    v-on:input="authError = false"
                    v-bind:type="showPassword ? 'text' : 'password'"
                    id="password"
                    name="password"
                    required
                     autocomplete="new-password"
                    class="form-input-auth rounded-none rounded-b-sm"
                    placeholder="Password">

              <div class="absolute inset-y-0 right-0 flex items-center pr-3 z-50 group">
                <div class="p-1 rounded-full bg-error text-error pointer-events-none"
                    v-show="passwordsMustMatchError || weakPasswordError || requiredError">
                  <svg width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12 2C17.523 2 22 6.478 22 12C22 17.522 17.523 22 12 22C6.477 22 2 17.522 2 12C2 6.478 6.477 2 12 2ZM12.0018 15.0037C11.4503 15.0037 11.0031 15.4508 11.0031 16.0024C11.0031 16.5539 11.4503 17.001 12.0018 17.001C12.5533 17.001 13.0005 16.5539 13.0005 16.0024C13.0005 15.4508 12.5533 15.0037 12.0018 15.0037ZM11.9996 7C11.4868 7.00018 11.0643 7.38638 11.0067 7.88374L11 8.00036L11.0018 13.0012L11.0086 13.1179C11.0665 13.6152 11.4893 14.0011 12.0022 14.0009C12.515 14.0007 12.9375 13.6145 12.9951 13.1171L13.0018 13.0005L13 7.99964L12.9932 7.88302C12.9353 7.3857 12.5125 6.99982 11.9996 7Z"/>
                  </svg>
                </div>

                <svg v-show="!showPassword && !authError"
                    v-on:click="showPassword = true"
                    class="text-our-gray-40 cursor-pointer select-none group-hover:text-white transition-colors ease-linear duration-150"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    viewBox="0 0 24 24">
                  <path d="M12 9.005a4 4 0 110 8 4 4 0 010-8zm0 1.5a2.5 2.5 0 100 5 2.5 2.5 0 000-5zM12 5.5c4.614 0 8.596 3.15 9.701 7.564a.75.75 0 11-1.455.365 8.504 8.504 0 00-16.493.004.75.75 0 01-1.455-.363A10.003 10.003 0 0112 5.5z"/>
                </svg>

                <svg v-show="showPassword && !authError"
                    v-on:click="showPassword = false"
                    class="text-our-gray-40 cursor-pointer select-none group-hover:text-white transition-colors ease-linear duration-150"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    viewBox="0 0 24 24">
                  <path d="M2.22 2.22a.75.75 0 00-.073.976l.073.084 4.034 4.035a9.986 9.986 0 00-3.955 5.75.75.75 0 001.455.364 8.49 8.49 0 013.58-5.034l1.81 1.81A4 4 0 0014.8 15.86l5.919 5.92a.75.75 0 001.133-.977l-.073-.084-6.113-6.114.001-.002-6.95-6.946.002-.002-1.133-1.13L3.28 2.22a.75.75 0 00-1.06 0zM12 5.5c-1 0-1.97.148-2.889.425l1.237 1.236a8.503 8.503 0 019.899 6.272.75.75 0 001.455-.363A10.003 10.003 0 0012 5.5zm.195 3.51l3.801 3.8a4.003 4.003 0 00-3.801-3.8z"/>
                </svg>

              </div>
            </div>
          </div>

          <div class="-gap-y-px  w-full">
            <div class="relative">
              <label for="password2"
                    class="sr-only">{{ t('login.password') }}</label>
              <input v-model="password2"
                    v-on:input="authError = false"
                    v-bind:type="showPassword2 ? 'text' : 'password'"
                    id="password2"
                    name="password2"
                    required
                     autocomplete="new-password"
                    class="form-input-auth rounded-none rounded-b-sm"
                    placeholder="Confirm password">

              <div class="absolute inset-y-0 right-0 flex items-center pr-3 z-50 group">
                <div class="p-1 rounded-full bg-error text-error pointer-events-none"
                    v-show="passwordsMustMatchError || weakPasswordError || requiredError">
                  <svg width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12 2C17.523 2 22 6.478 22 12C22 17.522 17.523 22 12 22C6.477 22 2 17.522 2 12C2 6.478 6.477 2 12 2ZM12.0018 15.0037C11.4503 15.0037 11.0031 15.4508 11.0031 16.0024C11.0031 16.5539 11.4503 17.001 12.0018 17.001C12.5533 17.001 13.0005 16.5539 13.0005 16.0024C13.0005 15.4508 12.5533 15.0037 12.0018 15.0037ZM11.9996 7C11.4868 7.00018 11.0643 7.38638 11.0067 7.88374L11 8.00036L11.0018 13.0012L11.0086 13.1179C11.0665 13.6152 11.4893 14.0011 12.0022 14.0009C12.515 14.0007 12.9375 13.6145 12.9951 13.1171L13.0018 13.0005L13 7.99964L12.9932 7.88302C12.9353 7.3857 12.5125 6.99982 11.9996 7Z"/>
                  </svg>
                </div>

                <svg v-show="!showPassword2 && !authError"
                    v-on:click="showPassword2 = true"
                    class="text-our-gray-40 cursor-pointer select-none group-hover:text-white transition-colors ease-linear duration-150"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    viewBox="0 0 24 24">
                  <path d="M12 9.005a4 4 0 110 8 4 4 0 010-8zm0 1.5a2.5 2.5 0 100 5 2.5 2.5 0 000-5zM12 5.5c4.614 0 8.596 3.15 9.701 7.564a.75.75 0 11-1.455.365 8.504 8.504 0 00-16.493.004.75.75 0 01-1.455-.363A10.003 10.003 0 0112 5.5z"/>
                </svg>

                <svg v-show="showPassword2 && !authError"
                    v-on:click="showPassword2 = false"
                    class="text-our-gray-40 cursor-pointer select-none group-hover:text-white transition-colors ease-linear duration-150"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    viewBox="0 0 24 24">
                  <path d="M2.22 2.22a.75.75 0 00-.073.976l.073.084 4.034 4.035a9.986 9.986 0 00-3.955 5.75.75.75 0 001.455.364 8.49 8.49 0 013.58-5.034l1.81 1.81A4 4 0 0014.8 15.86l5.919 5.92a.75.75 0 001.133-.977l-.073-.084-6.113-6.114.001-.002-6.95-6.946.002-.002-1.133-1.13L3.28 2.22a.75.75 0 00-1.06 0zM12 5.5c-1 0-1.97.148-2.889.425l1.237 1.236a8.503 8.503 0 019.899 6.272.75.75 0 001.455-.363A10.003 10.003 0 0012 5.5zm.195 3.51l3.801 3.8a4.003 4.003 0 00-3.801-3.8z"/>
                </svg>

              </div>
            </div>
          </div>

          <transition name="fade">
            <div class="px-3 py-1 mx-auto mt-4 text-sm font-medium rounded-md bg-error text-error"
                v-show="requiredError">
              {{ t('tokenLogin.passwordRequired') }}
            </div>
          </transition>

          <transition name="fade">
            <div class="px-3 py-1 mx-auto mt-4 text-sm font-medium rounded-md bg-error text-error"
                v-show="weakPasswordError">
              {{ t('tokenLogin.weakPassword') }}
            </div>
          </transition>

          <transition name="fade">
            <div class="px-3 py-1 mx-auto mt-4 text-sm font-medium rounded-md bg-error text-error"
                v-show="passwordsMustMatchError">
              {{ t('tokenLogin.passwordsMustMatch') }}
            </div>
          </transition>

          <div class="mt-6 w-full">
            <button type="button"
                    v-on:click="createPassword()"
                    class="text-base relative justify-center w-full px-4 leading-7 rounded-sm button  bg-our-cyan-50 text-white px-4 py-0.5">
                  <span class="absolute inset-y-0 left-0 flex items-center pl-3">
                    <transition name="fade"
                                mode="out-in">
                    <Loader class="w-4 h-4 text-white"
                            v-if="sending"></Loader>
                    <svg v-else
                        width="24"
                        height="24"
                        class="transition duration-100 ease-linear group-hover:text-white"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M19.0715 19.071C18.681 19.4616 18.0478 19.4616 17.6573 19.071L16.9502 18.3639L16.2431 19.071C15.8526 19.4616 15.2194 19.4616 14.8289 19.071C14.4663 18.7084 14.4403 18.1366 14.7512 17.744L14.8289 17.6568L15.536 16.9497L14.8289 16.2426L13.4147 17.6568C13.0241 18.0473 12.391 18.0473 12.0005 17.6568C11.6378 17.2942 11.6119 16.7223 11.9227 16.3298L12.0005 16.2426L13.4147 14.8284L9.87913 11.2929C9.84926 11.263 9.82168 11.2317 9.79638 11.1992C8.26177 12.1103 6.24917 11.9055 4.92939 10.5858C3.36729 9.02365 3.36729 6.49099 4.92939 4.9289C6.49148 3.3668 9.02414 3.3668 10.5862 4.9289C11.906 6.24868 12.1108 8.26128 11.2004 9.796L11.2476 9.83557L19.0715 17.6568C19.462 18.0473 19.462 18.6805 19.0715 19.071ZM9.17203 9.17154C9.95307 8.39049 9.95307 7.12416 9.17203 6.34311C8.39098 5.56206 7.12465 5.56206 6.3436 6.34311C5.56255 7.12416 5.56255 8.39049 6.3436 9.17154C7.12465 9.95259 8.39098 9.95259 9.17203 9.17154Z"/>
                                    </svg>
                    </transition>
                  </span>
              <span>{{ t('tokenLogin.createPassword') }}</span>
            </button>
          </div>
        </div>

        <div v-else-if="isExistUser" class="mt-8">
          <div class="-gap-y-px w-full">
            <div class="relative">

              <button type="button"
                    v-on:click="confirmNewInstance()"
                    class="text-base relative justify-center w-full px-4 leading-7 rounded-sm button  bg-our-cyan-50 text-white px-4 py-0.5">
                  <span class="absolute inset-y-0 left-0 flex items-center pl-3">
                    <transition name="fade"
                                mode="out-in">
                    <Loader class="w-4 h-4 text-white"
                            v-if="sending"></Loader>
                    <svg v-else
                        width="24"
                        height="24"
                        class="transition duration-100 ease-linear group-hover:text-white"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M19.0715 19.071C18.681 19.4616 18.0478 19.4616 17.6573 19.071L16.9502 18.3639L16.2431 19.071C15.8526 19.4616 15.2194 19.4616 14.8289 19.071C14.4663 18.7084 14.4403 18.1366 14.7512 17.744L14.8289 17.6568L15.536 16.9497L14.8289 16.2426L13.4147 17.6568C13.0241 18.0473 12.391 18.0473 12.0005 17.6568C11.6378 17.2942 11.6119 16.7223 11.9227 16.3298L12.0005 16.2426L13.4147 14.8284L9.87913 11.2929C9.84926 11.263 9.82168 11.2317 9.79638 11.1992C8.26177 12.1103 6.24917 11.9055 4.92939 10.5858C3.36729 9.02365 3.36729 6.49099 4.92939 4.9289C6.49148 3.3668 9.02414 3.3668 10.5862 4.9289C11.906 6.24868 12.1108 8.26128 11.2004 9.796L11.2476 9.83557L19.0715 17.6568C19.462 18.0473 19.462 18.6805 19.0715 19.071ZM9.17203 9.17154C9.95307 8.39049 9.95307 7.12416 9.17203 6.34311C8.39098 5.56206 7.12465 5.56206 6.3436 6.34311C5.56255 7.12416 5.56255 8.39049 6.3436 9.17154C7.12465 9.95259 8.39098 9.95259 9.17203 9.17154Z"/>
                                    </svg>
                    </transition>
                  </span>
              <span>{{ t('tokenLogin.confirm') }}</span>
            </button>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div v-else class="mt-4 text-center mx-auto w-full h-6">
      <Loader class="w-4 h-4 text-white mx-auto"></Loader>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import Notification from "@/components/Notification";

export default {
  name: "Login",
  components: {
    Notification
  },
  setup() {
    return useI18n()
  },
  data() {
    return {
      loaded: false,
      email: '',
      password: '',
      password2: '',
      termsOfService: false,
      privacyPolicy: false,
      marketingEmails: false,
      showPassword: false,
      showPassword2: false,
      sending: false,
      authError: false,
      requiredError: false,
      weakPasswordError: false,
      passwordsMustMatchError: false,
      validErrorTermsOfService: false,
      validErrorPrivacyPolicy: false,
      createdByUserFullname: '',
      serverName: '',
      isExistUser: null,
      resetPassword: null
    }
  },
  methods: {
    hideError(e) {
      if (e.key == 'Enter') return;
      this.$store.dispatch("resetAuthError");
    },
    createPassword(){

      this.sending = true
      this.requiredError = false
      this.weakPasswordError = false
      this.passwordsMustMatchError = false
      this.validErrorTermsOfService = false
      this.validErrorPrivacyPolicy = false

      if(!this.password){
        this.authError = true
        setTimeout(() => {
          this.requiredError = true
          this.sending = false
        }, 700)
      }

      if(this.password.length < 8){
      this.authError = true
        setTimeout(() => {
          this.weakPasswordError = true
          this.sending = false
        }, 700)
      }

      if(this.password != this.password2){
        this.authError = true
        setTimeout(() => {
          this.passwordsMustMatchError = true
          this.sending = false
        }, 700)
      }

      if(!this.resetPassword){
        if (!this.termsOfService) {
          this.validErrorTermsOfService = true
          setTimeout(() => {
            this.sending = false
          }, 700)
        }

        if (!this.privacyPolicy) {
          this.validErrorPrivacyPolicy = true
          setTimeout(() => {
            this.sending = false
          }, 700)
        }
      }

      if (!(this.authError ||
            this.validErrorTermsOfService ||
            this.validErrorPrivacyPolicy)) {

        if(this.resetPassword){

          this.$api.users.resetPassword({
              password: this.password,
              passwordConfirmation: this.password2,
              code: this.$route.query.code,
            }).then(response => {

            this.$store.dispatch('Notifications/send', {
              variant: 'success',
              message1: this.t('global.notification.successfullyCreated'),
              time: new Date().toISOString(),
              duration: 1500,
              overlay: false
            })

            setTimeout(() => {
              setTimeout(() => {
                this.$router.push({name: 'Login', query: {
                  email: response.data.user.email,
                  resetPassword: true
                }})
              }, 1500)
              
              this.$store.dispatch('Notifications/send', {
                variant: 'info',
                message1: this.t('global.notification.redirectingInMoment'),
                message2: this.t('global.notification.thisPageRedirectedTo') +
                          '<i>' + this.t('global.menu.login') + '</i>',
                time: new Date().toISOString(),
                duration: 2000,
                overlay: true
              })
            }, 500)

          }).catch(e => {
            this.sending = false
            this.$router.push('/')
          })

        }else{
          this.$api.users.confirmNewInstance({
            loginToken: this.$route.query.loginToken,
            newPassword: this.password,
            server: this.$route.query.server,
            termsOfService: this.termsOfService,
            privacyPolicy: this.privacyPolicy,
            marketingEmails: this.marketingEmails
            }).then(response => {

            this.$store.dispatch('Notifications/send', {
              variant: 'success',
              message1: this.t('global.notification.successfullyConfirmed'),
              message2: this.t('global.notification.successfullyConfirmedAlert'),
              time: new Date().toISOString(),
              duration: 15000,
              overlay: false
            })
            setTimeout(() => {
              setTimeout(() => {
                this.$router.push({name: 'Login', query: {
                  email: response.data.user.email
                }})
              }, 1500)
              this.$store.dispatch('Notifications/send', {
                variant: 'info',
                message1: this.t('global.notification.redirectingInMoment'),
                message2: this.t('global.notification.thisPageRedirectedTo') +
                          '<i>' + this.t('global.menu.login') + '</i>',
                time: new Date().toISOString(),
                duration: 2000,
                overlay: true
              })
            }, 500)

          }).catch(e => {
            this.sending = false
            this.$router.push('/')
          })
        }

      }
    },
    confirmNewInstance(){

      this.sending = true
      let newPassword = false

      this.$api.users.confirmNewInstance({loginToken: this.$route.query.loginToken, newPassword: newPassword, server: this.$route.query.server}).then(response => {

        this.$store.dispatch('Notifications/send', {
          variant: 'success',
          message1: this.t('global.notification.successfullyConfirmed'),
          message2: this.t('global.notification.successfullyConfirmedAlert'),
          time: new Date().toISOString(),
          duration: 15000,
          overlay: false
        })
        setTimeout(() => {
          setTimeout(() => {
            this.$router.push('/login')
          }, 1500)
          this.$store.dispatch('Notifications/send', {
            variant: 'info',
            message1: this.t('global.notification.redirectingInMoment'),
            message2: this.t('global.notification.thisPageRedirectedTo') +
                      '<i>' + this.t('global.menu.login') + '</i>',
            time: new Date().toISOString(),
            duration: 2000,
            overlay: true
          })
        }, 500)

      }).catch(e => {
        this.sending = false
        this.$router.push('/')
      })
    },
  },
  created(){
    if(localStorage.getItem('strapi-token') || localStorage.getItem('strapi-auth-token')){
      this.$store.dispatch('Login/signOut')
    }
    if(this.$route.query.server){ // server - create new user
      this.$api.users.checkValidTokenClient({loginToken: this.$route.query.loginToken, server: this.$route.query.server}).then(response => {
        this.isExistUser = response.data.isExistUser
        this.createdByUserFullname = response.data?.createdByUserFullname
        this.serverName = response.data?.serverName
        this.loaded = true
      }).catch(e => {
        this.$router.push('/')
      })
    }else{ // without server - forgot pass - create new pass
      this.$api.users.checkValidTokenAuth({token: this.$route.query.code}).then(response => {
        this.isExistUser = response.data.isExistUser
        this.resetPassword = response.data.resetPassword
        this.loaded = true
      }).catch(e => {
        this.$router.push('/')
      })
    }

  }
}
</script>

<style scoped>
  input:-webkit-autofill::first-line,
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-transition: background-color 5000s ease-in-out 0s !important;
    transition: background-color 500000s ease-in-out 0s !important;
    -webkit-box-shadow: 0 0 0 30px #323130 inset !important;
    -webkit-text-fill-color: white !important;
    color: #FFF !important;
  }
</style>
