<template>
  <div>
    <!--<pre class="text-xs">{{ calendarItemData.calendarItem }}</pre>-->

    <div class="flex flex-row items-center justify-center w-full p-2 gap-x-4 rounded-xl bg-our-gray-50">

      <!--<select class="items-center w-24 h-12 py-2 pl-3 pr-10 text-sm font-semibold transition-all duration-150 ease-linear bg-white border border-transparent rounded-lg outline-none form-select text-our-gray-160 border-our-gray-130 hover:border-our-gray-160 focus:outline-none focus:border-our-gray-190border-light-highlight-level-1">
        <option>AND</option>
        <option>OR</option>
      </select>-->

      <div class="flex items-center justify-center h-12 px-4 text-sm font-semibold transition-all duration-150 duration-300 ease-linear bg-white border border-transparent rounded-lg" :class="{ 'opacity-0' : index === 0 }" v-if="data.calendarItemsActiveLength > 1">
        <!--<div class="flex items-center justify-center w-full h-full px-3 rounded-md">-->
        <span class="text-sm font-semibold tracking-wide uppercase text-our-gray-200">{{ t('calendar.and') }}</span>
      </div>


      <select v-model="calendarItemData.calendarItem.precondition" class="w-auto ml-1 rounded-md form-select h-10">
        <option v-for="precondition in $store.state.Calendars.calendarItemPreconditions" :value="precondition.order" :key="precondition.order">
          {{ precondition.name }}
        </option>
      </select>

      <!--
            precondition:
              1 - date
              2 - time
              3 - weekday
            -->

      <!--<select v-model="type" class="w-auto ml-1 rounded-md form-select">
        <option value="date" selected>the date</option>
        <option value="time">the time</option>
        <option value="weekday">the weeekday</option>
        &lt;!&ndash;<option disabled>─────────────</option>
        <option>all of</option>
        <option>any of</option>&ndash;&gt;
      </select>-->

      <select v-model="calendarItemData.calendarItem.condition" v-if="calendarItemData.calendarItem.precondition !== 3" class="w-auto rounded-md form-select h-10">
        <option v-if="calendarItemData.calendarItem.precondition === 1" :value="1">{{ t('calendar.condition.isOnOrBefore') }}</option>
        <option v-if="calendarItemData.calendarItem.precondition === 1" :value="3">{{ t('calendar.condition.isOnOrAfter') }}</option>
        <option v-if="calendarItemData.calendarItem.precondition === 2" :value="1">{{ t('calendar.condition.isBefore') }}</option>
        <option v-if="calendarItemData.calendarItem.precondition === 2" :value="4">{{ t('calendar.condition.isBetween') }}</option>
        <option v-if="calendarItemData.calendarItem.precondition === 2" :value="3">{{ t('calendar.condition.isAfter') }}</option>
      </select>

      <!--
            condition:
              1 - before
              2 - exactly
              3 - after
              4 - between
              5 to 11 - weekdays
            -->

      <input :value="calendarItemData.calendarItem.postconditiondate" v-on:input="calendarItemData.calendarItem.postconditiondate = $helpers.date($event.target.value)" v-if="calendarItemData.calendarItem.precondition === 1" type="date" class="items-center h-10 py-2 pl-3 pr-2 text-sm font-semibold transition-all duration-150 ease-linear bg-white border border-transparent rounded-md outline-none w-80 text-our-gray-160 border-our-gray-130 hover:border-our-gray-160 focus:outline-none focus:border-our-gray-190border-light-highlight-level-1">

      <input :value="calendarItemData.calendarItem.postconditiontime1" v-on:input="calendarItemData.calendarItem.postconditiontime1 = $helpers.time($event.target.value)" v-if="calendarItemData.calendarItem.precondition === 2" type="time" class="items-center w-32 h-10 py-2 pl-3 pr-2 text-sm font-semibold transition-all duration-150 ease-linear bg-white border border-transparent rounded-md outline-none text-our-gray-160 border-our-gray-130 hover:border-our-gray-160 focus:outline-none focus:border-our-gray-190border-light-highlight-level-1">
      <span v-if="calendarItemData.calendarItem.precondition === 2">{{ t('calendar.yourUTCTime') }} <span v-if="getTimezone>0">+</span>{{ getTimezone }})</span>

      <div v-if="calendarItemData.calendarItem.precondition === 2 && calendarItemData.calendarItem.condition === 4" class="flex flex-row items-center gap-x-4">
        <span class="text-sm font-semibold">{{ t('calendar.and') }}</span>
        <input :value="calendarItemData.calendarItem.postconditiontime2" v-on:input="calendarItemData.calendarItem.postconditiontime2 = $helpers.time($event.target.value)" type="time" class="items-center w-32 h-10 py-2 pl-3 pr-2 text-sm font-semibold transition-all duration-150 ease-linear bg-white border border-transparent rounded-md outline-none text-our-gray-160 border-our-gray-130 hover:border-our-gray-160 focus:outline-none focus:border-our-gray-190border-light-highlight-level-1">
         <span>{{ t('calendar.yourUTCTime') }} <span v-if="getTimezone>0">+</span>{{ getTimezone }})</span>
      </div>

      <span v-if="calendarItemData.calendarItem.precondition === 3">
      <button type="button" v-on:click="setCalendarItemData(5)" :class="['h-10 text-sm rounded-r-none button rounded-l-md border-our-gray-130 '] + [calendarItemData.calendarItem.condition === 5 ? 'button-gray' : 'button-outline-gray']">
        {{ t('calendar.weekdays.mon') }}
      </button>
      <button type="button" v-on:click="setCalendarItemData(6)" :class="['h-10 -ml-px text-sm rounded-none button border-our-gray-130 '] + [calendarItemData.calendarItem.condition === 6 ? 'button-gray' : 'button-outline-gray']">
        {{ t('calendar.weekdays.tue') }}
      </button>
      <button type="button" v-on:click="setCalendarItemData(7)" :class="['h-10 -ml-px text-sm rounded-none button border-our-gray-130 '] + [calendarItemData.calendarItem.condition === 7 ? 'button-gray' : 'button-outline-gray']">
        {{ t('calendar.weekdays.wed') }}
      </button>
      <button type="button" v-on:click="setCalendarItemData(8)" :class="['h-10 -ml-px text-sm rounded-none button border-our-gray-130 '] + [calendarItemData.calendarItem.condition === 8 ? 'button-gray' : 'button-outline-gray']">
        {{ t('calendar.weekdays.thu') }}
      </button>
      <button type="button" v-on:click="setCalendarItemData(9)" :class="['h-10 -ml-px text-sm rounded-none button border-our-gray-130 '] + [calendarItemData.calendarItem.condition === 9 ? 'button-gray' : 'button-outline-gray']">
        {{ t('calendar.weekdays.fri') }}
      </button>
      <button type="button" v-on:click="setCalendarItemData(10)" :class="['h-10 -ml-px text-sm rounded-none button border-our-gray-130 '] + [calendarItemData.calendarItem.condition === 10 ? 'button-gray' : 'button-outline-gray']">
        {{ t('calendar.weekdays.sat') }}
      </button>
      <button type="button" v-on:click="setCalendarItemData(11)" :class="['h-10 -ml-px text-sm rounded-l-none rounded-r-md button border-our-gray-130 '] + [calendarItemData.calendarItem.condition === 11 ? 'button-gray' : 'button-outline-gray']">
        {{ t('calendar.weekdays.sun') }}
      </button>
    </span>

      <div class="flex justify-end flex-grow">
        <div class="flex flex-row items-center p-2 gap-x-2 bg-white rounded-lg">

          <button :disabled="index === 0" v-on:click="$emit('move-up', calendarItemData.calendarItem.order)" :class="{ 'opacity-50 cursor-not-allowed' : index === 0 }" class="flex items-center justify-center w-8 h-8 text-sm transition-all duration-150 ease-linear border rounded-md outline-none cursor-pointer text-our-gray-190 bg-our-gray-70 border-our-gray-70 hover:text-our-gray-190 hover:border-our-gray-170 focus:border-our-gray-170 focus:outline-none focus:bg-our-gray-170 focus:text-whiteborder-light-highlight-level-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 20 20">
              <path d="M3.202 8.735a.75.75 0 001.09 1.03l4.955-5.239V17.25a.75.75 0 001.5 0V4.524l4.957 5.241a.75.75 0 001.09-1.03l-6.07-6.418a.995.995 0 00-.566-.3.753.753 0 00-.328.002.995.995 0 00-.558.298l-6.07 6.418z"/>
            </svg>
          </button>

          <button :disabled="index === data.calendarItemsActiveLength - 1" v-on:click="$emit('move-down', calendarItemData.calendarItem.order)" :class="{ 'opacity-50 cursor-not-allowed' : index === data.calendarItemsActiveLength - 1 }" class="flex items-center justify-center w-8 h-8 text-sm transition-all duration-150 ease-linear border rounded-md outline-none cursor-pointer text-our-gray-190 bg-our-gray-70 border-our-gray-70 hover:text-our-gray-190 hover:border-our-gray-170 focus:border-our-gray-170 focus:outline-none focus:bg-our-gray-170 focus:text-whiteborder-light-highlight-level-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 20 20">
              <path d="M16.792 11.265a.75.75 0 00-1.09-1.03l-4.955 5.239V2.75a.75.75 0 00-1.5 0v12.726L4.29 10.235a.75.75 0 00-1.09 1.03l6.07 6.418a.995.995 0 00.566.3.753.753 0 00.329-.002.995.995 0 00.558-.298l6.07-6.418z"/>
            </svg>
          </button>

          <button v-on:click="$emit('remove-row', calendarItemData.calendarItem.order)" class="flex items-center justify-center w-8 h-8 text-sm transition-all duration-150 ease-linear border rounded-md outline-none cursor-pointer text-our-gray-190 bg-our-gray-70 border-our-gray-70 hover:text-our-gray-190 hover:border-our-gray-170 focus:border-our-gray-170 focus:outline-none focus:bg-our-gray-170 focus:text-whiteborder-light-highlight-level-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 20 20">
              <path d="M3.897 4.054l.073-.084a.75.75 0 01.976-.073l.084.073L10 8.939l4.97-4.97a.75.75 0 01.976-.072l.084.073a.75.75 0 01.073.976l-.073.084L11.061 10l4.97 4.97a.75.75 0 01.072.976l-.073.084a.75.75 0 01-.976.073l-.084-.073L10 11.061l-4.97 4.97a.75.75 0 01-.976.072l-.084-.073a.75.75 0 01-.073-.976l.073-.084L8.939 10l-4.97-4.97a.75.75 0 01-.072-.976l.073-.084-.073.084z"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useI18n} from "vue-i18n";

export default {
  name: "CalendarRow",
  props: {
    index: Number,
    data: Object
  },
  setup() {
    return useI18n()
  },
  computed: {
    calendarItemData() {
      return this.data
    },
    getTimezone(){
      return ((new Date().getTimezoneOffset()/60)*-1);
    }
  },
  methods: {
    setCalendarItemData(value){
      this.calendarItemData.calendarItem.condition = value;
    }
  }
}
</script>

<style scoped>

</style>
