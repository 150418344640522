<template>
  <div class="pointer-events-none"
       :class="[classes ? classes : 'px-2 text-center rounded-xs'] + ` text-${color} bg-${background} border-${background}` + ' ' + [`tooltip-${position}`] + ' ' + [image ? 'py-2' : ''] + ' ' + [width ? width : '']"
       style="z-index: 15"><!--:style="image ? '' : 'padding-top: 5px; padding-bottom: 4px;'"-->
    <div class="border" :class="`border-${imageBorderColor}`" v-if="image">
      <div class="" :class="[imageClasses ? imageClasses : '']">
        <img :src="image" class="object-cover w-full h-full">
      </div>
    </div>
    <span class="select-none">{{ icon ? icon + text : text }}</span>
    <span :class="`arrow-${position} border-${arrowBackground}`"></span>
  </div>
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    text: String,
    color: String,
    background: String,
    arrowBackground: String,
    classes: String,
    imageClasses: String,
    icon: String,
    position: String,
    image: String,
    imageBorderColor: String,
    width: String
  }
}
</script>

<style scoped>

</style>
