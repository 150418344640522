<template>
  <div class="flex flex-col gap-y-7">

    <form ref="form">
      <transition name="fade" mode="out-in">

        <div v-if="dataLoaded !== false" class="flex flex-col w-5/6 p-5 gap-y-5 border rounded-md border-our-gray-150 bg-our-gray-10">
          <div class="max-w-sm">
            <label for="name" class="block text-base font-medium leading-5 text-our-gray-160">
              {{ t('playlists.form.name') }}
            </label>
            <div class="mt-1.5">
              <input id="name" v-model="playlistData.name" required type="text" class="input-light-level-1">
            </div>
          </div>

          <SwitchGroup as="div" class="flex items-center gap-x-4">
            <SwitchLabel class="block text-base font-medium leading-5 text-our-gray-160">{{
                t('playlists.form.enabled')
              }}
            </SwitchLabel>
            <Switch
                as="button"
                v-model="playlistData.enabled"
                class="relative inline-flex flex-shrink-0 h-6 transition-all duration-200 ease-in-out border-2 border-transparent rounded-full outline-none cursor-pointer w-11 focus:outline-none"
                :class="playlistData.enabled ? 'bg-our-cyan-60' : 'bg-our-gray-200 border-light-high light-level-1'"
                v-slot="{ checked }"
            >
              <span
                  class="inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full"
                  :class="{ 'translate-x-5': checked, 'translate-x-0': !checked }"
              />
            </Switch>
          </SwitchGroup>

          <div>
            <label class="block text-base font-medium leading-5 text-our-gray-160">
              {{ t('playlists.form.group') }}
            </label>
            <Multiselect :placeholder="t('playlists.form.multiselectPlaceholder')" :items="displayGroupsSelected"
                        class="mt-1.5"></Multiselect>
            <!--:items="$store.state.Groups.groups" v-model:selected-items="displayGroupsSelected"-->
          </div>

          <div>
            <label for="name" class="block text-base font-medium leading-5 text-our-gray-160">
              {{ t('playlists.form.priority') }}
            </label>

            <div class="flex flex-col mt-2 gap-y-3">

              <div class="flex flex-row items-center gap-x-2">
                <!--<div>
                  <select v-model="playlistData.priority" :required="playlistData.priority !== null" class="h-10 form-select">
                    <option v-for="playlist in $store.state.Playlists.playlists" :key="playlist.id" :value="playlist.priority">
                      {{ playlist.priority }}
                      ({{
                        playlist.priority
                      }})
                    </option>
                    <option value="add-new">Add new priority</option>
                  </select>
                </div>-->

                <div class="flex flex-row items-center h-10 px-1 gap-x-1.5 rounded-lg bg-our-gray-50">
                  <div class="flex items-center h-8 px-4 text-our-gray-220">
                    <span class="font-semibold">
                      {{ playlistData.priority }}
                    </span>
                  </div>
                  <transition name="fade" mode="out-in">
                    <button
                        class="flex flex-row items-center h-8 gap-x-2 rounded-md cursor-not-allowed button button-dark-gray"
                        disabled v-if="(calendarData.playlist.id === playlistData.id) && dataLoaded">
                      <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="20" height="20"
                          viewBox="0 0 32 32">
                        <path
                            d="M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Zm0,6a1.5,1.5,0,1,1-1.5,1.5A1.5,1.5,0,0,1,16,8Zm4,16.125H12v-2.25h2.875v-5.75H13v-2.25h4.125v8H20Z"></path>
                      </svg>
                      <span class="text-xs font-semibold">{{ t('playlists.form.priorityOf') }}</span>
                    </button>
                  </transition>
                </div>

                <button type="button"
                        class="flex flex-row items-center h-10 px-4 gap-x-2 rounded-lg button button-outline-gray"
                        v-on:click="$store.dispatch('Slideover/toggleSlideover', true), $store.dispatch('Slideover/setSlideoverTitle', t('playlists.form.changePlaylistPriority')), $store.dispatch('Slideover/setSlideoverComponent', 'PlaylistPriorityList')">
                  <span class="text-sm font-medium">{{ t('playlists.form.changePriority') }}</span>
                </button>

              </div>

              <div v-if="playlistData.priority === 'add-new'" class="flex flex-row">

                <input type="number" class="w-auto rounded-r-none form-input">

                <!--<select class="h-10 rounded-l-none rounded-r-md form-select" :class="newPlayable === null ? 'border-our-gray-120 hover:border-our-gray-120' : 'border-our-gray-160'" v-model="newPlayable">
                  <option :value="null" selected disabled>Choose playable</option>
                  <option v-for="playable in $store.state.Playables.playablesList" :value="playable" v-text="playable.name" :key="playable.customid"></option>
                </select>-->

                <button type="button" class="h-10 -ml-px -mr-px text-sm rounded-l-none button button-outline-gray"
                        :class="{ 'text-opacity-50 cursor-not-allowed pointer-events-none border-our-gray-120' : newPlayable === null }"
                        :disabled="newPlayable === null">
                  <svg class="-ml-0.5 mr-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                      viewBox="0 0 20 20">
                    <path
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM6 10a.5.5 0 00.5.5h3v3a.5.5 0 001 0v-3h3a.5.5 0 000-1h-3v-3a.5.5 0 00-1 0v3h-3a.5.5 0 00-.5.5z"/>
                  </svg>
                  <span>{{ t('playlists.form.addPriority') }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div v-else class="w-5/6 gap-y-5 p-5 border border-light-level-1 rounded-level-3">
          <div class="w-full aspect-w-16 aspect-h-2 bg-our-gray-60 rounded-md">
            <Loader class="w-4 h-4 mx-auto realtive top-12"/>
          </div>
        </div>

      </transition>
    </form>

    <transition name="fade" mode="out-in">
      <div v-if="dataLoaded !== false" class="gap-y-5 p-5 border border-light-level-1 rounded-level-3">
        <legend class="block text-base font-medium leading-5 text-our-gray-160">{{ t('playlists.form.playlistOrder') }}</legend>
        <div v-if="playablesOrderListActive.length === 0" class="banner--data-information gap-y-4 bg-white">
          <div class="banner--data-information-inner">
            {{ t('playlists.form.noData') }}
          </div>
        </div>
        <div class="gap-y-3" v-else>
          <template v-if="playablesLoaded">
            <draggable
                      :list="playablesOrderListActive"
                      item-key="id"
                      ghost-class="dragable-ghost"
                      class='grid gap-y-2'
                      @end="moveDragableEnd"
                    >
              <template #item="{ element, index }">
                <div :key="element.id" class="list-group-cursor">
                  <transition-group name="flip-list">
                    <PlaylistRow :playableData="playablesOrderList.find(item => item.customid === element.customid)"
                                 :playablesOrderListActiveLength="playablesOrderListActive.length" :key="element.customid"
                                 :index="index" v-on:move-down="move($event,'down')" v-on:move-up="move($event,'up')"
                                 v-on:remove-playable="removePlayable($event)"></PlaylistRow>
                  </transition-group>
                </div>
              </template>
            </draggable>
            <!-- old without dragable
            <PlaylistRow v-for="(playable, index) in playablesOrderListActive"
                            :playableData="playablesOrderList.find(item => item.customid === playable.customid)"
                            :playablesOrderListActiveLength="playablesOrderListActive.length" :key="playable.customid"
                            :index="index" v-on:move-down="move($event,'down')" v-on:move-up="move($event,'up')"
                            v-on:remove-playable="removePlayable($event)"></PlaylistRow> -->
          </template>
          <div v-else class="gap-y-5 p-5 border border-light-level-1 rounded-level-3">
            <div class="w-full aspect-w-16 aspect-h-2 bg-our-gray-60 rounded-md">
              <Loader class="w-4 h-4 mx-auto realtive top-12"/>
            </div>
          </div>
        </div>

        <div class="p-4 ml-auto text-sm font-semibold text-white rounded-lg bg-our-gray-160 mt-2">
          {{ t('playlists.form.totalDuration') }}:
          {{ $moment.duration(playlistTotalTime, 'ms').format('h [hours] m [minutes] s [seconds] S[ ms]', {trim: true}) }}
        </div>

        <div class="flex flex-row pt-2 ml-auto">
          <button @click="openPlayableSelect()"
                  class="flex flex-row items-center h-10 px-3 gap-x-2 rounded-lg button button-outline-gray">
            <svg class="-ml-0.5 mr-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                viewBox="0 0 20 20">
              <path
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM6 10a.5.5 0 00.5.5h3v3a.5.5 0 001 0v-3h3a.5.5 0 000-1h-3v-3a.5.5 0 00-1 0v3h-3a.5.5 0 00-.5.5z"/>
            </svg>
            <span>{{ t('playlists.form.addPlayable') }}</span>
          </button>
        </div>
      </div>
      <div v-else class="gap-y-5 p-5 border border-light-level-1 rounded-level-3">
        <div class="w-full aspect-w-16 aspect-h-2 bg-our-gray-60 rounded-md">
          <Loader class="w-4 h-4 mx-auto realtive top-12"/>
        </div>
      </div>
    </transition>

    <div class="gap-y-5 p-5 border border-light-level-1 rounded-level-3">

      <!--<div>
        <legend class="block mb-3 text-base font-medium leading-5 text-our-gray-160">{{ t('form.calendar') }}</legend>

        <div class="flex flex-row mt-2 gap-x-2">
          <select v-model="selectedCalendarId"
                  v-on:change="$store.dispatch('Calendars/fetchCalendarItemsForOnePlaylist', selectedCalendarId)"
                  class="inline-flex items-center w-full h-10 max-w-sm py-2 pl-3 pr-10 text-sm font-medium leading-4 tracking-wide transition-all duration-150 ease-linear bg-white border border-transparent rounded-sm outline-none form-select text-our-gray-160 border-our-gray-130 hover:border-our-gray-160 focus:outline-none focus:border-our-gray-190border-light-highlight-level-1">
            <option v-if="selectedCalendarId === null" :value="null" disabled>{{ t('form.noCalendar') }}</option>-->
            <!--<option v-for="calendar in calendarsList" :key="calendar.name" :value="calendar.id">
              <span v-if="calendar.id === 'new'" class="font-medium"><i>{{ calendar.name }}</i></span>
              <span v-else>'{{ calendar.name }}' {{ t('form.forPlaylist') }} '{{ calendar.playlist.name }}'</span>
            </option>-->
          <!--</select>
        </div>
      </div>-->

      <!--<div class="flex flex-col gap-y-4 bg-our-gray-50 rounded-xl">

        <div class="flex flex-wrap justify-between">

          <div class="flex flex-row m-2 gap-x-2" v-if="selectedCalendarId !== null">
            <div
                class="flex flex-row items-center self-start h-10 py-1 pl-4 pr-1 whitespace-nowrap bg-white rounded-lg justify-self-start">
              <span class="text-sm font-medium leading-5 text-our-gray-160">{{ t('form.calendarAssigned') }}:</span>
              <router-link to="/groups/detail/1"
                           class="ml-2 mr-3 text-sm font-medium leading-5 border-b border-our-cyan-50 text-our-cyan-60">
                {{ calendarData.playlist.name }}
              </router-link>
            </div>
            <transition name="fade" mode="out-in">
              <button
                  class="flex flex-row items-center h-10 gap-x-2 rounded-lg cursor-not-allowed button button-dark-gray"
                  disabled v-if="calendarData.playlist.id === playlistData.id">
                <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="20" height="20"
                     viewBox="0 0 32 32">
                  <path
                      d="M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Zm0,6a1.5,1.5,0,1,1-1.5,1.5A1.5,1.5,0,0,1,16,8Zm4,16.125H12v-2.25h2.875v-5.75H13v-2.25h4.125v8H20Z"></path>
                </svg>
                <span class="text-xs font-semibold">{{ t('form.assignedTo') }}</span>
              </button>
              <button class="flex flex-row items-center h-10 px-4 gap-x-2 rounded-lg button button-outline-gray"
                      v-else>
                <span class="text-sm font-medium">{{ t('form.assignedTo') }} (TODO)</span>
              </button>
            </transition>
          </div>

          <div class="flex flex-row m-2 gap-x-2">
            <button v-if="selectedCalendarId !== 'new'"
                    class="flex flex-row items-center h-10 px-3 gap-x-2 rounded-lg button button-outline-gray"
                    v-on:click="addCalendar()">
              <svg class="w-5 h-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M17.5 12C20.5376 12 23 14.4624 23 17.5C23 20.5376 20.5376 23 17.5 23C14.4624 23 12 20.5376 12 17.5C12 14.4624 14.4624 12 17.5 12ZM21 8.5L21.0012 12.0226C19.9907 11.3753 18.7892 11 17.5 11C13.9101 11 11 13.9101 11 17.5C11 18.7892 11.3753 19.9907 12.0226 21.0012L6.25 21C4.45507 21 3 19.5449 3 17.75V8.5H21ZM17.5 14L17.4101 14.0081C17.206 14.0451 17.0451 14.206 17.0081 14.4101L17 14.5V17H14.5L14.4101 17.0081C14.206 17.0451 14.0451 17.206 14.0081 17.4101L14 17.5L14.0081 17.5899C14.0451 17.794 14.206 17.9549 14.4101 17.9919L14.5 18H17V20.5L17.0081 20.5899C17.0451 20.794 17.206 20.9549 17.4101 20.9919L17.5 21L17.5899 20.9919C17.794 20.9549 17.9549 20.794 17.9919 20.5899L18 20.5V18H20.5L20.5899 17.9919C20.794 17.9549 20.9549 17.794 20.9919 17.5899L21 17.5L20.9919 17.4101C20.9549 17.206 20.794 17.0451 20.5899 17.0081L20.5 17H18V14.5L17.9919 14.4101C17.9549 14.206 17.794 14.0451 17.5899 14.0081L17.5 14ZM17.75 3C19.5449 3 21 4.45507 21 6.25V7H3V6.25C3 4.45507 4.45507 3 6.25 3H17.75Z"/>
              </svg>
              <span class="text-sm font-medium">{{ t('form.createNewCalendar') }}</span>
            </button>
            <button v-if="selectedCalendarId !== null && selectedCalendarId !== 'new'"
                    class="flex flex-row items-center h-10 px-3 gap-x-2 rounded-lg button button-outline-gray">
              <svg class="w-5 h-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M21 8.5V12.0218C19.9897 11.375 18.7886 11 17.5 11C13.9101 11 11 13.9101 11 17.5C11 18.7886 11.375 19.9897 12.0218 21H6.25C4.45507 21 3 19.5449 3 17.75V8.5H21Z"/>
                <path d="M17.75 3C19.5449 3 21 4.45507 21 6.25V7H3V6.25C3 4.45507 4.45507 3 6.25 3H17.75Z"/>
                <path
                    d="M23 17.5C23 20.5376 20.5376 23 17.5 23C14.4624 23 12 20.5376 12 17.5C12 14.4624 14.4624 12 17.5 12C20.5376 12 23 14.4624 23 17.5ZM16.3536 15.3536C16.5488 15.1583 16.5488 14.8417 16.3536 14.6464C16.1583 14.4512 15.8417 14.4512 15.6464 14.6464L14.1464 16.1464C13.9512 16.3417 13.9512 16.6583 14.1464 16.8536L15.6464 18.3536C15.8417 18.5488 16.1583 18.5488 16.3536 18.3536C16.5488 18.1583 16.5488 17.8417 16.3536 17.6464L15.7071 17H17.75C18.9926 17 20 18.0074 20 19.25V19.5C20 19.7761 20.2239 20 20.5 20C20.7761 20 21 19.7761 21 19.5V19.25C21 17.4551 19.5449 16 17.75 16H15.7071L16.3536 15.3536Z"/>
              </svg>
              <span class="text-sm font-medium">{{ t('form.duplicateCalendar') }} (TODO)</span>
            </button>
            <button v-if="selectedCalendarId !== null"
                    class="flex flex-row items-center h-10 px-3 gap-x-2 rounded-lg button button-outline-gray"
                    v-on:click="deleteCalendar()">
              <svg class="w-5 h-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M17.5 12C20.5376 12 23 14.4624 23 17.5C23 20.5376 20.5376 23 17.5 23C14.4624 23 12 20.5376 12 17.5C12 14.4624 14.4624 12 17.5 12ZM15.0238 15.0241C14.8285 15.2194 14.8285 15.536 15.0238 15.7312L16.793 17.501L15.0264 19.2675C14.8311 19.4628 14.8311 19.7793 15.0264 19.9746C15.2217 20.1699 15.5382 20.1699 15.7335 19.9746L17.5 18.208L19.2694 19.9768C19.4647 20.1721 19.7812 20.1721 19.9765 19.9768C20.1718 19.7816 20.1718 19.465 19.9765 19.2697L18.208 17.501L19.9793 15.7313C20.1745 15.536 20.1745 15.2194 19.9793 15.0242C19.784 14.8289 19.4674 14.8289 19.2722 15.0242L17.501 16.794L15.7309 15.0241C15.5356 14.8289 15.2191 14.8289 15.0238 15.0241ZM21 8.5L21.0012 12.0226C19.9907 11.3753 18.7892 11 17.5 11C13.9101 11 11 13.9101 11 17.5C11 18.7892 11.3753 19.9907 12.0226 21.0012L6.25 21C4.45507 21 3 19.5449 3 17.75V8.5H21ZM17.75 3C19.5449 3 21 4.45507 21 6.25V7H3V6.25C3 4.45507 4.45507 3 6.25 3H17.75Z"/>
              </svg>
              <span class="text-sm font-medium">{{ t('form.deleteCalendar') }}</span>
            </button>
          </div>
        </div>

      </div>-->

      <!--<div class="max-w-sm" v-if="selectedCalendarId !== null">
        <label for="name" class="block text-base font-medium leading-5 text-our-gray-160">
          {{ t('form.calendarName') }} -
        </label>
        <div class="mt-1.5">
          <input v-model="calendarData.name" type="text" class="input-light-level-1">
        </div>
      </div>-->

      <div class="pt-4" v-if="selectedCalendarId !== null">
        <legend class="block mb-3 text-base font-medium leading-5 text-our-gray-160">{{
            t('playlists.form.calendarConditions')
          }}
        </legend>
        <Calendar v-if="dataLoaded && calendarData.id !== null" :data="calendarData"></Calendar>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "@/components/Multiselect"
import PlaylistRow from "@/components/PlaylistRow"
import Calendar from "@/components/Calendar"
import Loader from "@/components/Loader"
import draggable from 'vuedraggable'
import {useI18n} from "vue-i18n"

import {Switch, SwitchGroup, SwitchLabel} from '@headlessui/vue'

function initialData() {
  return {
    dataLoaded: false,
    playablesLoaded: true,
    playlistData: {
      id: null,
      name: ' ',
      enabled: null,
      playables: [{
        name: null,
        p1080: null,
        p2160: null,
      }],
      displaygroups: [{
        id: null,
        name: null,
        description: null,
        orientation: null,
      }],
      priority: null
    },
    newPlayable: {
      type: null,
      playable: null,
      id: null,
      name: null,
      duration: null
    },
    playablesOrderList: [],
    playableOrderListWaitingForDelete: [], // ids only
    calendarData: {
      playlist: {
        id: null
      },
      calendaritemdetails: []
    },
    selectedCalendarId: null,
    displayGroupsSelected: null
  }
}

export default {
  name: "Playlist",
  components: {
    Calendar,
    Multiselect,
    PlaylistRow,
    SwitchGroup,
    Switch,
    SwitchLabel,
    Loader,
    draggable,
  },
  setup() {
    /*const switchValue = ref(playlistData.enabled)*/
    return useI18n()
  },
  data() {
    return initialData()
  },
  computed: {
    playlistTotalTime() {
      let totalTimeMs = 0;
      this.playablesOrderListActive.forEach(item => {
        totalTimeMs += parseInt(item.duration ?? 0) * parseInt(item.repeat);
      });

      //console.log("COMPUTED total time: ", totalTimeMs);
      return totalTimeMs;
    },
    /*calendarsList() {
      return this.$store.state.Calendars.calendars.filter(calendar => calendar.id !== 'delete')
    },
    sortedPlayablesOrderList() {

      let array = this.$helpers.purify(this.playablesOrderList)

      return array.sort((a, b) => {
        return a.order - b.order;
      })
    },*/
    playablesOrderListActive() {

      if (this.playablesOrderList.length > 0) {

        /*let array = this.$helpers.purify(this.playablesOrderList)*/
        let array = this.playablesOrderList

        return array.filter(playable => {

          if (playable.customid === null) {
            /*store.dispatch('Notifications/send', {
              variant: 'error',
              message1: 'customid undefined',
              message2: 'Playlist.vue - playablesOrderListActive()',
              time: new Date().toISOString(),
              duration: false,
              overlay: false
            })*/
          } else if (playable.customid.toString().includes('delete')) {
            return false
          }
          /*else if (typeof playable.customid === 'string') {
            playable.customid = parseInt(this.$helpers.replaceStringMultiple(playable.customid, ['-new'], ['']))
            return true
          }*/
          else {
            return true
          }
          /*return !playable.customid.toString().includes('delete')*/
        })
      } else return []
    },
    /*playlistAndCalendarData() {
      return `${ this.playablesOrderList }|${ this.calendarData.calendaritemdetails }` /!*${ this.playlistData }|*!/
    },*/
  },
  watch: {
    '$store.state.Playlists.playlistData': {
      immediate: false,
      deep: true,
      handler(data) {
        if (!this.$helpers.isEqual(data, this.playlistData)) {

          if (Object.keys(data).length > 0) {

            this.playlistData = this.$helpers.purify(data)

            document.title = data.name + ' | ' + document.title.split('|')[1]
            this.$store.dispatch('setDetailName', data.name)
          }
        }
      }
    },
    '$store.state.Groups.groups': {
      immediate: false,
      deep: true,
      handler(data) {
        this.displayGroupsSelected = data
      }
    },
    '$store.state.Playlists.playlistOrderData': {
      immediate: false,
      deep: true,
      handler(data) {

        if (!this.$helpers.isEqual(data, this.playablesOrderList)) {

          if (Object.keys(data).length > 0) {


            this.playablesOrderList = this.$helpers.purify(data).sort((a, b) => {
              return a.order - b.order
            })

            /*this.playablesOrderList = this.playablesOrderList.sort((a, b) => {
              return a.order - b.order
            })*/

          }else{ // add new from detail - clear array
            this.playablesOrderList = []
          }
        }
      }
    },
    '$store.state.Calendars.calendarData': {
      immediate: false,
      deep: true,
      handler(data) {

        if (data && !this.$helpers.isEqual(data, this.calendarData)) {

          this.calendarData = this.$helpers.purify(data)

          this.selectedCalendarId = this.calendarData.id

          if (data.calendaritemdetails && Object.keys(data.calendaritemdetails).length > 0) {
            this.calendarData.calendaritemdetails = this.calendarData.calendaritemdetails.sort((a, b) => {
              return a.order - b.order
            })
          }
        }
      }
    },
    /*playlistAndCalendarData: {
      immediate: false,
      deep: true,
      handler() {

        if (this.dataLoaded) {
          this.$emit('save-changes', {
            playlistsData: this.$helpers.purify(this.playlistData),
            calendarData: this.$helpers.purify(this.calendarData),
            playablesOrderList: this.$helpers.purify(this.playablesOrderList),
            type: 'playlist&calendar',
            valid: true
          })
        }

        /!* TODO data validation *!/
        /!*if (this.dataLoaded) {

          /!* Outgoing data controller *!/
          if (this.$refs.form.reportValidity()) {

            let sendData = this.$helpers.purify(data)

            sendData.displaygroups = this.displayGroupsSelected

            let filteredData = this.$helpers.objectFalseyAndEmptyFilter(sendData)
            this.$emit('save-changes', { ...filteredData, type: 'display', valid: true })
          }
          else if (!this.$refs.form.reportValidity()) {
            this.$emit('save-changes', { valid: false })
          }
        }*!/
      }
    },*/
    playlistData: {
      immediate: false,
      deep: true,
      handler() {
        /*if (this.initialDataLoaded) {*/
        if (this.$refs.form.reportValidity()) {

          /*this.displayGroupsSelected = this.playlistData.displaygroups
          this.initialDataLoaded = true
*/
          if (this.dataLoaded) {
            this.sendToParent()
          }
        } else if (!this.$refs.form.reportValidity()) {
          this.$emit('save-changes', {valid: false})
        }
      }
    },
    playablesOrderList: {
      immediate: false,
      deep: true,
      handler(newValue, oldValue) {
        if (this.dataLoaded) {
          this.sendToParent(false)
        }
      }
    },
    calendarData: {
      immediate: false,
      deep: true,
      handler() {
        if (this.dataLoaded) {
          this.sendToParent()
        }
      }
    },
    dataLoaded: {
      immediate: false,
      deep: true,
      handler(data) {
        //console.log('dataLoaded', data)
      }
    },
    displayGroupsSelected: {
      immediate: false,
      deep: true,
      handler() {
        if (this.dataLoaded) {
          this.sendToParent()
        }
        /* else {
           this.dataLoaded = true
         }*/
      }
    }
  },
  methods: {
    idForPriority(order) {
      let filter = this.playablesOrderList.filter(i => i.order == order)
      return this.playablesOrderList.indexOf( filter[0] )
    },
    indexPlayableOrderById(id){
      let filter = this.playablesOrderList.filter(i => i.id == id)
      return this.playablesOrderList.indexOf( filter[0] )
    },
    indexPlayableOrderByCustomId(customId){
      let filter = this.playablesOrderList.filter(i => i.customid == customId)
      return this.playablesOrderList.indexOf( filter[0] )
    },
    moveDragableOnce(order, id, direction, customId){

      let thisId
      let movingId = this.idForPriority(order) - direction
      id ? thisId = this.indexPlayableOrderById(id) : thisId = this.indexPlayableOrderByCustomId(customId)
      let tmp = this.playablesOrderList[movingId].order

      this.playablesOrderList[movingId].order = this.playablesOrderList[thisId].order
      this.playablesOrderList[thisId].order = tmp
    },
    moveDragableEnd(e){
      let { oldIndex, newIndex } = e;

      if((newIndex - oldIndex)<0){
        for(let i = newIndex; i < oldIndex; i++){
          //console.log("DOWN", this.playablesOrderList[i])
          this.moveDragableOnce(this.playablesOrderList[i].order, this.playablesOrderList[i].id, -1, this.playablesOrderList[i].customid)
        }
      }else{
        for(let i = newIndex; i > oldIndex; i--){
          //console.log("UP-------------", this.playablesOrderList[i])
          this.moveDragableOnce(this.playablesOrderList[i].order, this.playablesOrderList[i].id, +1, this.playablesOrderList[i].customid)
        }
      }
      this.playablesOrderList.sort((a, b) => a.order - b.order)
      this.$store.dispatch('Playlists/updatePlayableOrderFromSlideOver', this.playablesOrderList);
    },
    sendToParent(emptyToRemoveArray = true) {
      if (this.dataLoaded) {

        let playlistDataWithSelectedGroups = this.$helpers.purify(this.playlistData)

        playlistDataWithSelectedGroups.displaygroups = this.$helpers.purify(this.displayGroupsSelected.filter(group => group.selected))

        this.$emit('save-changes', {
          playlistData: playlistDataWithSelectedGroups,
          calendarData: this.$helpers.purify(this.calendarData),
          playablesOrderList: this.$helpers.purify(this.playablesOrderList),
          playablesOrderIdsDelete: this.playableOrderListWaitingForDelete,
          _type: 'playlist&calendar',
          valid: true
        })

        if (emptyToRemoveArray) {
          this.playableOrderListWaitingForDelete = [];
        }
      }
    },
    move(inputPlayable, direction) {

      // for future use or check....

      let allOrdersArr = [];
      // list order validation
      for (let i = 0; i < this.playablesOrderList.length; i++) {
        if (allOrdersArr.includes(this.playablesOrderList[i].order)) {
          this.playablesOrderList[i].order++;
        }
        allOrdersArr.push(this.playablesOrderList[i].order);
      }

      // end check

      for (let i = 0; i < this.playablesOrderList.length; i++) {

        let boolId = false;
        if(inputPlayable.customid){
          inputPlayable.customid === this.playablesOrderList[i].customid ? boolId = true : ''
        }

        if(inputPlayable.id){
          inputPlayable.id === this.playablesOrderList[i].id ? boolId = true : ''
        }

        if (boolId) {
          if (this.playablesOrderList[i].order > 1 && direction === 'up') {
            this.moveDragableOnce(this.playablesOrderList[i].order, this.playablesOrderList[i].id, +1, this.playablesOrderList[i].customid)
          }
          if (this.playablesOrderList[i].order > 0 && direction === 'down') {
            this.moveDragableOnce(this.playablesOrderList[i].order, this.playablesOrderList[i].id, -1, this.playablesOrderList[i].customid)
          }
        }
        boolId = false
      }

      this.playablesOrderList.sort((a, b) => a.order - b.order)
      this.$store.dispatch('Playlists/updatePlayableOrderFromSlideOver', this.playablesOrderList);
    },
    removePlayable(activePlayable) {
      let deleteAtIndex = null;
      for (let i = 0; i < this.playablesOrderList.length; i++) {
        if (this.playablesOrderList[i].id === activePlayable.id && this.playablesOrderList[i].order === activePlayable.order) {
          deleteAtIndex = i;
          this.playableOrderListWaitingForDelete.push(activePlayable.id);
        }
      }
      this.playablesOrderList.splice(deleteAtIndex, 1);

      for (let i = 0; i < this.playablesOrderList.length; i++) {
        this.playablesOrderList[i].order = i + 1;
      }

      this.$store.dispatch('Playlists/updatePlayableOrderFromSlideOver', this.playablesOrderList);
    },
    /*addCalendar() {
      console.log(this.$store.state.Playlists)
      this.$store.dispatch('Calendars/setCalendarData', {
        name: 'new calendar',
        id: 'new',
        playlist: {
          id: this.$store.state.Playlists.playlistData.id,
          name: this.$store.state.Playlists.playlistData.name
        },
        calendaritemdetails: []
      }).then(() => {
        console.log(this.selectedCalendarId, this.$store.state.Playlists.playlistData.id)
        this.selectedCalendarId = this.$store.state.Calendars.calendarData.id
      })
    },*/
    deleteCalendar() {

      this.$store.dispatch('Calendars/setOneCalendarInCalendarsList', {
        data: {id: 'delete'},
        calendarID: this.calendarData.id
      }).then(() => {
        this.$store.dispatch('Calendars/setCalendarData', {
          id: null,
          playlist: {
            id: null
          },
          calendaritemdetails: []
        })
      })
    },
    openPlayableSelect() {
      this.$store.dispatch('Slideover/toggleSlideover', true)
      this.$store.dispatch('Slideover/setSlideoverTitle', 'Playable select')
      this.$store.dispatch('Slideover/setSlideoverComponent', 'PlayableSelect')
      this.$store.dispatch('Slideover/setSlideoverData', this.playablesOrderList)
    },
    fetchFullPlayables() {
      let playableIds = [];
      let playableIdsIn = [];
      for (const playableOrder of this.playablesOrderList) {
        playableIds.push(playableOrder.playable.id);
        playableIdsIn.push('id_in='+playableOrder.playable.id);
      }

      if (playableIds.length > 0) {
        let _this = this;
        this.$axios.get('/playables?'+playableIdsIn.join('&')).then(res => {
          if (res.status === 200) {
            for (const actualPlayable of res.data) {
              for (let i = 0; i < _this.playablesOrderList.length; i++) {
                if (_this.playablesOrderList[i].playable.id === actualPlayable.id) {
                  _this.playablesOrderList[i].playable = actualPlayable;
                }
              }
            }
          }
          _this.playablesLoaded = true;
        });
      }
    }
  },
  created() {
    let promises = []

    promises.push(this.$store.dispatch('Playlists/fetchPlaylist', this.$route.params.id))
    promises.push(this.$store.dispatch('Playlists/fetchHighestPlayableOrderId', this.$route.params.id))
    promises.push(this.$store.dispatch('Playlists/fetchPlayableOrderForOnePlaylist', this.$route.params.id))
    promises.push(this.$store.dispatch('Playlists/fetchPlayableOrderLastSavedForOnePlaylist', this.$route.params.id))
    promises.push(this.$store.dispatch('Playlists/fetchAllPlaylists'))
    promises.push(this.$store.dispatch('Playables/fetchAllPlayables'))
    promises.push(this.$store.dispatch('Calendars/fetchCalendarItemsForOnePlaylist', this.$route.params.id))
    promises.push(this.$store.dispatch('Calendars/fetchAllCalendars'))
    promises.push(this.$store.dispatch('Groups/fetchAllGroups'))

    Promise.all(promises).then(() => {

      const displayGroups = this.$helpers.purify(this.$store.state.Groups.groups)

      this.displayGroupsSelected = displayGroups.map(group => {
        if (this.playlistData.displaygroups.some(selectedGroup => selectedGroup.id === group.id)) {
          group["selected"] = true
        } else {
          group["selected"] = false
        }
        return group
      })

      this.$nextTick(() => {
        this.dataLoaded = true
      })

      //this.fetchFullPlayables();

    }).catch(() => {
      this.$router.push('/404')
    })
  },
  unmounted() {
    Object.assign(this.$data, initialData())
  }
}
</script>

<style scoped>

.dragable-ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group-cursor {
  cursor: move;
}

</style>
